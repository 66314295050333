import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { translations } from 'translations';

import { Icon } from '../Icon/Icon';

type MediaActionButtonsProps = {
  onEdit?: () => void;
  onDelete?: () => void;
};

const MediaActionButtons = ({ onEdit, onDelete }: MediaActionButtonsProps) => {
  if (!onEdit && !onDelete) {
    return null;
  }
  return (
    <Stack
      sx={(theme) => ({
        flexDirection: 'row',
        position: 'absolute',
        right: theme.spacing('sm'),
        top: theme.spacing('sm'),
        gap: theme.spacing('xs'),
      })}
    >
      {onDelete && (
        <Button
          size="small"
          variant="text"
          aria-label={translations.delete}
          onClick={onDelete}
          sx={(theme) => ({
            height: '2rem',
            width: '2rem',
            padding: '0 !important',
            minWidth: 'unset',
            border: `1px solid ${theme.colors.border.actionTertiaryDefault}`,
          })}
        >
          <Icon type="trash" color="brandBase" size={20} />
        </Button>
      )}
      {onEdit && (
        <Button
          size="small"
          variant="text"
          aria-label={translations.edit}
          onClick={onEdit}
          sx={(theme) => ({
            height: '2rem',
            width: '2rem',
            padding: '0 !important',
            minWidth: 'unset',
            border: `1px solid ${theme.colors.border.actionTertiaryDefault}`,
          })}
        >
          <Icon type="pencil" color="brandBase" size={20} />
        </Button>
      )}
    </Stack>
  );
};
export { MediaActionButtons };
