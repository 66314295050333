import {
  InnovationFocusAreaArticle,
  InnovationFocusAreaArticleCreateBody,
} from 'api/cms-innovation/types';

import { FormValues } from './FocusAreaForm';

const transformFormValuesToCreateOrEditPayload = (data: FormValues) => {
  const { hero, teamMembers, ...rest } = data;

  const transformed: InnovationFocusAreaArticleCreateBody = {
    ...rest,
    ...(hero.image && {
      heroAltText: hero.image.altText,
      heroImageUrl: hero.image.src,
      heroImageWidth: hero.image.width,
      heroImageHeight: hero.image.height,
    }),
    ...(hero.embedVideo && {
      heroVideoUrl: hero.embedVideo.url,
    }),
    teamMembers: {
      internalPersons: teamMembers.internalPersons.map((person) => ({
        name: person.name || '',
        oid: person.id,
      })),
    },
  };

  return transformed;
};

export const transformGetResponseToFormValues = (
  data: InnovationFocusAreaArticle
) => {
  const internalteamMembers = data.teamMembers?.internalPersons || [];
  const transformed: FormValues = {
    title: data.title,
    preamble: data.preamble,
    teamMembers: {
      internalPersons: internalteamMembers.map((person) => ({
        name: person.name || '',
        id: person.oid,
      })),
    },
    hero: {
      embedVideo: data.heroVideoUrl ? { url: data.heroVideoUrl } : undefined,
      image: data.heroImageUrl
        ? {
            src: data.heroImageUrl,
            altText: data.heroAltText,
            height: data.heroImageHeight || 0,
            width: data.heroImageWidth || 0,
          }
        : undefined,
    },
    note: data.note || '',
    body: data.body || '',
    contactDetails: data.contactDetails || '',
    displayOnHome: data.displayOnHome,
  };

  return transformed;
};

export { transformFormValuesToCreateOrEditPayload };
