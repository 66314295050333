import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { InnovationSimpleFocusArea } from 'api/cms-innovation/types';
import { Chip } from 'componentsNew';
import { useBreakpoints } from 'hooks';

type TitlePreambleAndFocusAreasProps = {
  title: string;
  preamble?: string;
  focusAreas?: InnovationSimpleFocusArea[];
  color?: string;
};

const TitlePreambleAndFocusAreas = ({
  title,
  preamble,
  focusAreas,
  color,
}: TitlePreambleAndFocusAreasProps) => {
  const { isMobile } = useBreakpoints();

  return (
    <>
      <Typography
        variant={isMobile ? 'h1' : 'display4'}
        component="h1"
        sx={(theme) => ({
          color: color || theme.colors.text.secondary,
          fontWeight: theme.typography.fontWeightBold,
          textShadow: theme.elevation.sm,
        })}
      >
        {title}
      </Typography>
      {preamble && (
        <Typography
          variant={isMobile ? 'body1' : 'h4'}
          component="p"
          sx={(theme) => ({
            color: color || theme.colors.text.secondary,
            fontWeight: 'unset',
          })}
        >
          {preamble}
        </Typography>
      )}
      {focusAreas && focusAreas.length > 0 && (
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            gap: theme.spacing('xxs'),
            flexWrap: 'wrap',
            maxWidth: '100%',
          })}
        >
          {focusAreas.map((focusArea, index) => (
            <Chip
              key={`focusAreas-${index}-${focusArea.id}`}
              size="small"
              color="default"
              variant="outlined"
              label={focusArea.title}
              sx={{ maxWidth: '100%' }}
            />
          ))}
        </Stack>
      )}
    </>
  );
};

export { TitlePreambleAndFocusAreas };
