import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { InnovationListingItemTypeEnum } from 'api/find/types';
import { PlaySymbol } from 'componentsNew';
import { useMemo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import type { ImageSize } from 'utils/cms/articleImage';
import * as articleImageUtils from 'utils/cms/articleImage';

import { ArticleImageFallback } from './ArticleImageFallback';

type ArticleImageProps = {
  to?: string;
  href?: string;
  src?: string;
  sx?: SxProps<Theme>;
  fetchSize?: ImageSize;
  isPortrait?: boolean;
  videoPlaySymbolSize?: number;
  altText?: string;
  type: InnovationListingItemTypeEnum;
  onClick?: () => void;
};

const ArticleImage = ({
  to,
  href,
  src,
  sx,
  fetchSize,
  isPortrait = false,
  videoPlaySymbolSize,
  altText,
  type,
  onClick,
}: ArticleImageProps) => {
  const isVideoSrc = src ? articleImageUtils.isVideoImageUrl(src) : false;

  const imageSrc = useMemo(() => {
    const imageSrcOptions = fetchSize
      ? { isPortrait, imageSize: fetchSize }
      : undefined;
    return src
      ? articleImageUtils.getFormattedArticleImageSrc(src, imageSrcOptions)
      : null;
  }, [fetchSize, isPortrait, src]);

  return (
    <Link
      {...(to ? { to: to, component: ReactRouterLink } : { href: href })}
      onClick={onClick}
      sx={[
        (theme) => ({
          backgroundColor: theme.colors.surface.informative,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 0,
          flexShrink: 0,
          aspectRatio: 8 / 5,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {imageSrc ? (
        <>
          <Box
            component="img"
            sx={(theme) => ({
              display: 'block',
              objectFit: 'cover',
              ...(isPortrait
                ? {
                    height: '85%',
                    boxShadow: theme.shadows[5],
                  }
                : {
                    height: '100%',
                    width: '100%',
                  }),
            })}
            alt={altText}
            title={altText}
            src={imageSrc}
          />
          {isVideoSrc && (
            <PlaySymbol
              size={videoPlaySymbolSize}
              sx={{ position: 'absolute' }}
            />
          )}
        </>
      ) : (
        <ArticleImageFallback type={type} />
      )}
    </Link>
  );
};

export { ArticleImage };
