import {
  InnovationCaseStudyArticle,
  InnovationCaseStudyArticleCreateBody,
} from 'api/cms-innovation/types';

import { FormValues } from './CaseStudyForm';

const transformFormValuesToCreateOrEditPayload = (data: FormValues) => {
  const { hero, ...rest } = data;

  const transformed: InnovationCaseStudyArticleCreateBody = {
    ...rest,
    ...(hero.image && {
      heroAltText: hero.image.altText,
      heroImageUrl: hero.image.src,
      heroImageWidth: hero.image.width,
      heroImageHeight: hero.image.height,
    }),
    ...(hero.embedVideo && {
      heroVideoUrl: hero.embedVideo.url,
    }),
  };

  return transformed;
};

export const transformGetResponseToFormValues = (
  data: InnovationCaseStudyArticle
) => {
  const transformed: FormValues = {
    title: data.title,
    preamble: data.preamble,
    focusAreas: data.focusAreas,
    hero: {
      embedVideo: data.heroVideoUrl ? { url: data.heroVideoUrl } : undefined,
      image: data.heroImageUrl
        ? {
            src: data.heroImageUrl,
            altText: data.heroAltText,
            height: data.heroImageHeight || 0,
            width: data.heroImageWidth || 0,
          }
        : undefined,
    },
    note: data.note || '',
    body: data.body || '',
    contactDetails: data.contactDetails || '',
    resources: data.resources || {},
    displayOnHome: data.displayOnHome,
  };

  return transformed;
};

export { transformFormValuesToCreateOrEditPayload };
