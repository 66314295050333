import axios from 'axios';
import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
import { msalInstance } from 'utils/auth';

const { find } = settings.api;

const sharepointTenantUrls = [
  'https://assaabloy.sharepoint.com',
  'https://assaabloyeur.sharepoint.com',
  'https://assaabloyapc.sharepoint.com',
  'https://assaabloyjpn.sharepoint.com',
  'https://assaabloyaus.sharepoint.com',
  'https://assaabloyind.sharepoint.com',
  'https://assaabloycan.sharepoint.com',
  'https://assaabloygbr.sharepoint.com',
  'https://assaabloykor.sharepoint.com',
  'https://assaabloyfra.sharepoint.com',
  'https://assaabloyare.sharepoint.com',
  'https://assaabloyzaf.sharepoint.com',
  'https://assaabloydeu.sharepoint.com',
  'https://assaabloyche.sharepoint.com',
  'https://assaabloynor.sharepoint.com',
];

export const getSearchResults = (query) =>
  createRequest({
    method: 'get',
    url: `${find}/search${query}`,
    errorMessages: {
      '*': 'Failed to get search results, please contact support for assistance.',
    },
  });

export const getAutoSuggestions = (query) =>
  createRequest({
    method: 'get',
    url: `${find}/suggestions${query}`,
    errorMessages: {
      '*': 'Failed to get search suggestions, please contact support for assistance.',
    },
  });

export const getRecentSearches = () =>
  createRequest({
    method: 'get',
    url: `${find}/recentsearches`,
    errorMessages: {
      '*': 'Failed to get recent searches, please contact support for assistance.',
    },
  });

export const addRecentSearch = (query) =>
  createRequest({
    method: 'post',
    url: `${find}/recentsearches${query}`,
    errorMessages: {
      '*': 'Failed to add recent searches, please contact support for assistance.',
    },
  });

export const getInnovationLearningItems = (query) =>
  createRequest({
    method: 'get',
    url: `${find}/learning/items${query}`,
    errorMessages: {
      '*': 'Failed to get innovation learning items, please contact support for assistance.',
    },
  });

export const getInnovationProcessAndGuidesItems = (query) =>
  createRequest({
    method: 'get',
    url: `${find}/process-and-guides/items${query}`,
    errorMessages: {
      '*': 'Failed to get innovation process and guides items, please contact support for assistance.',
    },
  });

export const getInnovationItems = (query) =>
  createRequest({
    method: 'get',
    url: `${find}/innovation/items${query}`,
    errorMessages: {
      '*': 'Failed to get innovation items, please contact support for assistance.',
    },
  });

export const getInnovationToolsAndTemplatesItems = (query) =>
  createRequest({
    method: 'get',
    url: `${find}/tools-and-templates/items${query}`,
    errorMessages: {
      '*': 'Failed to get tools and templates items, please contact support for assistance.',
    },
  });

export const getSharepointSearchResults = async (
  searchString,
  from = 0,
  size = 15
) => {
  const accessTokenRequest = {
    scopes: ['https://graph.microsoft.com/.default'],
  };
  const silentResponse = await msalInstance.acquireTokenSilent(
    accessTokenRequest
  );

  const headers = {
    Authorization: `Bearer ${silentResponse.accessToken}`,
  };

  const tenantUrls = sharepointTenantUrls.join(' path:');

  const response = await axios.post(
    'https://graph.microsoft.com/v1.0/search/query',
    {
      requests: [
        {
          entityTypes: ['driveItem', 'listItem'],
          query: {
            queryString: `${searchString} path:${tenantUrls}`,
          },
          fields: [
            'title',
            'webUrl',
            'fileName',
            'siteName',
            'lastModifiedBy',
            'HitHighlightedSummary',
            'isDocument',
            'id',
            'fileType',
          ],
          from: from,
          size: size,
        },
      ],
    },
    {
      headers,
    }
  );

  const responseData = response.data.value[0].hitsContainers[0].hits;

  if (!responseData) return { results: [], total: 0 };

  const results = responseData.map((result) => {
    const hasListItems = result?.resource?.listItem;
    const isDocument =
      result?.resource?.fields?.isDocument === true ||
      result?.resource?.listItem?.fields?.isDocument === true;

    const isAspx =
      result?.resource?.fields?.fileType === 'aspx' ||
      result?.resource?.listItem?.fields?.fileType === 'aspx';

    const isPdf =
      result?.resource?.fields?.fileType === 'pdf' ||
      result?.resource?.listItem?.fields?.fileType === 'pdf';

    const isFile = isDocument && !isAspx;

    if (hasListItems) {
      return {
        id: result?.resource?.listItem?.id,
        type: isFile ? 'file' : 'site',
        title: result?.resource?.listItem?.fields?.title,
        url: getUrl(result, isFile, hasListItems, isPdf),
        fileName: isFile ? result?.resource?.listItem?.fields?.fileName : '',
        siteName: result?.resource?.listItem?.fields?.siteName,
        summary: stripHTML(result?.summary),
        fileType: result?.resource?.listItem?.fields?.fileType,
        owner: reverseOwner(
          result?.resource?.lastModifiedBy?.user?.displayName
        ),
      };
    }
    return {
      id: result?.resource?.id,
      type: isFile ? 'file' : 'site',
      title: result?.resource?.fields?.title,
      url: getUrl(result, isFile, hasListItems, isPdf),
      fileName: isFile ? result?.resource?.fields?.fileName : '',
      siteName: result?.resource?.fields?.siteName,
      summary: stripHTML(result?.summary),
      fileType: result?.resource?.fields?.fileType,
      owner: reverseOwner(result?.resource?.lastModifiedBy?.user?.displayName),
    };
  });
  return {
    results,
    total: response.data.value[0].hitsContainers[0].total,
  };
};

const reverseOwner = (owner) => {
  return owner.split(',').reverse().join(' ');
};

const stripHTML = (summary) => {
  return summary.replace(/<\/?[^>]+(>|$)/g, '');
};

const getUrl = (result, isFile, hasListItems, isPdf) => {
  if (hasListItems && isFile && !isPdf) {
    return `${result.resource.listItem.fields.siteName}/_layouts/15/Doc.aspx?sourcedoc={${result.resource.listItem.id}}&file=${result.resource.listItem.fields.fileName}&action=default&mobileredirect=true`;
  }
  if (!hasListItems && isFile && !isPdf) {
    return `${result.resource.fields.siteName}/_layouts/15/Doc.aspx?sourcedoc={${result.resource.id}}&file=${result.resource.fields.fileName}&action=default&mobileredirect=true`;
  }
  return result.resource.webUrl;
};

export { getInnovationItemsQueryString } from './transformers';
