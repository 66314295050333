import Button, { ButtonProps } from '@mui/material/Button';
import { forwardRef } from 'react';

import { Icon, IconColor, IconSize, IconType } from './Icon/Icon';

type IconButtonProps = {
  type: IconType;
  size?: IconSize;
  color?: IconColor;
} & Pick<
  ButtonProps,
  | 'id'
  | 'sx'
  | 'role'
  | 'onClick'
  | 'variant'
  | 'disabled'
  | 'tabIndex'
  | 'component'
  | 'aria-label'
  | 'aria-controls'
  | 'aria-labelledby'
>;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      sx,
      type,
      size = 20,
      color = 'brandBase',
      variant = 'text',
      ...buttonProps
    },
    ref
  ) => {
    return (
      <Button
        size="small"
        variant={variant}
        ref={ref}
        {...buttonProps}
        sx={[
          () => ({
            height: '2rem',
            width: '2rem',
            padding: '0 !important',
            minWidth: 'unset',
            flexShrink: 0,
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Icon type={type} color={color} size={size} />
      </Button>
    );
  }
);

export { IconButton };
