import './AppSwitchItem.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// On <Link> we use 'target={'_self'}' to trigger a refresh otherwise the routing will sometimes throw a 404, async problem maybe?

const AppSwitchItem = ({ item }) => {
  const classNames = classnames('app-switch-item', {});
  const linkUrl =
    item.id !== 'avenue-home-page' && item.id !== 'product-innovation'
      ? `${item.homePath}/${item.id}`
      : item.homePath;

  return (
    <Link className={classNames} to={linkUrl}>
      <span className="app-switch-item__text">{item.name}</span>
    </Link>
  );
};

AppSwitchItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    homePath: PropTypes.string,
    icon: PropTypes.object,
  }),
  clickHandler: PropTypes.func,
};

export default AppSwitchItem;
