export enum AvenueRouteEnum {
  AlertCreate = '/alert/create',
  GroupLibrary = '/group-library',
  HeroBanner = '/hero-banner',
  HeroBannerCreate = '/hero-banner/create',
  HeroBannerEdit = '/hero-banner/edit',
  Home = '/',
  Jobs = '/jobs',
  Library = '/library',
  NewsletterBanner = '/newsletter-banner',
  NewsletterBannerCreate = '/newsletter-banner/create',
  NewsletterBannerEdit = '/newsletter-banner/edit',
  NotFound = '/404',
  ProductOfTheWeekGallery = '/product-of-the-week/gallery',
  Profile = '/profile',
  ProfilePreferences = '/profile/preferences',
  ProfileSubscriptions = '/profile/subscriptions',
  ProfileManageAlerts = '/profile/manage-alerts',
  ProfileManageBanners = '/profile/manage-banners',
  ProfileManageFeed = '/profile/manage-feed',
  ProfileManageLibrary = '/profile/manage-library',
  ProfileManageInsightsDatabase = '/profile/manage-insights-database',
}

export enum InnovationRouteEnum {
  CaseStudy = '/product-innovation/learning/case-study',
  CaseStudyCreate = '/product-innovation/learning/case-study/create',
  CaseStudyEdit = '/product-innovation/learning/case-study/edit',
  Create = '/product-innovation/create',
  FocusArea = '/product-innovation/focus-area',
  FocusAreaCreate = '/product-innovation/focus-area/create',
  FocusAreaEdit = '/product-innovation/focus-area/edit',
  Home = '/product-innovation',
  InsightsDatabase = '/product-innovation/insights-database',
  Learning = '/product-innovation/learning',
  ProcessAndGuides = '/product-innovation/process-and-guides',
  ProcessAndGuidesCreate = '/product-innovation/process-and-guides/create',
  ProcessAndGuidesEdit = '/product-innovation/process-and-guides/edit',
  Strategy = '/product-innovation/strategy',
  StrategyCreate = '/product-innovation/strategy/create',
  StrategyEdit = '/product-innovation/strategy/edit',
  ToolsAndTemplates = '/product-innovation/tools-and-templates',
  ToolsAndTemplatesCreate = '/product-innovation/tools-and-templates/create',
  ToolsAndTemplatesEdit = '/product-innovation/tools-and-templates/edit',
  Training = '/product-innovation/learning/training',
  TrainingCreate = '/product-innovation/learning/training/create',
  TrainingEdit = '/product-innovation/learning/training/edit',
  Webinar = '/product-innovation/learning/webinar',
  WebinarCreate = '/product-innovation/learning/webinar/create',
  WebinarEdit = '/product-innovation/learning/webinar/edit',
}
