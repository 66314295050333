import './AppSwitch.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import classnames from 'classnames';
import { ReactComponent as AvenueLogo } from 'components/Icon/Logo.svg';
import { InnovationRouteEnum } from 'enums';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import settings from 'settings';
import { translations } from 'translations';
import isProduction from 'utils/misc/isProduction';

import AppSwitchItem from './AppSwitchItem';
import AppSwitchSelect from './AppSwitchSelect';

const { portals } = settings;

const PRODUCT_INNOVATION_SWITCH_OPTION = {
  id: 'product-innovation',
  name: translations.innovationDocumentTitle,
  url: InnovationRouteEnum.Home,
  homePath: InnovationRouteEnum.Home,
  roleRestriction: { isRestricted: false, role: '' },
};

const switchOptions = [
  {
    id: 'avenue-home-page',
    name: 'Avenue',
    url: '/',
    homePath: '/',
    icon: <AvenueLogo />,
  },
  ...portals,
];

if (!isProduction()) {
  switchOptions.push(PRODUCT_INNOVATION_SWITCH_OPTION);
}

const avenueObj = switchOptions[0];

class AppSwitch extends Component {
  state = {
    showPortalsList: false,
  };

  toggleList = (showPortalsList) => {
    this.setState({ showPortalsList });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.toggleList(false);
    }
    return (
      nextProps.location.pathname !== this.props.location.pathname ||
      nextProps.isNavOpen !== this.props.isNavOpen ||
      nextProps.isInsightsDatabasePage !== this.props.isInsightsDatabasePage ||
      nextState.showPortalsList !== this.state.showPortalsList
    );
  }

  render() {
    const { showPortalsList } = this.state;
    const { isNavOpen, location, isInsightsDatabasePage } = this.props;

    const listClasses = classnames('app-switch__list', {
      'app-switch__list--opened': showPortalsList,
    });

    let currentPortal = switchOptions.find((item) =>
      item.url !== '/'
        ? location.pathname.includes(item.url) &&
          !location.pathname.includes('/find') &&
          !location.pathname.includes('/group-library') &&
          !location.pathname.includes('/library')
        : false
    );

    if (
      !isProduction() &&
      (location.pathname.includes(InnovationRouteEnum.Home) ||
        isInsightsDatabasePage)
    ) {
      currentPortal = PRODUCT_INNOVATION_SWITCH_OPTION;
    }

    const selectedOption = currentPortal || avenueObj;

    return (
      <ClickAwayListener onClickAway={() => this.toggleList(false)}>
        <div className="app-switch-wrapper">
          <div className="app-switch">
            <AppSwitchSelect
              isNavOpen={isNavOpen}
              item={selectedOption}
              openPortalList={() => this.toggleList(true)}
            />
            <ul className={listClasses}>
              {switchOptions
                .filter((item) => {
                  return item.id !== selectedOption.id;
                })
                .map((item) => {
                  return (
                    <li key={item.id}>
                      <AppSwitchItem item={item} />
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

export default withRouter(AppSwitch);
