import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon } from 'componentsNew';
import { useMemo } from 'react';
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';
import { translations } from 'translations';

interface ProfileMiniViewProps {
  elementId: string;
  name?: string;
  title?: string;
  imageUrl?: string;
  hideTitle?: boolean;
  sx?: SxProps<Theme>;
  to?: string;
  target?: LinkProps['target'];
  onClick?: () => void;
}

const ProfileMiniView = ({
  elementId,
  name,
  title,
  imageUrl,
  hideTitle,
  sx,
  to,
  target,
  onClick,
}: ProfileMiniViewProps) => {
  const initialAvatar = useMemo(() => {
    if (!name) return null;

    const parts = name.split(' ');
    const letters = parts.map((part) => part[0].toUpperCase());
    const slicedLetters = letters.slice(0, 2).join('');
    return (
      <Avatar sx={{ height: '2.5rem', width: '2.5rem' }}>
        {slicedLetters}
      </Avatar>
    );
  }, [name]);

  const content = useMemo(() => {
    return (
      <Stack direction="row" alignItems="center" sx={sx}>
        {imageUrl ? (
          <Avatar
            id={`${elementId}-avatar`}
            alt={name || ''}
            src={imageUrl}
            sx={{ height: '2.5rem', width: '2.5rem' }}
          />
        ) : initialAvatar ? (
          initialAvatar
        ) : (
          <Icon
            type="userCircle"
            color="secondary"
            svgProps={{ height: 40, width: 40 }}
          />
        )}
        <Stack sx={(theme) => ({ marginLeft: theme.spacing('xs') })}>
          <Typography
            id={`${elementId}-name`}
            variant="body2"
            sx={(theme) => ({
              color: theme.colors.text.primary,
              lineHeight: 1.5,
              fontWeight: 'bold',
            })}
          >
            {name || translations.notAvailable}
          </Typography>
          {!hideTitle && title && (
            <Typography
              id={`${elementId}-title`}
              variant="body2"
              sx={(theme) => ({
                color: theme.colors.text.tertiary,
              })}
            >
              {title}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  }, [elementId, hideTitle, imageUrl, initialAvatar, name, sx, title]);

  if (to) {
    return (
      <Link
        id={elementId}
        to={to}
        target={target}
        onClick={onClick}
        component={ReactRouterLink}
        sx={(theme) => ({
          textDecoration: 'none',
          [`&:hover #${elementId}-name`]: {
            color: theme.colors.text.actionHover,
          },
        })}
      >
        {content}
      </Link>
    );
  }

  return content;
};

export { ProfileMiniView };
