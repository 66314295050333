import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon } from 'componentsNew/Icon/Icon';
import { KeyboardKeyEnum } from 'enums';
import { FormFieldWrapper, FormStack } from 'layout';
import { useCallback, useState } from 'react';
import { translations } from 'translations';
import * as formUtils from 'utils/form';
import * as textUtils from 'utils/misc/text';

import { LinkList } from './LinkList';

const MAX_NUMBER_OF_LINKS = 10;

type LinkInputProps = {
  value: { title: string; url: string }[];
  limit?: number;
  elementId: string;
  onChange: (links: { title: string; url: string }[]) => void;
  sx?: SxProps<Theme>;
};

const LinkInput = ({
  onChange,
  value,
  elementId,
  limit = MAX_NUMBER_OF_LINKS,
  sx,
}: LinkInputProps) => {
  const [titleValue, setTitleValue] = useState<string>('');
  const [urlValue, setUrlValue] = useState<string>('');
  const [errors, setErrors] = useState<{ title?: string; url?: string }>({});

  const theme = useTheme();

  const validateTitle = useCallback((value: string) => {
    const isValid = value.trim() !== '';
    if (isValid) {
      return;
    }
    return formUtils.getErrorMessage('required', {
      displayName: translations.formLabelTitle,
    });
  }, []);

  const validateUrl = useCallback((value: string) => {
    const isValid =
      value.trim() !== '' && formUtils.customValidators.url(value);
    if (isValid) {
      return;
    }
    return formUtils.getErrorMessage('url', {
      displayName: translations.formLabelLink,
    });
  }, []);

  const onAddLink = useCallback(() => {
    if (value.length >= limit) {
      return;
    }
    const titleError = validateTitle(titleValue);
    const linkError = validateUrl(urlValue);

    if (titleError || linkError) {
      setErrors({ title: titleError, url: linkError });
      return;
    }
    const newLink: { title: string; url: string } = {
      title: titleValue,
      url: urlValue,
    };
    const newLinksList = [...value, newLink];
    onChange(newLinksList);
    setErrors({ title: '', url: '' });
    setTitleValue('');
    setUrlValue('');
  }, [
    value,
    limit,
    validateTitle,
    titleValue,
    validateUrl,
    urlValue,
    onChange,
  ]);

  const onRemove = useCallback(
    (index: number) => {
      const newValue = [...value];

      newValue.splice(index, 1);

      onChange(newValue);
    },
    [value, onChange]
  );

  return (
    <Stack
      sx={[
        (theme) => ({
          padding: theme.spacing('sm'),
          gap: theme.spacing('sm'),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography
        variant="caption"
        sx={(theme) => ({
          marginLeft: 'auto',
          color: theme.colors.text.tertiary,
        })}
      >
        {textUtils.replaceTranslationAliases(translations.resourcesMaxLinks, {
          maxLength: limit,
        })}
      </Typography>
      <FormStack sx={{ gap: theme.spacing('sm') }}>
        <FormFieldWrapper
          id={`${elementId}-title`}
          hideLabel
          label={translations.formLabelTitle}
          error={errors.title || undefined}
        >
          <OutlinedInput
            size="small"
            placeholder={translations.formLabelTitle}
            value={titleValue}
            onChange={(e) => setTitleValue(e.target.value)}
            onBlur={(e) => {
              setErrors((prevErrors) => {
                const titleError = validateTitle(e.target.value);
                const prevTitleError = prevErrors.title;

                if (titleError && !prevTitleError) {
                  return { ...errors, title: titleError };
                }
                if (!titleError && prevTitleError) {
                  return { ...errors, title: '' };
                }
                return prevErrors;
              });
            }}
            onKeyDown={(e) => {
              if (e.key === KeyboardKeyEnum.Enter) {
                e.preventDefault();
                onAddLink();
              }
            }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          id={`${elementId}-url`}
          label={translations.formLabelUrl}
          hideLabel
          error={errors.url || undefined}
        >
          <OutlinedInput
            size="small"
            placeholder={translations.resourcesLinkUrlPlaceholder}
            value={urlValue}
            onChange={(e) => setUrlValue(e.target.value)}
            onBlur={(e) => {
              setErrors((prevErrors) => {
                const urlError = validateUrl(e.target.value);
                const prevUrlError = prevErrors.url;

                if (urlError && !prevUrlError) {
                  return { ...errors, url: urlError };
                }
                if (!urlError && prevUrlError) {
                  return { ...errors, url: '' };
                }
                return prevErrors;
              });
            }}
            onKeyDown={(e) => {
              if (e.key === KeyboardKeyEnum.Enter) {
                e.preventDefault();
                onAddLink();
              }
            }}
          />
        </FormFieldWrapper>
        <Button
          id={`${elementId}-add-button`}
          variant="outlined"
          component="label"
          size="small"
          disabled={value.length >= limit}
          endIcon={<Icon type="plus" color="brandBase" />}
          onClick={onAddLink}
          sx={() => ({
            marginLeft: 'auto',
            maxWidth: '6.5rem',
          })}
        >
          {translations.add}
        </Button>
      </FormStack>
      {value.length > 0 && (
        <Stack>
          <LinkList
            id={`${elementId}-items`}
            sx={(theme) => ({
              '.MuiLink-root': {
                padding: theme.spacing('xxs'),
              },
            })}
            items={value.map((link, index) => ({
              id: `${elementId}-item-${index}`,
              descriptions: [link.title],
              href: link.url,
              target: '_blank',
              startElement: <Icon type="link" color="secondary" />,
              endElementExcludedFromLink: true,
              endElement: (
                <IconButton
                  id={`${index}-item-${index}-remove-button`}
                  onClick={() => onRemove(index)}
                >
                  <Icon type="xMark" color="secondary" />
                </IconButton>
              ),
            }))}
          />
        </Stack>
      )}
    </Stack>
  );
};

export { LinkInput };
