import ToggleButton from '@mui/material/ToggleButton';
import { useCurrentEditor } from '@tiptap/react';
import { useCallback } from 'react';
import { translations } from 'translations';

const elementId = 'rich-text-format-menu-bullet-list';
const title = translations.richTextFormatBulletList;

const BulletList = () => {
  const { editor } = useCurrentEditor();

  const onClick = useCallback(() => {
    if (!editor) {
      return;
    }
    editor.chain().focus().toggleBulletList().run();
  }, [editor]);

  return (
    <ToggleButton
      id={elementId}
      title={title}
      value="ul"
      aria-label={title}
      selected={editor ? editor.isActive('bulletList') : false}
      onClick={onClick}
    >
      UL
    </ToggleButton>
  );
};
export { BulletList };
