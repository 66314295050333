import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Checkbox, CheckboxProps } from 'componentsNew/Checkbox/Checkbox';
import {
  DatePicker,
  DatePickerProps,
} from 'componentsNew/DatePickers/DatePicker';
import {
  DateTimePicker,
  DateTimePickerProps,
} from 'componentsNew/DatePickers/DateTimePicker';
import {
  MediaInput,
  MediaInputProps,
} from 'componentsNew/MediaInput/MediaInput';
import { RichText, RichTextProps } from 'componentsNew/RichText/RichText';
import {
  MultiSelect,
  MultiSelectProps,
} from 'componentsNew/Select/MultiSelect';
import {
  SingleSelect,
  SingleSelectProps,
} from 'componentsNew/Select/SingleSelect';
import {
  SiteSearchSelect,
  SiteSearchSelectProps,
} from 'componentsNew/Select/SiteSearchSelect';
import {
  UserSearchSelect,
  UserSearchSelectProps,
} from 'componentsNew/Select/UserSearchSelect';
import {
  ArticlePersonsInput,
  ArticlePersonsInputProps,
} from 'pagesInnovation/common/ArticleForm/ArticlePersonsInput';
import {
  ArticleResourcesEdit,
  ArticleResourcesEditProps,
} from 'pagesInnovation/common/ArticleForm/ArticleResourcesEdit';
import {
  TabOrChapterInput,
  TabOrChapterInputProps,
} from 'pagesInnovation/common/ArticleForm/TabOrChapterInput/TabOrChapterInput';
import {
  VersionInput,
  VersionInputProps,
} from 'pagesInnovation/common/ArticleForm/VersionInput';
import React, { ReactElement, useMemo } from 'react';

import { FormFieldHelper, FormFieldHelperProps } from './FormFieldHelper';
import { FormFieldLabel, FormFieldLabelProps } from './FormFieldLabel';

/**
 * FormFieldWrapper can be used to wrap form components to help handle label, errors,
 * accessibility etc. We do this by applying some extra props to the wrapped element.
 *
 * If you're about to use this with a form component not listed in the switch/case
 * further down, you might need to add a case for it and do some tweaking when cloning
 * the element so that the extra props (error, aria labels etc) are applied correctly.
 */

export type FormFieldWrapperProps = {
  id?: string;
  label?: string;
  labelAsHtml?: boolean;
  labelSize?: FormFieldLabelProps['size'];
  labelComponent?: FormFieldLabelProps['component'];
  labelPlacement?: 'top' | 'right' | 'left';
  required?: boolean;
  hideLabel?: boolean;
  fullWidth?: boolean;
  error?: string | string[];
  info?: string | string[];
  warning?: string | string[];
  sx?: SxProps<Theme>;
  children: React.ReactElement;
};

const FormFieldWrapper = ({
  id,
  label,
  labelAsHtml = false,
  labelSize = 'large',
  labelPlacement = 'top',
  labelComponent,
  required,
  hideLabel,
  fullWidth = true,
  error = [],
  info = [],
  warning = [],
  sx,
  children,
}: FormFieldWrapperProps) => {
  const fieldId = useMemo(() => (id ? `${id}-input` : undefined), [id]);

  const fieldHelpers = useMemo(() => {
    if (!fieldId) {
      return [];
    }
    const infoHelpers: FormFieldHelperProps[] = [
      ...(Array.isArray(info) ? info : [info]),
    ]
      .filter(Boolean)
      .map((text, i) => ({
        id: `${fieldId}-info-${i}`,
        type: 'information',
        children: text,
        open: true,
      }));

    const warningHelpers: FormFieldHelperProps[] = [
      ...(Array.isArray(warning) ? warning : [warning]),
    ]
      .filter(Boolean)
      .map((text, i) => ({
        id: `${fieldId}-warning-${i}`,
        type: 'warning',
        children: text,
        open: true,
      }));

    const errorHelpers: FormFieldHelperProps[] = [
      ...(Array.isArray(error) ? error : [error]),
    ]
      .filter(Boolean)
      .map((text, i) => ({
        id: `${fieldId}-error-${i}`,
        type: 'critical',
        children: text,
        open: true,
      }));
    return [...infoHelpers, ...warningHelpers, ...errorHelpers];
  }, [error, fieldId, info, warning]);

  const fieldElement = useMemo(() => {
    if (!React.isValidElement(children)) {
      return null;
    }
    const isError = error.length > 0;
    const ariaDescribedBy =
      fieldHelpers.map((fieldHelper) => fieldHelper.id).join(' ') || undefined;

    switch (children.type) {
      case Checkbox:
        const checkbox = children as ReactElement<CheckboxProps>;
        return React.cloneElement(checkbox, {
          id: fieldId,
          error: isError,
          'aria-describedby': ariaDescribedBy,
          'aria-required': required,
          required: required,
        });
      case DatePicker:
        const datePicker = children as ReactElement<DatePickerProps>;
        return React.cloneElement(datePicker, {
          error: isError,
          inputProps: {
            id: fieldId,
            'aria-describedby': ariaDescribedBy,
            'aria-required': required,
            required: required,
          },
        });
      case DateTimePicker:
        const dateTimePicker = children as ReactElement<DateTimePickerProps>;
        return React.cloneElement(dateTimePicker, {
          error: isError,
          inputProps: {
            id: fieldId,
            'aria-describedby': ariaDescribedBy,
            'aria-required': required,
            required: required,
          },
        });
      case MediaInput:
        const mediaInput = children as ReactElement<MediaInputProps>;
        return React.cloneElement(mediaInput, {
          elementId: fieldId,
          ariaDescribedBy: ariaDescribedBy,
          error: isError,
        });
      case MultiSelect:
        const multiSelect = children as ReactElement<MultiSelectProps>;
        return React.cloneElement(multiSelect, {
          error: isError,
          inputProps: {
            id: fieldId,
            'aria-describedby': ariaDescribedBy,
            'aria-required': required,
          },
          required: required,
        });
      case OutlinedInput:
        const outlinedInput = children as ReactElement<OutlinedInputProps>;
        return React.cloneElement(outlinedInput, {
          error: isError,
          inputProps: {
            id: fieldId,
            'aria-describedby': ariaDescribedBy,
            'aria-required': required,
          },
          required: required,
        });
      case RadioGroup:
        const radioGroup = children as ReactElement<RadioGroupProps>;
        return React.cloneElement(radioGroup, {
          id: fieldId,
          'aria-describedby': ariaDescribedBy,
        });
      case RichText:
        const richText = children as ReactElement<RichTextProps>;
        return React.cloneElement(richText, {
          id: fieldId,
          ariaDescribedBy: ariaDescribedBy,
          error: isError,
        });
      case SingleSelect:
        const singleSelect = children as ReactElement<SingleSelectProps>;
        return React.cloneElement(singleSelect, {
          error: isError,
          inputProps: {
            id: fieldId,
            'aria-describedby': ariaDescribedBy,
            'aria-required': required,
          },
          required: required,
        });
      case SiteSearchSelect:
        const siteSearchSelect =
          children as ReactElement<SiteSearchSelectProps>;
        return React.cloneElement(siteSearchSelect, {
          error: isError,
          inputProps: {
            id: fieldId,
            'aria-describedby': ariaDescribedBy,
            'aria-required': required,
          },
          required: required,
        });
      case Switch:
        const switchh = children as ReactElement<SwitchProps>;
        return React.cloneElement(switchh, {
          inputProps: {
            id: fieldId,
            'aria-describedby': ariaDescribedBy,
            'aria-required': required,
          },
          required: required,
        });
      case TabOrChapterInput:
        const tabOrChapterInput =
          children as ReactElement<TabOrChapterInputProps>;
        return React.cloneElement(tabOrChapterInput, {
          id: fieldId,
          ariaDescribedBy: ariaDescribedBy,
          error: isError,
        });
      case UserSearchSelect:
        const userSearchSelect =
          children as ReactElement<UserSearchSelectProps>;
        return React.cloneElement(userSearchSelect, {
          error: isError,
          inputProps: {
            id: fieldId,
            'aria-describedby': ariaDescribedBy,
            'aria-required': required,
          },
          required: required,
        });
      case ArticlePersonsInput:
        const articlePersonsInput =
          children as ReactElement<ArticlePersonsInputProps>;
        return React.cloneElement(articlePersonsInput, {
          id: fieldId,
          ariaDescribedBy: ariaDescribedBy,
          error: isError,
        });
      case ArticleResourcesEdit:
        const articleResourcesEdit =
          children as ReactElement<ArticleResourcesEditProps>;
        return React.cloneElement(articleResourcesEdit, {
          id: fieldId,
          ariaDescribedBy: ariaDescribedBy,
          error: isError,
        });
      case VersionInput:
        const versionInput = children as ReactElement<VersionInputProps>;
        return React.cloneElement(versionInput, {
          id: fieldId,
          ariaDescribedBy: ariaDescribedBy,
          error: isError,
        });

      default:
        return children;
    }
  }, [children, error.length, fieldHelpers, fieldId, required]);

  return (
    <Stack
      sx={(theme) => ({
        position: 'relative',
        gap: theme.spacing('xxxs'),
        ...(fullWidth && { width: '100%' }),
      })}
    >
      <Stack
        sx={[
          (theme) => ({
            gap: theme.spacing(labelSize === 'large' ? 'xs' : 'xxs'),
            ...(labelPlacement === 'left' && {
              flexFlow: 'row',
              label: { margin: 'auto auto auto 0' },
            }),
            ...(labelPlacement === 'right' && {
              flexFlow: 'row-reverse',
              label: { margin: 'auto auto auto 0' },
            }),
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {label && (
          <FormFieldLabel
            size={labelSize}
            component={labelComponent}
            htmlFor={fieldId}
            hide={hideLabel}
            required={required}
            labelAsHtml={labelAsHtml}
            label={label}
          />
        )}
        {fieldElement}
      </Stack>
      {fieldHelpers.map((fieldHelper) => (
        <FormFieldHelper
          id={fieldHelper.id}
          key={fieldHelper.id}
          type={fieldHelper.type}
          open={fieldHelper.open}
        >
          {fieldHelper.children}
        </FormFieldHelper>
      ))}
    </Stack>
  );
};

export { FormFieldWrapper };
