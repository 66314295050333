import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { Icon, Radio } from 'componentsNew';
import { InnovationRouteEnum } from 'enums';
import { Page, PageTitle } from 'layout';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { translations } from 'translations';

const elementId = 'innovation-create';

const Create = () => {
  const [selectedPath, setSelectedPath] = useState<string>(
    InnovationRouteEnum.ProcessAndGuidesCreate
  );
  const [innerSelectedPath, setInnerSelectedPath] = useState<string>(
    InnovationRouteEnum.TrainingCreate
  );

  const history = useHistory();

  const documentTitle = useMemo(
    () => [
      translations.innovationDocumentTitle,
      translations.innovationContentCreation,
    ],
    []
  );

  const handleNext = useCallback(() => {
    if (selectedPath === InnovationRouteEnum.Learning) {
      history.push(innerSelectedPath);
      return;
    }

    history.push(selectedPath);
  }, [history, innerSelectedPath, selectedPath]);

  return (
    <Page title={documentTitle}>
      <Stack
        sx={(theme) => ({
          gap: { xs: theme.spacing('xxs'), md: theme.spacing('md') },
        })}
      >
        <PageTitle
          display={translations.innovationContentTypeSelection}
          hidden={documentTitle.join(' | ')}
        />
        <Stack
          sx={(theme) => ({
            width: '100%',
            maxWidth: '57rem',
            backgroundColor: theme.colors.surface.primary,
            boxShadow: theme.elevation.sm,
            padding: theme.spacing('md'),
            gap: theme.spacing('md'),
            borderRadius: theme.border.radius.md,
          })}
        >
          <RadioGroup
            value={selectedPath}
            onChange={(e) => {
              setSelectedPath(e.target.value);
            }}
            sx={(theme) => ({
              gap: theme.spacing('sm'),
            })}
          >
            <FormControlLabel
              label={translations.innovationTypeProcessAndGuides}
              value={InnovationRouteEnum.ProcessAndGuidesCreate}
              control={<Radio id={`${elementId}-process-and-guides`} />}
            />
            <FormControlLabel
              label={translations.innovationTypeToolsAndTemplates}
              value={InnovationRouteEnum.ToolsAndTemplatesCreate}
              control={<Radio id={`${elementId}-tools-and-templates`} />}
            />
            <FormControlLabel
              label={translations.learning}
              value={InnovationRouteEnum.Learning}
              control={<Radio id={`${elementId}-learning`} />}
            />
            {selectedPath === InnovationRouteEnum.Learning && (
              <RadioGroup
                value={innerSelectedPath}
                onChange={(e) => {
                  setInnerSelectedPath(e.target.value);
                }}
                sx={(theme) => ({
                  gap: theme.spacing('xs'),
                  paddingLeft: theme.spacing('lg'),
                })}
              >
                <FormControlLabel
                  label={translations.innovationTypeTraining}
                  value={InnovationRouteEnum.TrainingCreate}
                  control={<Radio id={`${elementId}-training`} />}
                />
                <FormControlLabel
                  label={translations.innovationTypeWebinar}
                  value={InnovationRouteEnum.WebinarCreate}
                  control={<Radio id={`${elementId}-webinar`} />}
                />
                <FormControlLabel
                  label={translations.innovationTypeCaseStudy}
                  value={InnovationRouteEnum.CaseStudyCreate}
                  control={<Radio id={`${elementId}-case-study`} />}
                />
              </RadioGroup>
            )}
            <FormControlLabel
              label={translations.innovationTypeStrategy}
              value={InnovationRouteEnum.StrategyCreate}
              control={<Radio id={`${elementId}-strategy`} />}
            />
            <FormControlLabel
              label={translations.innovationFocusArea}
              value={InnovationRouteEnum.FocusAreaCreate}
              control={<Radio id={`${elementId}-focus-area`} />}
            />
          </RadioGroup>
          <Divider />
          <Stack
            sx={() => ({
              justifyContent: 'end',
              flexDirection: 'row',
            })}
          >
            <Button
              variant="contained"
              endIcon={<Icon type="arrowLongRight" color="inversePrimary" />}
              onClick={handleNext}
            >
              {translations.next}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Page>
  );
};

export { Create };
