import * as heroBannerApi from 'api/cms/HeroBanner';
import { useSnackbar } from 'context';
import { AvenueRouteEnum } from 'enums';
import { Page } from 'layout';
import { useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { translations } from 'translations';
import * as attachmentUtils from 'utils/cms/attachment';
import * as formUtils from 'utils/form';

import * as helpers from './helpers';
import { DEFAULT_VALUES, FormValues, HeroBannerForm } from './HeroBannerForm';

const HeroBannerCreate = () => {
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const methods = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    shouldFocusError: true,
    mode: 'all',
  });

  const { setError, setValue, getValues } = methods;

  const setFormError = useCallback(
    (message: string) => {
      showSnackbar({
        type: 'error',
        text: message,
      });
      setError('root', {});
    },
    [setError, showSnackbar]
  );

  const beforeSubmit = useCallback(() => {
    const formValues = getValues();
    const trimmedValues = formUtils.trimValues(formValues);
    const fieldNames = Object.keys(formValues) as (keyof FormValues)[];

    for (const fieldName of fieldNames) {
      const formValue = formValues[fieldName];
      const trimmedValue = trimmedValues[fieldName];
      if (trimmedValue !== formValue) {
        setValue(fieldName, trimmedValue, { shouldValidate: true });
      }
    }
  }, [getValues, setValue]);

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    async (formValues: FormValues) => {
      let heroBannerId: string | null = null;

      if (formValues.heroImage.raw) {
        try {
          formValues.heroImage.uploaded = await attachmentUtils.uploadImage(
            formValues.heroImage.raw
          );
        } catch {
          setFormError(translations.imageUploadError);
          return;
        }
      }
      try {
        const payload =
          helpers.transformFormValuesToCreateOrEditPayload(formValues);

        const response = await heroBannerApi.createHeroItem(payload);
        heroBannerId = response.data.data._doc;

        await heroBannerApi.updateHeroItem(heroBannerId, payload);
        showSnackbar({
          type: 'success',
          text: translations.heroBannerCreateSuccess,
        });
        history.push(AvenueRouteEnum.ProfileManageBanners);
      } catch {
        if (formValues.heroImage.uploaded) {
          try {
            await attachmentUtils.deleteImage(formValues.heroImage.uploaded);
          } catch {}
        }
        if (heroBannerId) {
          try {
            await heroBannerApi.deleteHeroItem(heroBannerId);
          } catch {}
        }
        setFormError(translations.heroBannerCreateError);
        return;
      }
    },
    [history, setFormError, showSnackbar]
  );

  return (
    <Page
      title={[translations.heroBanner, translations.create]}
      breadcrumbs={[
        {
          text: translations.home,
          to: AvenueRouteEnum.Home,
        },
        {
          text: translations.heroBanner,
          to: AvenueRouteEnum.ProfileManageBanners,
        },
        { text: translations.create },
      ]}
    >
      <FormProvider {...methods}>
        <HeroBannerForm beforeSubmit={beforeSubmit} onSubmit={handleSubmit} />
      </FormProvider>
    </Page>
  );
};

export { HeroBannerCreate };
