import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { InnovationSimpleFocusArea } from 'api/cms-innovation/types';
import headerImage from 'assets/images/innovation-article-header-background-learning.png';
import {
  AlertDialog,
  Checkbox,
  Chip,
  Icon,
  MultiSelect,
  RichText,
} from 'componentsNew';
import { MediaInput, MediaValue } from 'componentsNew/MediaInput/MediaInput';
import { InternalPerson } from 'componentsNew/PersonsInput/InternalPersonsInput';
import { SelectItem } from 'componentsNew/Select/SelectMenuItem';
import { useFocusAreas } from 'context';
import {
  Form,
  FormButtons,
  FormFieldWrapper,
  FormLoading,
  FormStack,
} from 'layout';
import {
  ArticleFormSkeleton,
  ArticleHeader,
  ArticleHeaderLeftColumn,
  ArticleHeaderRightColumn,
  ArticleResourcesEdit,
  ContactDetailsInput,
  RelatedArticles,
} from 'pagesInnovation/common';
import { ArticlePersonsInput } from 'pagesInnovation/common/ArticleForm/ArticlePersonsInput';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { translations } from 'translations';
import * as formUtils from 'utils/form';
import * as textUtils from 'utils/misc/text';

const elementId = 'training-form';

export type FormValues = {
  title: string;
  preamble: string;
  focusAreas: InnovationSimpleFocusArea[];
  owners: {
    internalPersons: InternalPerson[];
  };
  hero: MediaValue;
  note: string;
  body: string;
  contactDetails: string;
  resources: {
    sharepointFolderName?: string;
    links?: { title: string; url: string }[];
  };
  displayOnHome: boolean;
};

export const DEFAULT_VALUES: FormValues = {
  title: '',
  preamble: '',
  focusAreas: [],
  owners: {
    internalPersons: [],
  },
  hero: {},
  note: '',
  body: '',
  contactDetails: '',
  resources: {
    sharepointFolderName: '',
    links: [],
  },
  displayOnHome: false,
};

type TrainingFormProps = {
  beforeSubmit?: () => void | Promise<void>;
  onSubmit: SubmitHandler<FormValues>;
  onDelete?: () => void;
  onBack: () => void;
};

const TrainingForm = ({
  beforeSubmit,
  onSubmit,
  onDelete,
  onBack,
}: TrainingFormProps) => {
  const [isBackDialogOpen, setIsBackDialogOpen] = useState<boolean>(false);

  const [relatedArticlesFocusAreas, setRelatedArticlesFocusAreas] = useState<
    InnovationSimpleFocusArea[] | null
  >(null);

  const { focusAreaSelectItems, isFocusAreasError } = useFocusAreas();

  const params = useParams<{ id?: string }>();
  const methods = useFormContext<FormValues>();
  const theme = useTheme();

  const {
    control,
    watch,
    formState: { errors, isLoading, defaultValues, isSubmitting },
    handleSubmit,
  } = methods;

  const watchFocusAreas = watch('focusAreas');

  useEffect(() => {
    if (!relatedArticlesFocusAreas) {
      setRelatedArticlesFocusAreas(watchFocusAreas);
    }
  }, [relatedArticlesFocusAreas, watchFocusAreas]);

  if (isLoading) {
    return (
      <Form id={elementId}>
        <ArticleFormSkeleton />
      </Form>
    );
  }

  return (
    <Form
      id={elementId}
      onSubmit={(e) => {
        beforeSubmit && beforeSubmit();
        handleSubmit(onSubmit)(e);
      }}
    >
      <FormLoading isLoading={isSubmitting} scroll color="secondary" />
      <Grid container spacing={theme.spacing('md')}>
        <Grid xs={12}>
          <ArticleHeader backgroundImage={headerImage}>
            <ArticleHeaderLeftColumn>
              <Chip
                variant="outlined"
                size="small"
                label={translations.innovationTypeTraining}
                sx={(theme) => ({
                  '&.MuiChip-outlined.MuiChip-outlinedDefault': {
                    fontWeight: theme.typography.fontWeightBold,
                    borderColor: theme.palette.secondary2[900],
                    color: theme.palette.secondary2[900],
                    backgroundColor: 'unset',
                  },
                })}
              />
              <Controller
                name="title"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formUtils.getErrorMessage('required', {
                      displayName: translations.formLabelTitle,
                    }),
                  },
                  maxLength: {
                    value: 90,
                    message: formUtils.getErrorMessage('maxLength', {
                      displayName: translations.formLabelTitle,
                      value: 90,
                    }),
                  },
                }}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-title`}
                      label={translations.formLabelTitle}
                      labelSize="small"
                      required={true}
                      error={errors.title?.message}
                    >
                      <OutlinedInput
                        {...field}
                        placeholder={textUtils.replaceTranslationAliases(
                          translations.formPlaceholderMaxCharacters,
                          { maxLength: 90 }
                        )}
                        size="small"
                        inputRef={ref}
                        sx={(theme) => ({
                          '.MuiOutlinedInput-notchedOutline': {
                            border: `1px dashed ${theme.colors.border.input}`,
                          },
                        })}
                      />
                    </FormFieldWrapper>
                  );
                }}
              />
              <Controller
                name="preamble"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formUtils.getErrorMessage('required', {
                      displayName: translations.formLabelPreamble,
                    }),
                  },
                  maxLength: {
                    value: 300,
                    message: formUtils.getErrorMessage('maxLength', {
                      displayName: translations.formLabelPreamble,
                      value: 300,
                    }),
                  },
                }}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-preamble`}
                      label={translations.formLabelPreamble}
                      labelSize="small"
                      required={true}
                      error={errors.preamble?.message}
                    >
                      <OutlinedInput
                        {...field}
                        multiline={true}
                        minRows={3}
                        placeholder={textUtils.replaceTranslationAliases(
                          translations.formPlaceholderMaxCharacters,
                          { maxLength: 300 }
                        )}
                        size="small"
                        inputRef={ref}
                        sx={(theme) => ({
                          '.MuiOutlinedInput-notchedOutline': {
                            border: `1px dashed ${theme.colors.border.input}`,
                          },
                        })}
                      />
                    </FormFieldWrapper>
                  );
                }}
              />
              <Controller
                name="focusAreas"
                control={control}
                render={({ field: { ref, ...field } }) => {
                  const valueSelectItems: SelectItem[] = field.value.map(
                    (focusArea) => ({
                      name: focusArea.title,
                      value: focusArea.id,
                    })
                  );
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-focus-areas`}
                      label={translations.formLabelFocusAreas}
                      labelSize="small"
                      error={errors.focusAreas?.message}
                      {...(isFocusAreasError && {
                        warning: translations.innovationFocusAreasFetchError,
                      })}
                    >
                      <MultiSelect
                        inputRef={ref}
                        items={focusAreaSelectItems || valueSelectItems}
                        placeholder={translations.select}
                        value={valueSelectItems}
                        sx={(theme) => ({
                          '.MuiOutlinedInput-notchedOutline': {
                            border: `1px dashed ${theme.colors.border.input}`,
                          },
                        })}
                        onBlur={field.onBlur}
                        onChange={(value) => {
                          const newValue = value.map((item) => ({
                            title: item.name,
                            id: item.value,
                          }));
                          field.onChange(newValue);
                          setRelatedArticlesFocusAreas(newValue);
                        }}
                      />
                    </FormFieldWrapper>
                  );
                }}
              />
            </ArticleHeaderLeftColumn>
            <ArticleHeaderRightColumn>
              <Controller
                name="owners"
                control={control}
                render={({ field }) => {
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-owners`}
                      label={translations.formLabelOwners}
                      hideLabel={true}
                      labelSize="small"
                      error={errors.owners?.message}
                    >
                      <ArticlePersonsInput
                        ref={field.ref}
                        labels={{
                          title:
                            field.value.internalPersons.length > 1
                              ? `${translations.owners}:`
                              : `${translations.owner}:`,
                          sheetTitle: translations.ownersAdd,
                        }}
                        hideExternalPersons={true}
                        externalPersons={[]}
                        internalPersons={field.value.internalPersons}
                        onInternalPersonChange={(value) => {
                          field.onChange({
                            internalPersons: value,
                          });
                        }}
                      />
                    </FormFieldWrapper>
                  );
                }}
              />
            </ArticleHeaderRightColumn>
          </ArticleHeader>
        </Grid>
        <Grid xs={12} lg={8}>
          <FormStack>
            <Controller
              name="hero"
              control={control}
              render={({ field }) => {
                return (
                  <FormFieldWrapper
                    id={`${elementId}-hero`}
                    label={translations.formLabelMedia}
                    labelSize="small"
                    error={errors.hero?.message}
                  >
                    <MediaInput
                      ref={field.ref}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </FormFieldWrapper>
                );
              }}
            />
            <FormStack sx={() => ({ maxWidth: '45rem' })}>
              <Controller
                name="note"
                control={control}
                rules={{
                  maxLength: {
                    value: 90,
                    message: formUtils.getErrorMessage('maxLength', {
                      displayName: translations.formLabelNote,
                      value: 90,
                    }),
                  },
                }}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-note`}
                      label={translations.formLabelNote}
                      labelSize="small"
                      error={errors.note?.message}
                    >
                      <OutlinedInput
                        placeholder={textUtils.replaceTranslationAliases(
                          translations.formPlaceholderNote,
                          {
                            article:
                              translations.innovationTypeTraining.toLowerCase(),
                          }
                        )}
                        {...field}
                        size="small"
                        inputRef={ref}
                      />
                    </FormFieldWrapper>
                  );
                }}
              />
              <Controller
                name="body"
                control={control}
                render={({ field }) => {
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-body`}
                      label={translations.formLabelBody}
                      labelSize="small"
                      error={errors.body?.message}
                    >
                      <RichText
                        defaultValue={defaultValues?.body}
                        disabled={field.disabled}
                        ref={field.ref}
                        onBlur={(html) => field.onChange(html)}
                      />
                    </FormFieldWrapper>
                  );
                }}
              />
            </FormStack>
            <Controller
              name="contactDetails"
              control={control}
              render={({ field }) => {
                return (
                  <ContactDetailsInput
                    id={`${elementId}-contact-details`}
                    maxLength={300}
                    inputRef={field.ref}
                    disabled={field.disabled}
                    placeholder={textUtils.replaceTranslationAliases(
                      translations.formPlaceholderMaxCharacters,
                      { maxLength: 300 }
                    )}
                    error={errors.contactDetails?.message}
                    defaultValue={defaultValues?.contactDetails}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                  />
                );
              }}
            />
            <Controller
              name="displayOnHome"
              control={control}
              render={({ field: { ref, value, onChange, ...field } }) => {
                return (
                  <FormFieldWrapper
                    id={`${elementId}-display-on-home`}
                    error={errors.displayOnHome?.message}
                  >
                    <Checkbox
                      inputRef={ref}
                      checked={value}
                      onChange={(_e, checked) => onChange(checked)}
                      label={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: translations.formLabelDisplayOnHome,
                          }}
                        />
                      }
                      {...field}
                    />
                  </FormFieldWrapper>
                );
              }}
            />
            <Divider />
          </FormStack>
        </Grid>
        <Grid xs={12} lg={4}>
          <FormStack>
            <Controller
              name="resources"
              control={control}
              render={({ field }) => {
                return (
                  <FormFieldWrapper
                    id={`${elementId}-resources`}
                    label={translations.formLabelResources}
                    hideLabel={true}
                    labelSize="small"
                    error={errors.resources?.message}
                  >
                    <ArticleResourcesEdit
                      ref={field.ref}
                      links={field.value.links || []}
                      documentsFolderId={field.value.sharepointFolderName}
                      onChangeLinks={(value) => {
                        field.onChange({ ...field.value, links: value });
                      }}
                      onChangeDocumentsFolderId={(value) => {
                        field.onChange({
                          ...field.value,
                          sharepointFolderName: value,
                        });
                      }}
                    />
                  </FormFieldWrapper>
                );
              }}
            />
            <RelatedArticles
              elementId={`${elementId}-related-articles`}
              articleId={params.id}
              focusAreas={relatedArticlesFocusAreas || []}
              showEmptyMessage={true}
            />
          </FormStack>
        </Grid>
        <Grid xs={12} lg={8}>
          <FormButtons sx={() => ({ justifyContent: 'end' })}>
            <Button
              variant="outlined"
              onClick={() => setIsBackDialogOpen(true)}
              startIcon={<Icon type="arrowLongLeft" color="brandBase" />}
            >
              {translations.back}
            </Button>
            {onDelete && (
              <Button variant="outlined" onClick={onDelete}>
                {translations.delete}
              </Button>
            )}
            <Button
              variant="contained"
              type="submit"
              endIcon={<Icon type="arrowLongRight" color="inversePrimary" />}
            >
              {translations.publish}
            </Button>
          </FormButtons>
        </Grid>
      </Grid>
      <AlertDialog
        open={isBackDialogOpen}
        type="warning"
        title={translations.confirmUnsavedChangesTitle}
        primaryButton={{
          text: translations.yes,
          onClick: () => {
            onBack();
            setIsBackDialogOpen(false);
          },
        }}
        secondaryButton={{
          text: translations.cancel,
          onClick: () => {
            setIsBackDialogOpen(false);
          },
        }}
      >
        {translations.confirmUnsavedChangesText}
      </AlertDialog>
    </Form>
  );
};

export { TrainingForm };
