import Button, { ButtonTypeMap } from '@mui/material/Button';
import Link, { LinkTypeMap } from '@mui/material/Link';
import { SxProps, Theme } from '@mui/material/styles';
import { AlertDialog } from 'componentsNew';
import { useWhitelistedUrls } from 'context';
import { useCallback, useMemo, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import settings from 'settings';
import { translations } from 'translations';

const { redirectUri } = settings.auth;

type WhitelistValidationLinkProps = {
  id?: string;
  href: string;
  sx?: SxProps<Theme>;
  target?: React.HTMLAttributeAnchorTarget;
  referrerPolicy?: React.HTMLAttributeReferrerPolicy;
  linkVariant?: LinkTypeMap['props']['variant'];
  buttonVariant?: ButtonTypeMap['props']['variant'];
  children: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
};

const WhitelistValidationLink = ({
  id,
  href,
  sx,
  target,
  referrerPolicy = 'no-referrer',
  linkVariant,
  buttonVariant,
  children,
  onClick,
}: WhitelistValidationLinkProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { urls: whitelistedUrls } = useWhitelistedUrls();

  const internalLink = useMemo(() => {
    const hrefStrippedFromInternalOrigin = href.replace(redirectUri, '');

    const isExternal =
      hrefStrippedFromInternalOrigin.includes('https://') ||
      hrefStrippedFromInternalOrigin.includes('http://') ||
      hrefStrippedFromInternalOrigin.includes('www.') ||
      hrefStrippedFromInternalOrigin.includes('mailto:');

    return isExternal ? null : hrefStrippedFromInternalOrigin;
  }, [href]);

  const isWhitelisted = useMemo(
    () =>
      Boolean(internalLink) ||
      whitelistedUrls.some((whitelistedUrl) => href.startsWith(whitelistedUrl)),
    [href, internalLink, whitelistedUrls]
  );

  const targetOrDefault = useMemo(() => {
    if (target) {
      return target;
    }
    if (internalLink) {
      return '_self';
    }
    return '_blank';
  }, [target, internalLink]);

  const handleExternalLinkClick = useCallback(
    (
      e:
        | React.MouseEvent<HTMLAnchorElement, MouseEvent>
        | React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => {
      e.preventDefault();
      if (!isWhitelisted) {
        setShowDialog(true);
        return;
      }
      window.open(href, targetOrDefault);
      if (onClick) onClick();
    },
    [href, isWhitelisted, onClick, targetOrDefault]
  );

  const handleConfirmRedirect = useCallback(() => {
    setShowDialog(false);
    window.open(href, targetOrDefault);
    if (onClick) onClick();
  }, [href, onClick, targetOrDefault]);

  if (internalLink) {
    if (buttonVariant) {
      return (
        <Button
          id={id}
          to={internalLink}
          sx={sx}
          variant={buttonVariant}
          target={targetOrDefault}
          component={ReactRouterLink}
          referrerPolicy={referrerPolicy}
          onClick={onClick}
        >
          {children}
        </Button>
      );
    }
    return (
      <Link
        id={id}
        to={internalLink}
        sx={sx}
        variant={linkVariant}
        target={targetOrDefault}
        component={ReactRouterLink}
        referrerPolicy={referrerPolicy}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }

  return (
    <>
      <AlertDialog
        open={showDialog}
        type="warning"
        title={translations.whitelistLinkDialogTitle}
        paragraphs={[
          translations.whitelistLinkDialogText1,
          href.length > 50 ? `${href.substring(0, 50)}...` : href,
          translations.whitelistLinkDialogText2,
        ]}
        primaryButton={{
          text: translations.yes,
          onClick: handleConfirmRedirect,
        }}
        secondaryButton={{
          text: translations.cancel,
          onClick: () => setShowDialog(false),
        }}
        onClose={() => {
          setShowDialog(false);
        }}
      />
      {buttonVariant ? (
        <Button
          id={id}
          href={href}
          sx={sx}
          variant={buttonVariant}
          target={targetOrDefault}
          referrerPolicy={referrerPolicy}
          onClick={handleExternalLinkClick}
        >
          {children}
        </Button>
      ) : (
        <Link
          id={id}
          href={href}
          sx={sx}
          variant={linkVariant}
          target={targetOrDefault}
          referrerPolicy={referrerPolicy}
          onClick={handleExternalLinkClick}
        >
          {children}
        </Link>
      )}
    </>
  );
};

export { WhitelistValidationLink };
