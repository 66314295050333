import ToggleButton from '@mui/material/ToggleButton';
import { useCurrentEditor } from '@tiptap/react';
import { useCallback } from 'react';
import { translations } from 'translations';

const elementId = 'rich-text-format-menu-italic';
const title = translations.richTextFormatItalic;

const Italic = () => {
  const { editor } = useCurrentEditor();

  const onClick = useCallback(() => {
    if (!editor) {
      return;
    }
    editor.chain().focus().toggleItalic().run();
  }, [editor]);

  return (
    <ToggleButton
      id={elementId}
      title={title}
      value="italic"
      aria-label={title}
      selected={editor ? editor.isActive('italic') : false}
      onClick={onClick}
      sx={{ fontStyle: 'italic' }}
    >
      I
    </ToggleButton>
  );
};
export { Italic };
