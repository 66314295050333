import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import RichText from 'components/Editable/RichText';
import { DatePicker, MultiSelect } from 'componentsNew';
import { SelectItem } from 'componentsNew/Select/SelectMenuItem';
import { organizationDataHelpers, useOrganizationData } from 'context';
import { AvenueRouteEnum } from 'enums';
import {
  Form,
  FormButtons,
  FormFieldLabel,
  FormFieldWrapper,
  FormLoading,
  FormStack,
  PageContentCard,
} from 'layout';
import { useCallback, useEffect, useMemo } from 'react';
import {
  Controller,
  ControllerRenderProps,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'translations';
import * as formUtils from 'utils/form';

import * as helpers from './helpers';

const elementId = 'alert-form';

const MIN_START_DATE = new Date();

export type FormValues = {
  title: string;
  description: string;
  publishedInDivisions: { id: string; name: string }[];
  publishedInCountries: { id: string; name: string }[];
  startDate: Date | null;
  endDate: Date | null;
};

export const DEFAULT_VALUES: FormValues = {
  title: '',
  description: '',
  publishedInDivisions: [],
  publishedInCountries: [],
  startDate: null,
  endDate: null,
};

type AlertFormProps = {
  beforeSubmit?: () => void | Promise<void>;
  onSubmit: SubmitHandler<FormValues>;
};

const AlertForm = ({ beforeSubmit, onSubmit }: AlertFormProps) => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const methods = useFormContext<FormValues>();

  const {
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = methods;

  const watchPublishedInDivisions = watch('publishedInDivisions');
  const watchPublishedInCountries = watch('publishedInCountries');
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  const {
    getDivisionsTree,
    divisionsTree,
    isError: isDivisionsError,
  } = useOrganizationData();

  const publishedInDivisionsSelectItems = useMemo(() => {
    const globalDivisionSelectItem: SelectItem = {
      name: helpers.GLOBAL_DIVISION.name,
      value: helpers.GLOBAL_DIVISION.id,
      disabled: false,
    };
    if (!divisionsTree) {
      return [globalDivisionSelectItem];
    }
    const isGlobalDivisionSelected = watchPublishedInDivisions.some(
      (item) => item.id === helpers.GLOBAL_DIVISION.id
    );
    const isDivisionSelected =
      !isGlobalDivisionSelected && watchPublishedInDivisions.length > 0;

    const divisionSelectItems: SelectItem[] = divisionsTree.divisions.map(
      (division) => ({
        name: division.name,
        value: division.id,
        disabled: isGlobalDivisionSelected,
      })
    );

    if (isDivisionSelected) {
      globalDivisionSelectItem.disabled = true;
    }
    return [globalDivisionSelectItem, ...divisionSelectItems];
  }, [divisionsTree, watchPublishedInDivisions]);

  const publishedInCountriesSelectItems = useMemo(() => {
    if (!divisionsTree) {
      return [];
    }
    const validCountryItems =
      organizationDataHelpers.getValidCountriesForDivisions(
        watchPublishedInDivisions,
        divisionsTree
      );
    const countrySelectItems: SelectItem[] = validCountryItems.map(
      (country) => ({
        name: country.name,
        value: country.id,
      })
    );
    return countrySelectItems;
  }, [divisionsTree, watchPublishedInDivisions]);

  const handlePublishedInDivisionsChange = useCallback(
    (
      value: SelectItem[],
      onChange: ControllerRenderProps<
        FormValues,
        'publishedInDivisions'
      >['onChange']
    ) => {
      const isGlobalDivisionSelected = value.some(
        (item) => item.value === helpers.GLOBAL_DIVISION.id
      );
      if (isGlobalDivisionSelected) {
        onChange([helpers.GLOBAL_DIVISION]);
        setValue('publishedInCountries', []);
        return;
      }

      const newDivisions = value.map((item) => ({
        id: item.value,
        name: item.name,
      }));

      const validCountries = divisionsTree
        ? organizationDataHelpers.getValidCountriesForDivisions(
            newDivisions,
            divisionsTree
          )
        : [];

      const isSelectedCountriesValid = watchPublishedInCountries.every(
        (watchCountry) =>
          validCountries.some((validCountry) => {
            return validCountry.id === watchCountry.id;
          })
      );

      if (!isSelectedCountriesValid) {
        setValue('publishedInCountries', []);
      }

      onChange(newDivisions);
    },
    [divisionsTree, setValue, watchPublishedInCountries]
  );

  useEffect(() => {
    getDivisionsTree();
  }, [getDivisionsTree]);

  const onCancel = useCallback(() => {
    const canGoBack = Boolean(location.key);
    if (canGoBack) {
      history.goBack();
    } else {
      history.push(AvenueRouteEnum.Home);
    }
  }, [location, history]);

  return (
    <Form
      id={elementId}
      onSubmit={async (e) => {
        beforeSubmit && (await beforeSubmit());
        handleSubmit(onSubmit)(e);
      }}
      sx={(theme) => ({
        '.MuiInputBase-root': { backgroundColor: theme.colors.surface.primary },
      })}
    >
      <Grid container>
        <Grid xs={12} lg={8}>
          <PageContentCard variant="elevated">
            <FormLoading isLoading={isSubmitting} scroll />
            <FormStack>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formUtils.getErrorMessage('required', {
                      displayName: translations.formLabelTitle,
                    }),
                  },
                  maxLength: {
                    value: 60,
                    message: formUtils.getErrorMessage('maxLength', {
                      displayName: translations.formLabelTitle,
                      value: 60,
                    }),
                  },
                }}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-title`}
                      label={translations.formLabelTitle}
                      error={errors.title?.message}
                    >
                      <OutlinedInput {...field} size="small" inputRef={ref} />
                    </FormFieldWrapper>
                  );
                }}
              />
              <Controller
                name="description"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formUtils.getErrorMessage('required', {
                      displayName: translations.formLabelDescription,
                    }),
                  },
                  maxLength: {
                    value: 270,
                    message: formUtils.getErrorMessage('maxLength', {
                      displayName: translations.formLabelDescription,
                      value: 270,
                    }),
                  },
                }}
                render={({ field }) => (
                  <FormFieldWrapper
                    id={`${elementId}-description`}
                    label={translations.formLabelDescription}
                    error={errors.description?.message}
                    sx={(theme) => ({
                      '> .editable': {
                        marginBottom: 0,
                        border: `1px solid ${theme.colors.border.input}`,
                        borderRadius: theme.border.radius.sm,
                        backgroundImage: 'none',
                      },
                    })}
                  >
                    <RichText onBlur={field.onChange} placeholder="" />
                  </FormFieldWrapper>
                )}
              />
              <Stack
                sx={(theme) => ({
                  gap: theme.spacing('xs'),
                })}
              >
                <FormFieldLabel label={translations.formLabelPublishTarget} />
                <Grid container spacing={theme.spacing('md')}>
                  <Grid xs={12} md={6}>
                    <Controller
                      name="publishedInDivisions"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: formUtils.getErrorMessage('required', {
                            displayName: translations.formLabelPublishTarget,
                          }),
                        },
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <FormFieldWrapper
                          id={`${elementId}-publishedInDivisions`}
                          label={translations.formLabelDivisions}
                          hideLabel
                          error={errors.publishedInDivisions?.message}
                          warning={
                            isDivisionsError
                              ? translations.divisionGetError
                              : undefined
                          }
                        >
                          <MultiSelect
                            {...field}
                            inputRef={ref}
                            items={publishedInDivisionsSelectItems}
                            placeholder={
                              translations.formPlaceholderDivisionOrGlobal
                            }
                            value={field.value.map((division) => ({
                              name: division.name,
                              value: division.id,
                            }))}
                            onChange={(value) =>
                              handlePublishedInDivisionsChange(
                                value,
                                field.onChange
                              )
                            }
                          />
                        </FormFieldWrapper>
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Controller
                      name="publishedInCountries"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <FormFieldWrapper
                          id={`${elementId}-publishedInCountries`}
                          label={translations.formLabelCountries}
                          hideLabel
                          error={errors.publishedInCountries?.message}
                          info={
                            !watchPublishedInDivisions.length
                              ? translations.formInfoCountrySelect
                              : undefined
                          }
                        >
                          <MultiSelect
                            {...field}
                            inputRef={ref}
                            items={publishedInCountriesSelectItems}
                            disabled={
                              !watchPublishedInDivisions.length ||
                              (watchPublishedInDivisions.length === 1 &&
                                watchPublishedInDivisions[0].id ===
                                  helpers.GLOBAL_DIVISION.id)
                            }
                            placeholder={translations.formPlaceholderCountry}
                            value={field.value.map((country) => ({
                              name: country.name,
                              value: country.id,
                            }))}
                            onChange={(value) => {
                              field.onChange(
                                value.map((item) => ({
                                  id: item.value,
                                  name: item.name,
                                }))
                              );
                            }}
                          />
                        </FormFieldWrapper>
                      )}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack
                sx={(theme) => ({
                  gap: theme.spacing('xs'),
                })}
              >
                <FormFieldLabel label={translations.formLabelDateRange} />
                <Grid container spacing={theme.spacing('md')}>
                  <Grid xs={12} md={6}>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: formUtils.getErrorMessage('required', {
                            displayName: translations.formLabelStartDate,
                          }),
                        },
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <FormFieldWrapper
                          id={`${elementId}-startdate`}
                          label={translations.formLabelStartDate}
                          hideLabel
                          error={errors.startDate?.message}
                        >
                          <DatePicker
                            {...field}
                            inputRef={ref}
                            placeholder={translations.formLabelStartDate}
                            minDate={MIN_START_DATE}
                            onChange={(value) => {
                              if (
                                value &&
                                watchEndDate &&
                                value > watchEndDate
                              ) {
                                setValue('endDate', null);
                              }

                              field.onChange(value);
                            }}
                          />
                        </FormFieldWrapper>
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: formUtils.getErrorMessage('required', {
                            displayName: translations.formLabelEndDate,
                          }),
                        },
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <FormFieldWrapper
                          id={`${elementId}-endDate`}
                          label={translations.formLabelEndDate}
                          hideLabel
                          error={errors.endDate?.message}
                        >
                          <DatePicker
                            {...field}
                            inputRef={ref}
                            placeholder={translations.formLabelEndDate}
                            minDate={watchStartDate || MIN_START_DATE}
                          />
                        </FormFieldWrapper>
                      )}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <FormButtons>
                <Button
                  variant="text"
                  onClick={onCancel}
                  sx={{ marginLeft: { xs: 0, sm: 'auto' } }}
                >
                  {translations.cancel}
                </Button>
                <Button variant="contained" type="submit">
                  {translations.publish}
                </Button>
              </FormButtons>
            </FormStack>
          </PageContentCard>
        </Grid>
      </Grid>
    </Form>
  );
};

export { AlertForm };
