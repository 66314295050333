import './storyContent.scss';

import { Country, Division, Segment } from 'api/organization/types';
import { AxiosResponse } from 'axios';
import ContentLabels from 'components/ContentLabels';
import { UserConsumer } from 'components/Context/User';
import LanguageSelector, {
  Language,
  Translation,
} from 'components/LanguageSelector';
import PageTitle from 'components/PageTitle';
import Parser from 'components/Parser';
import { ImageGallery, ImageGalleryItem } from 'componentsNew/ImageGallery';
import { FC, ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  getMyDivisionLabels,
  getMyDivisionName,
} from 'utils/misc/getMyDivision';
import { getMySegmentName } from 'utils/misc/getMySegment';
import { getDateOrDaysAgo } from 'utils/misc/time';
import { getCleanSegmentName } from 'utils/misc/transformSegmentName';

interface StoryContentProps {
  response: AxiosResponse;
  title: string;
  translations: Translation[];
  currentLocale: string;
  onLanguageChange: (language: Language, response: AxiosResponse) => boolean;
  channels: string[];
  preamble?: string;
  body: string;
  subTitle?: string;
  createdOn: string;
  readingTime: number;
  heroVideo: string;
  publishedInDivisions?: Division[];
  publishedInSegments?: Segment[];
  publishedInCountries?: Country[];
  imageGalleryItems?: ImageGalleryItem[];
}

const StoryContent: FC<StoryContentProps> = ({
  response,
  title,
  translations,
  currentLocale,
  onLanguageChange,
  channels,
  preamble,
  body,
  subTitle,
  createdOn,
  readingTime,
  heroVideo,
  publishedInDivisions,
  publishedInSegments,
  publishedInCountries,
  imageGalleryItems,
}) => {
  const getLabels = useCallback(
    (args: {
      publishedInDivisions?: Division[];
      publishedInSegments?: Segment[];
      publishedInCountries?: Country[];
      segmentName: string;
      countryName: string;
      divisionId: string;
      segmentId: string;
    }) => {
      const {
        publishedInDivisions,
        publishedInSegments,
        publishedInCountries,
        segmentName,
        countryName,
        divisionId = '',
        segmentId,
      } = args;

      const labels = [
        {
          name: 'Story',
          linkType: 'story',
          shape: 'square',
          color: 'deep-blue',
        },
      ];

      if (
        publishedInCountries &&
        publishedInCountries
          .map((country) => country.name)
          .includes(countryName)
      ) {
        labels.push({
          name: countryName,
          linkType: 'country',
          shape: 'square',
          color: 'white',
        });
      } else if (publishedInDivisions) {
        getMyDivisionLabels(publishedInDivisions, divisionId).forEach(
          (division) => {
            labels.push(division);
          }
        );
      } else {
        labels.push({
          name: 'Global',
          linkType: 'global',
          shape: 'square',
          color: 'white',
        });
      }
      if (
        publishedInSegments &&
        publishedInSegments.map((segment) => segment.id).includes(segmentId)
      ) {
        labels.push({
          name: getCleanSegmentName(segmentName, segmentId),
          linkType: 'segment',
          shape: 'square',
          color: 'white',
        });
      }
      return labels;
    },
    []
  );

  const setChannels = (channels: string[] | undefined): ReactNode => {
    if (!channels || !channels.length) {
      return <span></span>;
    }
    return channels.map((channel, index) => {
      return (
        <Link
          className="story-content__topic-link"
          key={index}
          to={`/topics/${channel}`}
        >
          {`#${channel} `}{' '}
        </Link>
      );
    });
  };

  const setIdOnFirstP = (body: string) => {
    if (!body) return body;

    const firstBlock = body.substring(0, body.indexOf('</p>'));

    if (
      firstBlock.indexOf('<p><') !== -1 ||
      firstBlock.indexOf('<li>') !== -1 ||
      firstBlock.indexOf('<blockquote>') !== -1 ||
      firstBlock.indexOf('<h2>') !== -1
    )
      return body;

    const pattern = '<p>';
    body = body.replace(pattern, '<p id="story-first-tag">');
    return body;
  };

  return (
    <>
      <div className="story-content__wrapper">
        <div className="story-content__container">
          <div className="story-content__header-container">
            <h1 className="story-content__title">{title}</h1>
            <div className="story-content__language-wrapper">
              <LanguageSelector
                className="m-b-2"
                availableTranslations={translations}
                currentLocale={currentLocale}
                onLanguageChange={(language: Language) =>
                  onLanguageChange(language, response)
                }
              />
            </div>
          </div>
          <h2 className="story-content__subtitle">{subTitle}</h2>
          <div className="story-content__topic">
            {setChannels(channels)}{' '}
            {channels && channels.length ? <span>&mdash;</span> : <></>}{' '}
            {getDateOrDaysAgo(createdOn)}{' '}
            {!heroVideo && (
              <span className="story-content__readingtime">
                &#124; {readingTime > 0 ? readingTime : 'less than 1'} min
                reading time
              </span>
            )}
            <div className="story-content__topic__targeting">
              <UserConsumer>
                {({ segmentName, countryName, divisionId, segmentId }) => (
                  <>
                    <PageTitle
                      titles={[
                        'Story',
                        publishedInDivisions
                          ? getMyDivisionName(publishedInDivisions, divisionId)
                          : 'Global',
                        publishedInSegments &&
                          getMySegmentName(publishedInSegments, segmentId),
                        title,
                      ]}
                    />
                    <ContentLabels
                      className="content-labels"
                      labels={getLabels({
                        publishedInDivisions,
                        publishedInSegments,
                        publishedInCountries,
                        segmentName,
                        countryName,
                        divisionId,
                        segmentId,
                      })}
                    />
                  </>
                )}
              </UserConsumer>
            </div>
          </div>
          <div className="content">
            <p className="story-content__preamble">{preamble}</p>
            {imageGalleryItems && imageGalleryItems.length > 0 && (
              <div className="story-content__image-gallery">
                <ImageGallery items={imageGalleryItems} />
              </div>
            )}
            <div className="story-content__body">
              <Parser html={setIdOnFirstP(body)} type="Story" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoryContent;
