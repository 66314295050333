import * as cmsInnovationApi from 'api/cms-innovation';
import { InnovationToolsAndTemplatesArticle } from 'api/cms-innovation/types';
import { AlertDialog } from 'componentsNew';
import { useSnackbar } from 'context';
import { AvenueRouteEnum, InnovationRouteEnum } from 'enums';
import { useDeleteDocumentsFolder } from 'hooks';
import { Page } from 'layout';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { translations } from 'translations';
import * as formUtils from 'utils/form';

import * as helpers from './helpers';
import {
  DEFAULT_VALUES,
  FormValues,
  ToolsAndTemplateForm,
} from './ToolsAndTemplatesForm';

const ToolsAndTemplatesEdit = () => {
  const [originalData, setOriginalData] =
    useState<InnovationToolsAndTemplatesArticle | null>(null);

  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    isLoading: boolean;
  }>({ open: false, isLoading: false });

  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const { showSnackbar } = useSnackbar();
  const { deleteDocumentsFolder } = useDeleteDocumentsFolder();

  const methods = useForm<FormValues>({
    defaultValues: async () => await getDefaultValues(),
    shouldFocusError: true,
    mode: 'all',
  });

  const {
    setError,
    setValue,
    getValues,
    formState: { defaultValues },
  } = methods;

  const setFormError = useCallback(
    (message: string) => {
      showSnackbar({
        type: 'error',
        text: message,
      });
      setError('root', {});
    },
    [setError, showSnackbar]
  );

  const documentTitle = useMemo(
    () =>
      originalData
        ? [
            translations.innovationDocumentTitle,
            translations.innovationTypeToolsAndTemplates,
            originalData.title,
            translations.edit,
          ]
        : [],
    [originalData]
  );

  const getDefaultValues = useCallback(async () => {
    try {
      if (!params.id) {
        throw Error();
      }
      const response = await cmsInnovationApi.getToolsAndTemplatesArticle(
        params.id
      );
      const data = helpers.transformGetResponseToFormValues(response);
      setOriginalData(response);
      return data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        history.push(AvenueRouteEnum.NotFound);
      } else {
        showSnackbar({
          type: 'error',
          text: translations.contentGetError,
        });
      }
      return DEFAULT_VALUES;
    }
  }, [history, params.id, showSnackbar]);

  const beforeSubmit = useCallback(() => {
    const formValues = getValues();
    const trimmedValues = formUtils.trimValues(formValues);
    const fieldNames = Object.keys(formValues) as (keyof FormValues)[];

    for (const fieldName of fieldNames) {
      const formValue = formValues[fieldName];
      const trimmedValue = trimmedValues[fieldName];
      if (trimmedValue !== formValue) {
        setValue(fieldName, trimmedValue, { shouldValidate: true });
      }
    }
  }, [getValues, setValue]);

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    async (formValues: FormValues) => {
      if (!originalData) {
        setFormError(translations.contentEditError);
        return;
      }

      try {
        const payload =
          helpers.transformFormValuesToCreateOrEditPayload(formValues);
        await cmsInnovationApi.updateToolsAndTemplatesArticle(
          originalData.id,
          payload
        );
        showSnackbar({
          type: 'success',
          text: translations.contentEditSuccess,
        });
      } catch {
        setFormError(translations.contentEditError);
        return;
      }
      history.push(
        `${InnovationRouteEnum.ToolsAndTemplates}/${originalData.id}`
      );
    },
    [history, originalData, setFormError, showSnackbar]
  );

  const handleDelete = useCallback(async () => {
    try {
      if (!originalData) {
        throw Error();
      }
      setDeleteDialog({ ...deleteDialog, isLoading: true });

      const chapters = getValues('chapters');

      let successfulDelete = true;

      await Promise.all(
        chapters.map(async (chapter, chapterIndex) => {
          if (chapter.resources && chapter.resources.sharepointFolderName) {
            const deleteDocumentsFolderResponse = await deleteDocumentsFolder({
              documentsFolderId: chapter.resources.sharepointFolderName,
            });

            if (deleteDocumentsFolderResponse.success) {
              setValue(
                `chapters.${chapterIndex}.resources.sharepointFolderName`,
                ''
              );
            } else {
              successfulDelete = false;
            }
          }
        })
      );

      if (!successfulDelete) {
        setDeleteDialog({ open: false, isLoading: false });
        return;
      }

      const toolsAndTemplatesSharepointFolderName = getValues(
        'toolsAndTemplates.sharepointFolderName'
      );
      if (toolsAndTemplatesSharepointFolderName) {
        const deleteDocumentsFolderResponse = await deleteDocumentsFolder({
          documentsFolderId: toolsAndTemplatesSharepointFolderName,
        });

        if (!deleteDocumentsFolderResponse.success) {
          setDeleteDialog({ open: false, isLoading: false });
          return;
        }
      }

      await cmsInnovationApi.deleteToolsAndTemplatesArticle(originalData.id);
      showSnackbar({
        type: 'success',
        text: translations.contentDeleteSuccess,
      });
      history.push(InnovationRouteEnum.ToolsAndTemplates);
    } catch {
      showSnackbar({
        type: 'error',
        text: translations.contentDeleteError,
      });
    } finally {
      setDeleteDialog({ open: false, isLoading: false });
    }
  }, [
    deleteDialog,
    deleteDocumentsFolder,
    getValues,
    history,
    originalData,
    setValue,
    showSnackbar,
  ]);

  const handleBack = useCallback(() => {
    const chapters = getValues('chapters');
    const originalChapters = (defaultValues?.chapters ||
      []) as FormValues['chapters'];

    const originalSharepointFolderNames =
      helpers.getSharepointFolderNamesFromChapters(originalChapters);

    const newSharepointFolderNames =
      helpers.getSharepointFolderNamesFromChapters(chapters);

    const sharepointFolderNamesToDelete = newSharepointFolderNames.filter(
      (newSharepointFolderName) => {
        return !originalSharepointFolderNames.includes(newSharepointFolderName);
      }
    );

    sharepointFolderNamesToDelete.forEach((sharepointFolderName) => {
      deleteDocumentsFolder({
        documentsFolderId: sharepointFolderName,
        showErrorSnackbar: false,
      });
    });

    const toolsAndTemplatesSharepointFolderName = getValues(
      'toolsAndTemplates.sharepointFolderName'
    );
    if (
      toolsAndTemplatesSharepointFolderName &&
      toolsAndTemplatesSharepointFolderName !==
        originalData?.toolsAndTemplates?.sharepointFolderName
    ) {
      deleteDocumentsFolder({
        documentsFolderId: toolsAndTemplatesSharepointFolderName,
        showErrorSnackbar: false,
      });
    }

    const canGoBack = Boolean(location.key);
    if (canGoBack) {
      history.goBack();
    } else {
      history.push(InnovationRouteEnum.ToolsAndTemplates);
    }
  }, [
    getValues,
    defaultValues?.chapters,
    originalData?.toolsAndTemplates?.sharepointFolderName,
    location.key,
    deleteDocumentsFolder,
    history,
  ]);

  return (
    <Page title={documentTitle}>
      <FormProvider {...methods}>
        <ToolsAndTemplateForm
          onBack={handleBack}
          beforeSubmit={beforeSubmit}
          onSubmit={handleSubmit}
          onDelete={() => setDeleteDialog({ ...deleteDialog, open: true })}
        />
      </FormProvider>
      <AlertDialog
        open={deleteDialog.open}
        type="critical"
        title={translations.articleDeleteTitle}
        paragraphs={[
          translations.articleDeleteText,
          translations.articleDeleteInfoText,
        ]}
        primaryButton={{
          loading: deleteDialog.isLoading,
          disabled: deleteDialog.isLoading,
          text: translations.delete,
          onClick: handleDelete,
        }}
        secondaryButton={{
          disabled: deleteDialog.isLoading,
          text: translations.cancel,
          onClick: () => setDeleteDialog({ ...deleteDialog, open: false }),
        }}
      />
    </Page>
  );
};

export { ToolsAndTemplatesEdit };
