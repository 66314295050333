import Box, { BoxProps } from '@mui/material/Box';
import { createEmbedUrl } from 'utils/cms/parseVideoUrls';

type EmbedVideoProps = {
  sx?: BoxProps['sx'];
  title?: string;
  src: string;
  aspectRatio?: number;
  elementId?: string;
  onLoad?: () => void;
};

const EmbedVideo = ({
  sx,
  src,
  title,
  aspectRatio = 5 / 3,
  elementId,
  onLoad,
}: EmbedVideoProps) => {
  return (
    <Box
      id={elementId}
      sx={[
        () => ({
          aspectRatio,
          overflow: 'hidden',
          width: '100%',
          '> iframe': {
            width: '100%',
            height: '100%',
            border: 'none',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <iframe
        title={title}
        allowFullScreen={true}
        src={createEmbedUrl(src)}
        onLoad={() => onLoad && onLoad()}
      />
    </Box>
  );
};

export { EmbedVideo };
