import Tab from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import { useMemo } from 'react';

type ArticleChaptersProps = {
  id: string;
  sx?: TabsProps['sx'];
  items: {
    id: string;
    title: string;
    ariaControls: string;
  }[];
  activeIndex: number;
  ariaLabel: string;
  onChange: (index: number) => void;
};

const ArticleChapters = ({
  id,
  sx,
  items,
  activeIndex,
  ariaLabel,
  onChange,
}: ArticleChaptersProps) => {
  const isFirstChapterGhost = useMemo(() => {
    return items.length === 1 && items[0].title === '';
  }, [items]);

  return (
    <Tabs
      id={id}
      aria-label={ariaLabel}
      orientation="vertical"
      selectionFollowsFocus={true}
      value={activeIndex}
      onChange={(_e, value) => {
        onChange(value);
      }}
      sx={[
        (theme) => ({
          borderBottom: 'none',
          '.MuiTabs-flexContainer': {
            gap: theme.spacing('xxxs'),
          },
          '.MuiTab-root': {
            width: '100%',
            maxWidth: 'unset',
            height: 'unset',
            minHeight: 'unset',
            alignItems: 'baseline',
            textAlign: 'left',
            wordBreak: 'break-word',
            color: theme.colors.text.secondary,
            fontSize: theme.typography.body2.fontSize,
            borderRadius: theme.border.radius.sm,
            padding: `${theme.spacing('xxs')} ${theme.spacing('sm')}`,
            fontWeight: 'unset',
            '&:hover': {
              backgroundColor: `${theme.colors.surface.selected}66`,
            },
            '&.Mui-selected': {
              borderRight: 'none',
              color: theme.colors.text.actionDefault,
              backgroundColor: theme.colors.surface.selected,
              fontWeight: theme.typography.fontWeightBold,
            },
          },
          '.MuiTabs-indicator': {
            display: 'none',
          },
          ...(isFirstChapterGhost && {
            display: 'none',
          }),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {items.map((item, index) => {
        return (
          <Tab
            key={`${id}-${index}`}
            id={item.id}
            label={item.title}
            aria-controls={item.ariaControls}
          />
        );
      })}
    </Tabs>
  );
};

export { ArticleChapters };
