import { SxProps, Theme } from '@mui/material/styles';
import { RichText } from 'componentsNew';

type ArticleContactDetailsProps = {
  elementId: string;
  sx?: SxProps<Theme>;
  htmlContent: string;
};

const ArticleContactDetails = ({
  elementId,
  sx,
  htmlContent,
}: ArticleContactDetailsProps) => {
  return (
    <RichText
      id={elementId}
      defaultValue={htmlContent}
      editable={false}
      sx={[
        (theme) => ({
          backgroundColor: theme.colors.surface.tertiary,
          border: `1px solid ${theme.colors.border.surfacePrimary}`,
          borderRadius: theme.border.radius.sm,
          padding: theme.spacing('xs'),
          fontSize: theme.typography.body2.fontSize,
          '& p:last-of-type': { marginBottom: 0 },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export { ArticleContactDetails };
