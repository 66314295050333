import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';

function useBreakpoints() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const isBreakpointMediumOrHigher = useMediaQuery(theme.breakpoints.up('md'));
  return useMemo(
    () => ({ isDesktop, isMobile, isBreakpointMediumOrHigher }),
    [isBreakpointMediumOrHigher, isDesktop, isMobile]
  );
}

export { useBreakpoints };
