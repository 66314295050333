import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useBreakpoints } from 'hooks';
import * as React from 'react';
import { translations } from 'translations';

type FormatMenuWrapperProps = { children: React.ReactNode };

const FormatMenuWrapper = ({ children }: FormatMenuWrapperProps) => {
  const { isMobile } = useBreakpoints();

  return (
    <ToggleButtonGroup
      aria-label={translations.richTextFormatAriaLabel}
      sx={(theme) => ({
        color: theme.colors.text.primary,
        backgroundColor: theme.colors.surface.primary,
        padding: '0.25rem',
        gap: '0.25rem',
        boxShadow: theme.elevation.sm,
        borderRadius: theme.border.radius.sm,
        flexWrap: 'wrap',
        maxWidth: isMobile ? '10rem' : 'unset',
        justifyContent: 'center',
        '.MuiTypography-root': {
          marginBottom: 0,
          ...theme.typography.body2,
        },
        '.MuiButtonBase-root': {
          padding: 0,
          height: isMobile ? '3rem' : '2rem',
          width: isMobile ? '3rem' : '2rem',
          color: theme.colors.text.secondary,
          flexShrink: 0,
          border: 'none',
          fontWeight: 'normal',
          backgroundColor: `${
            isMobile
              ? theme.colors.surface.tertiary
              : theme.colors.surface.primary
          } !important`,
          borderRadius: theme.border.radius.sm,

          '&:hover': {
            backgroundColor: `${theme.colors.surface.informative} !important`,
            zIndex: 1,
          },
        },
        '.MuiButtonBase-root.Mui-selected': {
          color: theme.colors.text.actionDefault,
          backgroundColor: `${theme.colors.surface.informative} !important`,
          zIndex: 1,
        },
        '.MuiButtonBase-root.Mui-focusVisible, .MuiButtonBase-root.Mui-selected.Mui-focusVisible':
          {
            outlineOffset: -1,
            outline: `1px solid ${theme.colors.border.focus}`,
            boxShadow: `0px 0px 0px 3px ${theme.palette.secondary[300]}`,
            zIndex: 2,
          },
      })}
    >
      {children}
    </ToggleButtonGroup>
  );
};

export { FormatMenuWrapper };
