import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import MuiIconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AlertDialog, Icon, IconButton, Sheet, Tooltip } from 'componentsNew';
import { KeyboardKeyEnum } from 'enums';
import { FormFieldWrapper, PageContentHeader } from 'layout';
import { useCallback, useMemo, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';
import * as formUtils from 'utils/form';
import { v1 as uuidv1 } from 'uuid';

type TabOrChapterItem = {
  id: string;
  title: string;
};

export type TabOrChapterInputSheetProps = {
  id: string;
  type: 'tab' | 'chapter';
  items: TabOrChapterItem[];
  disabled?: boolean;
  onAdd: (value: TabOrChapterItem) => void;
  onUpdate: (index: number, value: TabOrChapterItem) => void;
  onRemove: (index: number) => void;
  onClose: () => void;
};

const TabOrChapterInputSheet = ({
  id,
  type,
  items,
  disabled,
  onAdd,
  onUpdate,
  onRemove,
  onClose,
}: TabOrChapterInputSheetProps) => {
  const [indexToEdit, setIndexToEdit] = useState<number>(-1);
  const [indexToRemove, setIndexToRemove] = useState<number>(-1);

  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] =
    useState<boolean>(false);

  const [addInput, setAddInput] = useState<string>('');
  const [addError, setAddError] = useState<string>('');
  const addInputRef = useRef<HTMLInputElement | null>(null);

  const [editInput, setEditInput] = useState<string>('');
  const [editError, setEditError] = useState<string>('');
  const editInputRef = useRef<HTMLInputElement | null>(null);

  const validateInput = useCallback((value: string) => {
    return value.trim() === ''
      ? formUtils.getErrorMessage('required', {
          displayName: translations.formLabelTitle,
        })
      : '';
  }, []);

  const focusAddInputField = useCallback(() => {
    setTimeout(() => {
      if (addInputRef.current) {
        addInputRef.current.focus();
      }
    }, 0);
  }, []);

  const focusEditInputField = useCallback(() => {
    setTimeout(() => {
      if (editInputRef.current) {
        editInputRef.current.focus();
      }
    }, 0);
  }, []);

  const handleAddSubmit = useCallback(() => {
    const error = validateInput(addInput);
    if (error) {
      setAddError(error);
      focusAddInputField();
      return;
    }
    onAdd({ id: uuidv1(), title: addInput });
    setAddInput('');
    setAddError('');
    focusAddInputField();
  }, [validateInput, addInput, onAdd, focusAddInputField]);

  const handleAddInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setAddInput(value);
      setAddError((prevAddError) =>
        prevAddError ? validateInput(value) : prevAddError
      );
    },
    [validateInput]
  );

  const handleAddInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key === KeyboardKeyEnum.Enter) {
        e.preventDefault();
        handleAddSubmit();
      }
    },
    [handleAddSubmit]
  );

  const handleEdit = useCallback((index: number, item: TabOrChapterItem) => {
    setIndexToEdit(index);
    setEditInput(item.title);
  }, []);

  const handleEditSubmit = useCallback(() => {
    const itemToEdit = items[indexToEdit];
    const isChanged =
      itemToEdit && itemToEdit.title.trim() !== editInput.trim();

    if (!isChanged) {
      setEditInput('');
      setEditError('');
      setIndexToEdit(-1);
      return;
    }
    const error = validateInput(editInput);

    if (error) {
      setEditError(error);
      focusEditInputField();
      return;
    }
    onUpdate(indexToEdit, { id: itemToEdit.id, title: editInput });
    setEditInput('');
    setEditError('');
    setIndexToEdit(-1);
  }, [
    items,
    indexToEdit,
    editInput,
    validateInput,
    onUpdate,
    focusEditInputField,
  ]);

  const handleEditInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setEditInput(value);
      setEditError((prevEditError) =>
        prevEditError ? validateInput(value) : prevEditError
      );
    },
    [validateInput]
  );

  const handleEditInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key === KeyboardKeyEnum.Enter) {
        e.preventDefault();
        handleEditSubmit();
      }
      if (e.key === KeyboardKeyEnum.Escape) {
        e.preventDefault();
        e.stopPropagation();
        setEditInput('');
        setEditError('');
        setIndexToEdit(-1);
      }
    },
    [handleEditSubmit]
  );

  const handleRemove = useCallback((index: number) => {
    setIndexToRemove(index);
  }, []);

  const handleRemoveCancel = useCallback(() => {
    setIndexToRemove(-1);
  }, []);

  const handleRemoveConfirm = useCallback(() => {
    if (indexToRemove >= 0) {
      onRemove(indexToRemove);
      setIndexToRemove(-1);
    }
  }, [indexToRemove, onRemove]);

  const handleClose = useCallback(() => {
    if (indexToEdit >= 0) {
      setIsCloseConfirmationOpen(true);
      return;
    }
    onClose();
  }, [indexToEdit, onClose]);

  const handleCloseConfirm = useCallback(() => {
    setEditInput('');
    setEditError('');
    setIndexToEdit(-1);
    setIsCloseConfirmationOpen(false);
    onClose();
  }, [onClose]);

  const handleCloseCancel = useCallback(() => {
    setIsCloseConfirmationOpen(false);
  }, []);

  const handleInputFocus = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const lengthOfInput = event.target.value.length;
      return event.target.setSelectionRange(lengthOfInput, lengthOfInput);
    },
    []
  );

  const labels = useMemo(() => {
    if (type === 'tab') {
      return {
        header: translations.tabInputSheetHeader,
        titlePlaceholder: translations.tabInputTitlePlaceholder,
        removeDialogText: translations.tabInputRemoveText,
        removeDialogTitle: translations.tabInputRemoveTitle,
      };
    }
    return {
      header: translations.chapterInputSheetHeader,
      titlePlaceholder: translations.chapterInputTitlePlaceholder,
      removeDialogText: translations.chapterInputRemoveText,
      removeDialogTitle: translations.chapterInputRemoveTitle,
    };
  }, [type]);

  return (
    <Sheet id={id} open={true} width="33.125rem" onClose={handleClose}>
      <Stack sx={(theme) => ({ gap: theme.spacing('xs') })}>
        <PageContentHeader text={labels.header} />
        <FormFieldWrapper
          id={`${id}-title`}
          error={addError}
          label={translations.formLabelTitle}
          hideLabel
        >
          <OutlinedInput
            autoFocus
            multiline
            size="small"
            inputRef={addInputRef}
            value={addInput}
            placeholder={labels.titlePlaceholder}
            onChange={handleAddInputChange}
            onKeyDown={handleAddInputKeyDown}
            onFocus={handleInputFocus}
          />
        </FormFieldWrapper>
        <Button
          variant="outlined"
          disabled={disabled}
          endIcon={<Icon type="plus" color="brandBase" />}
          sx={() => ({ alignSelf: 'flex-end' })}
          onClick={handleAddSubmit}
        >
          {translations.add}
        </Button>
        <List>
          <TransitionGroup>
            {items.map((item, index) => (
              <Collapse key={item.id}>
                <ListItem
                  id={`${id}-item-${index}`}
                  sx={() => ({
                    paddingLeft: 0,
                    paddingRight: 0,
                    wordBreak: 'break-word',
                  })}
                >
                  {index === indexToEdit ? (
                    <Stack
                      sx={(theme) => ({
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: theme.spacing('xs'),
                        width: '100%',
                      })}
                    >
                      <FormFieldWrapper
                        id={`${id}-edit-title`}
                        error={editError}
                        label={translations.formLabelTitle}
                        hideLabel
                      >
                        <OutlinedInput
                          autoFocus
                          multiline
                          size="small"
                          inputRef={editInputRef}
                          value={editInput}
                          placeholder={labels.titlePlaceholder}
                          sx={(theme) => ({
                            fontSize: theme.typography.body2.fontSize,
                          })}
                          onChange={handleEditInputChange}
                          onKeyDown={handleEditInputKeyDown}
                          onFocus={handleInputFocus}
                        />
                      </FormFieldWrapper>
                      <Tooltip title={translations.save}>
                        <IconButton
                          type="check"
                          color="brandBase"
                          variant="outlined"
                          disabled={disabled}
                          id={`${id}-edit-submit`}
                          onClick={handleEditSubmit}
                          aria-label={translations.save}
                        />
                      </Tooltip>
                    </Stack>
                  ) : (
                    <Stack
                      sx={(theme) => ({
                        flexDirection: 'row',
                        alignItems: 'center',
                        color: theme.colors.text.tertiary,
                        minHeight: '2rem',
                        width: '100%',
                      })}
                    >
                      <Typography
                        variant="body2"
                        sx={() => ({ maxWidth: 'calc(100% - 4rem)' })}
                      >
                        {item.title}
                      </Typography>
                      {indexToEdit < 0 && (
                        <>
                          <Tooltip title={translations.delete}>
                            <MuiIconButton
                              size="small"
                              disabled={disabled}
                              id={`${id}-item-${index}-delete`}
                              aria-label={translations.delete}
                              sx={() => ({ flexShrink: 0, marginLeft: 'auto' })}
                              onClick={() => handleRemove(index)}
                            >
                              <Icon type="xMark" color="secondary" size={20} />
                            </MuiIconButton>
                          </Tooltip>
                          <Tooltip title={translations.edit}>
                            <MuiIconButton
                              size="small"
                              disabled={disabled}
                              id={`${id}-item-${index}-edit`}
                              aria-label={translations.edit}
                              sx={() => ({ flexShrink: 0 })}
                              onClick={() => handleEdit(index, item)}
                            >
                              <Icon type="pencil" color="secondary" size={20} />
                            </MuiIconButton>
                          </Tooltip>
                        </>
                      )}
                    </Stack>
                  )}
                </ListItem>
              </Collapse>
            ))}
          </TransitionGroup>
        </List>
      </Stack>
      <AlertDialog
        open={indexToRemove >= 0}
        type="warning"
        title={labels.removeDialogTitle}
        primaryButton={{
          text: translations.yes,
          onClick: handleRemoveConfirm,
        }}
        secondaryButton={{
          text: translations.cancel,
          onClick: handleRemoveCancel,
        }}
      >
        {labels.removeDialogText}
      </AlertDialog>
      <AlertDialog
        open={isCloseConfirmationOpen}
        type="critical"
        title={translations.confirmUnsavedChangesTitle}
        primaryButton={{
          text: translations.yes,
          onClick: handleCloseConfirm,
        }}
        secondaryButton={{
          text: translations.cancel,
          onClick: handleCloseCancel,
        }}
      >
        {translations.confirmUnsavedChangesText}
      </AlertDialog>
    </Sheet>
  );
};

export { TabOrChapterInputSheet };
