import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as feedbackApi from 'api/feedback';
import * as feedbackTransformers from 'api/feedback/transformers';
import {
  LikesAndCommentsMeta,
  LikesAndCommentsResponse,
  UpdateLikeResponse,
} from 'api/feedback/transformers';
import * as newsApi from 'api/news';
import * as newsTransformers from 'api/news/transformers';
import { NewsFlash } from 'api/news/transformers';
import { useUser } from 'components/Context/User';
import {
  CommentButton,
  LikeButton,
  TypographyWithMaxLines,
} from 'componentsNew';
import { ArticleContentTypeEnum } from 'enums';
import { PageContentHeader } from 'layout';
import { ArticleImage, ArticleTags, ArticleTitle } from 'pagesAvenue/common';
import type { ArticlePreview } from 'pagesAvenue/common/ArticleList/ArticleList';
import { useCallback, useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonClickHomeStoriesItem,
  GAonClickHomeStoriesReadMore,
  GAonCommentFeedClick,
  GAonLikeSent,
} from 'utils/analytics';
import { getUrlLink } from 'utils/misc/getUrlLink';
import makeQueryString from 'utils/misc/makeQueryString';
import * as textUtils from 'utils/misc/text';

import * as helpers from '../LatestArticles/helpers';
import { NoStories } from './NoStories';
import { StoriesSkeleton } from './StoriesSkeleton';

const elementId = 'home-stories';

const QUANTITY_LIMIT = 3;

type StoriesProps = {
  locale: string;
};

const Stories = ({ locale }: StoriesProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [articles, setArticles] = useState<ArticlePreview[]>([]);

  const user = useUser();
  const theme = useTheme();

  const fetchStories = useCallback(
    async (filter: { locale: string; user: any }) => {
      setIsLoading(true);
      const { locale, user } = filter;

      let newsFlashes: NewsFlash[] = [];
      let likesAndCommentsMetaByArticleId: Record<
        string,
        LikesAndCommentsMeta
      > = {};

      const params = {
        filter: helpers.getNewsFlashesFilter('stories', {
          countryId: user.countryId,
          divisionId: user.divisionId,
          departmentId: user.departmentId,
          secondaryDepartmentId: user.secondaryDepartmentId,
          segmentId: user.segmentId,
          siteId: user.siteId,
          companyId: user.companyId,
          locale: locale,
        }),
        page: { limit: QUANTITY_LIMIT, offset: 0 },
      };

      // Fetch news flashes
      try {
        const newsFlashesResponse = await newsApi.getNewsFlashes(
          makeQueryString(params)
        );
        newsFlashes =
          newsTransformers.newsFlashesResponseToNewsFlashes(
            newsFlashesResponse
          );
        if (!newsFlashes.length) {
          setIsLoading(false);
          return [];
        }
      } catch {
        setIsLoading(false);
        return [];
      }
      // Fetch likes and comments
      try {
        const newsFlashesIds = newsFlashes.map((newsFlash) =>
          newsFlash.id.replace('cms-', '')
        );

        const likesAndCommentsResponse = (await feedbackApi.getLikesAndComments(
          newsFlashesIds
        )) as LikesAndCommentsResponse;

        likesAndCommentsMetaByArticleId =
          feedbackTransformers.likesAndCommentsResponseToLikesAndCommentsMetaByArticleId(
            likesAndCommentsResponse,
            user.userId
          );

        // Create "preview" articles
        const articles: ArticlePreview[] = newsFlashes.map((newsFlash) => {
          const likesAndComments =
            likesAndCommentsMetaByArticleId[newsFlash.id] || {};
          const article: ArticlePreview = {
            ...newsFlash,
            likesAndCommentsMeta: {
              ...likesAndComments,
            },
          };
          return article;
        });
        return articles;
      } catch {
        return newsFlashes;
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const updateLike = useCallback(async (articleId: string, like: boolean) => {
    let result: { isLikedByMe: boolean; likesCount: number } | null = null;
    try {
      const response = like
        ? ((await feedbackApi.sendLike(
            articleId.replace('cms-', '')
          )) as UpdateLikeResponse)
        : ((await feedbackApi.deleteLike(
            articleId.replace('cms-', '')
          )) as UpdateLikeResponse);
      result = {
        isLikedByMe: response.data.meta.likedByMe,
        likesCount: response.data.meta.total,
      };
    } catch {}
    return result;
  }, []);

  const handleLikeChange = useCallback(
    async (articleId: string, like: boolean) => {
      const result = await updateLike(articleId, like);
      if (!result) return;

      const newArticles = articles.map((article) => {
        if (article.id !== articleId) {
          return article;
        }
        if (!article.likesAndCommentsMeta) {
          return article;
        }
        const newArticle: ArticlePreview = {
          ...article,
          likesAndCommentsMeta: {
            ...article.likesAndCommentsMeta,
            isLikedByMe: result.isLikedByMe,
            likesCount: result.likesCount,
          },
        };
        return newArticle;
      });
      setArticles(newArticles);
    },
    [articles, updateLike]
  );

  useEffect(() => {
    if (user.isLoading) {
      return;
    }
    async function fetchInitialData() {
      const stories = await fetchStories({ locale, user });
      if (!stories) return;
      setArticles(stories);
    }
    fetchInitialData();
  }, [fetchStories, locale, user]);

  if (user.isLoading || isLoading) {
    return (
      <Stack gap={theme.spacing('md')}>
        <PageContentHeader text={translations.storiesTitle} />
        <StoriesSkeleton />
      </Stack>
    );
  }

  if (!articles.length) {
    return (
      <Stack gap={theme.spacing('md')}>
        <PageContentHeader text={translations.storiesTitle} />
        <NoStories />
      </Stack>
    );
  }

  return (
    <Stack gap={theme.spacing('md')}>
      <PageContentHeader text={translations.storiesTitle} />
      <Stack
        id={elementId}
        sx={(theme) => ({
          gap: theme.spacing('md'),
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        })}
      >
        {articles.map((article, index) => {
          const articleLink = getUrlLink(article.type, article.id) || '/';

          const isPortraitImage =
            Boolean(article.imageUrl) &&
            (article.imageHeight || 0) > (article.imageWidth || 0);

          const showLikes = article.type !== ArticleContentTypeEnum.Press;
          const showComments =
            article.type !== ArticleContentTypeEnum.Press &&
            !article.disableComments;

          return (
            <Stack
              key={article.id}
              id={`${elementId}-item-${index}`}
              sx={(theme) => ({
                flex: {
                  xs: '1 1 1',
                  md: '1 1 0',
                },
                overflow: 'hidden',
                borderRadius: theme.border.radius.md,
                maxWidth: {
                  md: `calc((100% - (${theme.spacing('md')} * 2)) / 3)`,
                },
              })}
            >
              <ArticleImage
                src={article.imageUrl}
                type={article.type}
                altText={article.imageAltText}
                to={articleLink}
                fetchSize={articles.length === 1 ? 'xl' : 'md'}
                isPortrait={isPortraitImage}
                sx={{ aspectRatio: 16 / 9 }}
                onClick={() => GAonClickHomeStoriesItem(article.title)}
              />
              <Stack
                sx={(theme) => ({
                  flexGrow: 1,
                  padding: theme.spacing('xs'),
                  backgroundColor: theme.colors.surface.inversePrimary,
                })}
              >
                <ArticleTitle
                  title={article.title}
                  to={articleLink}
                  color={theme.colors.text.inversePrimary}
                  variant="h3"
                  onClick={() => GAonClickHomeStoriesItem(article.title)}
                />
                {article.subTitle && (
                  <TypographyWithMaxLines
                    maxLines={3}
                    variant="body2"
                    sx={(theme) => ({
                      color: theme.colors.text.inversePrimary,
                      marginBottom: theme.spacing('xxs'),
                    })}
                  >
                    {article.subTitle}
                  </TypographyWithMaxLines>
                )}
                {article.readingTime !== undefined && (
                  <Typography
                    variant="body2"
                    sx={(theme) => ({
                      color: theme.colors.text.inversePrimary,
                      marginBottom: theme.spacing('xxs'),
                    })}
                  >
                    {textUtils.replaceTranslationAliases(
                      translations.readingTime,
                      { readingTime: article.readingTime + 1 }
                    )}
                  </Typography>
                )}
                <Stack
                  sx={() => ({
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                    marginTop: 'auto',
                    width: '100%',
                  })}
                >
                  <ArticleTags
                    tags={article.tags}
                    color={theme.colors.text.inversePrimary}
                  />
                  {(showLikes || showComments) &&
                    article.likesAndCommentsMeta && (
                      <Stack
                        sx={() => ({
                          flexDirection: 'row',
                          marginLeft: theme.spacing('xs'),
                        })}
                      >
                        {showLikes && (
                          <LikeButton
                            id={`${elementId}-item-${index}-like`}
                            isLikedByMe={
                              article.likesAndCommentsMeta.isLikedByMe
                            }
                            likesCount={article.likesAndCommentsMeta.likesCount}
                            color="inversePrimary"
                            sx={(theme) => ({
                              marginRight: showComments
                                ? theme.spacing('xs')
                                : 0,
                            })}
                            onClick={() => {
                              const like =
                                !article.likesAndCommentsMeta?.isLikedByMe;
                              handleLikeChange(article.id, like);
                              if (like) GAonLikeSent(article.title, true);
                            }}
                          />
                        )}
                        {showComments && (
                          <CommentButton
                            id={`${elementId}-item-${index}-comment`}
                            isCommentedByMe={
                              article.likesAndCommentsMeta.isCommentedByMe
                            }
                            commentsCount={
                              article.likesAndCommentsMeta.commentsCount
                            }
                            color="inversePrimary"
                            to={articleLink}
                            onClick={() => GAonCommentFeedClick(article.title)}
                          />
                        )}
                      </Stack>
                    )}
                </Stack>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
      <Button
        id={`${elementId}-read-more`}
        to="/feed?type=story"
        variant="linkButton"
        component={ReactRouterLink}
        onClick={GAonClickHomeStoriesReadMore}
        sx={{ alignSelf: 'end' }}
      >
        {translations.readMoreStories}
      </Button>
    </Stack>
  );
};

export { Stories };
