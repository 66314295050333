import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { IconButton, ProfileMiniList } from 'componentsNew';
import { ExternalPerson } from 'componentsNew/PersonsInput/ExternalPersonsInput';
import { InternalPerson } from 'componentsNew/PersonsInput/InternalPersonsInput';
import { PersonsInputSheet } from 'componentsNew/PersonsInput/PersonsInputSheet';
import { PageContentHeader } from 'layout';
import { forwardRef, useState } from 'react';

export type ArticlePersonsInputProps = {
  sx?: SxProps<Theme>;
  id?: string;
  ariaDescribedBy?: string;
  error?: boolean;
  usePageContentHeader?: boolean;
  internalPersons: InternalPerson[];
  externalPersons: ExternalPerson[];
  hideInternalPersons?: boolean;
  hideExternalPersons?: boolean;
  limit?: number;
  labels?: {
    title?: string;
    sheetTitle?: string;
  };
  onInternalPersonChange?: (value: InternalPerson[]) => void;
  onExternalPersonChange?: (value: ExternalPerson[]) => void;
};

const ArticlePersonsInput = forwardRef<
  HTMLDivElement,
  ArticlePersonsInputProps
>(
  (
    {
      sx,
      id = 'article-persons-edit',
      error,
      usePageContentHeader = false,
      ariaDescribedBy,
      internalPersons,
      externalPersons,
      hideInternalPersons,
      hideExternalPersons,
      limit,
      labels = {},
      onInternalPersonChange,
      onExternalPersonChange,
    },
    ref
  ) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
      <>
        <Stack
          ref={ref}
          aria-describedby={ariaDescribedBy}
          sx={[
            (theme) => ({
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: theme.spacing('xs'),
              ...(error && {
                border: `1px solid ${theme.colors.border.critical}`,
                padding: theme.spacing('xxs'),
                borderRadius: theme.border.radius.sm,
              }),
            }),
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        >
          <Stack sx={(theme) => ({ gap: theme.spacing('xs'), flexGrow: 1 })}>
            {labels.title &&
              (usePageContentHeader ? (
                <PageContentHeader
                  text={labels.title}
                  sx={() => ({
                    borderBottom: '0',
                    padding: 0,
                  })}
                />
              ) : (
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '2rem',
                  }}
                >
                  {labels.title}
                </Typography>
              ))}
            <ProfileMiniList
              elementId={id}
              internalPersons={internalPersons}
              externalPersons={externalPersons}
            />
          </Stack>

          {!internalPersons.length && !externalPersons.length ? (
            <IconButton
              id={`${id}-sheet-button`}
              onClick={() => setOpen(!open)}
              type="plus"
            />
          ) : (
            <IconButton
              id={`${id}-sheet-button`}
              onClick={() => setOpen(!open)}
              type="pencil"
            />
          )}
        </Stack>
        <PersonsInputSheet
          elementId={`${id}-persons-input`}
          open={open}
          setOpen={setOpen}
          title={labels.sheetTitle}
          internalPersons={internalPersons}
          externalPersons={externalPersons}
          onInternalPersonsChange={onInternalPersonChange}
          onExternalPersonsChange={onExternalPersonChange}
          hideInternalPersons={hideInternalPersons}
          hideExternalPersons={hideExternalPersons}
          limit={limit}
        />
      </>
    );
  }
);

export { ArticlePersonsInput };
