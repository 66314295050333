import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { KeyboardKeyEnum } from 'enums';
import { KeyboardEvent, useCallback } from 'react';

export type FormProps = {
  id?: string;
  sx?: SxProps<Theme>;
  preventSubmitOnEnter?: boolean;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  children: React.ReactNode | React.ReactNode[];
};

const Form = ({
  id,
  sx,
  preventSubmitOnEnter = true,
  onSubmit,
  children,
}: FormProps) => {
  const preventEnterKeySubmission = useCallback(
    (e: KeyboardEvent<HTMLFormElement>) => {
      const { key, target } = e;

      if (
        key !== KeyboardKeyEnum.Enter ||
        target instanceof HTMLTextAreaElement
      ) {
        return;
      }

      e.preventDefault();
    },
    []
  );

  return (
    <Box
      id={id}
      sx={sx}
      component="form"
      position="relative"
      onSubmit={(e) => {
        e.stopPropagation();

        if (!onSubmit) {
          return;
        }

        onSubmit(e);
      }}
      noValidate={true}
      onKeyDown={preventSubmitOnEnter ? preventEnterKeySubmission : undefined}
    >
      {children}
    </Box>
  );
};

export { Form };
