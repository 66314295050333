import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { translations } from 'translations';

type TablePaginationProps = {
  elementId?: string;
  sx?: SxProps<Theme>;
  count: number;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  disabled?: boolean;
  hideRowsPerPage?: boolean;
  onPageChange: (value: number) => void;
  onRowsPerPageChange: (value: number) => void;
};

const TablePagination = ({
  elementId,
  sx,
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  disabled,
  hideRowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}: TablePaginationProps) => {
  return (
    <Stack
      id={elementId}
      sx={[
        (theme) => ({
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
          gap: theme.spacing('sm'),
          justifyContent: hideRowsPerPage ? 'center' : 'space-between',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {!hideRowsPerPage && (
        <Stack
          sx={() => ({
            flexDirection: 'row',
            alignItems: 'center',
          })}
        >
          <Typography
            variant="body2"
            sx={(theme) => ({ color: theme.colors.text.tertiary })}
          >{`${translations.paginationRowsPerPage}:`}</Typography>
          <Select
            value={rowsPerPage}
            disabled={disabled}
            onChange={(e) => {
              const value = Number(e.target.value);
              onRowsPerPageChange(value);
            }}
            sx={(theme) => ({
              height: '2rem',
              backgroundColor: theme.colors.surface.secondary,
              marginLeft: theme.spacing('sm'),
            })}
          >
            {rowsPerPageOptions.map((option, index) => (
              <MenuItem
                key={option || `pagination-select-${index}`}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
      <Pagination
        count={count}
        page={page}
        variant="outlined"
        disabled={disabled}
        hidePrevButton={page === 1}
        hideNextButton={page === count}
        onChange={(_e, page) => onPageChange(page)}
      />
    </Stack>
  );
};

export { TablePagination };
