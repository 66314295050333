import Divider from '@mui/material/Divider';

const FormatMenuDivider = () => {
  return (
    <Divider
      orientation="vertical"
      sx={() => ({
        height: `calc(2rem + 0.25rem * 2)`,
        margin: `-0.25rem 0.25rem`,
      })}
    />
  );
};
export { FormatMenuDivider };
