import Select, { SelectProps } from '@mui/material/Select';

import { ReactComponent as ChevronDown } from './BasicSelectIcon.svg';

const BasicSelect = <T,>(props: SelectProps<T>) => {
  return (
    <Select IconComponent={ChevronDown} {...props}>
      {props.children}
    </Select>
  );
};

export { BasicSelect };
