import Box, { BoxProps } from '@mui/material/Box';
import { useMemo, useState } from 'react';

import * as helpers from './helpers';

type ImageProps = {
  sx?: BoxProps['sx'];
  src: string;
  alt?: string;
  title?: string;
  originalHeight?: number;
  originalWidth?: number;
  aspectRatio?: number;
  elementId?: string;
  disableSrcSet?: boolean;
  disablePreload?: boolean;
  // The "sizes" prop in <img> specifies the image's display width
  // based on viewport size, e.g. sizes="(max-width: 600px) 480px, 800px"
  // adjusts the image used for different layouts.
  sizes?: string;
};

const Image = ({
  sx,
  src,
  alt,
  title,
  originalHeight,
  originalWidth,
  aspectRatio,
  elementId,
  disableSrcSet,
  disablePreload,
  sizes = '100vw',
}: ImageProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { originalSrc, preloadSrc, isCmsImage } = useMemo(
    () =>
      helpers.getSrc({
        pathOrUrl: src,
        originalWidth,
        originalHeight,
      }),
    [originalHeight, src, originalWidth]
  );

  const srcSet = useMemo(
    () =>
      isCmsImage && !disableSrcSet
        ? helpers.getSrcSet({
            src: originalSrc,
            originalWidth,
            originalHeight,
          })
        : undefined,
    [isCmsImage, disableSrcSet, originalSrc, originalWidth, originalHeight]
  );

  if (!originalSrc) {
    return null;
  }

  return (
    <>
      {!disablePreload && (
        <Box
          id={elementId}
          component="img"
          alt={alt}
          title={title}
          src={preloadSrc}
          sx={[
            (theme) => ({
              display: preloadSrc && isLoading ? 'block' : 'none',
              aspectRatio,
              objectFit: 'cover',
              width: '100%',
              backgroundColor: theme.colors.surface.tertiary,
            }),
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        />
      )}
      <Box
        id={elementId}
        component="img"
        alt={alt}
        title={title}
        src={originalSrc}
        srcSet={srcSet}
        sizes={sizes}
        onLoad={() => setIsLoading(false)}
        sx={[
          (theme) => ({
            display: isLoading ? 'none' : 'block',
            aspectRatio,
            objectFit: 'cover',
            width: '100%',
            maxWidth: `${originalWidth}px`,
            backgroundColor: theme.colors.surface.tertiary,
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      />
    </>
  );
};

export { Image };
