import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PromptResult } from 'api/aiSearch/types';
import { useUser } from 'components/Context/User';
import { AlertBar, Icon, Tooltip } from 'componentsNew';
import { Sheet } from 'componentsNew';
import { useSnackbar } from 'context';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';
import { GAonAIClearClick, GAonAISendPromptClick } from 'utils/analytics';

import { ReactComponent as RobotIcon } from '../icons/Robot.svg';
import { AIChatBubblePrompt } from './AIChatBubblePrompt';
import { AIChatBubbleResult } from './AIChatBubbleResult';
import { AIChatDisclaimer } from './AIChatDisclaimer';
import { AIChatInput } from './AIChatInput';
import { AIChatIntroduction } from './AIChatIntroduction';

const elementId = 'ai-chat-sheet';

export type ChatItemResult = {
  type: 'result';
  result: PromptResult;
  prompt: string;
  isDisliked: boolean;
  isLiked: boolean;
};

export type ChatItem = { type: 'prompt'; prompt: string } | ChatItemResult;

type AIChatSheetProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const AIChatSheet = ({ open, setOpen }: AIChatSheetProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chatInput, setChatInput] = useState<string>('');
  const [chatItems, setChatItems] = useState<ChatItem[]>([]);
  const [isAutoScrollDisabled, setIsAutoScrollDisabled] =
    useState<boolean>(false);

  const user = useUser();
  const { showSnackbar } = useSnackbar();

  const chatItemsBottomRef = useRef<HTMLDivElement | null>(null);
  const chatItemsRef = useRef<HTMLDivElement | null>(null);
  const prevScrollTopPosition = useRef<number>(0);

  const isUserProfileComplete = useMemo(
    () => Boolean(user.divisionId) && Boolean(user.countryId),
    [user.countryId, user.divisionId]
  );

  const handlePromptSubmit = useCallback(() => {
    if (isLoading || !chatInput || !isUserProfileComplete) {
      return;
    }
    GAonAISendPromptClick(chatInput);

    const chatItemPrompt: ChatItem = {
      type: 'prompt',
      prompt: chatInput,
    };

    const chatItemResult: ChatItem = {
      type: 'result',
      prompt: chatInput,
      result: {
        text: '',
        sources: [],
        page: 0,
      },
      isDisliked: false,
      isLiked: false,
    };

    const newChatItems = [...chatItems, chatItemPrompt, chatItemResult];
    setChatItems(newChatItems);
    setChatInput('');
  }, [chatInput, isLoading, isUserProfileComplete, chatItems]);

  const onResultStreamStart = useCallback(() => {
    setIsLoading(true);
  }, []);

  const onResultStreamDone = useCallback(
    (newChatItem: ChatItemResult, error?: boolean) => {
      const newChatItems = [...chatItems];

      if (error) {
        newChatItems.splice(chatItems.length - 1, 1);

        showSnackbar({
          type: 'error',
          text: translations.aiChatErrorGeneric,
        });
      } else {
        newChatItems.splice(chatItems.length - 1, 1, newChatItem);
      }

      setChatItems(newChatItems);
      setIsLoading(false);
    },
    [chatItems, showSnackbar]
  );

  const onRetry = useCallback(
    (currentPage: number, prompt: string) => {
      if (isLoading || !prompt) {
        return;
      }

      const nextPage = currentPage + 1;

      const chatItemResult: ChatItem = {
        type: 'result',
        prompt: prompt,
        result: {
          text: '',
          sources: [],
          page: nextPage,
        },
        isDisliked: false,
        isLiked: false,
      };

      const newChatItems = [...chatItems, chatItemResult];
      setChatItems(newChatItems);
    },
    [chatItems, isLoading]
  );

  const onDisliked = useCallback(
    (chatItemIndex: number) => {
      const newChatItems = chatItems.map((chatItem, index) => {
        if (index === chatItemIndex && chatItem.type === 'result') {
          return {
            ...chatItem,
            isDisliked: true,
          };
        }
        return chatItem;
      });
      setChatItems(newChatItems);
    },
    [chatItems]
  );

  const onLiked = useCallback(
    (chatItemIndex: number) => {
      const newChatItems = chatItems.map((chatItem, index) => {
        if (index === chatItemIndex && chatItem.type === 'result') {
          return {
            ...chatItem,
            isLiked: true,
          };
        }
        return chatItem;
      });
      setChatItems(newChatItems);
    },
    [chatItems]
  );

  const setChatItemsBottomRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      chatItemsBottomRef.current = node;
      node.scrollIntoView({ behavior: 'auto' });
    }
  }, []);

  const scrollEventListener = useCallback((_event: Event) => {
    if (!chatItemsRef.current) {
      return;
    }

    const scrollTop = chatItemsRef.current.scrollTop;
    const scrollHeight = chatItemsRef.current.scrollHeight;
    const clientHeight = chatItemsRef.current.clientHeight;

    if (scrollTop === prevScrollTopPosition.current) {
      return;
    }

    const isBottomReached =
      Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1;

    if (isBottomReached) {
      setIsAutoScrollDisabled(false);
      prevScrollTopPosition.current = scrollTop;
      return;
    }

    const isScrollingUp = scrollTop < prevScrollTopPosition.current;

    if (isScrollingUp) {
      setIsAutoScrollDisabled(true);
      prevScrollTopPosition.current = scrollTop;
      return;
    }

    prevScrollTopPosition.current = scrollTop;
  }, []);

  // Add scroll event listener
  useEffect(() => {
    if (!chatItemsRef.current || chatItems.length < 1) {
      return;
    }

    const chatRefCurrent = chatItemsRef.current;
    chatItemsRef.current.addEventListener('scroll', scrollEventListener);

    return () => {
      chatRefCurrent.removeEventListener('scroll', scrollEventListener);
    };
  }, [scrollEventListener, chatItems.length]);

  // Scroll to bottom when opening Sheet
  useEffect(() => {
    if (!open) return;

    if (chatItemsBottomRef.current && chatItems.length) {
      chatItemsBottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [open, chatItems.length]);

  return (
    <Sheet
      id={elementId}
      open={open}
      width="33.125rem"
      showCloseIcon={false}
      onClose={() => setOpen(false)}
      sx={() => ({
        backgroundColor: '#FFFFFFDD',
        backdropFilter: 'blur(4px)',
        padding: 0,
      })}
    >
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          color: theme.colors.text.brand,
          backgroundColor: theme.colors.surface.primary,
          padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
          gap: theme.spacing(0.5),
        })}
      >
        <RobotIcon />
        <Typography variant="h3" component="h2" sx={{ marginRight: 'auto' }}>
          {translations.aiChatTitle}
        </Typography>
        <Tooltip title={translations.aiChatClear}>
          <Box>
            <Button
              size="small"
              variant="text"
              disabled={isLoading}
              startIcon={<Icon type="eraser" color="brandBase" />}
              sx={(theme) => ({
                '&.MuiButton-text:not(.Mui-disabled)': {
                  border: `1px solid ${theme.colors.surface.actionTertiaryDefault}`,
                },
              })}
              onClick={() => {
                setChatItems([]);
                GAonAIClearClick();
              }}
            >
              {translations.clear}
            </Button>
          </Box>
        </Tooltip>
        <IconButton
          id={`${elementId}-close`}
          size="small"
          aria-label={translations.close}
          onClick={() => setOpen(false)}
        >
          <Icon type="xMark" color="secondary" />
        </IconButton>
      </Stack>
      <TransitionGroup>
        {!chatItems.length && (
          <Collapse>
            <Stack
              sx={(theme) => ({
                flexDirection: 'row',
                justifyContent: 'center',
                padding: theme.spacing('md'),
              })}
            >
              <AIChatIntroduction />
            </Stack>
          </Collapse>
        )}
      </TransitionGroup>

      {chatItems.length > 0 && (
        <Stack
          ref={chatItemsRef}
          sx={(theme) => ({
            flexDirection: 'column',
            gap: theme.spacing('xs'),
            padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
            overflowY: 'auto',
          })}
        >
          {chatItems.map((chatItem, index) =>
            chatItem.type === 'prompt' ? (
              <AIChatBubblePrompt
                key={`${elementId}-item-${index}`}
                id={`${elementId}-item-${index}`}
                prompt={chatItem.prompt}
              />
            ) : (
              <AIChatBubbleResult
                key={`${elementId}-item-${index}`}
                id={`${elementId}-item-${index}`}
                chatItem={chatItem}
                isAutoScrollDisabled={isAutoScrollDisabled}
                isChatLoading={isLoading}
                onResultStreamStart={onResultStreamStart}
                onResultStreamDone={onResultStreamDone}
                onRetry={onRetry}
                onDisliked={() => onDisliked(index)}
                onLiked={() => onLiked(index)}
              />
            )
          )}
          <Box ref={setChatItemsBottomRef} />
        </Stack>
      )}

      <Stack
        sx={(theme) => ({
          gap: theme.spacing('xs'),
          padding: theme.spacing('md'),
          marginTop: 'auto',
        })}
      >
        {!user.isLoading && !isUserProfileComplete && (
          <AlertBar
            open
            fullWidth
            type="warning"
            title={translations.aiChatAlertIncompleteProfileTitle}
            text={translations.aiChatAlertIncompleteProfileText}
          />
        )}
        <AIChatInput
          loading={isLoading}
          disabled={!isUserProfileComplete}
          value={chatInput}
          onChange={setChatInput}
          onSubmit={handlePromptSubmit}
        />
        <AIChatDisclaimer />
      </Stack>
    </Sheet>
  );
};

export { AIChatSheet };
