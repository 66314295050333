import MenuItem from '@mui/material/MenuItem';
import { JobSortEnum } from 'api/jobs/types';
import { BasicSelect } from 'componentsNew/Select/BasicSelect';
import { SelectItem } from 'componentsNew/Select/SelectMenuItem';
import { translations } from 'translations';

const elementId = 'job-sort';

const selectItems: SelectItem<JobSortEnum>[] = [
  {
    value: JobSortEnum.Relevance,
    name: translations.jobsSortRelevance,
  },
  {
    value: JobSortEnum.Published,
    name: translations.jobsSortPublished,
  },
];

type JobSortProps = {
  activeSort: JobSortEnum;
  onChange: (value: JobSortEnum) => void;
};

const JobSort = ({ activeSort, onChange }: JobSortProps) => {
  return (
    <BasicSelect
      id={elementId}
      value={activeSort}
      size="small"
      onChange={(e) => {
        onChange(e.target.value as JobSortEnum);
      }}
      sx={() => ({
        width: '13rem',
      })}
    >
      {selectItems.map((selectItem) => (
        <MenuItem key={selectItem.value} value={selectItem.value}>
          {selectItem.name}
        </MenuItem>
      ))}
    </BasicSelect>
  );
};

export { JobSort };
