import Typography from '@mui/material/Typography';

export type FormFieldLabelProps = {
  id?: string;
  label: string;
  labelAsHtml?: boolean;
  size?: 'small' | 'large' | 'none';
  component?: React.ElementType;
  hide?: boolean;
  required?: boolean;
  htmlFor?: string;
};

const FormFieldLabel = ({
  id,
  label,
  labelAsHtml = false,
  size = 'large',
  component = 'label',
  hide = false,
  required,
  htmlFor,
}: FormFieldLabelProps) => {
  if (hide) {
    return (
      <Typography
        id={id}
        variant="srOnly"
        component={component}
        htmlFor={htmlFor}
        {...(labelAsHtml
          ? {
              dangerouslySetInnerHTML: { __html: label },
            }
          : {
              children: label,
            })}
      />
    );
  }
  if (size === 'large') {
    return (
      <Typography
        id={id}
        variant="h4"
        component={component}
        htmlFor={htmlFor}
        {...(labelAsHtml
          ? {
              dangerouslySetInnerHTML: { __html: label },
            }
          : {
              children: label,
            })}
        sx={(theme) => ({
          fontWeight: 400,
          color: theme.colors.text.tertiary,
          ...(required && {
            '&::after': {
              content: '" *"',
              color: theme.colors.text.critical,
            },
          }),
        })}
      />
    );
  }

  return (
    <Typography
      id={id}
      variant="body2"
      component={component}
      htmlFor={htmlFor}
      {...(labelAsHtml
        ? {
            dangerouslySetInnerHTML: { __html: label },
          }
        : {
            children: label,
          })}
      sx={(theme) => ({
        fontWeight: 'bold',
        ...(required && {
          '&::after': {
            content: '" *"',
            color: theme.colors.text.critical,
          },
        }),
      })}
    />
  );
};

export { FormFieldLabel };
