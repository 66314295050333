import { StackProps } from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ArticleListFilterDesktop } from './ArticleListFilterDesktop';
import { ArticleListFilterMobile } from './ArticleListFilterMobile';
import { Filter, FilterIds } from './helpers';

export type ArticleListFilterProps = {
  elementId: string;
  sx?: StackProps['sx'];
  title?: string;
  activeFilter: FilterIds;
  availableFilter: Filter;
  onChange: (value: FilterIds) => void;
};

const ArticleListFilter = ({
  elementId,
  sx,
  title,
  activeFilter,
  availableFilter,
  onChange,
}: ArticleListFilterProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  if (isDesktop) {
    return (
      <ArticleListFilterDesktop
        sx={sx}
        elementId={elementId}
        activeFilter={activeFilter}
        availableFilter={availableFilter}
        onChange={onChange}
      />
    );
  }

  return (
    <ArticleListFilterMobile
      sx={sx}
      title={title}
      elementId={elementId}
      activeFilter={activeFilter}
      availableFilter={availableFilter}
      onChange={onChange}
    />
  );
};
export { ArticleListFilter };
