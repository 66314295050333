import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { useUser } from 'components/Context/User';
import { ButtonWithMenu, Icon } from 'componentsNew';
import { translations } from 'translations';

const elementId = 'article-action-button';

export type ArticleActionButtonProps = {
  sx?: SxProps<Theme>;
  editPath: string;
};

const ArticleActionButton = ({ sx, editPath }: ArticleActionButtonProps) => {
  const user = useUser();

  if (!user.isPortalEditor) {
    return null;
  }

  return (
    <ButtonWithMenu
      id={elementId}
      size="small"
      variant="text"
      placement="bottom-end"
      items={[
        {
          to: editPath,
          text: translations.edit,
          startIcon: <Icon type="pencil" color="brandBase" size={20} />,
        },
      ]}
      sx={[
        () => ({
          height: '2rem',
          width: '2rem',
          padding: '0 !important',
          minWidth: 'unset',
          flexShrink: 0,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Icon type="ellipsisHorizontal" color="brandBase" size={20} />
    </ButtonWithMenu>
  );
};

export { ArticleActionButton };
