import {
  InnovationProcessAndGuidesArticle,
  InnovationProcessAndGuidesCreateBody,
} from 'api/cms-innovation/types';

import { FormValues } from './ProcessAndGuidesForm';

export const transformFormValuesToCreateOrEditPayload = (data: FormValues) => {
  const transformed: InnovationProcessAndGuidesCreateBody = data;
  return transformed;
};

export const transformGetResponseToFormValues = (
  data: InnovationProcessAndGuidesArticle
) => {
  const transformed: FormValues = {
    title: data.title,
    preamble: data.preamble,
    focusAreas: data.focusAreas,
    version: data.version || '',
    tabs: data.tabs.map((tab) => ({
      id: tab.id,
      title: tab.title || '',
      chapters: tab.chapters.map((chapter) => ({
        id: chapter.id,
        title: chapter.title || '',
        body: chapter.body || '',
        resources: chapter.resources || {},
      })),
    })),
    contactDetails: data.contactDetails || '',
    displayOnHome: data.displayOnHome,
  };

  return transformed;
};

export const getSharepointFolderNamesFromTabs = (tabs: FormValues['tabs']) => {
  return tabs
    .map((tab) => {
      return tab.chapters.map((chapter) => {
        return chapter.resources?.sharepointFolderName;
      });
    })
    .flat()
    .filter(Boolean) as string[];
};
