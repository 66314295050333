import * as cmsInnovationApi from 'api/cms-innovation';
import { InnovationSimpleFocusArea } from 'api/cms-innovation/types';
import { SelectItem } from 'componentsNew/Select/SelectMenuItem';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type FilterOption = { id: string; name: string };

const FocusAreasContext = React.createContext<{
  focusAreas: InnovationSimpleFocusArea[] | null;
  focusAreaSelectItems: SelectItem[] | null;
  focusAreaFilterOptions: FilterOption[] | null;
  isFocusAreasError: boolean;
  isFocusAreasLoading: boolean;
  fetchFocusAreas: () => Promise<void>;
} | null>(null);

const { Consumer: FocusAreasConsumer } = FocusAreasContext;

type FocusAreasProviderProps = {
  children: React.ReactNode;
};

export function FocusAreasProvider({ children }: FocusAreasProviderProps) {
  const [isFocusAreasError, setIsFocusAreasError] = useState<boolean>(false);

  const [focusAreas, setFocusAreas] = useState<
    InnovationSimpleFocusArea[] | null
  >(null);

  const focusAreaSelectItems = useMemo(() => {
    if (focusAreas === null) {
      return null;
    }
    const items: SelectItem[] = focusAreas.map((focusArea) => ({
      value: focusArea.id,
      name: focusArea.title,
    }));
    return items;
  }, [focusAreas]);

  const focusAreaFilterOptions = useMemo(() => {
    if (focusAreas === null) {
      return null;
    }
    const items: FilterOption[] = focusAreas.map((focusArea) => ({
      id: focusArea.id,
      name: focusArea.title,
    }));
    return items;
  }, [focusAreas]);

  const fetchFocusAreas = useCallback(async () => {
    try {
      setFocusAreas(null);
      const data = await cmsInnovationApi.getSimpleFocusAreas();
      setFocusAreas(data);
      setIsFocusAreasError(false);
    } catch {
      setFocusAreas([]);
      setIsFocusAreasError(true);
    }
  }, []);

  useEffect(() => {
    fetchFocusAreas();
  }, [fetchFocusAreas]);

  const contextValue = useMemo(
    () => ({
      focusAreas,
      focusAreaSelectItems,
      focusAreaFilterOptions,
      isFocusAreasError,
      isFocusAreasLoading: focusAreas === null,
      fetchFocusAreas,
    }),
    [
      fetchFocusAreas,
      focusAreaSelectItems,
      focusAreaFilterOptions,
      focusAreas,
      isFocusAreasError,
    ]
  );

  return (
    <FocusAreasContext.Provider value={contextValue}>
      {children}
    </FocusAreasContext.Provider>
  );
}

export const useFocusAreas = () => {
  const context = useContext(FocusAreasContext);
  if (!context) {
    throw new Error('FocusAreasContext is not defined');
  }
  return context;
};

export { FocusAreasConsumer };
