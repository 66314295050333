import TiptapLink from '@tiptap/extension-link';
import { customValidators } from 'utils/form';

const LinkExtension = TiptapLink.configure({
  openOnClick: false,
  linkOnPaste: true,
  autolink: true,
  defaultProtocol: 'https',
  protocols: ['http', 'https'],
  isAllowedUri: (url) => {
    return customValidators.url(url);
  },
});

export { LinkExtension };
