import Skeleton from '@mui/material/Skeleton';

const JobContentSkeleton = () => (
  <>
    <Skeleton
      variant="rounded"
      animation="wave"
      sx={{ height: '9rem', marginTop: '0.75rem' }}
    />
    <Skeleton variant="rounded" animation="wave" sx={{ height: '3rem' }} />
    <Skeleton variant="rounded" animation="wave" sx={{ height: '3rem' }} />
    <Skeleton variant="rounded" animation="wave" sx={{ height: '30rem' }} />
  </>
);

export { JobContentSkeleton };
