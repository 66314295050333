import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import * as cmsInnovationApi from 'api/cms-innovation';
import { InnovationToolsAndTemplatesArticle } from 'api/cms-innovation/types';
import * as findApi from 'api/find';
import { InnovationListingItem } from 'api/find/types';
import * as documentsApi from 'api/sharepointdocuments';
import { FolderItem } from 'api/sharepointdocuments/types';
import headerBackground from 'assets/images/innovation-article-header-background-tools-and-templates.png';
import { FetchError } from 'componentsNew';
import { useSnackbar } from 'context';
import { AvenueRouteEnum, InnovationRouteEnum } from 'enums';
import { Page } from 'layout';
import {
  ArticleContactDetails,
  ArticleHeader,
  ArticleHeaderLeftColumn,
  ArticleHeaderRightColumn,
  ArticleSkeleton,
  TitlePreambleAndFocusAreas,
} from 'pagesInnovation/common';
import { LastModifiedDateAndVersion } from 'pagesInnovation/common/ArticlePage/LastModifiedDateAndVersion';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translations } from 'translations';

import { Chapters } from './Chapters';

const elementId = 'tools-and-templates';

const ToolsAndTemplates = () => {
  const [article, setArticle] =
    useState<InnovationToolsAndTemplatesArticle | null>(null);

  const [relatedArticles, setRelatedArticles] = useState<
    InnovationListingItem[] | null
  >(null);

  const [toolsAndTemplateDocuments, setToolsAndTemplateDocuments] = useState<
    FolderItem[] | null
  >(null);

  const [isError, setIsError] = useState<boolean>(false);

  const theme = useTheme();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { showSnackbar } = useSnackbar();

  const documentTitle = useMemo(
    () =>
      article
        ? [
            translations.innovationDocumentTitle,
            translations.innovationTypeToolsAndTemplates,
            article.title,
          ]
        : null,
    [article]
  );

  const documentBreadcrumbs = useMemo(
    () =>
      article
        ? [
            {
              text: translations.innovationTypeToolsAndTemplates,
              to: InnovationRouteEnum.ToolsAndTemplates,
            },
            { text: article.title },
          ]
        : null,
    [article]
  );

  useEffect(() => {
    if (params.id === article?.id || isError) {
      return;
    }
    const fetchArticle = async () => {
      try {
        setIsError(false);
        const data = await cmsInnovationApi.getToolsAndTemplatesArticle(
          params.id
        );
        setArticle(data);
      } catch (e: any) {
        if (e['status'] === 404) {
          history.push(AvenueRouteEnum.NotFound);
          return;
        }
        setIsError(true);
      }
    };
    fetchArticle();
  }, [article, history, isError, params.id]);

  useEffect(() => {
    const fetchRelatedArticles = async () => {
      if (!article) {
        return;
      }
      let articles: InnovationListingItem[] = [];
      try {
        const query = findApi.getInnovationItemsQueryString({
          limit: 5,
          filter: {
            focusAreas: article.focusAreas.map((focusArea) => focusArea.id),
          },
        });
        const response = await findApi.getInnovationItems(query);
        const data = response?.data?.data;
        if (data) {
          articles = data.items as InnovationListingItem[];
          articles = articles.filter(
            (relatedArticle) => relatedArticle.id !== article.id
          );
        }
      } catch {
      } finally {
        setRelatedArticles(articles);
      }
    };
    if (!article) {
      return;
    }
    if (!article.focusAreas.length) {
      setRelatedArticles([]);
      return;
    }
    fetchRelatedArticles();
  }, [article]);

  useEffect(() => {
    const fetchToolsAndTemplatesDocuments = async () => {
      if (!article) {
        return;
      }
      let _documents: FolderItem[] = [];
      try {
        const response = await documentsApi.readFolder(
          article.toolsAndTemplates.sharepointFolderName
        );
        const data = response?.data?.data;
        if (data) {
          _documents = data as FolderItem[];
        }
      } catch {
        showSnackbar({
          type: 'error',
          text: translations.resourcesFetchError,
        });
      } finally {
        setToolsAndTemplateDocuments(_documents);
      }
    };
    if (!article) {
      return;
    }
    if (!article.toolsAndTemplates.sharepointFolderName) {
      setToolsAndTemplateDocuments([]);
      return;
    }
    fetchToolsAndTemplatesDocuments();
  }, [article, showSnackbar]);

  if (isError) {
    return (
      <Page title={documentTitle} gridContainer>
        <FetchError />
      </Page>
    );
  }

  if (!article) {
    return (
      <Page title={documentTitle} gridContainer>
        <ArticleSkeleton />
      </Page>
    );
  }

  return (
    <Page title={documentTitle} breadcrumbs={documentBreadcrumbs} gridContainer>
      <Grid xs={12}>
        <ArticleHeader
          backgroundImage={headerBackground}
          articleActionButtonProps={{
            editPath: `${InnovationRouteEnum.ToolsAndTemplatesEdit}/${article.id}`,
          }}
        >
          <ArticleHeaderLeftColumn>
            <TitlePreambleAndFocusAreas
              title={article.title}
              preamble={article.preamble}
              focusAreas={article.focusAreas}
              color={theme.colors.text.brand}
            />
          </ArticleHeaderLeftColumn>
          <ArticleHeaderRightColumn>
            <LastModifiedDateAndVersion
              lastModifiedDate={article.lastModified.date}
            />
          </ArticleHeaderRightColumn>
        </ArticleHeader>
      </Grid>
      <Grid xs={12}>
        <Chapters
          chapters={article.chapters}
          toolsAndTemplateDocuments={toolsAndTemplateDocuments || []}
          relatedArticles={relatedArticles || []}
        />
      </Grid>
      <Grid xs={12} lg={8}>
        {article.contactDetails && (
          <ArticleContactDetails
            elementId={`${elementId}-contact-details`}
            htmlContent={article.contactDetails}
          />
        )}
      </Grid>
    </Page>
  );
};

export { ToolsAndTemplates };
