import { InputValidationRules } from 'react-hook-form';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';

import * as customValidators from './customValidators';

type ValidatorName = keyof InputValidationRules | keyof typeof customValidators;

export const getErrorMessage = (
  type: ValidatorName,
  params?: { displayName?: string; value?: string | number }
): string => {
  const displayName =
    params?.displayName || translations.formValidationFallbackDisplayName;

  switch (type) {
    case 'required':
      return textUtils.replaceTranslationAliases(
        translations.formValidationRequired,
        { displayName }
      );

    case 'maxLength':
      const maxLength = params?.value as number;
      if (!maxLength) {
        return translations.formValidationInvalid;
      }
      return textUtils.replaceTranslationAliases(
        translations.formValidationMaxLength,
        { displayName, maxLength }
      );

    case 'minLength':
      const minLength = params?.value as number;
      if (!minLength) {
        return translations.formValidationInvalid;
      }
      return textUtils.replaceTranslationAliases(
        translations.formValidationMinLength,
        { displayName, minLength }
      );
    case 'url':
      return textUtils.replaceTranslationAliases(
        translations.formValidationUrl,
        { displayName }
      );
    case 'pattern':
      return textUtils.replaceTranslationAliases(
        translations.formValidationPattern,
        { displayName }
      );
    default:
      return translations.formValidationInvalid;
  }
};

const trimValues = <T extends Record<string, any>>(data: T) => {
  const newData: Record<string, any> = {};
  Object.keys(data).forEach((key) => {
    newData[key] = typeof data[key] === 'string' ? data[key].trim() : data[key];
  });
  return newData as T;
};

export { customValidators, trimValues };
