import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { translations } from 'translations';

const Intro = () => {
  return (
    <Stack
      sx={(theme) => ({
        borderRadius: theme.border.radius.xxl,
        backgroundColor: theme.colors.surface.primary,
        padding: theme.spacing('md'),
        color: theme.colors.text.brand,
      })}
    >
      <Typography variant="h3" component="p">
        {translations.innovationIntro1}
      </Typography>
      <Typography
        variant="h3"
        component="p"
        sx={(theme) => ({ fontWeight: theme.typography.fontWeightLight })}
      >
        {translations.innovationIntro2}
      </Typography>
    </Stack>
  );
};

export { Intro };
