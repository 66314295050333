import ToggleButton from '@mui/material/ToggleButton';
import { useCurrentEditor } from '@tiptap/react';
import { Icon } from 'componentsNew';
import {
  LinkModal,
  LinkValue,
} from 'componentsNew/RichText/extensions/LinkExtension/LinkModal';
import { useSnackbar } from 'context';
import { useCallback, useState } from 'react';
import { translations } from 'translations';

const elementId = 'rich-text-format-menu-link';
const title = translations.richTextFormatLink;

const Link = () => {
  const [linkModal, setLinkModal] = useState<{
    open: boolean;
    defaultValue?: LinkValue;
  }>({ open: false });

  const { editor } = useCurrentEditor();
  const { showSnackbar } = useSnackbar();

  const onClick = useCallback(() => {
    if (!editor) {
      return;
    }
    const previousUrl = editor.getAttributes('link')['href'] || '';
    setLinkModal({ open: true, defaultValue: { url: previousUrl } });
  }, [editor]);

  const handleLink = useCallback(
    (data: LinkValue) => {
      if (!editor) {
        return;
      }
      try {
        const unlink = linkModal.defaultValue && !data.url;
        if (unlink) {
          editor.chain().focus().extendMarkRange('link').unsetLink().run();
        } else {
          editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: data.url })
            .run();
        }
        setLinkModal({ open: false });
      } catch (e) {
        showSnackbar({
          text: translations.richTextFormatLinkError,
          type: 'error',
        });
      }
    },
    [editor, linkModal.defaultValue, showSnackbar]
  );

  return (
    <>
      {linkModal.open && (
        <LinkModal
          defaultValue={linkModal.defaultValue}
          onClose={() => setLinkModal({ open: false })}
          onSubmit={handleLink}
        />
      )}
      <ToggleButton
        id={elementId}
        title={title}
        value="link"
        aria-label={title}
        selected={editor ? editor.isActive('link') : false}
        onClick={onClick}
      >
        <Icon type="link" color="secondary" size={20} />
      </ToggleButton>
    </>
  );
};
export { Link };
