import ToggleButton from '@mui/material/ToggleButton';
import { useCurrentEditor } from '@tiptap/react';
import { useCallback } from 'react';
import { translations } from 'translations';

const elementId = 'rich-text-format-menu-underline';
const title = translations.richTextFormatUnderline;

const Underline = () => {
  const { editor } = useCurrentEditor();

  const onClick = useCallback(() => {
    if (!editor) {
      return;
    }
    editor.chain().focus().toggleUnderline().run();
  }, [editor]);

  return (
    <ToggleButton
      id={elementId}
      title={title}
      value="underline"
      aria-label={title}
      selected={editor ? editor.isActive('underline') : false}
      onClick={onClick}
      sx={{ textDecoration: 'underline' }}
    >
      U
    </ToggleButton>
  );
};
export { Underline };
