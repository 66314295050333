export enum InnovationListingItemTypeEnum {
  CaseStudy = 'aadw:innovation-case-study',
  Training = 'aadw:innovation-training',
  Webinar = 'aadw:innovation-webinar',
  ProcessAndGuides = 'aadw:innovation-process-and-guides',
  ToolsAndTemplates = 'aadw:innovation-tools-and-templates',
}

export type InnovationListingItem = {
  id: string;
  type: InnovationListingItemTypeEnum;
  title: string;
  preamble?: string;
  imageUrl?: string;
  imageAltText?: string;
  lastModifiedDate: string;
  focusAreas?: { id: string; title: string }[];
};

export type InnovationListingItemsRequestParams = {
  page?: number;
  limit?: number;
  search?: string;
  filter?: {
    types?: string[];
    focusAreas?: string[];
  };
};
