import Stack from '@mui/material/Stack';
import * as documentsApi from 'api/sharepointdocuments';
import { FolderItem } from 'api/sharepointdocuments/types';
import { IconButton } from 'componentsNew';
import { useSnackbar } from 'context';
import { PageContentHeader } from 'layout';
import { ResourcesInputSheet } from 'pagesInnovation/common/ArticleForm/ResourcesInputSheet';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { translations } from 'translations';

import { ToolsAndTemplatesDocumentsList } from '../ToolsAndTemplates/ToolsAndTemplatesDocumentsList';

export type ToolsAndTemplatesDocumentsEditProps = {
  id?: string;
  ariaDescribedBy?: string;
  error?: boolean;
  documentsFolderId?: string;
  onChangeDocumentsFolderId: (DocumentsFolderId: string) => void;
};

const ToolsAndTemplatesDocumentsEdit = forwardRef<
  HTMLDivElement,
  ToolsAndTemplatesDocumentsEditProps
>(
  (
    {
      id = 'tools-and-templates-documents-edit',
      ariaDescribedBy,
      error,
      documentsFolderId,
      onChangeDocumentsFolderId,
    },
    ref
  ) => {
    const [open, setOpen] = useState<boolean>(false);

    const [documents, setDocuments] = useState<FolderItem[] | null>(null);

    const { showSnackbar } = useSnackbar();

    const fetchDocuments = useCallback(
      async (folderId: string | undefined) => {
        if (!folderId) {
          setDocuments([]);
          return;
        }

        let _documents: FolderItem[] = [];
        try {
          const response = await documentsApi.readFolder(folderId);
          const data = response?.data?.data;
          if (data) {
            _documents = data as FolderItem[];
          }
        } catch {
          showSnackbar({
            type: 'error',
            text: translations.resourcesFetchError,
          });
        } finally {
          setDocuments(_documents);
        }
      },
      [showSnackbar]
    );

    useEffect(() => {
      if (documents) {
        return;
      }

      fetchDocuments(documentsFolderId);
    }, [fetchDocuments, documentsFolderId, documents]);

    return (
      <Stack
        ref={ref}
        aria-describedby={ariaDescribedBy}
        sx={(theme) => ({
          gap: theme.spacing('sm'),
          ...(error && {
            border: `1px solid ${theme.colors.border.critical}`,
            padding: theme.spacing('xxs'),
            borderRadius: theme.border.radius.sm,
          }),
        })}
      >
        <PageContentHeader
          text={translations.toolsAndTemplates}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {!documents ? null : !documents.length ? (
            <IconButton
              id={`${id}-sheet-button`}
              onClick={() => setOpen(!open)}
              type="plus"
            />
          ) : (
            <IconButton
              id={`${id}-sheet-button`}
              onClick={() => setOpen(!open)}
              type="pencil"
            />
          )}
        </PageContentHeader>
        {open && documents && (
          <ResourcesInputSheet
            elementId={id}
            title={translations.toolsAndTemplatesAdd}
            documentsFolderId={documentsFolderId}
            documents={documents}
            hideLinks={true}
            onChangeDocumentsFolderId={onChangeDocumentsFolderId}
            onChangeDocuments={setDocuments}
            setOpen={setOpen}
          />
        )}
        <ToolsAndTemplatesDocumentsList
          elementId={`${id}-list`}
          documents={documents || []}
          isDocumentsLoading={!documents}
        />
      </Stack>
    );
  }
);

export { ToolsAndTemplatesDocumentsEdit };
