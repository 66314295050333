import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageTitle from 'components/PageTitle';
import { Icon } from 'componentsNew';
import { GlobalStyles } from 'componentsNew/GlobalStyles';
import { AvenueRouteEnum, InnovationRouteEnum } from 'enums';
import { ConfettiCanvas } from 'layout/ConfettiCanvas/ConfettiCanvas';
import {
  GLOBAL_WEBCAST_IFRAME_TRANSITION,
  GlobalWebcast,
} from 'pagesAvenue/Home/GlobalWebcast/GlobalWebcast';
import React, { useMemo } from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

export const PAGE_GRID_GAP = '1.5rem';
export const PAGE_GRID_PADDING_DESKTOP = '1.5rem';
export const PAGE_GRID_PADDING_MOBILE = '1rem';

type PageProps = {
  title: string | string[] | null;
  isConfettiActive?: boolean;
  isGlobalWebcastActive?: boolean;
  overrideBlueRibbonHeight?: number;
  breadcrumbs?: { to?: string; text: string }[] | null;
  gridContainer?: boolean;
  children: React.ReactNode;
};

const Page = ({
  title,
  isConfettiActive = false,
  isGlobalWebcastActive = false,
  overrideBlueRibbonHeight,
  breadcrumbs,
  gridContainer = false,
  children,
}: PageProps) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  const location = useLocation();

  const blueRibbonHeight = useMemo(() => {
    const blueRibbonMinHeight = isLargeScreen ? 370 : 405;

    if (!overrideBlueRibbonHeight) {
      return blueRibbonMinHeight;
    }
    if (overrideBlueRibbonHeight < blueRibbonMinHeight) {
      return blueRibbonMinHeight;
    }
    return overrideBlueRibbonHeight;
  }, [isLargeScreen, overrideBlueRibbonHeight]);

  const isPageWithoutBlueRibbon = useMemo(() => {
    return (
      location.pathname.startsWith(AvenueRouteEnum.Jobs) ||
      location.pathname.startsWith(InnovationRouteEnum.Home)
    );
  }, [location.pathname]);

  const disableConfetti = useMemo(() => {
    return isGlobalWebcastActive && !isLargeScreen;
  }, [isGlobalWebcastActive, isLargeScreen]);

  return (
    <>
      <GlobalStyles />
      {title && <PageTitle titles={Array.isArray(title) ? title : [title]} />}
      <Box
        sx={(theme) => ({
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          flexGrow: 1,
          background: isPageWithoutBlueRibbon
            ? theme.colors.surface.secondary
            : theme.colors.surface.primary,
          paddingBottom: theme.spacing('md'),
        })}
      >
        {!isPageWithoutBlueRibbon && (
          <Box
            sx={(theme) => ({
              position: 'absolute',
              backgroundColor: theme.colors.surface.actionPrimaryDefault,
              height: `${blueRibbonHeight}px`,
              transition: GLOBAL_WEBCAST_IFRAME_TRANSITION,
              overflow: 'hidden',
              width: '100%',
            })}
          >
            {isConfettiActive && !disableConfetti && (
              <ConfettiCanvas
                height={isGlobalWebcastActive ? 1500 : blueRibbonHeight}
              />
            )}
          </Box>
        )}

        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            minWidth: 0,
            width: theme.breakpoints.values.xl,
            maxWidth: theme.breakpoints.values.xl,
            // TODO: Remove z-index when ConfettiCanvas is removed
            zIndex: 1,
          })}
        >
          {isGlobalWebcastActive && <GlobalWebcast />}
          <Grid
            container
            // Spacing between grid items
            spacing={PAGE_GRID_GAP}
            sx={() => ({
              // Spacing around the grid
              padding: {
                xs: PAGE_GRID_PADDING_MOBILE,
                sm: PAGE_GRID_PADDING_DESKTOP,
                xxl: `${PAGE_GRID_PADDING_DESKTOP} 0`,
              },
            })}
          >
            {breadcrumbs && (
              <Grid xs={12} sx={{ marginTop: { xs: '0.5rem', sm: 0 } }}>
                <Breadcrumbs
                  separator={
                    <Icon
                      type="chevronRight"
                      color={
                        isPageWithoutBlueRibbon ? 'secondary' : 'inversePrimary'
                      }
                      size={20}
                    />
                  }
                  sx={(theme) => ({
                    li: {
                      lineHeight: 1,
                      '> .MuiTypography-root, > .MuiLink-root': {
                        color: isPageWithoutBlueRibbon
                          ? theme.colors.text.tertiary
                          : theme.colors.text.inversePrimary,
                        textDecoration: 'none',
                      },
                      '&:last-of-type': {
                        '> .MuiTypography-root, > .MuiLink-root': {
                          color: isPageWithoutBlueRibbon
                            ? theme.colors.text.disabled
                            : theme.colors.text.inverseTertiary,
                        },
                      },
                    },
                  })}
                >
                  {breadcrumbs.map((breadcrumb, i) =>
                    breadcrumb.to ? (
                      <Link
                        key={`page-bread-crumb-${i}`}
                        variant="body2"
                        component={ReactRouterLink}
                        to={breadcrumb.to}
                      >
                        {breadcrumb.text}
                      </Link>
                    ) : (
                      <Typography key={`page-bread-crumb-${i}`} variant="body2">
                        {breadcrumb.text}
                      </Typography>
                    )
                  )}
                </Breadcrumbs>
              </Grid>
            )}
            {gridContainer ? children : <Grid xs={12}>{children}</Grid>}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export { Page };
