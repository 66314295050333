import Stack from '@mui/material/Stack';
import { DateTimePicker } from 'componentsNew';
import { Component } from 'react';
import { translations } from 'translations';

import { Consumer } from '..';

class PublishDate extends Component {
  render() {
    return (
      <Consumer>
        {({ updatedArticle: { publishedDate }, onChangeArticle }) => {
          return (
            <Stack
              sx={(theme) => ({
                maxWidth: '17.5rem',
                gap: theme.spacing('sm'),
              })}
            >
              <label>{translations.formLabelPublishDate}</label>
              <DateTimePicker
                value={publishedDate ? new Date(publishedDate) : null}
                disabled={this.props.disabled}
                onChange={(value) =>
                  onChangeArticle({
                    publishedDate: value ? value.toISOString() : null,
                  })
                }
                sx={(theme) => ({
                  '.MuiInputBase-root:not(.Mui-disabled)': {
                    backgroundColor: theme.colors.surface.primary,
                  },
                })}
              />
            </Stack>
          );
        }}
      </Consumer>
    );
  }
}

export default PublishDate;
