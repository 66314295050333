import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Chip } from 'componentsNew';
import { Icon } from 'componentsNew/Icon/Icon';
import { useCallback, useMemo, useState } from 'react';
import { translations } from 'translations';

import { SelectItem, SelectMenuItem } from './SelectMenuItem';
import { SelectBaseProps } from './SingleSelect';

export type MultiSelectProps<T = {}> = {
  value: string[] | SelectItem<T>[];
  limit?: number;
  limitTags?: number;
  hideTags?: boolean;
  hideClearIcon?: boolean;
  onChange: (value: SelectItem<T>[]) => void;
} & SelectBaseProps<T>;

const MultiSelect = <T,>({
  id,
  value,
  items,
  error,
  fullWidth,
  placeholder,
  noItemsText,
  limit = -1,
  limitTags = -1,
  hideTags = false,
  hideClearIcon = false,
  disabled = false,
  disableItems = false,
  disableCheckIcon = false,
  disablePopupIcon = false,
  disableCloseOnSelect = false,
  filterSelectedItems = false,
  openOnFocus = true,
  inputProps,
  inputRef,
  required,
  sx,
  onInputChange,
  onChange,
  onBlur,
}: MultiSelectProps<T>) => {
  const [inputValue, setInputValue] = useState<string>('');

  const selectValue = useMemo(() => {
    if (!value.length) {
      return [];
    }
    if (typeof value[0] === 'object') {
      return value as SelectItem<T>[];
    }
    const valueAsItems = value
      .map((v) => items.find((item) => item.value === v))
      .filter(Boolean) as SelectItem<T>[];

    return valueAsItems;
  }, [items, value]);

  const isLimitReached = useMemo(() => {
    return limit > -1 && value.length >= limit;
  }, [limit, value]);

  const getOptionDisabled = useCallback(
    (option: SelectItem<T>) => {
      if (option.disabled || disableItems) {
        return true;
      }
      if (!isLimitReached) {
        return false;
      }
      const isSelected = selectValue.some(
        (item) => item.value === option.value
      );
      return !isSelected;
    },
    [disableItems, isLimitReached, selectValue]
  );

  const getChipMaxWidth = useCallback(() => {
    if (limitTags <= 0 || value.length < limitTags) {
      return undefined;
    }
    const isNumberVisible = value.length > limitTags;
    return isNumberVisible
      ? `calc((100% - 24px) / ${limitTags} - ${limitTags} * 2 * 2px)`
      : `calc((100% - 8px) / ${value.length} - ${value.length} * 2 * 2px)`;
  }, [limitTags, value]);

  return (
    <Autocomplete
      id={id}
      sx={sx}
      multiple
      autoHighlight
      disablePortal
      size="small"
      options={items}
      value={selectValue}
      inputValue={inputValue}
      fullWidth={fullWidth}
      disabled={disabled}
      disableCloseOnSelect={disableCloseOnSelect}
      openOnFocus={openOnFocus}
      popupIcon={
        disabled || disablePopupIcon ? null : (
          <Icon type="chevronDown" color="secondary" size={20} />
        )
      }
      clearIcon={
        hideClearIcon ? null : <Icon type="xMark" color="secondary" size={16} />
      }
      openText={translations.open}
      clearText={translations.clear}
      noOptionsText={noItemsText}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => `${option.name}-${option.value}`}
      getOptionDisabled={getOptionDisabled}
      limitTags={limitTags}
      filterSelectedOptions={filterSelectedItems}
      isOptionEqualToValue={(option, selected) =>
        option.value === selected.value
      }
      slotProps={{
        popper: {
          sx: {
            '.MuiAutocomplete-noOptions': {
              display: noItemsText ? 'block' : 'none',
            },
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          required={required && selectValue.length === 0}
          inputProps={{
            ...params.inputProps,
            ...inputProps,
            disabled: disabled,
            readOnly: isLimitReached,
          }}
          sx={{
            '.MuiInputBase-root:not(.Mui-focused)': {
              '.MuiAutocomplete-tag.MuiChip-root': {
                maxWidth: getChipMaxWidth(),
              },
              '.MuiAutocomplete-input': {
                ...(selectValue.length > 0 && {
                  minWidth: '0px',
                  maxWidth: '0px',
                  padding: 0,
                }),
              },
            },
            '.MuiInputBase-root': {
              '.MuiAutocomplete-tag.MuiChip-root': {
                maxWidth: 'calc(100% - 6px)',
              },
            },
          }}
          placeholder={!selectValue.length ? placeholder : undefined}
          disabled={disabled}
          error={error}
        />
      )}
      renderTags={(value, getTagProps) => {
        if (hideTags) {
          return null;
        }
        return value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              {...tagProps}
              key={key}
              id={`${id}-chip-${index}`}
              size="small"
              color="default"
              variant="outlined"
              label={option.name}
              sx={{
                alignSelf: 'center',
              }}
            />
          );
        });
      }}
      renderOption={({ key, ...props }, option) => (
        <SelectMenuItem
          {...props}
          key={key}
          item={option}
          disableCheckIcon={disableCheckIcon}
        />
      )}
      onInputChange={(_e, value, reason) => {
        if (reason === 'reset') {
          return;
        }
        setInputValue(value);
        onInputChange && onInputChange(value);
      }}
      onChange={(_e, value) => {
        if (!disableCloseOnSelect) {
          setInputValue('');
        }
        onChange(value);
      }}
      onBlur={() => {
        setInputValue('');
        onBlur && onBlur();
      }}
    />
  );
};

export { MultiSelect };
