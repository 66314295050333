import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import * as cmsInnovationApi from 'api/cms-innovation';
import { InnovationTrainingArticle } from 'api/cms-innovation/types';
import headerBackground from 'assets/images/innovation-article-header-background-learning.png';
import {
  Chip,
  EmbedVideo,
  FetchError,
  Image,
  ProfileMiniList,
} from 'componentsNew';
import { MEDIA_IMAGE_ASPECT_RATIO } from 'componentsNew/MediaInput/MediaInput';
import { AvenueRouteEnum, InnovationRouteEnum } from 'enums';
import { Page } from 'layout';
import {
  ArticleBody,
  ArticleContactDetails,
  ArticleHeader,
  ArticleHeaderLeftColumn,
  ArticleHeaderRightColumn,
  ArticleResources,
  ArticleSkeleton,
  RelatedArticles,
  TimestampsAndNote,
  TitlePreambleAndFocusAreas,
} from 'pagesInnovation/common';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonInnovationArticleOwnerClick,
  GAonInnovationArticleRelatedClick,
  GAonInnovationArticleResourceDocumentClick,
  GAonInnovationArticleResourceLinkClick,
} from 'utils/analytics';

const elementId = 'training';

const Training = () => {
  const [article, setArticle] = useState<InnovationTrainingArticle | null>(
    null
  );
  const [isError, setIsError] = useState<boolean>(false);

  const history = useHistory();
  const params = useParams<{ id: string }>();
  const theme = useTheme();

  const documentTitle = useMemo(
    () =>
      article
        ? [
            translations.innovationDocumentTitle,
            translations.learning,
            article.title,
          ]
        : null,
    [article]
  );

  const documentBreadcrumbs = useMemo(
    () =>
      article
        ? [
            {
              text: translations.learning,
              to: InnovationRouteEnum.Learning,
            },
            { text: article.title },
          ]
        : null,
    [article]
  );

  useEffect(() => {
    if (params.id === article?.id || isError) {
      return;
    }
    const fetchArticle = async () => {
      try {
        setIsError(false);
        const data = await cmsInnovationApi.getTrainingArticle(params.id);
        setArticle(data);
      } catch (e: any) {
        if (e['status'] === 404) {
          history.push(AvenueRouteEnum.NotFound);
          return;
        }
        setIsError(true);
      }
    };
    fetchArticle();
  }, [article, history, isError, params.id]);

  if (isError) {
    return (
      <Page title={documentTitle} gridContainer>
        <FetchError />
      </Page>
    );
  }

  if (!article) {
    return (
      <Page title={documentTitle} gridContainer>
        <ArticleSkeleton />
      </Page>
    );
  }

  return (
    <Page title={documentTitle} breadcrumbs={documentBreadcrumbs} gridContainer>
      <Grid xs={12}>
        <ArticleHeader
          backgroundImage={headerBackground}
          articleActionButtonProps={{
            editPath: `${InnovationRouteEnum.TrainingEdit}/${article.id}`,
          }}
        >
          <ArticleHeaderLeftColumn>
            <Chip
              variant="outlined"
              size="small"
              label={translations.innovationTypeTraining}
              sx={(theme) => ({
                '&.MuiChip-outlined.MuiChip-outlinedDefault': {
                  fontWeight: theme.typography.fontWeightBold,
                  borderColor: theme.palette.secondary2[900],
                  color: theme.palette.secondary2[900],
                  backgroundColor: 'unset',
                },
              })}
            />
            <TitlePreambleAndFocusAreas
              title={article.title}
              preamble={article.preamble}
              focusAreas={article.focusAreas}
              color={theme.palette.secondary2[900]}
            />
          </ArticleHeaderLeftColumn>
          <ArticleHeaderRightColumn>
            {article.owners &&
              article.owners.internalPersons &&
              article.owners.internalPersons.length > 0 && (
                <ProfileMiniList
                  elementId={`${elementId}-owners`}
                  title={
                    article.owners.internalPersons.length > 1
                      ? `${translations.owners}:`
                      : `${translations.owner}:`
                  }
                  internalPersons={article.owners.internalPersons.map(
                    (owner) => ({
                      id: owner.oid,
                      name: owner.name,
                    })
                  )}
                  onInternalPersonClick={(user) =>
                    GAonInnovationArticleOwnerClick(user.name)
                  }
                />
              )}
          </ArticleHeaderRightColumn>
        </ArticleHeader>
      </Grid>
      <Grid xs={12} lg={8}>
        <Stack sx={(theme) => ({ gap: theme.spacing('sm') })}>
          {article.heroImageUrl && !article.heroVideoUrl && (
            <Image
              alt={article.heroAltText}
              title={article.heroAltText}
              src={article.heroImageUrl}
              originalHeight={article.heroImageHeight}
              originalWidth={article.heroImageWidth}
              aspectRatio={MEDIA_IMAGE_ASPECT_RATIO}
              sx={(theme) => ({ borderRadius: theme.border.radius.lg })}
            />
          )}
          {article.heroVideoUrl && (
            <EmbedVideo
              title={article.heroAltText}
              src={article.heroVideoUrl}
              aspectRatio={2}
              sx={(theme) => ({ borderRadius: theme.border.radius.lg })}
            />
          )}
          <TimestampsAndNote
            note={article.note}
            created={article.created}
            lastModified={article.lastModified}
          />
          <Divider
            sx={(theme) => ({
              margin: `${theme.spacing('xxs')} 0`,
              borderColor: theme.colors.border.surfacePrimary,
            })}
          />
          {article.body && (
            <ArticleBody
              elementId={`${elementId}-body`}
              htmlContent={article.body}
            />
          )}
          {article.contactDetails && (
            <ArticleContactDetails
              elementId={`${elementId}-contact-details`}
              htmlContent={article.contactDetails}
            />
          )}
        </Stack>
      </Grid>
      <Grid xs={12} lg={4}>
        <Stack sx={(theme) => ({ gap: theme.spacing('lg') })}>
          <ArticleResources
            elementId={`${elementId}-resources`}
            links={article.resources?.links || []}
            documentsFolderId={article.resources?.sharepointFolderName}
            onLinkClick={(link) =>
              GAonInnovationArticleResourceLinkClick(link.title)
            }
            onDocumentClick={(document) =>
              GAonInnovationArticleResourceDocumentClick(document.name)
            }
          />
          <RelatedArticles
            elementId={`${elementId}-related-articles`}
            articleId={article.id}
            focusAreas={article.focusAreas}
            onClick={(article) =>
              GAonInnovationArticleRelatedClick(article.title)
            }
          />
        </Stack>
      </Grid>
    </Page>
  );
};

export { Training };
