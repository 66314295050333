import ToggleButton from '@mui/material/ToggleButton';
import { useCurrentEditor } from '@tiptap/react';
import { useCallback } from 'react';
import { translations } from 'translations';

const elementId = 'rich-text-format-menu-highlight';
const title = translations.richTextFormatHighlight;

const Highlight = () => {
  const { editor } = useCurrentEditor();

  const onClick = useCallback(() => {
    if (!editor) {
      return;
    }
    editor.chain().focus().toggleHighlight().run();
  }, [editor]);

  return (
    <ToggleButton
      id={elementId}
      title={title}
      value="highlight"
      aria-label={title}
      selected={editor ? editor.isActive('highlight') : false}
      onClick={onClick}
    >
      Hi
    </ToggleButton>
  );
};
export { Highlight };
