import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import * as documentsApi from 'api/sharepointdocuments';
import { FolderItem } from 'api/sharepointdocuments/types';
import { AxiosProgressEvent } from 'axios';
import { Icon } from 'componentsNew/Icon/Icon';
import { useSnackbar } from 'context';
import { useCallback, useEffect, useState } from 'react';
import { translations } from 'translations';

import { FileItemIcon } from './FileItemIcon';
import * as helpers from './helpers';

export type FileItemType = {
  raw?: File;
  uploaded?: FolderItem;
};

type FileItemProps = {
  elementId: string;
  index: number;
  documentsFolderId: string;
  item: FileItemType;
  isLoading: boolean;
  setIsLoading: (name: string, isLoading: boolean) => void;
  onRemoveItem: (item: FileItemType) => void;
  onAdd: (item: FileItemType, index: number) => void;
  sx?: SxProps<Theme>;
};

const FileItem = ({
  elementId,
  index,
  documentsFolderId,
  item,
  isLoading,
  setIsLoading,
  onAdd,
  onRemoveItem,
  sx,
}: FileItemProps) => {
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);

  const { showSnackbar } = useSnackbar();

  const onUploadProgress = useCallback((progressEvent: AxiosProgressEvent) => {
    if (!progressEvent.loaded || !progressEvent.total) {
      return;
    }

    const uploadPercentage = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );

    setUploadPercentage(uploadPercentage);
  }, []);

  const uploadFile = useCallback(async () => {
    if (item.uploaded || !item.raw) {
      return;
    }

    setIsLoading(item.raw.name, true);

    setError(false);
    setUploadPercentage(1);

    const newFileListItem = { ...item };

    try {
      const formData = new FormData();
      formData.append('files', item.raw, item.raw.name);

      const response = await documentsApi.uploadFile(
        documentsFolderId,
        null,
        formData,
        onUploadProgress
      );

      const data = response?.data?.data;
      if (data) {
        newFileListItem.uploaded = data as FolderItem;
        setUploaded(true);
        onAdd(newFileListItem, index);
      }
    } catch {
      setError(true);
      setUploadPercentage(0);
    } finally {
      setIsLoading(item.raw.name, false);
    }
  }, [documentsFolderId, index, item, onAdd, setIsLoading, onUploadProgress]);

  const removeFile = useCallback(async () => {
    if (!item.uploaded) {
      onRemoveItem(item);
      return;
    }

    try {
      setIsLoading(item.uploaded.name, true);
      setError(false);

      await documentsApi.deleteItem(
        documentsFolderId,
        item.uploaded.name,
        item.uploaded.id,
        'file'
      );

      onRemoveItem(item);
    } catch {
      showSnackbar({
        type: 'error',
        text: translations.fileUploadRemoveError,
      });
    } finally {
      setIsLoading(item.uploaded.name, false);
    }
  }, [documentsFolderId, item, onRemoveItem, setIsLoading, showSnackbar]);

  useEffect(() => {
    if (item.uploaded || uploadPercentage > 0 || error || !documentsFolderId) {
      return;
    }

    uploadFile();
  }, [documentsFolderId, error, item.uploaded, uploadFile, uploadPercentage]);

  return (
    <Stack
      sx={[
        (theme) => ({
          width: '100%',
          alignItems: 'center',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          gap: theme.spacing('xxs'),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {error ? (
        <FileItemIcon type="error" />
      ) : uploaded ? (
        <FileItemIcon type="success" />
      ) : (
        <FileItemIcon type="default" />
      )}
      <Stack sx={() => ({ wordBreak: 'break-word', marginRight: 'auto' })}>
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.colors.text.secondary,
          })}
        >
          {item.uploaded?.name || item.raw?.name}
        </Typography>
        <Typography
          variant="caption"
          sx={(theme) => ({
            color: `${
              error ? theme.colors.text.critical : theme.colors.text.tertiary
            }`,
          })}
        >
          {error
            ? translations.fileUploadFileError
            : helpers.convertBytesToSize(
                item.uploaded?.size || item.raw?.size || 0
              )}
        </Typography>
      </Stack>
      {uploadPercentage > 0 && !uploaded && (
        <LinearProgress
          variant="determinate"
          value={uploadPercentage}
          sx={() => ({
            flexShrink: 0,
            width: '30%',
            height: '10px',
          })}
        />
      )}
      {error && (
        <Link
          id={`${elementId}-${index}-retry`}
          component="button"
          onClick={uploadFile}
        >
          {translations.retry}
        </Link>
      )}
      {(item.uploaded || uploaded || error) && (
        <IconButton
          id={`${elementId}-${index}-remove`}
          onClick={removeFile}
          disabled={isLoading}
        >
          <Icon type="xMark" color="secondary" />
        </IconButton>
      )}
    </Stack>
  );
};

export { FileItem };
