import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

const { aiSearch } = settings.api;

export const sendPrompt = (data: {
  objectId: string;
  prompt: string;
  page?: number;
  dateTime: string;
  countryId?: string;
  divisionId?: string;
  divisionName?: string;
  departmentId?: string;
  siteId?: string;
  companyId?: string;
  regionId?: string;
  segmentId?: string;
}) => {
  return createRequest({
    method: 'post',
    url: `${aiSearch}`,
    data,
    headers: {
      Accept: 'text/event-stream',
    },
    responseType: 'stream',
    adapter: 'fetch',
    errorMessages: {
      '*': 'Failed to send prompt, please contact support for assistance.',
    },
  });
};
