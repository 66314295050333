import './PublishSelector.scss';

import * as API from 'api/organization';
import {
  ENTRANCE_SYSTEMS_DIVISION_ID,
  GLOBAL_SOLUTIONS_DIVISION_ID,
} from 'api/organization/transformers';
import Reset from 'components/Button';
import withConsumer from 'components/Context/withConsumer';
import Error from 'components/Error';
import Select from 'components/Select';
import SiteSelector from 'components/SiteSelector';
import { OrganizationDataConsumer } from 'context';
import { getCustomSegmentLabel } from 'pagesAvenue/Profile/Preferences/helpers';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { getCleanSegmentName } from 'utils/misc/transformSegmentName';

import CompanySelector from './components/CompanySelector';

class PublishSelector extends Component {
  constructor(props) {
    super();

    this.state = {
      globalDivision: null,
      divisionsWithSegmentsAndCountries: [],
      departments: [],
      sites: [],
      companies: [],
      error: false,
      errorCode: null,
      selected: props.defaultValue,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getDivisionWithSegmentsAndCountries();
    this.getDepartments();
  }

  componentDidUnMount() {
    this._isMounted = false;
  }

  getDivisionWithSegmentsAndCountries = async () => {
    if (!this._isMounted) return;

    const { getDivisionsTree } = this.props.OrganizationDataContext;
    const divisionsTree = await getDivisionsTree();
    if (!divisionsTree) {
      this.setState({
        error: true,
        errorMessage:
          'An error occurred while populating parts of the target audience data',
      });

      return;
    }

    this.setState({
      divisionsWithSegmentsAndCountries: divisionsTree.divisions,
      globalDivision: divisionsTree.rootNode,
    });
  };

  getDivisions = () => {
    const { divisionsWithSegmentsAndCountries, globalDivision } = this.state;

    if (!divisionsWithSegmentsAndCountries.length || !globalDivision) return [];

    const global = {
      value: globalDivision.id,
      name: this.props.defaultDivisionName,
    };

    const divisions = divisionsWithSegmentsAndCountries
      .map((division) => ({
        value: division.id,
        name: division.name,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

    return [global, ...divisions];
  };

  getDepartments = async () => {
    if (this._isMounted) {
      try {
        const { data } = await API.getDepartments();
        const formattedData = this.formatDepartmentData(data);
        this.setState({ departments: formattedData });
      } catch (e) {
        return this.setState({
          error: true,
          errorMessage: e.message,
        });
      }
    }
  };

  formatDepartmentData = (response) => {
    let result = [];
    if (response.data && response.data.length > 0) {
      result = response.data.map((node) => ({
        value: node.id,
        name: node.attributes.name,
        children: [],
      }));
    }
    return result;
  };

  formatSegmentData = (segments) => {
    let result = [];
    if (segments && segments.length > 0) {
      result = segments.map((segment) => ({
        value: segment.id,
        name: getCleanSegmentName(segment.name, segment.id),
        children: [],
      }));
    }
    return result;
  };

  getDivisionCountries = () => {
    const selectedDivisions = this.state.selected.divisions;
    const divisionsWithSegmentsAndCountries =
      this.state.divisionsWithSegmentsAndCountries;

    const isGlobalSelected = selectedDivisions.find(
      (division) => division.value === this.props.defaultDivisionName
    );

    if (isGlobalSelected) {
      return [];
    }

    if (
      divisionsWithSegmentsAndCountries &&
      divisionsWithSegmentsAndCountries.length
    ) {
      const countriesForSelectedDivisions = divisionsWithSegmentsAndCountries
        .filter((division) =>
          selectedDivisions.some(
            (selectedDivision) => division.id === selectedDivision.value
          )
        )
        .map((division) => division.countries);

      const listLength = countriesForSelectedDivisions.length;

      const flatListOfSelectedCountries = countriesForSelectedDivisions.flat();

      const countCountries = {};

      flatListOfSelectedCountries.forEach((country) => {
        countCountries[`${country.name};${country.id}`] =
          (countCountries[`${country.name};${country.id}`] || 0) + 1;
      });

      const countries = [];

      for (let key in countCountries) {
        if (countCountries[key] === listLength) {
          countries.push({
            name: key.split(';')[0],
            value: key.split(';')[1],
          });
        }
      }

      return countries.sort((a, b) =>
        a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      );
    }

    return [];
  };

  onChangeDivision = (field) => (divisions) => {
    if (this._isMounted) {
      const { selected } = this.state;
      selected[field] = divisions;
      selected.countries = [];
      if (!this.canSelectSegments) selected.segments = [];

      this.setState({ selected });
      this.props.onChange(selected);
    }
  };

  createOnChangeHandler = (field) => (values) => {
    const { selected } = this.state;
    selected[field] = values;
    if (this._isMounted) {
      this.setState({ selected });
    }
    this.props.onChange(selected);
  };

  reset = () => {
    this.setState({
      selected: {
        divisions: [],
        segments: [],
        countries: [],
        departments: [],
        sites: [],
        companies: [],
      },
    });
    this.props.onChange({
      divisions: null,
      segments: null,
      countries: null,
      departments: null,
      sites: null,
      companies: null,
    });
  };

  get canSelectSegments() {
    const { divisions = [] } = this.state.selected;
    if (divisions.length !== 1) {
      return false;
    }
    return divisions.some(
      (division) =>
        division.value === ENTRANCE_SYSTEMS_DIVISION_ID ||
        division.value === GLOBAL_SOLUTIONS_DIVISION_ID
    );
  }

  renderSegmentsSelector() {
    if (!this.canSelectSegments) {
      return null;
    }
    const { divisionsWithSegmentsAndCountries, selected, showInitialError } =
      this.state;

    if (!divisionsWithSegmentsAndCountries) {
      return null;
    }

    const showError = showInitialError && selected.segments.length === 0;

    const selectedDivision = selected.divisions[0];
    const segments = divisionsWithSegmentsAndCountries.find(
      (division) => division.id === selected.divisions[0].value
    )?.segments;

    return (
      <div className="division-country-segment-selector__select">
        <Select
          elementId="publish--segment--selector"
          data={this.formatSegmentData(segments)}
          selected={selected.segments}
          multiple={true}
          deSelectEnabled={true}
          placeholder={`Select ${getCustomSegmentLabel(
            selectedDivision.value
          )}`}
          onChange={this.createOnChangeHandler('segments')}
          showError={showError}
        />
      </div>
    );
  }

  render() {
    const {
      defaultDivisionName,
      showInitialError,
      publishWithCountry,
      publishWithDepartment,
      publishWithSegments,
      publishWithSite,
      publishWithCompany,
    } = this.props;
    const { departments, selected, error, errorMessage } = this.state;

    if (error) {
      return <Error message={errorMessage} />;
    }

    const countries = this.getDivisionCountries();

    const divisions = this.getDivisions();
    const noDivisionSelected = selected.divisions.length === 0;
    const divisionSelected =
      selected.divisions.length > 0 &&
      !selected.divisions.some((x) => x.name === defaultDivisionName);
    const noSegmentSelected = selected.segments.length === 0;
    const noDepartmentSelected = selected.departments.length === 0;
    const noSiteSelected = selected.sites.length === 0;
    const noCompanySelected = selected.companies.length === 0;
    const globalSelected = selected.divisions.some(
      (x) => x.name === defaultDivisionName
    );

    return (
      <div className="ddivision-country-segment-selector">
        <div className="division-country-segment-selector__inner">
          <div className="division-country-segment-selector__selectors">
            <div className="division-country-segment-selector__select">
              <Select
                elementId="publish--division--selector"
                data={divisions}
                selected={selected.divisions}
                multiple={true}
                placeholder="Select Global or divisions"
                onChange={this.onChangeDivision('divisions')}
                showError={showInitialError && noDivisionSelected}
                disableGlobal={true}
                globalSelected={globalSelected}
                divisionSelected={divisionSelected}
                deSelectEnabled={true}
              />
            </div>
            {publishWithSegments && this.renderSegmentsSelector()}
            {publishWithCountry && (
              <div className="division-country-segment-selector__select">
                <Select
                  elementId="publish--country--selector"
                  data={countries}
                  selected={selected.countries}
                  multiple={true}
                  deSelectEnabled={true}
                  placeholder="Select country"
                  disabled={globalSelected || noDivisionSelected}
                  onChange={this.createOnChangeHandler('countries')}
                />
              </div>
            )}
            {/* As there is no second line, show the reset button in the first line itself */}
            {!(
              publishWithDepartment ||
              publishWithSite ||
              publishWithCompany
            ) && (
              <Reset
                onClick={this.reset}
                disabled={noDivisionSelected}
                size="small"
                width="narrow"
                color="white"
              >
                Reset
              </Reset>
            )}
          </div>
          {(publishWithDepartment || publishWithSite) && (
            <div className="division-country-segment-selector__selectors m-t-4">
              {publishWithDepartment && (
                <div className="division-country-segment-selector__select">
                  <Select
                    elementId="publish--departments--selector"
                    data={departments}
                    selected={selected.departments}
                    placeholder="Select Function (optional)"
                    onChange={this.createOnChangeHandler('departments')}
                    multiple={true}
                    deSelectEnabled={true}
                  />
                </div>
              )}
              {publishWithSite && (
                <div className="division-country-segment-selector__select">
                  <SiteSelector
                    identifier="publish--site--selector"
                    selectedSites={selected.sites}
                    onChange={this.createOnChangeHandler('sites')}
                    multiple={true}
                  />
                </div>
              )}
            </div>
          )}
          {publishWithCompany && (
            <div className="division-country-segment-selector__selectors m-t-4">
              <div className="division-country-segment-selector__select">
                <CompanySelector
                  identifier="publish--company--selector"
                  selectedCompanies={selected.companies}
                  onChange={this.createOnChangeHandler('companies')}
                  multiple={true}
                />
              </div>
              <div className="division-country-segment-selector__select">
                <Reset
                  onClick={this.reset}
                  disabled={
                    noDivisionSelected &&
                    noDepartmentSelected &&
                    noSiteSelected &&
                    ((this.canSelectSegments && noSegmentSelected) ||
                      !this.canSelectSegment) &&
                    noCompanySelected
                  }
                  size="small"
                  width="narrow"
                  color="white"
                >
                  Reset
                </Reset>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

PublishSelector.propTypes = {
  defaultDivisionName: PropTypes.string.isRequired,
  showInitialError: PropTypes.bool,
  publishWithCountry: PropTypes.bool,
  publishWithDepartment: PropTypes.bool,
  publishWithSite: PropTypes.bool,
  publishWithCompany: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.shape({
    divisions: PropTypes.array.isRequired,
    segments: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
    sites: PropTypes.array.isRequired,
    companies: PropTypes.array.isRequired,
  }),
  divisions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

PublishSelector.defaultProps = {
  publishWithCountry: true,
  publishWithDepartment: true,
  publishWithSite: true,
  publishWithCompany: true,
};

export default withConsumer(
  OrganizationDataConsumer,
  PublishSelector,
  'OrganizationDataContext'
);
