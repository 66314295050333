import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as jobsApi from 'api/jobs';
import * as jobsTransformers from 'api/jobs/transformers';
import {
  Job,
  JobListingFilter,
  JobListingFilterIds,
  JobListingItem,
  JobsFilterResponse,
  JobSortEnum,
} from 'api/jobs/types';
import { useUser } from 'components/Context/User';
import { Icon } from 'componentsNew';
import { useSnackbar } from 'context';
import { usePrevious } from 'hooks';
import { Page, PageTitle } from 'layout';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonJobSearchSubmit,
  GAonMostRelevantJobItemClick,
  GAonShowMoreClick,
  GAonSortJobClick,
} from 'utils/analytics';

import * as helpers from './helpers';
import {
  EMPTY_AVAILABLE_FILTER,
  JobListRequestParams,
  JobRequestParams,
  PAGINATION_DEFAULT_LIMIT,
  PAGINATION_DEFAULT_PAGE,
} from './helpers';
import { JobContent } from './JobContent/JobContent';
import { JobFilter } from './JobFilter/JobFilter';
import { JobList } from './JobList/JobList';
import { JobSearch } from './JobSearch/JobSearch';
import { JobsEmpty } from './JobsEmpty';

const Jobs = () => {
  const [availableFilter, setAvailableFilter] =
    useState<JobListingFilter | null>(null);

  const [jobList, setJobList] = useState<{
    items: JobListingItem[];
    total: number;
  } | null>(null);

  const [jobContentById, setJobContentById] = useState<Record<string, Job>>({});

  const [isJobListError, setIsJobListError] = useState<boolean>(false);
  const [isJobListLoading, setIsJobListLoading] = useState<boolean>(false);
  const [isJobContentError, setIsJobContentError] = useState<boolean>(false);
  const [isJobContentLoading, setIsJobContentLoading] =
    useState<boolean>(false);

  const user = useUser();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { showSnackbar } = useSnackbar();

  const activeParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    const idStr = urlSearchParams.get('id') || '';
    const id = idStr ? idStr : null;

    const pageStr = urlSearchParams.get('page') || '';
    const page = pageStr ? Number(pageStr) : PAGINATION_DEFAULT_PAGE;

    const searchStr = urlSearchParams.get('search') || '';
    const search = helpers.parseSearch(searchStr);

    const filterStr = urlSearchParams.get('filter') || '';
    const filter = helpers.parseFilter(filterStr);

    const sortStr = urlSearchParams.get('sort') || '';
    const sort = helpers.parseSort(sortStr);

    return { id, page, search, filter, filterStr, sort };
  }, [location.search]);

  const prevActiveParams = usePrevious(activeParams);

  const userParam = useMemo(() => {
    const departmentName = user.departmentName as string | undefined;
    const secondaryDepartmentName = user.secondaryDepartmentName as
      | string
      | undefined;
    const countryName = user.countryName as string | undefined;
    return { departmentName, secondaryDepartmentName, countryName };
  }, [user.departmentName, user.secondaryDepartmentName, user.countryName]);

  const documentTitle = useMemo(() => {
    const id = activeParams.id;
    if (id && jobContentById[id]) {
      return [translations.jobs, jobContentById[id].title];
    }
    return null;
  }, [jobContentById, activeParams.id]);

  const fetchJobList = useCallback(async (params: JobListRequestParams) => {
    const jobList: {
      items: JobListingItem[];
      total: number;
    } = { items: [], total: 0 };

    try {
      setIsJobListLoading(true);
      setIsJobListError(false);
      const queryString = helpers.getJobListRequestString(params);
      const response = await jobsApi.getJobs(queryString);
      if (!response) {
        throw Error();
      }
      jobList.total = response.data.meta.page.total;
      jobList.items = response.data.data as JobListingItem[];
    } catch {
      setIsJobListError(true);
    } finally {
      setIsJobListLoading(false);
      return jobList;
    }
  }, []);

  const fetchJobListWithDebounce = useMemo(() => {
    return debounce(
      async (
        params: JobListRequestParams,
        callback: (newJobList: {
          items: JobListingItem[];
          total: number;
        }) => void
      ) => {
        const newJobList = await fetchJobList(params);
        callback(newJobList);
      },
      500,
      { leading: true, trailing: true }
    );
  }, [fetchJobList]);

  const fetchJobContent = useCallback(async (params: JobRequestParams) => {
    let jobContent: Job | null = null;
    try {
      setIsJobContentLoading(true);
      setIsJobContentError(false);
      const querystring = helpers.getJobRequestString(params);
      const response = await jobsApi.getJob(params.id, querystring);
      if (!response) {
        throw Error();
      }
      jobContent = response.data.data as Job;
    } catch {
      setIsJobContentError(true);
    } finally {
      setIsJobContentLoading(false);
      return jobContent;
    }
  }, []);

  const fetchAvailableFilter = useCallback(async () => {
    try {
      const response = await jobsApi.getJobsFilter();
      const availableFilter = jobsTransformers.transformJobsFilterResponse(
        response as JobsFilterResponse
      );
      if (!availableFilter) {
        throw Error();
      }
      return availableFilter;
    } catch {
      showSnackbar({ type: 'error', text: translations.jobsFilterFetchError });
      return null;
    }
  }, [showSnackbar]);

  const handleJobListItemClick = useCallback(
    (item: JobListingItem) => {
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set('id', `${item.id}`);
      history.push({ search: urlSearchParams.toString() });
      if (item.isMostRelevant) {
        GAonMostRelevantJobItemClick(item.id, item.title);
      }
    },
    [history, location.search]
  );

  const handlePageChange = useCallback(
    (value: number) => {
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set('page', `${value}`);
      history.push({ search: urlSearchParams.toString() });
      GAonShowMoreClick('Jobs');
    },
    [history, location.search]
  );

  const handleFilterChange = useCallback(
    (value: JobListingFilterIds) => {
      const stringified = helpers.stringifyFilter(value);
      const urlSearchParams = new URLSearchParams(location.search);
      if (stringified) {
        urlSearchParams.set('filter', stringified);
      } else {
        urlSearchParams.delete('filter');
      }
      urlSearchParams.set('page', `${PAGINATION_DEFAULT_PAGE}`);
      history.push({ search: urlSearchParams.toString() });
    },
    [history, location.search]
  );

  const handleSortChange = useCallback(
    (value: JobSortEnum) => {
      const urlSearchParams = new URLSearchParams(location.search);
      if (value) {
        urlSearchParams.set('sort', value);
      } else {
        urlSearchParams.delete('sort');
      }
      urlSearchParams.set('page', `${PAGINATION_DEFAULT_PAGE}`);
      history.push({ search: urlSearchParams.toString() });
      GAonSortJobClick(value);
    },
    [history, location.search]
  );

  const handleSearchSubmit = useCallback(
    (value: string) => {
      const stringified = helpers.stringifySearch(value);
      const urlSearchParams = new URLSearchParams(location.search);
      if (stringified) {
        urlSearchParams.set('search', stringified);
      } else {
        urlSearchParams.delete('search');
      }
      urlSearchParams.set('page', `${PAGINATION_DEFAULT_PAGE}`);
      history.push({ search: urlSearchParams.toString() });
      GAonJobSearchSubmit(value);
    },
    [history, location.search]
  );

  const handleMobileBackClick = useCallback(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.delete('id');
    history.push({ search: urlSearchParams.toString() });
  }, [history, location.search]);

  // Initialize filter and job list
  useEffect(() => {
    async function initFilterAndJobList() {
      const initAvailableFilter =
        (await fetchAvailableFilter()) || EMPTY_AVAILABLE_FILTER;

      const initJobList = await fetchJobList({
        user: userParam,
        filter: activeParams.filter,
        search: activeParams.search,
        page: activeParams.page,
        sort: activeParams.sort,
      });
      setJobList(initJobList);
      setAvailableFilter(initAvailableFilter);

      if (isDesktop && !activeParams.id && initJobList.items.length) {
        const urlSearchParams = new URLSearchParams(location.search);
        urlSearchParams.set('id', `${initJobList.items[0].id}`);
        history.replace({ search: urlSearchParams.toString() });
      }
    }
    if (
      availableFilter !== null ||
      (!isDesktop && activeParams.id) ||
      user.isLoading
    ) {
      return;
    }
    initFilterAndJobList();
  }, [
    activeParams,
    availableFilter,
    fetchAvailableFilter,
    fetchJobList,
    history,
    isDesktop,
    location.search,
    user.isLoading,
    userParam,
  ]);

  // Fetch job list when "filter", "search", "sort" or "page" param change
  useEffect(() => {
    if (!prevActiveParams) {
      return;
    }
    if (
      prevActiveParams.page === activeParams.page &&
      prevActiveParams.search === activeParams.search &&
      prevActiveParams.filterStr === activeParams.filterStr &&
      prevActiveParams.sort === activeParams.sort
    ) {
      return;
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const params: JobListRequestParams = {
      user: userParam,
      filter: activeParams.filter,
      search: activeParams.search,
      page: activeParams.page,
      sort: activeParams.sort,
    };
    const callback = (newJobList: {
      items: JobListingItem[];
      total: number;
    }) => {
      setJobList(newJobList);
      if (!isDesktop) {
        return;
      }
      const newId = newJobList.items[0]?.id;
      const urlSearchParams = new URLSearchParams(location.search);
      if (newId) {
        urlSearchParams.set('id', `${newId}`);
      } else {
        urlSearchParams.delete('id');
      }
      history.replace({ search: urlSearchParams.toString() });
    };
    fetchJobListWithDebounce(params, callback);
  }, [
    activeParams,
    fetchJobListWithDebounce,
    history,
    isDesktop,
    location.search,
    prevActiveParams,
    userParam,
  ]);

  // Fetch job content when "id" param changes
  useEffect(() => {
    async function fetchNewJobContent(id: string) {
      const params: JobRequestParams = {
        id: id,
        user: userParam,
      };

      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      const newJobContent = await fetchJobContent(params);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      if (!newJobContent) {
        return;
      }
      setJobContentById((prevJobContentById) => ({
        ...prevJobContentById,
        [id]: newJobContent,
      }));
    }
    if (!activeParams.id) {
      return;
    }
    if (jobContentById[activeParams.id]) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }
    fetchNewJobContent(activeParams.id);
  }, [fetchJobContent, jobContentById, activeParams.id, userParam]);

  const showEmpty = useMemo(() => {
    return (
      availableFilter &&
      !isJobContentLoading &&
      !isJobListLoading &&
      !jobList?.items.length &&
      !(activeParams.id && jobContentById[activeParams.id])
    );
  }, [
    activeParams.id,
    availableFilter,
    isJobContentLoading,
    isJobListLoading,
    jobContentById,
    jobList?.items.length,
  ]);

  if (isDesktop) {
    return (
      <Page title={documentTitle}>
        <Stack sx={(theme) => ({ gap: theme.spacing('xxs') })}>
          <Stack
            sx={(theme) => ({
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: theme.spacing('xxxs'),
            })}
          >
            <PageTitle
              display={translations.jobs}
              hidden={documentTitle ? documentTitle.join(' | ') : ''}
            />
            <JobSearch
              activeSearch={activeParams.search}
              onSubmit={handleSearchSubmit}
            />
          </Stack>
          <Divider />
          {availableFilter && jobList && (
            <>
              <JobFilter
                isLoading={isJobListLoading}
                jobListTotal={jobList.total}
                availableFilter={availableFilter}
                activeFilter={activeParams.filter}
                onChange={handleFilterChange}
              />
              <Divider />
            </>
          )}
          {showEmpty ? (
            <JobsEmpty>{translations.jobsEmpty}</JobsEmpty>
          ) : (
            <Stack sx={() => ({ flexDirection: 'row' })}>
              <JobList
                sx={{ width: '30%', flexShrink: 0 }}
                activeItemId={activeParams.id || undefined}
                jobList={jobList}
                pagination={{
                  page: activeParams.page,
                  rowsPerPage: PAGINATION_DEFAULT_LIMIT,
                }}
                activeSort={activeParams.sort}
                isLoading={isJobListLoading}
                isError={isJobListError}
                onPageChange={handlePageChange}
                onItemClick={handleJobListItemClick}
                onSortChange={handleSortChange}
              />
              <JobContent
                sx={{ flexGrow: 1 }}
                job={
                  activeParams.id ? jobContentById[activeParams.id] : undefined
                }
                isLoading={
                  isJobContentLoading || (!activeParams.id && isJobListLoading)
                }
                isError={isJobContentError}
              />
            </Stack>
          )}
        </Stack>
      </Page>
    );
  }

  if (activeParams.id) {
    return (
      <Page title={documentTitle}>
        <Stack sx={(theme) => ({ gap: theme.spacing('xxs') })}>
          <PageTitle
            display={translations.jobs}
            hidden={documentTitle ? documentTitle.join(' | ') : ''}
          />
          <Divider />
          <Button
            variant="text"
            sx={{ alignSelf: 'baseline' }}
            startIcon={<Icon type="arrowLongLeft" color="brandBase" />}
            onClick={handleMobileBackClick}
          >
            {translations.back}
          </Button>
          <Divider />
          <JobContent
            job={jobContentById[activeParams.id]}
            isLoading={isJobContentLoading}
            isError={isJobContentError}
          />
        </Stack>
      </Page>
    );
  }

  return (
    <Page title={[translations.jobs]}>
      <Stack sx={(theme) => ({ gap: theme.spacing('xxs') })}>
        <Stack
          sx={(theme) => ({
            flexDirection: 'column',
            gap: theme.spacing('xxs'),
            marginBottom: theme.spacing('xxxs'),
          })}
        >
          <PageTitle
            display={translations.jobs}
            hidden={documentTitle ? documentTitle.join(' | ') : ''}
          />
          <JobSearch
            activeSearch={activeParams.search}
            onSubmit={handleSearchSubmit}
          />
        </Stack>
        <Divider />
        {availableFilter && jobList && (
          <>
            <JobFilter
              isLoading={isJobListLoading}
              jobListTotal={jobList.total}
              availableFilter={availableFilter}
              activeFilter={activeParams.filter}
              onChange={handleFilterChange}
            />
            <Divider />
          </>
        )}
        <JobList
          jobList={jobList}
          pagination={{
            page: activeParams.page,
            rowsPerPage: PAGINATION_DEFAULT_LIMIT,
          }}
          activeSort={activeParams.sort}
          isLoading={isJobListLoading}
          isError={isJobListError}
          onPageChange={handlePageChange}
          onItemClick={handleJobListItemClick}
          onSortChange={handleSortChange}
        />
      </Stack>
    </Page>
  );
};

export { Jobs };
