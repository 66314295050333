import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FolderItem } from 'api/sharepointdocuments/types';
import { Badge, Icon, Sheet } from 'componentsNew';
import { FileUpload } from 'componentsNew/FileUpload/FileUpload';
import { LinkInput } from 'componentsNew/LinkInput';
import { PageContentHeader } from 'layout';
import { useState } from 'react';
import { translations } from 'translations';

const MAX_FILE_SIZE_BYTES = 250 * 1024 * 1024;

type ResourcesInputSheetProps = {
  elementId: string;
  title?: string;
  links?: { title: string; url: string }[];
  documentsFolderId?: string;
  documents: FolderItem[];
  hideLinks?: boolean;
  hideDocuments?: boolean;
  setOpen: (open: boolean) => void;
  onChangeLinks?: (links: { title: string; url: string }[]) => void;
  onChangeDocumentsFolderId?: (documentsFolderId: string) => void;
  onChangeDocuments?: (documents: FolderItem[]) => void;
};

const ResourcesInputSheet = ({
  elementId,
  title = translations.resourcesTitle,
  links,
  documentsFolderId,
  documents,
  hideLinks,
  hideDocuments,
  setOpen,
  onChangeLinks,
  onChangeDocumentsFolderId,
  onChangeDocuments,
}: ResourcesInputSheetProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Sheet
      open={true}
      width="33.125rem"
      onClose={() => {
        if (isLoading) {
          return;
        }

        setOpen(false);
      }}
    >
      <PageContentHeader text={title} />

      {!hideDocuments && onChangeDocumentsFolderId && onChangeDocuments && (
        <Accordion
          id={`${elementId}-downloads`}
          disableGutters
          elevation={0}
          sx={() => ({
            '&.Mui-expanded': { margin: 0 },
            '&:before': { display: 'none' },
          })}
        >
          <AccordionSummary
            aria-controls={`${elementId}-download-items`}
            expandIcon={<Icon type="chevronDown" color="brandBase" size={20} />}
            sx={(theme) => ({
              padding: 0,
              margin: `0 ${theme.spacing('md')}`,
              '.MuiAccordionSummary-content': {
                alignItems: 'center',
              },
            })}
          >
            <Icon
              type="arrowDownTray"
              color="brandBase"
              sx={(theme) => ({
                marginRight: theme.spacing('xxs'),
              })}
            />
            <Typography
              variant="body2"
              sx={(theme) => ({
                color: theme.colors.text.brand,
                fontWeight: theme.typography.fontWeightBold,
              })}
            >
              {translations.downloads}
            </Typography>
            {documents && documents.length > 0 && (
              <Badge
                color="default"
                size="small"
                label={documents.length}
                sx={(theme) => ({
                  fontWeight: 'bold',
                  marginLeft: 'auto',
                  marginRight: theme.spacing('xxs'),
                })}
              />
            )}
          </AccordionSummary>
          <AccordionDetails
            id={`${elementId}-download-items`}
            sx={() => ({ padding: 0 })}
          >
            <Stack>
              <FileUpload
                elementId={`${elementId}-file-upload`}
                initialDocuments={documents}
                initialDocumentsFolderId={documentsFolderId}
                onChangeDocumentsFolderId={onChangeDocumentsFolderId}
                onChangeDocuments={onChangeDocuments}
                onLoading={setIsLoading}
                maxFileSizeBytes={MAX_FILE_SIZE_BYTES}
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.secondary,
                  borderTop: `${theme.border.thickness[1]} solid ${theme.colors.border.surfacePrimary}`,
                })}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}
      {!hideDocuments && !hideLinks && <Divider />}
      {!hideLinks && onChangeLinks && (
        <Accordion
          id={`${elementId}-links`}
          disableGutters
          elevation={0}
          sx={() => ({
            '&.Mui-expanded': { margin: 0 },
            '&:before': { display: 'none' },
          })}
        >
          <AccordionSummary
            aria-controls={`${elementId}-link-items`}
            expandIcon={<Icon type="chevronDown" color="brandBase" size={20} />}
            sx={(theme) => ({
              padding: 0,
              margin: `0 ${theme.spacing('md')}`,
              '.MuiAccordionSummary-content': {
                alignItems: 'center',
              },
            })}
          >
            <Icon
              type="link"
              color="brandBase"
              sx={(theme) => ({
                marginRight: theme.spacing('xxs'),
              })}
            />
            <Typography
              variant="body2"
              sx={(theme) => ({
                color: theme.colors.text.brand,
                fontWeight: theme.typography.fontWeightBold,
              })}
            >
              {translations.links}
            </Typography>
            {links && links.length > 0 && (
              <Badge
                color="default"
                size="small"
                label={links.length}
                sx={(theme) => ({
                  marginLeft: 'auto',
                  fontWeight: 'bold',
                  marginRight: theme.spacing('xxs'),
                })}
              />
            )}
          </AccordionSummary>
          <AccordionDetails
            id={`${elementId}-link-items`}
            sx={(theme) => ({ padding: 0, marginBottom: theme.spacing('sm') })}
          >
            <LinkInput
              elementId={`${elementId}-link-input`}
              value={links ? links : []}
              onChange={onChangeLinks}
              sx={(theme) => ({
                backgroundColor: theme.colors.surface.secondary,
                borderTop: `${theme.border.thickness[1]} solid ${theme.colors.border.surfacePrimary}`,
                borderBottom: `${theme.border.thickness[1]} solid ${theme.colors.border.surfacePrimary}`,
              })}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </Sheet>
  );
};

export { ResourcesInputSheet };
