import * as cmsInnovationApi from 'api/cms-innovation';
import { InnovationWebinarArticle } from 'api/cms-innovation/types';
import { AlertDialog } from 'componentsNew';
import { useSnackbar } from 'context';
import { AvenueRouteEnum, InnovationRouteEnum } from 'enums';
import { useDeleteDocumentsFolder } from 'hooks';
import { Page } from 'layout';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { translations } from 'translations';
import * as formUtils from 'utils/form';

import * as helpers from './helpers';
import { DEFAULT_VALUES, FormValues, WebinarForm } from './WebinarForm';

const WebinarEdit = () => {
  const [originalData, setOriginalData] =
    useState<InnovationWebinarArticle | null>(null);

  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    isLoading: boolean;
  }>({ open: false, isLoading: false });

  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const { showSnackbar } = useSnackbar();
  const { deleteDocumentsFolder } = useDeleteDocumentsFolder();

  const documentTitle = useMemo(
    () =>
      originalData
        ? [
            translations.innovationDocumentTitle,
            translations.learning,
            translations.innovationTypeWebinar,
            originalData.title,
            translations.edit,
          ]
        : [],
    [originalData]
  );

  const getDefaultValues = useCallback(async () => {
    try {
      if (!params.id) {
        throw Error();
      }
      const response = await cmsInnovationApi.getWebinarArticle(params.id);
      const data = helpers.transformGetResponseToFormValues(response);
      setOriginalData(response);
      return data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        history.push(AvenueRouteEnum.NotFound);
      } else {
        showSnackbar({
          type: 'error',
          text: translations.contentGetError,
        });
      }
      return DEFAULT_VALUES;
    }
  }, [history, params.id, showSnackbar]);

  const methods = useForm<FormValues>({
    defaultValues: async () => await getDefaultValues(),
    shouldFocusError: true,
    mode: 'all',
  });

  const { setError, setValue, getValues } = methods;

  const setFormError = useCallback(
    (message: string) => {
      showSnackbar({
        type: 'error',
        text: message,
      });
      setError('root', {});
    },
    [setError, showSnackbar]
  );

  const beforeSubmit = useCallback(() => {
    const formValues = getValues();
    const trimmedValues = formUtils.trimValues(formValues);
    const fieldNames = Object.keys(formValues) as (keyof FormValues)[];

    for (const fieldName of fieldNames) {
      const formValue = formValues[fieldName];
      const trimmedValue = trimmedValues[fieldName];
      if (trimmedValue !== formValue) {
        setValue(fieldName, trimmedValue, { shouldValidate: true });
      }
    }
  }, [getValues, setValue]);

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    async (formValues: FormValues) => {
      if (!originalData) {
        setFormError(translations.contentEditError);
        return;
      }

      try {
        const payload =
          helpers.transformFormValuesToCreateOrEditPayload(formValues);
        await cmsInnovationApi.updateWebinarArticle(originalData.id, payload);
        showSnackbar({
          type: 'success',
          text: translations.contentEditSuccess,
        });
      } catch {
        setFormError(translations.contentEditError);
        return;
      }
      history.push(`${InnovationRouteEnum.Webinar}/${originalData.id}`);
    },
    [history, originalData, setFormError, showSnackbar]
  );

  const handleDelete = useCallback(async () => {
    try {
      if (!originalData) {
        throw Error();
      }
      setDeleteDialog({ ...deleteDialog, isLoading: true });

      const sharepointFolderName = getValues('resources.sharepointFolderName');
      if (sharepointFolderName) {
        const deleteDocumentsFolderResponse = await deleteDocumentsFolder({
          documentsFolderId: sharepointFolderName,
        });

        if (!deleteDocumentsFolderResponse.success) {
          setDeleteDialog({ open: false, isLoading: false });
          return;
        }
      }

      await cmsInnovationApi.deleteWebinarArticle(originalData.id);
      showSnackbar({
        type: 'success',
        text: translations.contentDeleteSuccess,
      });

      history.push(InnovationRouteEnum.Learning);
    } catch {
      showSnackbar({
        type: 'error',
        text: translations.contentDeleteError,
      });
    } finally {
      setDeleteDialog({ open: false, isLoading: false });
    }
  }, [
    deleteDialog,
    deleteDocumentsFolder,
    getValues,
    history,
    originalData,
    showSnackbar,
  ]);

  const handleBack = useCallback(() => {
    const sharepointFolderName = getValues('resources.sharepointFolderName');
    const originalSharepointFolderName =
      originalData?.resources?.sharepointFolderName;

    if (
      sharepointFolderName &&
      sharepointFolderName !== originalSharepointFolderName
    ) {
      deleteDocumentsFolder({
        documentsFolderId: sharepointFolderName,
        showErrorSnackbar: false,
      });
    }

    const canGoBack = Boolean(location.key);
    if (canGoBack) {
      history.goBack();
    } else {
      history.push(InnovationRouteEnum.Learning);
    }
  }, [
    getValues,
    originalData?.resources?.sharepointFolderName,
    location.key,
    deleteDocumentsFolder,
    history,
  ]);

  return (
    <Page title={documentTitle}>
      <FormProvider {...methods}>
        <WebinarForm
          onBack={handleBack}
          beforeSubmit={beforeSubmit}
          onSubmit={handleSubmit}
          onDelete={() => setDeleteDialog({ ...deleteDialog, open: true })}
        />
      </FormProvider>
      <AlertDialog
        open={deleteDialog.open}
        type="critical"
        title={translations.articleDeleteTitle}
        paragraphs={[
          translations.articleDeleteText,
          translations.articleDeleteInfoText,
        ]}
        primaryButton={{
          loading: deleteDialog.isLoading,
          disabled: deleteDialog.isLoading,
          text: translations.delete,
          onClick: handleDelete,
        }}
        secondaryButton={{
          disabled: deleteDialog.isLoading,
          text: translations.cancel,
          onClick: () => setDeleteDialog({ ...deleteDialog, open: false }),
        }}
      />
    </Page>
  );
};

export { WebinarEdit };
