import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import * as organizationApi from 'api/organization';
import * as organizationTranformers from 'api/organization/transformers';
import { Profile } from 'api/organization/types';
import { useCallback, useEffect, useState } from 'react';
import { LinkProps } from 'react-router-dom';

import { ProfileMiniSkeleton } from './ProfileMiniSkeleton';
import { ProfileMiniView } from './ProfileMiniView';

interface ProfileMiniProps {
  elementId: string;
  profileId: string;
  profileName?: string;
  hideTitle?: boolean;
  target?: LinkProps['target'];
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

const ProfileMini = ({
  elementId,
  profileId,
  profileName,
  hideTitle,
  sx,
  target,
  onClick,
}: ProfileMiniProps) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile | null>(null);

  const fetchProfile = useCallback(async (id: string) => {
    try {
      setIsError(false);
      const response = await organizationApi.getProfile(id);
      if (!response?.data?.data) {
        throw Error();
      }
      const transformed =
        organizationTranformers.profileResponseToProfile(response);
      setProfile(transformed);
    } catch {
      setIsError(true);
    }
  }, []);

  useEffect(() => {
    fetchProfile(profileId);
  }, [profileId, fetchProfile]);

  if (!profile && !isError) {
    return <ProfileMiniSkeleton />;
  }

  const fullName =
    `${profile?.firstName || ''} ${profile?.lastName || ''}`.trim() ||
    profileName;

  return (
    <ProfileMiniView
      elementId={elementId}
      name={fullName}
      imageUrl={profile?.imageUrl}
      title={profile?.title}
      to={`/people/${profileId}`}
      target={target}
      onClick={onClick}
      hideTitle={hideTitle}
      sx={[
        (theme) => ({
          textDecoration: 'none',
          [`&:hover #${elementId}-name`]: {
            color: theme.colors.text.actionHover,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export { ProfileMini };
