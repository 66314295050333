const uatBlue = {
  portals: [
    {
      id: 'edf6c01880729e6d0562',
      name: 'Code of Conduct',
      url: 'codeofconduct',
      homePath: '/portals/codeofconduct',
      roleRestriction: { isRestricted: false, role: '' },
    },
    {
      id: '3a77eb590b378631353a',
      name: 'Innovation',
      url: 'innovation',
      homePath: '/portals/innovation',
      roleRestriction: { isRestricted: false, role: '' },
    },
  ],
};

export default uatBlue;
