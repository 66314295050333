import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon } from 'componentsNew';
import { ProfileMiniView } from 'componentsNew/ProfileMini/ProfileMiniView';
import { useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { translations } from 'translations';
import * as formUtils from 'utils/form';

import {
  DEFAULT_VALUES,
  ExternalPersonsInputForm,
  FormValues,
} from './ExternalPersonsInputForm';

export type ExternalPerson = {
  name: string;
  email: string;
  title: string;
};

type ExternalPersonsInputProps = {
  elementId: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  persons: ExternalPerson[];
  onChange?: (value: ExternalPerson[]) => void;
};

const ExternalPersonsInput = ({
  elementId,
  disabled,
  persons,
  onChange,
}: ExternalPersonsInputProps) => {
  const methods = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    shouldFocusError: true,
    mode: 'all',
  });

  const { setValue, getValues } = methods;

  const handleRemove = useCallback(
    (index: number) => {
      if (!onChange) return;
      const newPersons = [...persons];
      newPersons.splice(index, 1);
      onChange(newPersons);
    },
    [onChange, persons]
  );

  const beforeSubmit = useCallback(() => {
    const formValues = getValues();
    const trimmedValues = formUtils.trimValues(formValues);
    const fieldNames = Object.keys(formValues) as (keyof FormValues)[];

    for (const fieldName of fieldNames) {
      const formValue = formValues[fieldName];
      const trimmedValue = trimmedValues[fieldName];
      if (trimmedValue !== formValue) {
        setValue(fieldName, trimmedValue, { shouldValidate: true });
      }
    }
  }, [getValues, setValue]);

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    (formValues: FormValues) => {
      if (!onChange) return;
      const newPerson: ExternalPerson = {
        name: formValues.name,
        email: formValues.email,
        title: formValues.title,
      };
      const newPersons = [...persons, newPerson];
      onChange(newPersons);
    },
    [onChange, persons]
  );

  return (
    <Stack
      sx={(theme) => ({
        flexDirection: 'column',
        gap: theme.spacing('md'),
      })}
    >
      <FormProvider {...methods}>
        <ExternalPersonsInputForm
          elementId={`${elementId}-form`}
          beforeSubmit={beforeSubmit}
          onSubmit={handleSubmit}
          disabled={disabled}
        />
      </FormProvider>
      <Divider orientation="horizontal" />
      <Box
        sx={(theme) => ({
          '> div': {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing('sm'),
          },
        })}
      >
        <TransitionGroup>
          {persons.map((person, index) => {
            return (
              <Collapse key={`${elementId}-collapse-${index}`}>
                <Stack
                  sx={() => ({
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  })}
                >
                  <ProfileMiniView
                    elementId={`${elementId}-${index}`}
                    name={person.name}
                    title={person.title}
                  />
                  <IconButton
                    id={`${elementId}-remove-${index}`}
                    size="small"
                    aria-label={translations.delete}
                    onClick={() => handleRemove(index)}
                  >
                    <Icon type="xMark" color="secondary" />
                  </IconButton>
                </Stack>
              </Collapse>
            );
          })}
        </TransitionGroup>
      </Box>
    </Stack>
  );
};

export { ExternalPersonsInput };
