import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FolderItem } from 'api/sharepointdocuments/types';
import { Icon, IconButton } from 'componentsNew';
import { LinkList, LinkListItem } from 'componentsNew/LinkList';
import { useMemo } from 'react';

export type ToolsAndTemplatesDocumentListProps = {
  elementId: string;
  documents: FolderItem[];
  isDocumentsLoading?: boolean;
  onDocumentClick?: (document: FolderItem) => void;
};

const ToolsAndTemplatesDocumentsList = ({
  elementId = 'tools-and-templates-document-list',
  isDocumentsLoading,
  documents,
  onDocumentClick,
}: ToolsAndTemplatesDocumentListProps) => {
  const listItems = useMemo(() => {
    const _listItems: LinkListItem[] = [];
    if (documents) {
      const documentItems = documents.map((document, index) => {
        const item: LinkListItem = {
          id: `${elementId}-document-${index}`,
          href: document.downloadUrl,
          startElement: <Icon type="file" color="secondary" />,
          contentElement: (
            <Typography
              variant="caption"
              sx={(theme) => ({
                wordBreak: 'break-word',
                color: theme.colors.text.secondary,
                width: '100%',
              })}
            >
              {document.name}
            </Typography>
          ),
          endElement: (
            <IconButton
              role="img"
              variant="contained"
              component="div"
              type="arrowDownTray"
              color="inversePrimary"
              tabIndex={-1}
            />
          ),
          onClick: () => onDocumentClick && onDocumentClick(document),
        };
        return item;
      });
      _listItems.push(...documentItems);
    }
    return _listItems;
  }, [documents, elementId, onDocumentClick]);

  if (!listItems.length) {
    return null;
  }

  return (
    <Stack
      sx={(theme) => ({
        padding: theme.spacing('md'),
        boxShadow: theme.elevation.sm,
        borderRadius: theme.border.radius.lg,
        backgroundColor: theme.colors.surface.primary,
      })}
    >
      <LinkList
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing('sm'),
          '.MuiLink-root': {
            textDecorationColor: theme.colors.text.secondary,
            padding: `${theme.spacing('xxxs')} 0`,
          },
        })}
        items={listItems}
      />
      {isDocumentsLoading && (
        <>
          {Array(3)
            .fill(0)
            .map((_, i) => (
              <Skeleton
                key={`${elementId}-skeleton-${i}`}
                variant="rounded"
                animation="wave"
                sx={() => ({ height: '2.5rem' })}
              />
            ))}
        </>
      )}
    </Stack>
  );
};

export { ToolsAndTemplatesDocumentsList };
