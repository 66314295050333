import './Toggle.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

const Toggle = ({
  children,
  type,
  selected = false,
  className,
  disabled = false,
  value,
  onClick,
  color = 'black',
}) => {
  const toggleClassNames = classNames('toggle-button', {
    'toggle-button--selected': selected,
    'toggle-button--disabled': disabled,
    [`toggle-button__${color}`]: color,
    [className]: className,
  });

  return (
    <button
      className={toggleClassNames}
      disabled={disabled}
      value={value}
      onClick={() => {
        onClick({
          type,
          selected,
          value,
        });
      }}
    >
      {children}
    </button>
  );
};

Toggle.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['black', 'white', 'blue', 'sharepoint-black']),
};

export { Toggle };
export default memo(Toggle);
