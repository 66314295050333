import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'componentsNew/Icon/Icon';
import { forwardRef, useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';

import { EmbedVideoModal, EmbedVideoValue } from './EmbedInput/EmbedVideoModal';
import { EmbedVideoPreview } from './EmbedInput/EmbedVideoPreview';
import { ImageFilePicker } from './ImageInput/ImageFilePicker';
import { ImageModal, ImageValue } from './ImageInput/ImageModal';
import { ImagePreview } from './ImageInput/ImagePreview';

export const MEDIA_IMAGE_MIN_WIDTH = 1000;
export const MEDIA_IMAGE_MIN_HEIGHT = 500;
export const MEDIA_IMAGE_ASPECT_RATIO =
  MEDIA_IMAGE_MIN_WIDTH / MEDIA_IMAGE_MIN_HEIGHT;

export type MediaValue = {
  image?: ImageValue | null;
  embedVideo?: EmbedVideoValue | null;
};

export type MediaInputProps = {
  elementId?: string;
  value: MediaValue;
  error?: boolean;
  aspectRatio?: number;
  minWidth?: number;
  minHeight?: number;
  hideImageInput?: boolean;
  hideVideoInput?: boolean;
  ariaDescribedBy?: string;
  onChange: (value: MediaValue) => void;
};

const MediaInput = forwardRef<HTMLDivElement, MediaInputProps>(
  (
    {
      elementId = 'media-input',
      value,
      error,
      aspectRatio = MEDIA_IMAGE_ASPECT_RATIO,
      minWidth = MEDIA_IMAGE_MIN_WIDTH,
      minHeight = MEDIA_IMAGE_MIN_HEIGHT,
      hideImageInput,
      hideVideoInput,
      ariaDescribedBy,
      onChange,
    },
    ref
  ) => {
    const [imageModal, setImageModal] = useState<{
      open: boolean;
      image: ImageValue | null;
    }>({
      open: false,
      image: null,
    });

    const [embedVideoModal, setEmbedVideoModal] = useState<{
      open: boolean;
      embedVideo: EmbedVideoValue | null;
    }>({
      open: false,
      embedVideo: null,
    });

    const activePreview = useMemo(() => {
      if (value.image && !hideImageInput) {
        return 'image';
      }
      if (value.embedVideo && !hideVideoInput) {
        return 'embedVideo';
      }
      return null;
    }, [hideImageInput, hideVideoInput, value.embedVideo, value.image]);

    if (hideImageInput && hideVideoInput) {
      return null;
    }

    return (
      <>
        <TransitionGroup>
          {activePreview === 'image' && value.image && (
            <Collapse>
              <ImagePreview
                elementId={`${elementId}-image-preview`}
                image={value.image}
                onEdit={(image) => {
                  setImageModal({ open: true, image });
                }}
                onDelete={() => {
                  onChange({ ...value, image: null });
                }}
              />
            </Collapse>
          )}
          {activePreview === 'embedVideo' && value.embedVideo && (
            <Collapse>
              <EmbedVideoPreview
                elementId={`${elementId}-embed-video-preview`}
                embedVideo={value.embedVideo}
                onEdit={(embedVideo) => {
                  setEmbedVideoModal({ open: true, embedVideo });
                }}
                onDelete={() => {
                  onChange({ ...value, embedVideo: null });
                }}
              />
            </Collapse>
          )}
          {activePreview === null && (
            <Collapse>
              <Stack
                ref={ref}
                aria-describedby={ariaDescribedBy}
                sx={(theme) => ({
                  padding: theme.spacing('md'),
                  border: `1px dashed ${theme.colors.border.input}`,
                  borderRadius: theme.border.radius.sm,
                  gap: theme.spacing('xs'),
                  ...(error && {
                    border: `1px solid ${theme.colors.border.critical}`,
                  }),
                })}
              >
                <Stack
                  sx={(theme) => ({
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: theme.spacing('xs'),
                    flexWrap: 'wrap',
                  })}
                >
                  {!hideImageInput && !hideVideoInput && (
                    <Typography
                      sx={(theme) => ({
                        color: theme.colors.text.brand,
                        fontWeight: theme.typography.fontWeightBold,
                      })}
                    >
                      {translations.add}
                    </Typography>
                  )}
                  {!hideImageInput && (
                    <ImageFilePicker
                      elementId={`${elementId}-image-button`}
                      aspectRatio={aspectRatio}
                      minWidth={minWidth}
                      minHeight={minHeight}
                      onSubmit={(blobImage) => {
                        setImageModal({
                          open: true,
                          image: { ...blobImage, altText: '' },
                        });
                      }}
                      triggerElement={
                        <Button
                          variant="outlined"
                          endIcon={<Icon type="photo" color="brandBase" />}
                        >
                          {translations.mediaInputImage}
                        </Button>
                      }
                    />
                  )}
                  {!hideImageInput && !hideVideoInput && (
                    <Typography
                      sx={(theme) => ({
                        color: theme.colors.text.brand,
                        fontWeight: theme.typography.fontWeightBold,
                      })}
                    >
                      {translations.or}
                    </Typography>
                  )}
                  {!hideVideoInput && (
                    <Button
                      id={`${elementId}-embed-video-button`}
                      variant="outlined"
                      endIcon={<Icon type="code" color="brandBase" />}
                      onClick={() =>
                        setEmbedVideoModal({ open: true, embedVideo: null })
                      }
                    >
                      {translations.mediaInputVideo}
                    </Button>
                  )}
                </Stack>
                <Box
                  component="ul"
                  sx={(theme) => ({
                    margin: `${theme.spacing('xxxs')} ${theme.spacing('sm')}`,
                    paddingInlineStart: 0,
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                    li: {
                      color: theme.colors.text.tertiary,
                      '&::marker': {
                        fontSize: '0.75em',
                      },
                    },
                  })}
                >
                  {!hideImageInput && (
                    <Typography
                      variant="caption"
                      component="li"
                      dangerouslySetInnerHTML={{
                        __html: translations.mediaInputImageInfo,
                      }}
                    />
                  )}
                  {!hideVideoInput && (
                    <Typography
                      variant="caption"
                      component="li"
                      dangerouslySetInnerHTML={{
                        __html: translations.mediaInputVideoInfo,
                      }}
                    />
                  )}
                </Box>
              </Stack>
            </Collapse>
          )}
        </TransitionGroup>
        {imageModal.open && imageModal.image && (
          <ImageModal
            elementId={`${elementId}-image-modal`}
            defaultValue={{ ...imageModal.image }}
            hideCaption
            minWidth={minWidth}
            minHeight={minHeight}
            aspectRatio={aspectRatio}
            onSubmit={(image) => {
              onChange({ ...value, image });
              setImageModal({ open: false, image: null });
            }}
            onClose={() => {
              setImageModal({ open: false, image: null });
            }}
          />
        )}
        {embedVideoModal.open && (
          <EmbedVideoModal
            elementId={`${elementId}-video-modal`}
            defaultValue={embedVideoModal.embedVideo || undefined}
            onSubmit={(embedVideo) => {
              onChange({ ...value, embedVideo });
              setEmbedVideoModal({ open: false, embedVideo: null });
            }}
            onClose={() => {
              setEmbedVideoModal({ open: false, embedVideo: null });
            }}
          />
        )}
      </>
    );
  }
);

export { MediaInput };
