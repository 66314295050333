import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { InnovationRouteEnum } from 'enums';
import {
  PAGE_GRID_PADDING_DESKTOP,
  PAGE_GRID_PADDING_MOBILE,
} from 'layout/Page/Page';
import { INSIGHTS_DATABASE_LINK_URL } from 'pagesAvenue/Home/InsightsDatabase/helpers';
import { useMemo } from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { translations } from 'translations';

const elementId = 'innovation-navigation';

const NavigationDesktop = () => {
  const location = useLocation();

  const selectedValue = useMemo(() => {
    if (location.pathname.startsWith(InnovationRouteEnum.ProcessAndGuides)) {
      return InnovationRouteEnum.ProcessAndGuides;
    }
    if (location.pathname.startsWith(InnovationRouteEnum.ToolsAndTemplates)) {
      return InnovationRouteEnum.ToolsAndTemplates;
    }
    if (location.pathname.startsWith(InnovationRouteEnum.Learning)) {
      return InnovationRouteEnum.Learning;
    }
    if (location.pathname.startsWith(InnovationRouteEnum.Strategy)) {
      return InnovationRouteEnum.Strategy;
    }
    if (location.pathname.startsWith(InnovationRouteEnum.FocusArea)) {
      return InnovationRouteEnum.FocusArea;
    }
    if (location.pathname.startsWith('/portals/innovation')) {
      return InnovationRouteEnum.InsightsDatabase;
    }
    if (
      location.pathname === InnovationRouteEnum.Home ||
      location.pathname === `${InnovationRouteEnum.Home}/`
    ) {
      return InnovationRouteEnum.Home;
    }
    return false;
  }, [location.pathname]);

  return (
    <Tabs
      id={elementId}
      role="navigation"
      variant="scrollable"
      value={selectedValue}
      allowScrollButtonsMobile
      selectionFollowsFocus
      aria-label={translations.innovationNavigationAriaLabel}
      sx={(theme) => ({
        borderBottom: 'none',
        backgroundColor: theme.colors.surface.inversePrimary,
        minHeight: '3.3125rem',
        '.MuiTabs-flexContainer': {
          gap: theme.spacing('xxs'),
          height: '100%',
          width: theme.breakpoints.values.xl,
          maxWidth: '100%',
          margin: '0 auto',
          padding: {
            xs: `${theme.spacing('xxs')} ${PAGE_GRID_PADDING_MOBILE}`,
            sm: `${theme.spacing('xxs')} ${PAGE_GRID_PADDING_DESKTOP}`,
            xxl: theme.spacing('xxs'),
          },
        },
        '.MuiButtonBase-root': {
          height: 'unset',
          minHeight: 'unset',
          minWidth: 0,
          fontWeight: 'unset',
          fontSize: theme.typography.body2.fontSize,
          color: theme.colors.text.inversePrimary,
          borderRadius: theme.border.radius.sm,
          padding: `${theme.spacing('xxs')} ${theme.spacing('xs')}`,
          '&.Mui-selected, &:hover': {
            color: theme.colors.text.inversePrimary,
            backgroundColor: '#FFFFFF22',
          },
        },
        '.MuiTabs-indicator': {
          display: 'none',
        },
      })}
    >
      <Tab
        id={`${elementId}-${InnovationRouteEnum.Home}`}
        to={InnovationRouteEnum.Home}
        value={InnovationRouteEnum.Home}
        label={translations.home}
        component={ReactRouterLink}
      />
      <Tab
        id={`${elementId}-${InnovationRouteEnum.ProcessAndGuides}`}
        to={InnovationRouteEnum.ProcessAndGuides}
        value={InnovationRouteEnum.ProcessAndGuides}
        label={translations.innovationTypeProcessAndGuides}
        component={ReactRouterLink}
      />
      <Tab
        id={`${elementId}-${InnovationRouteEnum.ToolsAndTemplates}`}
        to={InnovationRouteEnum.ToolsAndTemplates}
        value={InnovationRouteEnum.ToolsAndTemplates}
        label={translations.innovationTypeToolsAndTemplates}
        component={ReactRouterLink}
      />
      <Tab
        id={`${elementId}-${InnovationRouteEnum.Learning}`}
        to={InnovationRouteEnum.Learning}
        value={InnovationRouteEnum.Learning}
        label={translations.learning}
        component={ReactRouterLink}
      />
      <Tab
        id={`${elementId}-${InnovationRouteEnum.Strategy}`}
        to={InnovationRouteEnum.Strategy}
        value={InnovationRouteEnum.Strategy}
        label={translations.innovationTypeStrategy}
        component={ReactRouterLink}
      />
      <Tab
        id={`${elementId}-${InnovationRouteEnum.InsightsDatabase}`}
        to={INSIGHTS_DATABASE_LINK_URL}
        value={InnovationRouteEnum.InsightsDatabase}
        label={translations.insightsDatabase}
        component={ReactRouterLink}
      />
      <Tab
        disabled
        label=""
        icon={<Divider orientation="vertical" />}
        sx={(theme) => ({
          padding: `${0} !important`,
          '.MuiDivider-root': {
            borderColor: `${theme.colors.border.surfaceInversePrimary} !important`,
          },
        })}
      />
      <Tab
        id={`${elementId}-${InnovationRouteEnum.FocusArea}`}
        to={InnovationRouteEnum.FocusArea}
        value={InnovationRouteEnum.FocusArea}
        label={translations.innovationFocusArea}
        component={ReactRouterLink}
      />
    </Tabs>
  );
};

export { NavigationDesktop };
