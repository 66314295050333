import Link from '@mui/material/Link';
import { Icon, IconType } from 'componentsNew/Icon/Icon';
import React from 'react';

type MediaMenuItemProps = {
  icon: IconType;
  text: string;
  elementId?: string;
  children?: React.ReactNode;
  onClick?: () => void;
};

const MediaMenuItem = ({
  icon,
  text,
  elementId,
  children,
  onClick,
  ...props
}: MediaMenuItemProps) => {
  return (
    <Link
      id={elementId}
      onClick={onClick}
      type="button"
      component="button"
      sx={(theme) => ({
        width: '100%',
        minWidth: '10rem',
        display: 'flex',
        gap: theme.spacing('xxs'),
        color: theme.colors.text.secondary,
        textDecoration: 'none',
        borderRadius: theme.border.radius.sm,
        paddingTop: theme.spacing('xxs'),
        paddingRight: theme.spacing('sm'),
        paddingBottom: theme.spacing('xxs'),
        paddingLeft: theme.spacing('xs'),
        position: 'relative',
        '&:hover': {
          color: theme.colors.text.secondary,
          backgroundColor: theme.colors.surface.informative,
          svg: { color: theme.colors.icon.brandBase },
        },
      })}
      {...props}
    >
      <Icon type={icon} color="secondary" size={20} />
      {text}
      {children}
    </Link>
  );
};

export { MediaMenuItem };
