import Stack from '@mui/material/Stack';
import { InnovationListingItemTypeEnum } from 'api/find/types';
import backgroundImageLearning from 'assets/images/innovation-article-image-background-learning.png';
import backgroundImageProcessAndGuides from 'assets/images/innovation-article-image-background-process-and-guides.png';
import backgroundImageToolsAndTemplates from 'assets/images/innovation-article-image-background-tools-and-templates.png';

import { ReactComponent as ChartOnBoard } from './ChartOnBoard.svg';
import { ReactComponent as Configurable } from './Configurable.svg';
import { ReactComponent as Document } from './Document.svg';
import { ReactComponent as Microphone } from './Microphone.svg';
import { ReactComponent as TablerIconRoute } from './TablerIconRoute.svg';

type ArticleImageFallbackProps = {
  type: InnovationListingItemTypeEnum;
};

const ArticleImageFallback = ({ type }: ArticleImageFallbackProps) => {
  let Icon = null;
  let backgroundImage = null;

  switch (type) {
    case InnovationListingItemTypeEnum.CaseStudy:
      Icon = Document;
      backgroundImage = backgroundImageLearning;
      break;
    case InnovationListingItemTypeEnum.Training:
      Icon = ChartOnBoard;
      backgroundImage = backgroundImageLearning;
      break;
    case InnovationListingItemTypeEnum.Webinar:
      Icon = Microphone;
      backgroundImage = backgroundImageLearning;
      break;
    case InnovationListingItemTypeEnum.ToolsAndTemplates:
      Icon = Configurable;
      backgroundImage = backgroundImageToolsAndTemplates;
      break;
    case InnovationListingItemTypeEnum.ProcessAndGuides:
      Icon = TablerIconRoute;
      backgroundImage = backgroundImageProcessAndGuides;
      break;
    default:
      Icon = null;
      backgroundImage = null;
  }
  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        '> svg': {
          height: { xs: '40px', md: '96px' },
          width: { xs: '40px', md: '96px' },
        },
      }}
    >
      {Icon && <Icon />}
    </Stack>
  );
};

export { ArticleImageFallback };
