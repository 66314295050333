import ToggleButton from '@mui/material/ToggleButton';
import { useCurrentEditor } from '@tiptap/react';
import { useCallback } from 'react';
import { translations } from 'translations';

const elementId = 'rich-text-format-menu-heading';
const title = translations.richTextFormatHeading2;

const Heading = () => {
  const { editor } = useCurrentEditor();

  const onClick = useCallback(() => {
    if (!editor) {
      return;
    }
    editor.chain().focus().toggleHeading({ level: 2 }).run();
  }, [editor]);

  return (
    <ToggleButton
      id={elementId}
      title={title}
      value="h2"
      aria-label={title}
      selected={editor ? editor.isActive('heading', { level: 2 }) : false}
      onClick={onClick}
    >
      H2
    </ToggleButton>
  );
};
export { Heading };
