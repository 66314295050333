export enum FilterName {
  Types = 'types',
  FocusAreas = 'focusAreas',
}

export type FilterOption = { id: string; name: string };
export type Filter = Record<FilterName, FilterOption[]>;
export type FilterIds = Record<FilterName, string[]>;

export const EMPTY_ACTIVE_FILTER: FilterIds = {
  types: [],
  focusAreas: [],
};

export const ALL_FILTER_NAMES = Object.values(FilterName) as FilterName[];

const getFilterLabelsAsStr = (filter: FilterIds, availableFilter: Filter) => {
  const filterLabels: string[] = [];
  ALL_FILTER_NAMES.forEach((name) => {
    const ids = filter[name];
    ids.forEach((id) => {
      const filterOption = availableFilter[name].find(
        (option) => option.id === id
      );
      if (filterOption) {
        filterLabels.push(filterOption.name);
      }
    });
  });
  return filterLabels.join(', ');
};

const parseFilter = (filterStr: string) => {
  try {
    if (!filterStr) {
      return EMPTY_ACTIVE_FILTER;
    }
    const filterObject = JSON.parse(filterStr);
    if (typeof filterObject !== 'object') {
      return EMPTY_ACTIVE_FILTER;
    }
    const filter = { ...EMPTY_ACTIVE_FILTER };
    ALL_FILTER_NAMES.forEach((name) => {
      const filterValue = filterObject[name];
      if (filterValue) {
        filter[name] = filterValue;
      }
    });
    return filter;
  } catch {
    return EMPTY_ACTIVE_FILTER;
  }
};

const stringifyFilter = (filter: FilterIds) => {
  const cleanedFilter: Partial<FilterIds> = {};
  ALL_FILTER_NAMES.forEach((name) => {
    const ids = filter[name];
    if (ids.length) {
      cleanedFilter[name] = ids;
    }
  });
  const isEmpty = !Object.keys(cleanedFilter).length;
  return isEmpty ? '' : JSON.stringify(cleanedFilter);
};

export { getFilterLabelsAsStr, parseFilter, stringifyFilter };
