import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';

const ArticleSkeleton = () => (
  <>
    <Grid xs={12} marginTop={2.5}>
      <Skeleton
        variant="rounded"
        animation="wave"
        sx={() => ({ height: '20rem' })}
      />
    </Grid>
    <Grid xs={12} lg={8} marginBottom={2.5}>
      <Stack spacing={1.5}>
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={() => ({
            width: '100%',
            aspectRatio: 2,
            maxWidth: 'unset',
            height: 'unset',
          })}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={() => ({ height: '10rem' })}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={() => ({ height: '10rem' })}
        />

        <Skeleton
          variant="rounded"
          animation="wave"
          sx={() => ({ height: '3rem' })}
        />
      </Stack>
    </Grid>
    <Grid xs={12} lg={4}>
      <Stack spacing={1.5}>
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={() => ({ height: '20rem' })}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={() => ({ height: '20rem' })}
        />
      </Stack>
    </Grid>
  </>
);

export { ArticleSkeleton };
