import { Page } from 'layout';
import { useMemo } from 'react';
import { translations } from 'translations';

const FocusAreaListing = () => {
  const documentTitle = useMemo(
    () => [
      translations.innovationDocumentTitle,
      translations.innovationFocusArea,
    ],
    []
  );

  return (
    <Page title={documentTitle}>TODO: Add Focus Area Listing Content</Page>
  );
};

export { FocusAreaListing };
