import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as AdjustmentsHorizontal } from 'componentsNew/Icon/common/AdjustmentsHorizontal.svg';
import { ReactComponent as ArrowDownTray } from 'componentsNew/Icon/common/ArrowDownTray.svg';
import { ReactComponent as ArrowLongLeft } from 'componentsNew/Icon/common/ArrowLongLeft.svg';
import { ReactComponent as ArrowLongRight } from 'componentsNew/Icon/common/ArrowLongRight.svg';
import { ReactComponent as ArrowLongUp } from 'componentsNew/Icon/common/ArrowLongUp.svg';
import { ReactComponent as ArrowRight } from 'componentsNew/Icon/common/ArrowRight.svg';
import { ReactComponent as ArrowTopRightOnSquare } from 'componentsNew/Icon/common/ArrowTopRightOnSquare.svg';
import { ReactComponent as ArrowUp } from 'componentsNew/Icon/common/ArrowUp.svg';
import { ReactComponent as ArrowUpTray } from 'componentsNew/Icon/common/ArrowUpTray.svg';
import { ReactComponent as ArrowUturnRight } from 'componentsNew/Icon/common/ArrowUturnRight.svg';
import { ReactComponent as Bell } from 'componentsNew/Icon/common/Bell.svg';
import { ReactComponent as BellAlert } from 'componentsNew/Icon/common/BellAlert.svg';
import { ReactComponent as BellAlertFilled } from 'componentsNew/Icon/common/BellAlertFilled.svg';
import { ReactComponent as Briefcase } from 'componentsNew/Icon/common/Briefcase.svg';
import { ReactComponent as ChatBubbleBottomLeft } from 'componentsNew/Icon/common/ChatBubbleBottomLeft.svg';
import { ReactComponent as ChatBubbleBottomLeftFilled } from 'componentsNew/Icon/common/ChatBubbleBottomLeftFilled.svg';
import { ReactComponent as Check } from 'componentsNew/Icon/common/Check.svg';
import { ReactComponent as CheckCircle } from 'componentsNew/Icon/common/CheckCircle.svg';
import { ReactComponent as CheckCircleFilled } from 'componentsNew/Icon/common/CheckCircleFilled.svg';
import { ReactComponent as ChevronDown } from 'componentsNew/Icon/common/ChevronDown.svg';
import { ReactComponent as ChevronLeft } from 'componentsNew/Icon/common/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from 'componentsNew/Icon/common/ChevronRight.svg';
import { ReactComponent as ChevronUp } from 'componentsNew/Icon/common/ChevronUp.svg';
import { ReactComponent as ChevronUpDown } from 'componentsNew/Icon/common/ChevronUpDown.svg';
import { ReactComponent as ClipboardDocumentCheck } from 'componentsNew/Icon/common/ClipboardDocumentCheck.svg';
import { ReactComponent as Clock } from 'componentsNew/Icon/common/Clock.svg';
import { ReactComponent as Code } from 'componentsNew/Icon/common/Code.svg';
import { ReactComponent as CogTooth } from 'componentsNew/Icon/common/CogTooth.svg';
import { ReactComponent as Copy } from 'componentsNew/Icon/common/Copy.svg';
import { ReactComponent as EllipsisHorizontal } from 'componentsNew/Icon/common/EllipsisHorizontal.svg';
import { ReactComponent as Eraser } from 'componentsNew/Icon/common/Eraser.svg';
import { ReactComponent as ExclamationTriangle } from 'componentsNew/Icon/common/ExclamationTriangle.svg';
import { ReactComponent as ExclamationTriangleFilled } from 'componentsNew/Icon/common/ExclamationTriangleFilled.svg';
import { ReactComponent as Eye } from 'componentsNew/Icon/common/Eye.svg';
import { ReactComponent as File } from 'componentsNew/Icon/common/File.svg';
import { ReactComponent as Heart } from 'componentsNew/Icon/common/Heart.svg';
import { ReactComponent as HeartFilled } from 'componentsNew/Icon/common/HeartFilled.svg';
import { ReactComponent as InformationCircle } from 'componentsNew/Icon/common/InformationCircle.svg';
import { ReactComponent as InformationCircleFilled } from 'componentsNew/Icon/common/InformationCircleFilled.svg';
import { ReactComponent as Link } from 'componentsNew/Icon/common/Link.svg';
import { ReactComponent as MagnifyingGlass } from 'componentsNew/Icon/common/MagnifyingGlass.svg';
import { ReactComponent as MapPin } from 'componentsNew/Icon/common/MapPin.svg';
import { ReactComponent as Minus } from 'componentsNew/Icon/common/Minus.svg';
import { ReactComponent as Pencil } from 'componentsNew/Icon/common/Pencil.svg';
import { ReactComponent as Photo } from 'componentsNew/Icon/common/Photo.svg';
import { ReactComponent as PhotoFilled } from 'componentsNew/Icon/common/PhotoFilled.svg';
import { ReactComponent as Pin } from 'componentsNew/Icon/common/Pin.svg';
import { ReactComponent as PlayCircle } from 'componentsNew/Icon/common/PlayCircle.svg';
import { ReactComponent as Plus } from 'componentsNew/Icon/common/Plus.svg';
import { ReactComponent as Send } from 'componentsNew/Icon/common/Send.svg';
import { ReactComponent as Star } from 'componentsNew/Icon/common/Star.svg';
import { ReactComponent as ThumbDown } from 'componentsNew/Icon/common/ThumbDown.svg';
import { ReactComponent as ThumbDownFilled } from 'componentsNew/Icon/common/ThumbDownFilled.svg';
import { ReactComponent as ThumbUp } from 'componentsNew/Icon/common/ThumbUp.svg';
import { ReactComponent as ThumbUpFilled } from 'componentsNew/Icon/common/ThumbUpFilled.svg';
import { ReactComponent as Trash } from 'componentsNew/Icon/common/Trash.svg';
import { ReactComponent as TrashFilled } from 'componentsNew/Icon/common/TrashFilled.svg';
import { ReactComponent as UserCircle } from 'componentsNew/Icon/common/UserCircle.svg';
import { ReactComponent as UserCircleFilled } from 'componentsNew/Icon/common/UserCircleFilled.svg';
import { ReactComponent as XCircle } from 'componentsNew/Icon/common/XCircle.svg';
import { ReactComponent as XCircleFilled } from 'componentsNew/Icon/common/XCircleFilled.svg';
import { ReactComponent as XMark } from 'componentsNew/Icon/common/XMark.svg';
import React from 'react';
import { muiColors } from 'theme';

const ComponentMap = {
  adjustmentsHorizontal: AdjustmentsHorizontal,
  arrowDownTray: ArrowDownTray,
  arrowUpTray: ArrowUpTray,
  arrowLongLeft: ArrowLongLeft,
  arrowLongRight: ArrowLongRight,
  arrowLongUp: ArrowLongUp,
  arrowRight: ArrowRight,
  arrowTopRightOnSquare: ArrowTopRightOnSquare,
  arrowUp: ArrowUp,
  arrowUturnRight: ArrowUturnRight,
  bell: Bell,
  bellAlert: BellAlert,
  bellAlertFilled: BellAlertFilled,
  briefcase: Briefcase,
  chatBubbleBottomLeft: ChatBubbleBottomLeft,
  chatBubbleBottomLeftFilled: ChatBubbleBottomLeftFilled,
  check: Check,
  checkCircle: CheckCircle,
  checkCircleFilled: CheckCircleFilled,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  chevronUpDown: ChevronUpDown,
  clipboardDocumentCheck: ClipboardDocumentCheck,
  clock: Clock,
  code: Code,
  cogTooth: CogTooth,
  copy: Copy,
  ellipsisHorizontal: EllipsisHorizontal,
  eraser: Eraser,
  exclamationTriangle: ExclamationTriangle,
  exclamationTriangleFilled: ExclamationTriangleFilled,
  eye: Eye,
  file: File,
  heart: Heart,
  heartFilled: HeartFilled,
  informationCircle: InformationCircle,
  informationCircleFilled: InformationCircleFilled,
  link: Link,
  magnifyingGlass: MagnifyingGlass,
  mapPin: MapPin,
  minus: Minus,
  pencil: Pencil,
  photo: Photo,
  photoFilled: PhotoFilled,
  pin: Pin,
  playCircle: PlayCircle,
  plus: Plus,
  send: Send,
  star: Star,
  thumbDown: ThumbDown,
  thumbDownFilled: ThumbDownFilled,
  thumbUp: ThumbUp,
  thumbUpFilled: ThumbUpFilled,
  trash: Trash,
  trashFilled: TrashFilled,
  userCircle: UserCircle,
  userCircleFilled: UserCircleFilled,
  xCircle: XCircle,
  xCircleFilled: XCircleFilled,
  xMark: XMark,
};

export type IconType = keyof typeof ComponentMap;
export type IconColor = keyof typeof muiColors.icon;
export type IconSize = 16 | 20 | 24 | 32;

type IconProps = {
  type: IconType;
  color?: IconColor;
  size?: IconSize;
  svgProps?: React.SVGProps<SVGSVGElement>;
} & Omit<BoxProps, 'color'>;

const Icon = React.forwardRef<HTMLSpanElement, IconProps>(
  (
    { type, size = 24, color = 'primary', sx, svgProps = {}, ...boxProps },
    ref
  ) => {
    const theme = useTheme();
    const Component = ComponentMap[type];

    return (
      <Box
        ref={ref}
        component="span"
        {...boxProps}
        sx={[
          {
            flexGrow: 0,
            flexShrink: 0,
            '> svg': { display: 'block' },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Component
          color={theme.colors.icon[color]}
          height={size}
          width={size}
          {...svgProps}
        />
      </Box>
    );
  }
);

export { Icon };
