import ToggleButton from '@mui/material/ToggleButton';
import { useCurrentEditor } from '@tiptap/react';
import { useCallback } from 'react';
import { translations } from 'translations';

const elementId = 'rich-text-format-menu-bold';
const title = translations.richTextFormatBold;

const Bold = () => {
  const { editor } = useCurrentEditor();

  const onClick = useCallback(() => {
    if (!editor) {
      return;
    }
    editor.chain().focus().toggleBold().run();
  }, [editor]);

  return (
    <ToggleButton
      id={elementId}
      title={title}
      value="bold"
      aria-label={title}
      selected={editor ? editor.isActive('bold') : false}
      onClick={onClick}
      sx={{ fontWeight: 'bold !important' }}
    >
      B
    </ToggleButton>
  );
};
export { Bold };
