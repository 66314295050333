import { useCurrentEditor } from '@tiptap/react';
import { AlertDialog } from 'componentsNew/AlertDialog/AlertDialog';
import { useWhitelistedUrls } from 'context';
import { useCallback, useEffect, useState } from 'react';
import { translations } from 'translations';

const LinkClickListener = () => {
  const [dialog, setDialog] = useState<{
    open: boolean;
    href: string;
    target: string;
  }>({
    open: false,
    href: '',
    target: '',
  });

  const { urls: whitelistedUrls } = useWhitelistedUrls();

  const { editor } = useCurrentEditor();

  const isLinkWhitelisted = useCallback(
    (href: string) => {
      return whitelistedUrls.some((whitelistedUrl) =>
        href.startsWith(whitelistedUrl)
      );
    },
    [whitelistedUrls]
  );

  const handleConfirmRedirect = useCallback(
    (params: { href: string; target: string }) => {
      window.open(params.href, params.target);
      setDialog({ open: false, href: '', target: '' });
    },
    []
  );

  const onClickListener = useCallback(
    (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement;
      const linkElement = clickedElement.closest('a');

      if (!linkElement) {
        return;
      }

      event.preventDefault();

      if (!editor || editor.isEditable) {
        return;
      }

      const href = linkElement.getAttribute('href');
      const target = linkElement.getAttribute('target') || '_blank';

      if (!href) {
        return;
      }

      if (!isLinkWhitelisted(href)) {
        setDialog({ open: true, href, target });
        return;
      }

      window.open(href, target);
    },
    [editor, isLinkWhitelisted]
  );

  useEffect(() => {
    if (!editor || editor.isEditable) {
      return;
    }
    const editorElement = editor.view.dom;
    editorElement.addEventListener('click', onClickListener);

    return () => {
      editorElement.removeEventListener('click', onClickListener);
    };
  }, [editor, onClickListener]);

  return (
    <AlertDialog
      open={dialog.open}
      type="warning"
      title={translations.whitelistLinkDialogTitle}
      paragraphs={[
        translations.whitelistLinkDialogText1,
        dialog.href.length > 200
          ? `${dialog.href.substring(0, 200)}...`
          : dialog.href,
        translations.whitelistLinkDialogText2,
      ]}
      primaryButton={{
        text: translations.yes,
        onClick: () => {
          handleConfirmRedirect({ href: dialog.href, target: dialog.target });
        },
      }}
      secondaryButton={{
        text: translations.cancel,
        onClick: () => {
          setDialog({ ...dialog, open: false });
        },
      }}
      onClose={() => {
        setDialog({ ...dialog, open: false });
      }}
    />
  );
};

export { LinkClickListener };
