import Grid from '@mui/material/Unstable_Grid2';
import { Page, PageTitle } from 'layout';
import { useMemo } from 'react';
import { translations } from 'translations';

import { Intro } from './Intro';

const Home = () => {
  const documentTitle = useMemo(
    () => [translations.innovationDocumentTitle, translations.home],
    []
  );

  return (
    <Page title={documentTitle} gridContainer>
      <PageTitle hidden={documentTitle.join(' | ')} />
      <Grid xs={12}>
        <Intro />
      </Grid>
    </Page>
  );
};

export { Home };
