import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useMemo } from 'react';

type ArticleTabsProps = {
  id: string;
  items: {
    id: string;
    title: string;
    ariaControls: string;
  }[];
  activeIndex: number;
  ariaLabel: string;
  onChange: (index: number) => void;
};

const ArticleTabs = ({
  id,
  items,
  activeIndex,
  ariaLabel,
  onChange,
}: ArticleTabsProps) => {
  const isFirstTabGhost = useMemo(() => {
    return items.length === 1 && items[0].title === '';
  }, [items]);

  return (
    <Tabs
      id={id}
      aria-label={ariaLabel}
      value={activeIndex}
      variant={items.length > 1 ? 'scrollable' : undefined}
      allowScrollButtonsMobile={true}
      selectionFollowsFocus={true}
      onChange={(_e, value) => {
        onChange(value);
      }}
      sx={() => ({
        ...(isFirstTabGhost && {
          display: 'none',
        }),
      })}
    >
      {items.map((item, index) => {
        return (
          <Tab
            key={`${id}-${index}`}
            id={item.id}
            label={item.title}
            aria-controls={item.ariaControls}
          />
        );
      })}
    </Tabs>
  );
};

export { ArticleTabs };
