import * as cmsInnovationApi from 'api/cms-innovation';
import { InnovationFocusAreaArticle } from 'api/cms-innovation/types';
import { AlertDialog } from 'componentsNew';
import { ImageValue } from 'componentsNew/MediaInput/ImageInput/ImageModal';
import { useSnackbar } from 'context';
import { AvenueRouteEnum, InnovationRouteEnum } from 'enums';
import { Page } from 'layout';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { translations } from 'translations';
import * as attachmentUtils from 'utils/cms/attachment';
import * as formUtils from 'utils/form';

import { DEFAULT_VALUES, FocusAreaForm, FormValues } from './FocusAreaForm';
import * as helpers from './helpers';

const FocusAreaEdit = () => {
  const [originalData, setOriginalData] =
    useState<InnovationFocusAreaArticle | null>(null);
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    isLoading: boolean;
  }>({ open: false, isLoading: false });

  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const { showSnackbar } = useSnackbar();

  const documentTitle = useMemo(
    () =>
      originalData
        ? [
            translations.innovationDocumentTitle,
            translations.innovationFocusArea,
            originalData.title,
            translations.edit,
          ]
        : [],
    [originalData]
  );

  const getDefaultValues = useCallback(async () => {
    try {
      if (!params.id) {
        throw Error();
      }
      const response = await cmsInnovationApi.getFocusAreaArticle(params.id);
      const data = helpers.transformGetResponseToFormValues(response);
      setOriginalData(response);
      return data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        history.push(AvenueRouteEnum.NotFound);
      } else {
        showSnackbar({
          type: 'error',
          text: translations.contentGetError,
        });
      }
      return DEFAULT_VALUES;
    }
  }, [history, params.id, showSnackbar]);

  const methods = useForm<FormValues>({
    defaultValues: async () => await getDefaultValues(),
    shouldFocusError: true,
    mode: 'all',
  });

  const { setError, setValue, getValues } = methods;

  const setFormError = useCallback(
    (message: string) => {
      showSnackbar({
        type: 'error',
        text: message,
      });
      setError('root', {});
    },
    [setError, showSnackbar]
  );

  const beforeSubmit = useCallback(() => {
    const formValues = getValues();
    const trimmedValues = formUtils.trimValues(formValues);
    const fieldNames = Object.keys(formValues) as (keyof FormValues)[];

    for (const fieldName of fieldNames) {
      const formValue = formValues[fieldName];
      const trimmedValue = trimmedValues[fieldName];
      if (trimmedValue !== formValue) {
        setValue(fieldName, trimmedValue, { shouldValidate: true });
      }
    }
  }, [getValues, setValue]);

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    async (formValues: FormValues) => {
      if (!originalData) {
        setFormError(translations.contentEditError);
        return;
      }

      const heroImage = formValues.hero.image;
      let uploadedHeroImage: ImageValue | null = null;

      if (heroImage && heroImage.blob) {
        try {
          const uploadedSrc = await attachmentUtils.uploadImage({
            blob: heroImage.blob,
            width: heroImage.width,
            height: heroImage.height,
          });
          uploadedHeroImage = {
            src: uploadedSrc,
            width: heroImage.width,
            height: heroImage.height,
            altText: heroImage.altText,
          };
          formValues.hero.image = uploadedHeroImage;
        } catch {
          setFormError(translations.imageUploadError);
          return;
        }
      }

      try {
        const payload =
          helpers.transformFormValuesToCreateOrEditPayload(formValues);
        await cmsInnovationApi.updateFocusAreaArticle(originalData.id, payload);
        showSnackbar({
          type: 'success',
          text: translations.contentEditSuccess,
        });
      } catch {
        if (uploadedHeroImage) {
          try {
            await attachmentUtils.deleteImage(uploadedHeroImage.src);
          } catch {}
        }
        setFormError(translations.contentEditError);
        return;
      }

      if (originalData.heroImageUrl) {
        try {
          await attachmentUtils.deleteImage(originalData.heroImageUrl);
        } catch {}
      }
      history.push(`${InnovationRouteEnum.FocusArea}/${originalData.id}`);
    },
    [history, originalData, setFormError, showSnackbar]
  );

  const handleDelete = useCallback(async () => {
    try {
      if (!originalData) {
        throw Error();
      }
      setDeleteDialog({ ...deleteDialog, isLoading: true });

      if (originalData.heroImageUrl) {
        await attachmentUtils.deleteImage(originalData.heroImageUrl);
        setValue('hero.image', null);
      }
      await cmsInnovationApi.deleteFocusAreaArticle(originalData.id);
      showSnackbar({
        type: 'success',
        text: translations.contentDeleteSuccess,
      });

      history.push(InnovationRouteEnum.FocusArea);
    } catch {
      showSnackbar({
        type: 'error',
        text: translations.contentDeleteError,
      });
    } finally {
      setDeleteDialog({ open: false, isLoading: false });
    }
  }, [deleteDialog, history, originalData, setValue, showSnackbar]);

  const handleBack = useCallback(() => {
    const canGoBack = Boolean(location.key);
    if (canGoBack) {
      history.goBack();
    } else {
      history.push(InnovationRouteEnum.FocusArea);
    }
  }, [location.key, history]);

  return (
    <Page title={documentTitle}>
      <FormProvider {...methods}>
        <FocusAreaForm
          beforeSubmit={beforeSubmit}
          onSubmit={handleSubmit}
          onBack={handleBack}
          onDelete={() => setDeleteDialog({ ...deleteDialog, open: true })}
        />
      </FormProvider>
      <AlertDialog
        open={deleteDialog.open}
        type="critical"
        title={translations.articleDeleteTitle}
        paragraphs={[
          translations.articleDeleteText,
          translations.articleDeleteInfoText,
        ]}
        primaryButton={{
          loading: deleteDialog.isLoading,
          disabled: deleteDialog.isLoading,
          text: translations.delete,
          onClick: handleDelete,
        }}
        secondaryButton={{
          disabled: deleteDialog.isLoading,
          text: translations.cancel,
          onClick: () => setDeleteDialog({ ...deleteDialog, open: false }),
        }}
      />
    </Page>
  );
};

export { FocusAreaEdit };
