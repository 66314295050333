import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Popper, { PopperProps as MuiPopperProps } from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

export type PopperMenuItem = {
  text: string;
  subtext?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  to?: string;
  onClick?: () => void;
};

export type PopperMenuProps = {
  id: string;
  items?: PopperMenuItem[];
  onClose: () => void;
  children?: React.ReactNode;
} & Omit<MuiPopperProps, 'children'>;

const PopperMenu = ({
  id,
  items,
  onClose,
  children,
  ...muiPopperProps
}: PopperMenuProps) => {
  const listElement = useMemo(
    () =>
      !items ? null : (
        <List
          id={`${id}-list`}
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing('xxs'),
          })}
        >
          {items.map((item, index) => (
            <ListItem
              id={`${id}-list-item-${index}`}
              key={`${id}-list-item-${index}`}
              sx={() => ({ padding: 0 })}
            >
              <ListItemButton
                {...(item.href && { href: item.href, target: item.target })}
                {...(item.to && {
                  to: item.to,
                  component: ReactRouterLink,
                })}
                onClick={item.onClick}
                sx={(theme) => ({
                  gap: theme.spacing('xxs'),
                  alignItems: 'flex-start',
                  padding: `${theme.spacing('xxs')} ${theme.spacing('sm')}`,
                  borderRadius: theme.border.radius.sm,
                  svg: {
                    flexShrink: 0,
                  },
                })}
              >
                {item.startIcon}
                <Stack sx={{ marginRight: 'auto' }}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({
                      color: Boolean(item.subtext)
                        ? theme.colors.text.primary
                        : theme.colors.text.secondary,
                      wordBreak: 'break-word',
                      lineHeight: 1.5,
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    })}
                  >
                    {item.text}
                  </Typography>
                  {item.subtext && (
                    <Typography
                      variant="caption"
                      sx={(theme) => ({
                        color: theme.colors.text.tertiary,
                        wordBreak: 'break-word',
                        lineHeight: 1.5,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      })}
                    >
                      {item.subtext}
                    </Typography>
                  )}
                </Stack>
                {item.endIcon}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ),
    [id, items]
  );

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (e.target !== muiPopperProps.anchorEl) {
          onClose();
        }
      }}
    >
      <Popper
        id={id}
        disablePortal
        {...muiPopperProps}
        sx={[
          (theme) => ({
            backgroundColor: theme.colors.surface.primary,
            boxShadow: theme.elevation.sm,
            borderRadius: theme.border.radius.md,
            padding: theme.spacing('xxs'),
            typography: theme.typography.body2,
            zIndex: theme.zIndex.tooltip,
          }),
          ...(Array.isArray(muiPopperProps.sx)
            ? muiPopperProps.sx
            : [muiPopperProps.sx]),
        ]}
      >
        {listElement}
        {children}
      </Popper>
    </ClickAwayListener>
  );
};

export { PopperMenu };
