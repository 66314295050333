const ci = {
  portals: [
    {
      id: '779707869b2c787ea6ab',
      name: 'Code of Conduct',
      url: 'codeofconduct',
      homePath: '/portals/codeofconduct',
      roleRestriction: { isRestricted: false, role: '' },
    },
    {
      id: '53419824287575bfe08e',
      name: 'Innovation',
      url: 'innovation',
      homePath: '/portals/innovation',
      roleRestriction: { isRestricted: false, role: '' },
      insightsDatabaseId: '363926e2932075dfd323',
    },
  ],
};

export default ci;
