import { InnovationListingItemTypeEnum } from 'api/find/types';
import { InnovationRouteEnum } from 'enums';
import { translations } from 'translations';

export const INNOVATION_LISTING_ITEM_TYPE_TO_LABEL: Record<
  InnovationListingItemTypeEnum,
  string
> = {
  [InnovationListingItemTypeEnum.CaseStudy]:
    translations.innovationTypeCaseStudy,
  [InnovationListingItemTypeEnum.Training]: translations.innovationTypeTraining,
  [InnovationListingItemTypeEnum.Webinar]: translations.innovationTypeWebinar,
  [InnovationListingItemTypeEnum.ToolsAndTemplates]:
    translations.innovationTypeToolsAndTemplates,
  [InnovationListingItemTypeEnum.ProcessAndGuides]:
    translations.innovationTypeProcessAndGuides,
};

export const getInnovationListingItemLinkUrl = (
  id: string,
  type: InnovationListingItemTypeEnum
) => {
  switch (type) {
    case InnovationListingItemTypeEnum.CaseStudy:
      return `${InnovationRouteEnum.CaseStudy}/${id}`;
    case InnovationListingItemTypeEnum.Training:
      return `${InnovationRouteEnum.Training}/${id}`;
    case InnovationListingItemTypeEnum.Webinar:
      return `${InnovationRouteEnum.Webinar}/${id}`;
    case InnovationListingItemTypeEnum.ToolsAndTemplates:
      return `${InnovationRouteEnum.ToolsAndTemplates}/${id}`;
    case InnovationListingItemTypeEnum.ProcessAndGuides:
      return `${InnovationRouteEnum.ProcessAndGuides}/${id}`;
  }
};

export const INNOVATION_LISTING_PAGINATION_LIMITS = [15, 25, 50, 100];

export const INNOVATION_LISTING_PAGINATION_DEFAULT_LIMIT =
  INNOVATION_LISTING_PAGINATION_LIMITS[0];
