import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import * as feedbackApi from 'api/feedback';
import * as feedbackTransformers from 'api/feedback/transformers';
import {
  LikesAndCommentsMeta,
  LikesAndCommentsResponse,
  UpdateLikeResponse,
} from 'api/feedback/transformers';
import * as newsApi from 'api/news';
import * as newsTransformers from 'api/news/transformers';
import { NewsFlash, NewsFlashesResponse } from 'api/news/transformers';
import { useNextArticle } from 'components/Context/NextArticle';
import { useUser } from 'components/Context/User';
import { usePrevious } from 'hooks';
import type { ArticlePreview } from 'pagesAvenue/common/ArticleList/ArticleList';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GAonClickHomeFeedFilter,
  GAonClickHomeFeedItem,
  GAonCommentFeedClick,
  GAonLikeSent,
} from 'utils/analytics';
import makeQueryString from 'utils/misc/makeQueryString';

import { LanguageSelector } from '../LanguageSelector';
import { FeaturedArticles } from './FeaturedArticles/FeaturedArticles';
import * as helpers from './helpers';
import { LatestArticlesSkeleton } from './LatestArticlesSkeleton';
import { TabArticles, TabName } from './TabArticles/TabArticles';

const QUANTITY_LIMIT = 8;

type LatestArticlesProps = {
  locale: string;
  setLocale: (locale: string) => void;
};

const LatestArticles = ({ locale, setLocale }: LatestArticlesProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<TabName | null>(null);

  const [featuredArticles, setFeaturedArticles] = useState<
    ArticlePreview[] | null
  >(null);

  const [articlesByTab, setArticlesByTab] = useState<
    Record<TabName, ArticlePreview[] | null>
  >({
    [TabName.Default]: null,
    [TabName.Division]: null,
    [TabName.Department]: null,
    [TabName.SecondaryDepartment]: null,
    [TabName.Site]: null,
    [TabName.Global]: null,
  });

  const user = useUser();
  const prevLocale = usePrevious(locale);
  const nextInYourFeed = useNextArticle();

  const fetchPreviewArticles = useCallback(
    async (filter: { tab: TabName; locale: string; user: any }) => {
      setIsLoading(true);
      let newsFlashes: NewsFlash[] = [];
      let likesAndCommentsMetaByArticleId: Record<
        string,
        LikesAndCommentsMeta
      > = {};

      const { tab, locale, user } = filter;

      const params = {
        filter: helpers.getNewsFlashesFilter(tab, {
          countryId: user.countryId,
          divisionId: user.divisionId,
          departmentId: user.departmentId,
          secondaryDepartmentId: user.secondaryDepartmentId,
          segmentId: user.segmentId,
          siteId: user.siteId,
          companyId: user.companyId,
          locale: locale,
        }),
        page: { limit: QUANTITY_LIMIT, offset: 0 },
      };

      // Fetch news flashes
      try {
        const newsFlashesResponse = (await newsApi.getNewsFlashes(
          makeQueryString(params)
        )) as NewsFlashesResponse;

        newsFlashes =
          newsTransformers.newsFlashesResponseToNewsFlashes(
            newsFlashesResponse
          );
        if (!newsFlashes.length) {
          setIsLoading(false);
          return {
            tabArticles: [],
            featuredArticles: null,
          };
        }
      } catch {
        setIsLoading(false);
        return {
          tabArticles: [],
          featuredArticles: null,
        };
      }
      // Split articles into "featuredArticles" and "tabArticles".
      // "featuredArticles" are articles from the default articles/tab
      // marked as "top news". If there are currently no "top news" we
      // will take the first article from the list.
      const tabArticles: ArticlePreview[] = newsFlashes;
      let featuredArticles: ArticlePreview[] | null = null;
      const useFeaturedArticles = tab === TabName.Default;

      if (useFeaturedArticles) {
        featuredArticles = tabArticles.filter((article) => article.isTopNews);
        const hasFeaturedArticles = (featuredArticles || []).length > 0;

        featuredArticles = hasFeaturedArticles
          ? featuredArticles
          : tabArticles.slice(0, 1);
      }

      // Fetch likes and comments
      try {
        const newsFlashesIds = newsFlashes.map((newsFlash) =>
          newsFlash.id.replace('cms-', '')
        );
        const likesAndCommentsResponse = (await feedbackApi.getLikesAndComments(
          newsFlashesIds
        )) as LikesAndCommentsResponse;

        likesAndCommentsMetaByArticleId =
          feedbackTransformers.likesAndCommentsResponseToLikesAndCommentsMetaByArticleId(
            likesAndCommentsResponse,
            user.userId
          );
      } catch {
        setIsLoading(false);
        return { tabArticles, featuredArticles };
      }

      // Add likes and comments data to articles
      [...tabArticles, ...(featuredArticles || [])].forEach((article) => {
        article.likesAndCommentsMeta =
          likesAndCommentsMetaByArticleId[article.id];
      });
      setIsLoading(false);
      return { tabArticles, featuredArticles };
    },
    []
  );

  const updateLike = useCallback(async (articleId: string, like: boolean) => {
    let result: { isLikedByMe: boolean; likesCount: number } | null = null;
    try {
      const response = like
        ? ((await feedbackApi.sendLike(
            articleId.replace('cms-', '')
          )) as UpdateLikeResponse)
        : ((await feedbackApi.deleteLike(
            articleId.replace('cms-', '')
          )) as UpdateLikeResponse);
      result = {
        isLikedByMe: response.data.meta.likedByMe,
        likesCount: response.data.meta.total,
      };
    } catch {}
    return result;
  }, []);

  const handleLikeChange = useCallback(
    async (articleId: string, like: boolean) => {
      if (!activeTab) return;

      const result = await updateLike(articleId, like);
      if (!result) return;

      const tabArticles = articlesByTab[activeTab] || [];
      const newTabArticles = tabArticles.map((article) => {
        if (article.id !== articleId) {
          return article;
        }
        if (!article.likesAndCommentsMeta) {
          return article;
        }
        const newArticle: ArticlePreview = {
          ...article,
          likesAndCommentsMeta: {
            ...article.likesAndCommentsMeta,
            isLikedByMe: result.isLikedByMe,
            likesCount: result.likesCount,
          },
        };
        return newArticle;
      });
      setArticlesByTab({ ...articlesByTab, [activeTab]: newTabArticles });

      if (!featuredArticles) return;

      const newFeaturedArticles = featuredArticles.map((article) => {
        if (article.id !== articleId) {
          return article;
        }
        if (!article.likesAndCommentsMeta) {
          return article;
        }
        const newArticle: ArticlePreview = {
          ...article,
          likesAndCommentsMeta: {
            ...article.likesAndCommentsMeta,
            isLikedByMe: result.isLikedByMe,
            likesCount: result.likesCount,
          },
        };
        return newArticle;
      });
      setFeaturedArticles(newFeaturedArticles);
    },
    [activeTab, articlesByTab, featuredArticles, updateLike]
  );

  const handleTabChange = useCallback(
    async (tab: TabName) => {
      setActiveTab(tab);
      if (articlesByTab[tab]) return;

      const articles = await fetchPreviewArticles({ tab, locale, user });
      if (!articles) return;

      const { tabArticles, featuredArticles } = articles;
      setArticlesByTab({
        ...articlesByTab,
        [tab]: tabArticles,
      });
      if (featuredArticles) {
        setFeaturedArticles(featuredArticles);
      }
    },
    [articlesByTab, fetchPreviewArticles, locale, user]
  );

  const handleLocaleChange = useCallback(
    async (locale: string) => {
      if (!activeTab) return;

      // In order to show translated "featuredArticles", we always need
      // to fetch "default" articles when the locale is updated. This is
      // because "featuredArticles" is a subset of "default" articles.
      const defaultArticles = await fetchPreviewArticles({
        tab: TabName.Default,
        locale,
        user,
      });
      const featuredArticles = defaultArticles?.featuredArticles || null;
      const defaultTabArticles = defaultArticles?.tabArticles || null;
      let activeTabArticles: ArticlePreview[] | null = null;

      // If the active tab is not the "default" tab we also need to
      // fetch articles to show in the active tab.
      if (activeTab !== TabName.Default) {
        const activeArticles = await fetchPreviewArticles({
          tab: activeTab,
          locale,
          user,
        });
        activeTabArticles = activeArticles?.tabArticles || null;
      }
      setArticlesByTab({
        [TabName.Division]: null,
        [TabName.Department]: null,
        [TabName.SecondaryDepartment]: null,
        [TabName.Site]: null,
        [TabName.Global]: null,
        [activeTab]: activeTabArticles,
        [TabName.Default]: defaultTabArticles,
      });
      setFeaturedArticles(featuredArticles);
    },
    [activeTab, fetchPreviewArticles, user]
  );

  const handleNextInYourFeed = useCallback(
    (articleId: string) => {
      const tab = activeTab || TabName.Default;

      const nextInYourFeedFilter = helpers.getNextInYourFeedFilter(tab, {
        countryId: user.countryId,
        divisionId: user.divisionId,
        departmentId: user.departmentId,
        secondaryDepartmentId: user.secondaryDepartmentId,
        segmentId: user.segmentId,
        siteId: user.siteId,
        companyId: user.companyId,
        locale: locale,
      });
      nextInYourFeed.updateCurrentFilter(nextInYourFeedFilter);

      const articles = articlesByTab[tab];
      if (!articles) {
        return;
      }
      const nextInYourFeedIndex = articles.findIndex(
        (article) => article.id === articleId
      );
      nextInYourFeed.updateArticleIndex(nextInYourFeedIndex);
    },
    [
      activeTab,
      articlesByTab,
      locale,
      nextInYourFeed,
      user.companyId,
      user.countryId,
      user.departmentId,
      user.secondaryDepartmentId,
      user.divisionId,
      user.segmentId,
      user.siteId,
    ]
  );

  // We filter out "featured articles" from the tab article
  // lists so that we do not display any duplicate articles
  const visibleArticlesByTab = useMemo(() => {
    if (!featuredArticles) return articlesByTab;
    const tabs = Object.keys(articlesByTab) as TabName[];
    return tabs.reduce(
      (filteredArticlesByTab, tab) => {
        filteredArticlesByTab[tab] = (articlesByTab[tab] || []).filter(
          (article) =>
            !featuredArticles.some(
              (featuredArticle) => featuredArticle.id === article.id
            )
        );
        return filteredArticlesByTab;
      },
      {
        ...articlesByTab,
      }
    );
  }, [articlesByTab, featuredArticles]);

  useEffect(() => {
    // Set default tab when user has finished loading
    if (!activeTab && !user.isLoading) {
      handleTabChange(TabName.Default);
    }
  }, [activeTab, handleTabChange, user.isLoading]);

  useEffect(() => {
    if (locale === prevLocale) return;
    handleLocaleChange(locale);
  }, [locale, prevLocale, handleLocaleChange]);

  if (user.isLoading || (isLoading && !featuredArticles)) {
    return <LatestArticlesSkeleton />;
  }
  return (
    <Box position="relative">
      <LanguageSelector
        locale={locale}
        setLocale={setLocale}
        topRightPosition={Boolean(featuredArticles?.length)}
      />
      <Stack>
        {featuredArticles && (
          <FeaturedArticles
            articles={featuredArticles}
            onLikeClick={(article) => {
              const like = !article.likesAndCommentsMeta?.isLikedByMe;
              handleLikeChange(article.id, like);
              if (like) GAonLikeSent(article.title, true);
            }}
            onCommentClick={(article) => GAonCommentFeedClick(article.title)}
            onArticleClick={(article) => {
              GAonClickHomeFeedItem(article.title);
              handleNextInYourFeed(article.id);
            }}
          />
        )}
        <TabArticles
          isLoading={isLoading}
          articlesByTab={visibleArticlesByTab}
          onTabChange={(tab) => {
            GAonClickHomeFeedFilter(tab);
            handleTabChange(tab);
          }}
          onLikeClick={(article) => {
            const like = !article.likesAndCommentsMeta?.isLikedByMe;
            handleLikeChange(article.id, like);
            if (like) GAonLikeSent(article.title, true);
          }}
          onCommentClick={(article) => GAonCommentFeedClick(article.title)}
          onArticleClick={(article) => {
            GAonClickHomeFeedItem(article.title);
            handleNextInYourFeed(article.id);
          }}
        />
      </Stack>
    </Box>
  );
};

export { LatestArticles };
