import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import * as cmsInnovationApi from 'api/cms-innovation';
import { InnovationFocusAreaArticle } from 'api/cms-innovation/types';
import { EmbedVideo, FetchError, Image, ProfileMiniList } from 'componentsNew';
import { MEDIA_IMAGE_ASPECT_RATIO } from 'componentsNew/MediaInput/MediaInput';
import { AvenueRouteEnum, InnovationRouteEnum } from 'enums';
import { Page, PageContentHeader } from 'layout';
import {
  ArticleBody,
  ArticleContactDetails,
  ArticleHeader,
  ArticleHeaderLeftColumn,
  ArticleSkeleton,
  TimestampsAndNote,
  TitlePreambleAndFocusAreas,
} from 'pagesInnovation/common';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translations } from 'translations';

import { ArticlesTabList } from './ArticleTabList';

const elementId = 'focus-area';

const FocusArea = () => {
  const [article, setArticle] = useState<InnovationFocusAreaArticle | null>(
    null
  );
  const [isError, setIsError] = useState<boolean>(false);

  const history = useHistory();
  const params = useParams<{ id: string }>();
  const theme = useTheme();

  const documentTitle = useMemo(
    () =>
      article
        ? [
            translations.innovationDocumentTitle,
            translations.innovationFocusArea,
            article.title,
          ]
        : null,
    [article]
  );

  const documentBreadcrumbs = useMemo(
    () =>
      article
        ? [
            {
              text: translations.innovationFocusAreas,
              to: InnovationRouteEnum.FocusArea,
            },
            { text: article.title },
          ]
        : null,
    [article]
  );

  useEffect(() => {
    if (params.id === article?.id || isError) {
      return;
    }
    const fetchArticle = async () => {
      try {
        setIsError(false);
        const data = await cmsInnovationApi.getFocusAreaArticle(params.id);
        setArticle(data);
      } catch (e: any) {
        if (e['status'] === 404) {
          history.push(AvenueRouteEnum.NotFound);
          return;
        }
        setIsError(true);
      }
    };
    fetchArticle();
  }, [article, history, isError, params.id]);

  if (isError) {
    return (
      <Page title={documentTitle} gridContainer>
        <FetchError />
      </Page>
    );
  }

  if (!article) {
    return (
      <Page title={documentTitle} gridContainer>
        <ArticleSkeleton />
      </Page>
    );
  }

  return (
    <Page title={documentTitle} breadcrumbs={documentBreadcrumbs} gridContainer>
      <Grid xs={12}>
        <ArticleHeader
          sx={(theme) => ({
            background: theme.colors.surface.highlight,
          })}
          articleActionButtonProps={{
            editPath: `${InnovationRouteEnum.FocusAreaEdit}/${article.id}`,
          }}
        >
          <ArticleHeaderLeftColumn>
            <TitlePreambleAndFocusAreas
              title={article.title}
              preamble={article.preamble}
              color={theme.colors.text.brand}
            />
          </ArticleHeaderLeftColumn>
        </ArticleHeader>
      </Grid>
      <Grid xs={12} lg={8}>
        <Stack sx={(theme) => ({ gap: theme.spacing('sm') })}>
          {article.heroImageUrl && !article.heroVideoUrl && (
            <Image
              alt={article.heroAltText}
              title={article.heroAltText}
              src={article.heroImageUrl}
              originalHeight={article.heroImageHeight}
              originalWidth={article.heroImageWidth}
              aspectRatio={MEDIA_IMAGE_ASPECT_RATIO}
              sx={(theme) => ({ borderRadius: theme.border.radius.lg })}
            />
          )}
          {article.heroVideoUrl && (
            <EmbedVideo
              title={article.heroAltText}
              src={article.heroVideoUrl}
              aspectRatio={2}
              sx={(theme) => ({ borderRadius: theme.border.radius.lg })}
            />
          )}
          <TimestampsAndNote
            note={article.note}
            created={article.created}
            lastModified={article.lastModified}
          />
          <Divider
            sx={(theme) => ({
              margin: `${theme.spacing('xxs')} 0`,
              borderColor: theme.colors.border.surfacePrimary,
            })}
          />
          {article.body && (
            <ArticleBody
              elementId={`${elementId}-body`}
              htmlContent={article.body}
            />
          )}
        </Stack>
      </Grid>
      <Grid xs={12} lg={4}>
        <Stack sx={(theme) => ({ gap: theme.spacing('md') })}>
          {article.teamMembers &&
            article.teamMembers.internalPersons &&
            article.teamMembers.internalPersons.length > 0 && (
              <Stack sx={(theme) => ({ gap: theme.spacing('sm') })}>
                <PageContentHeader text={translations.meetOurTeam} />
                <ProfileMiniList
                  elementId={`${elementId}-team-members`}
                  internalPersons={article.teamMembers?.internalPersons.map(
                    (internalPerson) => ({
                      id: internalPerson.oid,
                      name: internalPerson.name,
                    })
                  )}
                />
              </Stack>
            )}
          {article.contactDetails && (
            <ArticleContactDetails
              elementId={`${elementId}-contact-details`}
              htmlContent={article.contactDetails}
            />
          )}
        </Stack>
      </Grid>
      <Grid xs={12} lg={8}>
        <ArticlesTabList
          id={`${elementId}-article-list`}
          focusAreaId={article.id}
        />
      </Grid>
    </Page>
  );
};

export { FocusArea };
