import Stack from '@mui/material/Stack';
import { EmbedVideo } from 'componentsNew/Embed/EmbedVideo';
import { useState } from 'react';
import { translations } from 'translations';

import { MediaActionButtons } from '../MediaActionButtons';
import { EmbedVideoValue } from './EmbedVideoModal';

type EmbedVideoPreviewProps = {
  elementId: string;
  embedVideo: EmbedVideoValue;
  onEdit: (embedVideo: EmbedVideoValue) => void;
  onDelete: (embedVideo: EmbedVideoValue) => void;
};

const EmbedVideoPreview = ({
  elementId,
  embedVideo,
  onEdit,
  onDelete,
}: EmbedVideoPreviewProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  return (
    <Stack id={elementId} sx={() => ({ position: 'relative' })}>
      <EmbedVideo
        src={embedVideo.url}
        title={translations.mediaInputVideo}
        sx={(theme) => ({ borderRadius: theme.border.radius.lg })}
        onLoad={() => setIsLoaded(true)}
      />
      {isLoaded && (
        <MediaActionButtons
          onEdit={() => onEdit && onEdit(embedVideo)}
          onDelete={() => onDelete && onDelete(embedVideo)}
        />
      )}
    </Stack>
  );
};
export { EmbedVideoPreview };
