import Box from '@mui/material/Box';
import Stack, { StackProps } from '@mui/material/Stack';
import * as imageHelpers from 'componentsNew/Image/helpers';
import { useState } from 'react';

import { MediaActionButtons } from '../MediaActionButtons';
import { ImageValue } from './ImageModal';

type ImagePreviewProps = {
  elementId?: string;
  image: ImageValue;
  onEdit?: (image: ImageValue) => void;
  onDelete?: (image: ImageValue) => void;
  sx?: StackProps['sx'];
};

const ImagePreview = ({
  elementId,
  image,
  onEdit,
  onDelete,
  sx,
}: ImagePreviewProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  return (
    <Stack
      id={elementId}
      sx={[
        (theme) => ({
          position: 'relative',
          ...(image.width &&
            image.height && {
              width: `${image.width}px`,
              aspectRatio: image.width / image.height,
              maxWidth: '100%',
              backgroundColor: theme.colors.surface.tertiary,
              borderRadius: theme.border.radius.lg,
            }),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        component="img"
        src={imageHelpers.getSrc({ pathOrUrl: image.src }).originalSrc}
        alt={image.altText}
        title={image.altText}
        sx={(theme) => ({ borderRadius: theme.border.radius.lg })}
        onLoad={() => setIsLoaded(true)}
        onError={() => setIsError(true)}
      />
      {(isLoaded || isError) && (
        <MediaActionButtons
          onEdit={onEdit ? () => onEdit(image) : undefined}
          onDelete={onDelete ? () => onDelete(image) : undefined}
        />
      )}
    </Stack>
  );
};
export { ImagePreview };
