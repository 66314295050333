import * as documentsApi from 'api/sharepointdocuments';
import { AxiosError } from 'axios';
import { useSnackbar } from 'context';
import { useCallback } from 'react';
import { translations } from 'translations';

function useDeleteDocumentsFolder() {
  const { showSnackbar } = useSnackbar();

  const deleteDocumentsFolder = useCallback(
    async (params: {
      documentsFolderId: string;
      showErrorSnackbar?: boolean;
    }) => {
      const { documentsFolderId, showErrorSnackbar = true } = params;

      try {
        await documentsApi.deleteItem(
          documentsFolderId,
          undefined,
          undefined,
          'folder'
        );
        return { success: true };
      } catch (error) {
        if (!showErrorSnackbar) {
          return { success: false };
        }

        if (error instanceof AxiosError) {
          if (error.status === 404) {
            // Call is considered a success since the documents folder doesn't exists
            return { success: true };
          }
          if (error.status === 423) {
            showSnackbar({
              type: 'error',
              text: translations.documentOpenError,
              autoHideDuration: 6000,
            });
            return { success: false };
          }
        }

        showSnackbar({
          type: 'error',
          text: translations.contentDeleteError,
        });
        return { success: false };
      }
    },
    [showSnackbar]
  );

  return { deleteDocumentsFolder };
}

export { useDeleteDocumentsFolder };
