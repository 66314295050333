import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon, ProfileMini } from 'componentsNew';
import { ProfileMiniView } from 'componentsNew/ProfileMini/ProfileMiniView';
import { User, UserSearchSelect } from 'componentsNew/Select/UserSearchSelect';
import { useCallback } from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { translations } from 'translations';

export type InternalPerson = {
  id: string;
  name?: string;
  title?: string;
  email?: string;
  imageUrl?: string;
};

type InternalPersonsInputProps = {
  elementId: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  onChange?: (value: InternalPerson[]) => void;
  persons: InternalPerson[];
};

const InternalPersonsInput = ({
  elementId,
  sx,
  disabled,
  onChange,
  persons,
}: InternalPersonsInputProps) => {
  const handleRemove = useCallback(
    (index: number) => {
      if (!onChange) return;

      const newPersons = [...persons];
      newPersons.splice(index, 1);
      onChange(newPersons);
    },
    [onChange, persons]
  );

  const handleUsersSearchChange = useCallback(
    (addedUsers: User[]) => {
      if (!onChange) return;
      const addedUser = addedUsers.length > 0 ? addedUsers[0] : null;
      if (addedUser === null) return;

      const isAdded = persons.some((person) => person.id === addedUser.id);
      if (isAdded) return;

      const intPerson: InternalPerson = {
        id: addedUser.id,
        name: addedUser.name,
        title: addedUser.title || '',
        email: addedUser.email || '',
        imageUrl: addedUser.imageUrl || '',
      };
      onChange([...persons, intPerson]);
    },
    [onChange, persons]
  );

  return (
    <Stack
      sx={[
        (theme) => ({ gap: theme.spacing('sm') }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <UserSearchSelect
        sx={{ '.MuiInputBase-root': { borderRadius: '20px' } }}
        disabled={disabled}
        placeholder={translations.searchPeople}
        multiple
        value={[]}
        onChange={handleUsersSearchChange}
        hideTags={true}
        hideClearIcon={true}
      />
      <Box
        sx={(theme) => ({
          '> div': {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing('sm'),
          },
        })}
      >
        <TransitionGroup>
          {persons.map((person, index) => {
            return (
              <Collapse key={`${elementId}-collapse-${index}`}>
                <Stack
                  key={`${elementId}-stack-${index}`}
                  sx={(theme) => ({
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: theme.spacing('xxs'),
                  })}
                >
                  {person.name ? (
                    <ProfileMiniView
                      to={`/people/${person.id}`}
                      target="_blank"
                      elementId={`${elementId}-${index}`}
                      name={person.name}
                      title={person.title}
                      imageUrl={person.imageUrl}
                    />
                  ) : (
                    <ProfileMini
                      elementId={`${elementId}-${index}`}
                      target="_blank"
                      profileId={person.id}
                      profileName={person.name}
                    />
                  )}

                  <IconButton
                    id={`${elementId}-remove-${index}`}
                    size="small"
                    aria-label={translations.delete}
                    onClick={() => handleRemove(index)}
                  >
                    <Icon type="xMark" color="secondary" />
                  </IconButton>
                </Stack>
              </Collapse>
            );
          })}
        </TransitionGroup>
      </Box>
    </Stack>
  );
};

export { InternalPersonsInput };
