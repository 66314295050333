import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { RichText } from 'componentsNew';
import { useBreakpoints, useDeleteDocumentsFolder } from 'hooks';
import { FormFieldWrapper, FormLoading } from 'layout';
import {
  ArticleChapterContent,
  ArticleChapters,
  ArticleResourcesEdit,
  TabOrChapterInput,
} from 'pagesInnovation/common';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { translations } from 'translations';
import { v1 as uuidv1 } from 'uuid';

import { FormValues } from './ProcessAndGuidesForm';

const elementId = 'process-and-guides-form-chapters';

type ChaptersEditProps = {
  activeTabIndex: number;
};

const ChaptersEdit = ({ activeTabIndex }: ChaptersEditProps) => {
  const [activeChapterIndex, setActiveChapterIndex] = useState<number>(0);

  const [isDeletingDocuments, setIsDeletingDocuments] =
    useState<boolean>(false);

  const { isMobile } = useBreakpoints();
  const { deleteDocumentsFolder } = useDeleteDocumentsFolder();

  const methods = useFormContext<FormValues>();
  const { control, watch } = methods;

  const {
    fields: chapterFields,
    append: appendChapter,
    remove: removeChapter,
    update: updateChapter,
  } = useFieldArray({
    control,
    name: `tabs.${activeTabIndex}.chapters`,
    keyName: 'key',
  });

  const watchChapters = watch(`tabs.${activeTabIndex}.chapters`);

  const isFirstChapterGhost = useMemo(() => {
    return watchChapters.length === 1 && watchChapters[0].title === '';
  }, [watchChapters]);

  const handleAddChapter = useCallback(
    (value: { id: string; title: string }) => {
      if (isFirstChapterGhost) {
        updateChapter(0, {
          id: value.id,
          title: value.title,
          body: watchChapters[0].body,
          resources: watchChapters[0].resources,
        });
        return;
      }
      appendChapter({
        id: value.id,
        title: value.title,
        body: '',
        resources: {},
      });
      setActiveChapterIndex(chapterFields.length);
    },
    [
      appendChapter,
      chapterFields.length,
      isFirstChapterGhost,
      updateChapter,
      watchChapters,
    ]
  );

  const handleUpdateChapter = useCallback(
    (index: number, value: { id: string; title: string }) => {
      const chapterValue = watchChapters[index];
      updateChapter(index, {
        id: value.id,
        title: value.title,
        body: chapterValue.body,
        resources: chapterValue.resources,
      });
    },
    [updateChapter, watchChapters]
  );

  const handleRemoveChapter = useCallback(
    async (index: number) => {
      const chapter = watchChapters[index];
      const sharepointFolderName = chapter.resources.sharepointFolderName;

      if (sharepointFolderName) {
        setIsDeletingDocuments(true);
        const deleteDocumentsFolderResponse = await deleteDocumentsFolder({
          documentsFolderId: sharepointFolderName,
        });
        setIsDeletingDocuments(false);
        if (!deleteDocumentsFolderResponse.success) {
          return;
        }
      }
      const isLast = chapterFields.length === 1;
      if (isLast) {
        updateChapter(0, {
          id: uuidv1(),
          title: '',
          body: '',
          resources: {},
        });
        return;
      }
      removeChapter(index);

      if (activeChapterIndex > index) {
        setActiveChapterIndex(activeChapterIndex - 1);
        return;
      }
      if (activeChapterIndex > 0 && activeChapterIndex === index) {
        setActiveChapterIndex(index - 1);
      }
    },
    [
      activeChapterIndex,
      chapterFields.length,
      deleteDocumentsFolder,
      removeChapter,
      updateChapter,
      watchChapters,
    ]
  );

  return (
    <Stack
      sx={(theme) => ({
        position: 'relative',
        flexDirection: { xs: 'column', lg: 'row' },
        backgroundColor: theme.colors.surface.tertiary,
        padding: `${theme.spacing('md')} ${theme.spacing('sm')} ${theme.spacing(
          'lg'
        )} ${theme.spacing('sm')}`,
      })}
    >
      <FormLoading isLoading={isDeletingDocuments} />
      <Stack
        sx={(theme) => ({
          gap: theme.spacing('sm'),
          width: { xs: '100%', lg: '22.5%' },
          flexShrink: 0,
        })}
      >
        <TabOrChapterInput
          id={`${elementId}-input`}
          type="chapter"
          items={(isFirstChapterGhost ? [] : chapterFields).map((field) => ({
            id: field.id,
            title: field.title,
          }))}
          disabled={isDeletingDocuments}
          onAdd={handleAddChapter}
          onUpdate={handleUpdateChapter}
          onRemove={handleRemoveChapter}
          fullWidth
        />
        <Divider />
        <ArticleChapters
          id={`${elementId}-items`}
          ariaLabel={translations.chapters}
          activeIndex={activeChapterIndex}
          onChange={setActiveChapterIndex}
          items={chapterFields.map((chapterField, index) => ({
            id: `${elementId}-items-${index}`,
            title: chapterField.title,
            ariaControls: `${elementId}-content-${index}`,
          }))}
        />
        {isMobile && !isFirstChapterGhost && <Divider />}
      </Stack>
      {chapterFields.map((chapterField, index) => {
        return (
          <ArticleChapterContent
            key={chapterField.key}
            id={`${elementId}-content-${index}`}
            active={index === activeChapterIndex}
            ariaLabelledBy={`${elementId}-items-${index}`}
            sx={() => ({ flexDirection: { xs: 'column', lg: 'row' } })}
          >
            <Stack
              sx={(theme) => ({
                minWidth: 0,
                width: '100%',
                gap: theme.spacing('sm'),
                margin: {
                  xs: `${theme.spacing('md')} 0`,
                  lg: `0 ${theme.spacing('md')}`,
                },
              })}
            >
              {chapterField.title && (
                <Typography
                  variant={isMobile ? 'h1' : 'display4'}
                  sx={(theme) => ({
                    wordBreak: 'break-word',
                    fontWeight: 'unset',
                    color: theme.colors.text.secondary,
                  })}
                >
                  {chapterField.title}
                </Typography>
              )}
              <Controller
                name={`tabs.${activeTabIndex}.chapters.${activeChapterIndex}.body`}
                control={control}
                render={({ field }) => {
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-chapter-${activeChapterIndex}-body`}
                      label={translations.formLabelBody}
                      labelSize="small"
                    >
                      <RichText
                        ref={field.ref}
                        name={field.name}
                        defaultValue={field.value}
                        disabled={field.disabled}
                        onBlur={(html) => {
                          field.onChange(html);
                        }}
                      />
                    </FormFieldWrapper>
                  );
                }}
              />
            </Stack>
            <Stack
              sx={() => ({
                width: { xs: '100%', lg: `${(0.225 / 0.775) * 100}%` },
                flexShrink: 0,
              })}
            >
              <Controller
                name={`tabs.${activeTabIndex}.chapters.${activeChapterIndex}.resources`}
                control={control}
                render={({ field }) => {
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-chapter-${activeChapterIndex}-resources`}
                    >
                      <ArticleResourcesEdit
                        ref={field.ref}
                        links={field.value.links || []}
                        documentsFolderId={field.value.sharepointFolderName}
                        onChangeLinks={(value) => {
                          field.onChange({
                            ...field.value,
                            links: value,
                          });
                        }}
                        onChangeDocumentsFolderId={(value) => {
                          field.onChange({
                            ...field.value,
                            sharepointFolderName: value,
                          });
                        }}
                      />
                    </FormFieldWrapper>
                  );
                }}
              />
            </Stack>
          </ArticleChapterContent>
        );
      })}
    </Stack>
  );
};

export { ChaptersEdit };
