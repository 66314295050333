import Button, { ButtonProps } from '@mui/material/Button';
import { PopperMenu } from 'componentsNew';
import { useState } from 'react';

import { PopperMenuItem, PopperMenuProps } from './PopperMenu';

type ButtonWithMenuProps = {
  items: PopperMenuItem[];
  placement?: PopperMenuProps['placement'];
} & ButtonProps;

const ButtonWithMenu = ({
  items,
  placement = 'top',
  ...buttonProps
}: ButtonWithMenuProps) => {
  const [menuAnchorElement, setMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  return (
    <>
      <Button
        {...buttonProps}
        onClick={(e) => {
          e.stopPropagation();
          setMenuAnchorElement(e.currentTarget);
          buttonProps.onClick && buttonProps.onClick(e);
        }}
      >
        {buttonProps.children}
      </Button>
      <PopperMenu
        id={`${buttonProps.id}-menu`}
        placement={placement}
        open={Boolean(menuAnchorElement)}
        anchorEl={menuAnchorElement}
        onClose={() => setMenuAnchorElement(null)}
        items={items}
      />
    </>
  );
};
export { ButtonWithMenu };
