const url = (value: string | undefined | null) => {
  if (!value) {
    return true;
  }
  if (typeof value !== 'string') {
    return false;
  }
  const pattern = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
    'g'
  );
  const isValid = pattern.test(value);
  return isValid;
};

const emailPattern = () => {
  const pattern = new RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/ // eslint-disable-line
  );

  return pattern;
};

export { emailPattern, url };
