import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'componentsNew/Icon/Icon';
import { KeyboardKeyEnum } from 'enums';
import { FormFieldWrapper } from 'layout';
import { useCallback, useEffect, useState } from 'react';
import { translations } from 'translations';
import * as formUtils from 'utils/form';

const elementId = 'link-modal';

export type LinkValue = {
  url: string;
};

type LinkModalProps = {
  defaultValue?: LinkValue;
  disabled?: boolean;
  onSubmit: (value: LinkValue) => void;
  onClose: () => void;
};

const LinkModal = ({
  defaultValue = { url: '' },
  disabled,
  onSubmit,
  onClose,
}: LinkModalProps) => {
  const [urlInput, setUrlInput] = useState<{
    value: string;
    error: string;
  } | null>(null);

  const validateUrl = useCallback((value: string) => {
    if (formUtils.customValidators.url(value)) {
      return '';
    }
    return formUtils.getErrorMessage('url');
  }, []);

  const handleSubmit = useCallback(() => {
    if (!urlInput) {
      return;
    }
    const urlError = validateUrl(urlInput.value);

    if (urlError) {
      setUrlInput({ ...urlInput, error: urlError });
      return;
    }
    onSubmit({ url: urlInput.value });
  }, [onSubmit, urlInput, validateUrl]);

  const handleUnlink = useCallback(() => {
    setUrlInput({ value: '', error: '' });
    onSubmit({ url: '' });
  }, [onSubmit]);

  const handlePaste = useCallback(async () => {
    const newValue = (await navigator.clipboard.readText()).trim();
    const newError = validateUrl(newValue);
    setUrlInput({ value: newValue, error: newError });
  }, [validateUrl]);

  useEffect(() => {
    if (urlInput) {
      return;
    }
    setUrlInput({ value: defaultValue.url, error: '' });
  }, [defaultValue, urlInput]);

  if (!urlInput) {
    return null;
  }

  return (
    <Dialog
      open
      aria-labelledby={`${elementId}-title`}
      onClose={onClose}
      PaperProps={{
        sx: (theme) => ({
          maxWidth: '100%',
          width: '34.5rem',
          backgroundColor: theme.colors.surface.primary,
          boxShadow: theme.elevation.md,
          borderRadius: theme.border.radius.md,
        }),
      }}
    >
      <DialogContent
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: theme.spacing('md'), md: theme.spacing('sm') },
          padding: theme.spacing('md'),
          overflowX: 'hidden',
        })}
      >
        <Stack sx={{ flexDirection: 'row' }}>
          <Typography variant="h3" id={`${elementId}-title`}>
            {translations.richTextFormatLinkModalTitle}
          </Typography>
          <IconButton
            size="small"
            aria-label={translations.close}
            onClick={onClose}
            sx={{
              alignSelf: 'baseline',
              marginLeft: 'auto',
              padding: 0,
              '&.MuiButtonBase-root.MuiIconButton-root:hover': {
                backgroundColor: 'unset',
              },
            }}
          >
            <Icon type="xMark" color="secondary" />
          </IconButton>
        </Stack>
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: theme.spacing('xs'), md: theme.spacing('sm') },
            alignItems: { xs: 'end', md: 'baseline' },
          })}
        >
          <FormFieldWrapper
            id={`${elementId}-url`}
            label={translations.formLabelUrl}
            hideLabel
            error={urlInput.error || ''}
          >
            <OutlinedInput
              size="small"
              placeholder={translations.formPlaceholderUrl}
              value={urlInput.value}
              multiline
              autoFocus
              maxRows={10}
              onChange={(e) => {
                const newValue = e.target.value;
                const newError = validateUrl(newValue);
                setUrlInput({ value: newValue, error: newError });
              }}
              onKeyDown={(e) => {
                if (e.key === KeyboardKeyEnum.Enter) {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
            />
          </FormFieldWrapper>
          <Button
            size="medium"
            variant="text"
            disabled={disabled}
            onClick={handlePaste}
          >
            {translations.paste}
          </Button>
        </Stack>
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: theme.spacing('xs'), md: theme.spacing('sm') },
            marginTop: theme.spacing('sm'),
            justifyContent: 'end',
          })}
        >
          <Button
            size="medium"
            variant="text"
            disabled={disabled}
            onClick={onClose}
          >
            {translations.cancel}
          </Button>
          {defaultValue && defaultValue.url && (
            <Button size="medium" variant="outlined" onClick={handleUnlink}>
              Unlink
            </Button>
          )}
          <Button size="medium" variant="contained" onClick={handleSubmit}>
            {translations.save}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export { LinkModal };
