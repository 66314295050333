import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  JobFilterName,
  JobListingFilter,
  JobListingFilterIds,
} from 'api/jobs/types';
import { Chip, Icon, MultiSelect } from 'componentsNew';
import { SelectItem } from 'componentsNew/Select/SelectMenuItem';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { translations } from 'translations';
import { GAonResetJobFilterClick } from 'utils/analytics';

import { ALL_FILTER_NAMES, EMPTY_ACTIVE_FILTER } from '../helpers';
import { JobListTotal } from '../JobList/JobListTotal';

const elementId = 'job-filter';

type FilterChip = {
  id: string;
  name: string;
  filterName: JobFilterName;
};

type JobFilterProps = {
  isLoading?: boolean;
  jobListTotal: number;
  activeFilter: JobListingFilterIds;
  availableFilter: JobListingFilter;
  onChange: (filter: JobListingFilterIds) => void;
};

const JobFilter = ({
  isLoading,
  jobListTotal,
  activeFilter,
  availableFilter,
  onChange,
}: JobFilterProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [displayChips, setDisplayChips] = useState<FilterChip[]>([]);

  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuButtonRef = useRef<HTMLButtonElement | null>(null);

  const activeFilterChips = useMemo(() => {
    return ALL_FILTER_NAMES.map((filterName) => {
      return activeFilter[filterName].map((id) => {
        const option = availableFilter[filterName].find((o) => o.id === id);
        return option ? { id: option.id, name: option.name, filterName } : null;
      });
    })
      .flat()
      .filter((chip) => chip !== null) as FilterChip[];
  }, [activeFilter, availableFilter]);

  const locationSelectItems = useMemo(() => {
    const items: SelectItem[] = availableFilter.locations.map((option) => ({
      name: option.name,
      value: option.id,
    }));
    return items;
  }, [availableFilter.locations]);

  const stateSelectItems = useMemo(() => {
    const items: SelectItem[] = availableFilter.states
      .filter((option) =>
        activeFilter[JobFilterName.Locations].some(
          (id) => id === option.locationId
        )
      )
      .map((option) => ({
        name: option.name,
        value: option.id,
      }));
    return items;
  }, [availableFilter.states, activeFilter]);

  const categorySelectItems = useMemo(() => {
    const items: SelectItem[] = availableFilter.categories.map((option) => ({
      name: option.name,
      value: option.id,
    }));
    return items;
  }, [availableFilter.categories]);

  const seniorityLevelSelectItems = useMemo(() => {
    const items: SelectItem[] = availableFilter.seniorityLevels.map(
      (option) => ({
        name: option.name,
        value: option.id,
      })
    );
    return items;
  }, [availableFilter.seniorityLevels]);

  const handleFilterChange = useCallback(
    (name: JobFilterName, ids: string[]) => {
      if (name !== JobFilterName.Locations) {
        const newFilter = {
          ...activeFilter,
          [name]: ids,
        };
        onChange(newFilter);
        return;
      }

      const allowedStates = availableFilter[JobFilterName.States].filter(
        (state) => ids.some((id) => id === state.locationId)
      );
      const newStateIds = activeFilter.states.filter((id) =>
        allowedStates.some((state) => state.id === id)
      );
      const newFilter = {
        ...activeFilter,
        [JobFilterName.Locations]: ids,
        [JobFilterName.States]: newStateIds,
      };
      onChange(newFilter);
    },
    [activeFilter, availableFilter, onChange]
  );

  const handleChipDelete = useCallback(
    (chip: FilterChip) => {
      const filterName = chip.filterName;
      const filterIds = activeFilter[filterName].filter((id) => id !== chip.id);
      handleFilterChange(filterName, filterIds);
    },
    [activeFilter, handleFilterChange]
  );

  const handleClearFilter = useCallback(() => {
    onChange(EMPTY_ACTIVE_FILTER);
    GAonResetJobFilterClick();
  }, [onChange]);

  // Update "displayChips" when active filter changes
  useEffect(() => {
    setDisplayChips((prevChips) => {
      if (!activeFilterChips.length) {
        return [];
      }
      if (prevChips.length === activeFilterChips.length) {
        return prevChips;
      }
      if (prevChips.length < activeFilterChips.length) {
        const chipsToAdd = activeFilterChips.filter(
          (chip) => !prevChips.some((prevChip) => prevChip.id === chip.id)
        );
        const newChips = [...prevChips, ...chipsToAdd];
        return newChips;
      }
      const newChips = prevChips.filter((prevChip) =>
        activeFilterChips.some((chip) => chip.id === prevChip.id)
      );
      return newChips;
    });
  }, [activeFilterChips]);

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        flexDirection: { xs: 'column', md: 'row' },
        gap: theme.spacing('xs'),
      })}
    >
      <Stack id={elementId} sx={{ position: 'relative' }}>
        <Button
          variant="text"
          id={`${elementId}-expand`}
          ref={menuButtonRef}
          startIcon={<Icon type="adjustmentsHorizontal" color="brandBase" />}
          onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
          sx={(theme) => ({
            alignSelf: 'flex-start',
            ...(isOpen && {
              '&.MuiButton-text': {
                color: theme.colors.text.actionHover,
                backgroundColor: theme.colors.surface.actionTertiaryHover,
                border: `1px solid ${theme.colors.border.brandBase}`,
              },
            }),
          })}
        >
          {translations.jobsFilter}
        </Button>
        {isOpen && (
          <ClickAwayListener
            onClickAway={(e) => {
              if (
                e.target === menuRef.current ||
                e.target === menuButtonRef.current
              ) {
                return;
              }
              setIsOpen(false);
            }}
          >
            <Stack
              id={`${elementId}-menu`}
              ref={menuRef}
              sx={(theme) => ({
                position: 'absolute',
                top: '3.25rem',
                backgroundColor: theme.colors.surface.primary,
                borderRadius: theme.border.radius.md,
                boxShadow: theme.elevation.sm,
                padding: theme.spacing('sm'),
                zIndex: theme.zIndex.tooltip,
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing('xs'),
                width: '20rem',
              })}
            >
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="h4" component="h2">
                  {translations.jobsFilterTitle}
                </Typography>
                <IconButton
                  size="small"
                  aria-label={translations.close}
                  onClick={() => setIsOpen(false)}
                >
                  <Icon type="xMark" color="secondary" />
                </IconButton>
              </Stack>
              <MultiSelect
                id={`${JobFilterName.Locations}-select`}
                placeholder={translations.jobsFilterLocation}
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.secondary,
                  '.MuiPaper-root': { maxHeight: '30rem' },
                })}
                limitTags={2}
                disableItems={isLoading}
                items={locationSelectItems}
                value={activeFilter.locations}
                onChange={(items) =>
                  handleFilterChange(
                    JobFilterName.Locations,
                    items.map((item) => item.value)
                  )
                }
              />
              <MultiSelect
                id={`${JobFilterName.States}-select`}
                placeholder={translations.jobsFilterState}
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.secondary,
                  '.MuiPaper-root': { maxHeight: '30rem' },
                })}
                limitTags={2}
                disableItems={isLoading}
                disabled={!stateSelectItems.length}
                items={stateSelectItems}
                value={activeFilter.states}
                onChange={(items) =>
                  handleFilterChange(
                    JobFilterName.States,
                    items.map((item) => item.value)
                  )
                }
              />
              <MultiSelect
                id={`${JobFilterName.Categories}-select`}
                placeholder={translations.jobsFilterCategory}
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.secondary,
                  '.MuiPaper-root': { maxHeight: '30rem' },
                })}
                limitTags={2}
                disableItems={isLoading}
                items={categorySelectItems}
                value={activeFilter.categories}
                onChange={(items) =>
                  handleFilterChange(
                    JobFilterName.Categories,
                    items.map((item) => item.value)
                  )
                }
              />
              <MultiSelect
                id={`${JobFilterName.SeniorityLevels}-select`}
                placeholder={translations.jobsFilterSeniorityLevel}
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.secondary,
                  '.MuiPaper-root': { maxHeight: '30rem' },
                })}
                limitTags={2}
                disableItems={isLoading}
                items={seniorityLevelSelectItems}
                value={activeFilter.seniorityLevels}
                onChange={(items) =>
                  handleFilterChange(
                    JobFilterName.SeniorityLevels,
                    items.map((item) => item.value)
                  )
                }
              />
              <Divider sx={{ margin: '0.25rem 0 0.125rem 0' }} />
              <JobListTotal
                total={jobListTotal}
                sx={{ alignSelf: 'flex-end' }}
              />
            </Stack>
          </ClickAwayListener>
        )}
      </Stack>
      {displayChips && displayChips.length > 0 && (
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            columnGap: theme.spacing('xs'),
            rowGap: theme.spacing('xxs'),
            padding: {
              xs: 0,
              md: `0 ${theme.spacing('xs')}`,
            },
            borderLeft: {
              xs: 'none',
              md: `1px solid ${theme.colors.border.surfacePrimary}`,
            },
            flexWrap: 'wrap',
          })}
        >
          {displayChips.map((chip, index) => (
            <Chip
              id={`${elementId}-chip-${index}`}
              key={`${elementId}-chip-${index}`}
              size="small"
              color="primary"
              variant="outlined"
              label={chip.name}
              sx={{ alignSelf: 'center' }}
              onDelete={() => handleChipDelete(chip)}
            />
          ))}
          <Link
            id={`${elementId}-clear`}
            variant="caption"
            onClick={handleClearFilter}
            sx={(theme) => ({
              alignSelf: 'center',
              marginLeft: theme.spacing('xs'),
            })}
          >
            {translations.jobsFilterClear}
          </Link>
        </Stack>
      )}
    </Stack>
  );
};

export { JobFilter };
