import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { InnovationToolsAndTemplatesArticle } from 'api/cms-innovation/types';
import { InnovationListingItem } from 'api/find/types';
import { FolderItem } from 'api/sharepointdocuments/types';
import { useBreakpoints } from 'hooks';
import {
  ArticleBody,
  ArticleChapterContent,
  ArticleChapters,
  ArticleResources,
  RelatedArticles,
} from 'pagesInnovation/common';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonInnovationArticleRelatedClick,
  GAonInnovationArticleResourceDocumentClick,
  GAonInnovationArticleResourceLinkClick,
} from 'utils/analytics';

import { ToolsAndTemplatesDocuments } from './ToolsAndTemplatesDocuments';

const elementIdChapters = 'tools-and-templates-chapters';

type ChaptersProps = Pick<InnovationToolsAndTemplatesArticle, 'chapters'> & {
  toolsAndTemplateDocuments: FolderItem[];
  relatedArticles: InnovationListingItem[];
};

const Chapters = ({
  chapters,
  toolsAndTemplateDocuments,
  relatedArticles,
}: ChaptersProps) => {
  const { isMobile } = useBreakpoints();
  const location = useLocation();
  const history = useHistory();

  const activeParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    const chapterId = urlSearchParams.get('chapterId');

    let chapterIndex = chapters.findIndex(
      (chapter) => chapter.id === chapterId
    );
    chapterIndex = chapterIndex === -1 ? 0 : chapterIndex;

    return { chapterIndex };
  }, [chapters, location.search]);

  const handleChapterChange = useCallback(
    (index: number) => {
      const selectedChapter = chapters[index];
      if (!selectedChapter) {
        return;
      }
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set('chapterId', selectedChapter.id);
      history.push({ search: urlSearchParams.toString() });
    },
    [chapters, history, location.search]
  );

  return (
    <Stack
      sx={(theme) => ({
        flexDirection: { xs: 'column', lg: 'row' },
        padding: `${theme.spacing('md')} 0`,
      })}
    >
      <ArticleChapters
        id={`${elementIdChapters}-items`}
        ariaLabel={translations.chapters}
        activeIndex={activeParams.chapterIndex}
        onChange={handleChapterChange}
        items={chapters.map((chapter, index) => ({
          id: `${elementIdChapters}-items-${index}`,
          title: chapter.title || '',
          ariaControls: `${elementIdChapters}-content-${index}`,
        }))}
        sx={() => ({
          width: { xs: '100%', lg: '22.5%' },
          flexShrink: 0,
        })}
      />
      {chapters.map((chapter, index) => {
        return (
          <ArticleChapterContent
            key={`${elementIdChapters}-content-${index}`}
            id={`${elementIdChapters}-content-${index}`}
            active={index === activeParams.chapterIndex}
            ariaLabelledBy={`${elementIdChapters}-items-${index}`}
            sx={() => ({
              flexDirection: { xs: 'column', lg: 'row' },
              minHeight: '30rem',
            })}
          >
            <Stack
              sx={(theme) => ({
                minWidth: 0,
                width: '100%',
                gap: theme.spacing('sm'),
                margin: {
                  xs: `${theme.spacing('md')} 0`,
                  lg: `0 ${theme.spacing('md')}`,
                },
              })}
            >
              {chapter.title && (
                <Typography
                  id={`${elementIdChapters}-content-${index}-title`}
                  variant={isMobile ? 'h1' : 'display4'}
                  sx={(theme) => ({
                    wordBreak: 'break-word',
                    color: theme.colors.text.secondary,
                    fontWeight: 'unset',
                  })}
                >
                  {chapter.title}
                </Typography>
              )}
              {chapter.body && (
                <ArticleBody
                  elementId={`${elementIdChapters}-content-${index}-body`}
                  htmlContent={chapter.body}
                  fullWidth
                />
              )}
            </Stack>
            <Stack
              sx={(theme) => ({
                gap: theme.spacing('lg'),
                width: {
                  xs: '100%',
                  lg:
                    chapters.length === 1 && chapter.title === ''
                      ? '27.5%'
                      : `${(27.5 / (100 - 27.5)) * 100}%`,
                },
                flexShrink: 0,
              })}
            >
              <ToolsAndTemplatesDocuments
                elementId={`${elementIdChapters}-documents`}
                onDocumentClick={(document) =>
                  GAonInnovationArticleResourceDocumentClick(document.name)
                }
                documents={toolsAndTemplateDocuments}
              />
              <ArticleResources
                elementId={`${elementIdChapters}-content-${index}-resources`}
                links={chapter.resources?.links || []}
                documentsFolderId={chapter.resources?.sharepointFolderName}
                onLinkClick={(link) =>
                  GAonInnovationArticleResourceLinkClick(link.title)
                }
                onDocumentClick={(document) =>
                  GAonInnovationArticleResourceDocumentClick(document.name)
                }
              />
              <RelatedArticles
                elementId={`${elementIdChapters}-content-${index}-related-articles`}
                articles={relatedArticles}
                onClick={(article) =>
                  GAonInnovationArticleRelatedClick(article.title)
                }
              />
            </Stack>
          </ArticleChapterContent>
        );
      })}
    </Stack>
  );
};

export { Chapters };
