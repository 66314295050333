import { SxProps, Theme } from '@mui/material/styles';
import CharacterCount from '@tiptap/extension-character-count';
import Highlight from '@tiptap/extension-highlight';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { Transaction } from '@tiptap/pm/state';
import { Editor, EditorProvider } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useCallback, useMemo } from 'react';
import { translations } from 'translations';

import {
  IframeExtension,
  ImageExtension,
  LinkClickListener,
  LinkExtension,
} from './extensions';
import { FormatMenu } from './FormatMenu/FormatMenu';
import { MediaMenu } from './MediaMenu/MediaMenu';
import { RichTextRefHandler, RichTextRefType } from './RichTextRefHandler';
import { RichTextStyles } from './RichTextStyles';

export type RichTextProps = {
  id?: string;
  name?: string;
  sx?: SxProps<Theme>;
  placeholder?: string;
  ariaDescribedBy?: string;
  defaultValue?: string;
  maxLength?: number;
  editable?: boolean;
  disabled?: boolean;
  error?: boolean;
  hideMediaMenu?: boolean;
  onBlur?: (html: string) => void;
};

const RichText = React.forwardRef<RichTextRefType, RichTextProps>(
  (
    {
      sx,
      id = 'rich-text',
      name,
      placeholder = translations.richTextPlaceholder,
      ariaDescribedBy,
      defaultValue = '',
      maxLength,
      editable = true,
      disabled,
      error,
      hideMediaMenu,
      onBlur,
    },
    ref
  ) => {
    const extensions = useMemo(
      () => [
        StarterKit,
        Placeholder.configure({
          placeholder,
        }),
        ...(maxLength ? [CharacterCount.configure({ limit: maxLength })] : []),
        Highlight,
        Underline,
        ImageExtension,
        IframeExtension,
        LinkExtension,
      ],
      [maxLength, placeholder]
    );

    const classNames = useMemo(() => {
      const list = ['rich-text'];
      if (editable) {
        list.push('rich-text-editable');
      } else {
        list.push('rich-text-readonly');
      }
      if (error) {
        list.push('rich-text-error');
      }
      if (hideMediaMenu) {
        list.push('rich-text-hide-media-menu');
      }
      return list.join(' ');
    }, [editable, error, hideMediaMenu]);

    const handleBlur = useCallback(
      (e: { editor: Editor; event: FocusEvent; transaction: Transaction }) => {
        if (!onBlur) {
          return;
        }
        let html = e.editor.getHTML();
        if (html === '<p></p>') {
          html = '';
        }
        onBlur(html);
      },
      [onBlur]
    );

    return (
      <RichTextStyles sx={sx}>
        <EditorProvider
          extensions={extensions}
          content={defaultValue}
          editable={editable && !disabled}
          onBlur={handleBlur}
          editorProps={{
            attributes: {
              id: id,
              name: name || '',
              ariaDescribedBy: ariaDescribedBy || '',
              class: classNames,
            },
          }}
        >
          <RichTextRefHandler ref={ref} />
          <LinkClickListener />
          {!hideMediaMenu && <MediaMenu />}
          <FormatMenu />
        </EditorProvider>
      </RichTextStyles>
    );
  }
);

export { RichText };
