import './SearchField.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import SearchHit from 'api/models/SearchHit';
import classnames from 'classnames';
import { UserContext } from 'components/Context/User';
import { ReactComponent as RecentSearchClock } from 'components/Icon/RecentSearchClock.svg';
import { ReactComponent as SearchIconLightBlue } from 'components/Icon/SearchIconLightBlue.svg';
import Parser from 'components/Parser';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { Component } from 'react';
import TextClamp from 'react-string-clamp';
import {
  GAonClickAvenueOverlaySearchResult,
  GAonRecentSearchClick,
  GAonSearchSubmit,
} from 'utils/analytics';
import {
  formatSearchQuery,
  getSuggestions,
  splitSuggestion,
} from 'utils/misc/autoSuggest';

class SearchField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: props.initialValue || '',
      justSelectedSuggestionIndex: null,
      suggestions: [],
      isSuggestionsOpen: false,
    };
  }

  handleKeyDown = (e) => {
    const key = e.keyCode || e.key;
    if (key === 'Enter' || key === 13) {
      if (
        this.state.justSelectedSuggestionIndex === null ||
        this.state.justSelectedSuggestionIndex === undefined
      ) {
        this.submitQuery();
        if (this.props.onSubmitClicked) {
          this.props.onSubmitClicked();
        }
        return;
      }

      const { suggestions, justSelectedSuggestionIndex } = this.state;

      this.redirectToLink(suggestions[justSelectedSuggestionIndex]);
      return;
    }

    if (key === 'ArrowUp' || key === 38) {
      e.preventDefault();
      const { suggestions, justSelectedSuggestionIndex } = this.state;

      if (!justSelectedSuggestionIndex) {
        const lastSuggestionIndex = suggestions.length - 1;
        this.setState({ justSelectedSuggestionIndex: lastSuggestionIndex });
      } else {
        this.setState({
          justSelectedSuggestionIndex: justSelectedSuggestionIndex - 1,
        });
      }
    } else if (key === 'ArrowDown' || key === 40) {
      e.preventDefault();
      const { suggestions, justSelectedSuggestionIndex } = this.state;

      if (
        justSelectedSuggestionIndex === null ||
        justSelectedSuggestionIndex >= suggestions.length - 1
      ) {
        this.setState({ justSelectedSuggestionIndex: 0 });
      } else {
        this.setState({
          justSelectedSuggestionIndex: justSelectedSuggestionIndex + 1,
        });
      }
    } else {
      this.setState({
        justSelectedSuggestionIndex: null,
      });
    }
    this.onSuggestionsFetchRequested(this.props.filters);
  };

  handleInputChange = () => {
    if (this._isMounted) {
      this.setState({ query: this.queryInput.value });
    }
  };

  handleInputFocus = () => {
    if (this._isMounted) {
      this.setState({ isSuggestionsOpen: true });
      this.onSuggestionsFetchRequested(this.props.filters);
    }
  };

  handleInputBlur = () => {
    if (this._isMounted) {
      this.setState({ suggestions: [], justSelectedSuggestionIndex: null });
    }
  };

  redirectToLink = (suggestion) => {
    if (suggestion.type === 'recentsearches') {
      this.redirectToRecentSearch(suggestion);
      return;
    }
    const { resetQueryOnSubmit } = this.props;
    GAonClickAvenueOverlaySearchResult(suggestion.attributes.title);
    const suggestionUrl = suggestion.get('linkUrl');
    const previewUrl = suggestion.get('previewUrl');
    const type = suggestion.type;

    if (type === 'tool') {
      window.open(suggestionUrl, '_blank');
    } else if (type === 'document') {
      window.open(previewUrl, '_blank');
    } else if (
      suggestionUrl &&
      Boolean(~suggestionUrl.indexOf(window.location.origin))
    ) {
      this.props.history.push(
        suggestionUrl.replace(window.location.origin, '')
      );
    } else {
      window.location.href = suggestionUrl;
    }
    if (this.props.onSubmitClicked) {
      this.props.onSubmitClicked();
    }

    if (resetQueryOnSubmit) {
      this.handleInputBlur();
    }
  };

  redirectToRecentSearch(suggestion) {
    if (!suggestion.searchPhrase) {
      return;
    }
    GAonRecentSearchClick(suggestion.searchPhrase);

    if (this.props.toggleSearch) {
      this.props.toggleSearch();
    }

    this.handleInputBlur();

    const searchParams = new URLSearchParams();
    searchParams.set('query', suggestion.searchPhrase);
    if (this.props.filters.length) {
      searchParams.set('filters', JSON.stringify(this.props.filters));
    }
    this.props.history.push(`/find?${searchParams.toString()}`);
  }

  onSuggestionsFetchRequested = debounce((filters = []) => {
    const {
      divisionId: division,
      countryId: country,
      regionId: region,
      departmentId: department,
      companyId: company,
      siteId: site,
      segmentId: segment,
    } = this.context;

    const { query } = this.state;

    const types = filters
      .map((filter) => SearchHit.transformFilterToSearchType(filter))
      .filter(Boolean);

    const portals = filters
      .map((filter) => SearchHit.transformFilterToPortalType(filter))
      .filter(Boolean);

    const suggestionsFilter = {
      division,
      country: division && country ? country : undefined,
      region: division && region ? region : undefined,
      department,
      company,
      site,
      segment,
      type: types,
      portal: portals,
    };

    getSuggestions(query, suggestionsFilter, false, true).then(
      (suggestions) => {
        if (suggestions && suggestions.length > 20) {
          suggestions.length = 20;
        }
        if (this._isMounted) {
          this.setState({ suggestions: suggestions || [] });
        }
      }
    );
  }, 150);

  submitQuery = () => {
    const { query, justSelectedSuggestionIndex, suggestions } = this.state;
    const { filters, resetQueryOnSubmit } = this.props;

    const queryString = suggestions[justSelectedSuggestionIndex]
      ? suggestions[justSelectedSuggestionIndex].attributes?.title ||
        suggestions[justSelectedSuggestionIndex].searchPhrase
      : query;

    const strippedValue = formatSearchQuery(queryString);

    if (this._isMounted) {
      this.setState({
        suggestions: [],
      });
    }
    if (strippedValue.length === 0) return false;
    GAonSearchSubmit(strippedValue);

    const searchParams = new URLSearchParams();
    searchParams.set('query', strippedValue);
    if (filters.length) {
      searchParams.set('filters', JSON.stringify(filters));
    }
    this.props.history.push(`/find?${searchParams.toString()}`);

    if (this.props.onSubmitClicked) {
      this.props.onSubmitClicked();
    }

    if (resetQueryOnSubmit) {
      this.resetQuery();
    }
  };

  resetQuery = () => {
    if (this._isMounted) {
      this.setState(
        { query: '', suggestions: [], isSuggestionsOpen: false },
        () => {
          this.queryInput.focus();
        }
      );
    }
  };

  componentDidUpdate(prevProps) {
    const filters = this.props.filters;
    const prevFilters = prevProps.filters;
    if (
      filters !== prevFilters &&
      JSON.stringify(filters) !== JSON.stringify(prevFilters)
    ) {
      this.onSuggestionsFetchRequested(filters);
      this.queryInput.focus();
    }
  }

  componentDidMount() {
    const { resetQueryOnSubmit } = this.props;

    this._isMounted = true;

    if (!resetQueryOnSubmit) this.queryInput.focus();

    this.queryInput.addEventListener('keydown', this.handleKeyDown);

    this.queryInput.addEventListener('click', () => {
      this.handleInputFocus();
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderSuggestions(suggestion, query, justSelectedSuggestionIndex, index) {
    const parts =
      suggestion.type !== 'person'
        ? splitSuggestion(suggestion.get('title'), query)
        : splitSuggestion(
            `${suggestion.get('firstName')} ${suggestion.get('lastName')}`,
            query
          );

    const body = suggestion.get('body');
    const suggestionClasses = classnames('search-bar__suggestion', {
      'search-bar__suggestion--selected': justSelectedSuggestionIndex === index,
    });

    return (
      <li
        className={suggestionClasses}
        key={suggestion.id}
        onClick={() => this.redirectToLink(suggestion)}
      >
        <p className="search-bar__suggestion-title">
          {parts.map((part, index) => {
            const highlightClass = classnames({
              highlight: part.highlight,
            });
            return (
              <span className={highlightClass} key={index}>
                {part.text}
              </span>
            );
          })}
        </p>
        {!body && (
          <p className="search-bar__suggestion-category">
            {suggestion.getTypeLabel()}
          </p>
        )}
        {body && (
          <p className="search-bar__suggestion-preamble">
            <Parser html={body.substring(0, 255)} type="Feed" />
          </p>
        )}
      </li>
    );
  }

  renderRecentSearches(suggestion, justSelectedSuggestionIndex, index) {
    const suggestionClasses = classnames('search-bar__suggestion', {
      'search-bar__suggestion--selected': justSelectedSuggestionIndex === index,
    });

    return (
      <li
        className={suggestionClasses}
        key={index}
        onClick={() => this.redirectToRecentSearch(suggestion)}
      >
        <div className="search-bar__recentSearch">
          <div className="search-bar__recentSearch--clock">
            <RecentSearchClock />
          </div>
          <TextClamp
            lines={2}
            text={suggestion.searchPhrase ? suggestion.searchPhrase : ''}
            className="search-bar__recentSearch--text"
            element="p"
          />
        </div>
      </li>
    );
  }

  render() {
    const { query, suggestions, justSelectedSuggestionIndex } = this.state;
    const cancelBtnClasses = classnames('search-bar__cancel-btn', {
      'search-bar__cancel-btn--active': query.length,
    });

    return (
      <ClickAwayListener onClickAway={this.handleInputBlur}>
        <div className="search-bar">
          <div className="search-bar__field">
            <input
              type="text"
              className="search-bar__input"
              placeholder={this.props.placeholder || 'Search...'}
              ref={(e) => (this.queryInput = e)}
              value={query}
              onChange={this.handleInputChange}
            />

            <span className={cancelBtnClasses} onClick={this.resetQuery}>
              <p>Clear</p>
            </span>
            <div className="search-bar__search" onClick={this.submitQuery}>
              <SearchIconLightBlue />
            </div>
          </div>

          {Boolean(suggestions.length) && this.state.isSuggestionsOpen && (
            <ul className="search-bar__suggestion-list">
              {suggestions.map((suggestion, index) => {
                return suggestion.type !== 'recentsearches'
                  ? this.renderSuggestions(
                      suggestion,
                      query,
                      justSelectedSuggestionIndex,
                      index
                    )
                  : this.renderRecentSearches(
                      suggestion,
                      justSelectedSuggestionIndex,
                      index
                    );
              })}
            </ul>
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

SearchField.contextType = UserContext;

SearchField.propTypes = {
  history: PropTypes.object,
  initialValue: PropTypes.string,
  onSubmitClicked: PropTypes.func,
  placeholder: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.string),
};

SearchField.defaultProps = {
  filters: [],
};

export default SearchField;
