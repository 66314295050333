import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { AlertDialog, Checkbox, RichText } from 'componentsNew';
import { RichTextProps } from 'componentsNew/RichText/RichText';
import { FormFieldWrapper } from 'layout';
import { FormFieldWrapperProps } from 'layout/Form/FormFieldWrapper';
import { useCallback, useEffect, useState } from 'react';
import { RefCallBack } from 'react-hook-form';
import { translations } from 'translations';

type ContactDetailsInputProps = {
  id?: string;
  inputRef?: RefCallBack;
  disabled?: boolean;
  error?: FormFieldWrapperProps['error'];
  defaultValue?: RichTextProps['defaultValue'];
  placeholder?: RichTextProps['placeholder'];
  maxLength?: RichTextProps['maxLength'];
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
};

const ContactDetailsInput = ({
  id,
  inputRef,
  disabled,
  error,
  defaultValue,
  placeholder,
  maxLength,
  onChange,
  onBlur,
}: ContactDetailsInputProps) => {
  const [input, setInput] = useState<string | null>(null);
  const [isInputEnabled, setIsInputEnabled] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleInputBlur = useCallback(
    (value: string) => {
      setInput(value);
      onChange(value);
      onBlur && onBlur(value);
    },
    [onBlur, onChange]
  );

  const handleCheckboxChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked && Boolean(input)) {
        setIsDialogOpen(true);
      } else {
        setIsInputEnabled(checked);
      }
    },
    [input]
  );

  const handleDialogConfirm = useCallback(() => {
    setIsInputEnabled(false);
    setInput('');
    setIsDialogOpen(false);
    onChange('');
  }, [onChange]);

  const handleDialogCancel = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  useEffect(() => {
    if (input !== null) {
      return;
    }
    setIsInputEnabled(Boolean(defaultValue));
    setInput(defaultValue || '');
  }, [defaultValue, input]);

  return (
    <Stack width="100%">
      <FormFieldWrapper
        id={`${id}-enabled`}
        {...(!isInputEnabled && {
          error: error,
        })}
      >
        <Checkbox
          {...(!isInputEnabled && {
            inputRef: inputRef,
          })}
          label={translations.formLabelContactDetailsEnabled}
          checked={isInputEnabled}
          disabled={disabled}
          onChange={handleCheckboxChange}
        />
      </FormFieldWrapper>
      <Collapse in={isInputEnabled}>
        <FormFieldWrapper
          {...(isInputEnabled && {
            error: error,
          })}
          id={id}
          label={translations.formLabelContactDetails}
          labelSize="small"
          sx={(theme) => ({
            fontSize: theme.typography.body2.fontSize,
            backgroundColor: theme.colors.surface.tertiary,
            border: `1px solid ${theme.colors.border.surfacePrimary}`,
            borderRadius: theme.border.radius.sm,
            marginTop: theme.spacing('xs'),
            padding: theme.spacing('xs'),
            gap: theme.spacing('xxs'),
          })}
        >
          <RichText
            {...(isInputEnabled && {
              ref: inputRef,
            })}
            hideMediaMenu
            defaultValue={defaultValue}
            placeholder={placeholder}
            maxLength={maxLength}
            disabled={disabled}
            sx={(theme) => ({
              '.rich-text-editable': {
                minHeight: '5rem',
                backgroundColor: theme.colors.surface.secondary,
              },
            })}
            onBlur={handleInputBlur}
          />
        </FormFieldWrapper>
      </Collapse>
      <AlertDialog
        open={isDialogOpen}
        size="small"
        type="warning"
        title={translations.contactDetailsUnsavedChangesTitle}
        primaryButton={{
          text: translations.continue,
          onClick: handleDialogConfirm,
        }}
        secondaryButton={{
          text: translations.cancel,
          onClick: handleDialogCancel,
        }}
      >
        {translations.contactDetailsUnsavedChangesText}
      </AlertDialog>
    </Stack>
  );
};

export { ContactDetailsInput };
