import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ProductOfTheWeekType } from 'api/cms/ProductOfTheWeek/types';
import OnlyWithRole from 'components/OnlyWithRole';
import Parser from 'components/Parser';
import { LikeButton, Sheet, TypographyWithMaxLines } from 'componentsNew';
import { PageContentHeader } from 'layout';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import settings from 'settings';
import { translations } from 'translations';
import * as timeUtils from 'utils/misc/time';

const { cdnTokenKey } = settings.misc;
const { cms } = settings.cmsBaseImagePath;

interface ProductOfTheWeekSheetProps {
  id?: string;
  open: boolean;
  product?: ProductOfTheWeekType;
  likesMeta?: { likesCount: number; isLikedByMe: boolean } | null;
  onLike: () => void;
  onClose: () => void;
}

const ProductOfTheWeekSheet = ({
  id,
  open,
  product,
  likesMeta,
  onLike,
  onClose,
}: ProductOfTheWeekSheetProps) => {
  const elementId = id || product?._doc;

  return (
    <Sheet id={elementId} open={open} width="40.5rem" onClose={onClose}>
      <PageContentHeader text={translations.productOfTheWeek} />
      <Stack
        sx={(theme) => ({
          gap: theme.spacing('md'),
          marginTop: theme.spacing('md'),
          flexDirection: 'column',
        })}
      >
        {product?.heroImage ? (
          <Box
            id={`${elementId}-image`}
            component="img"
            src={`${cms}${product.heroImage}/480?${
              cdnTokenKey ? cdnTokenKey : ''
            }`}
            alt={product.heroAltText || ''}
            title={product.heroAltText || ''}
            sx={{
              width: '20rem',
              aspectRatio: 1.1,
              margin: '0 auto',
              display: 'block',
            }}
          />
        ) : (
          <Box
            sx={(theme) => ({
              width: '20rem',
              aspectRatio: 1.1,
              margin: '0 auto',
              backgroundColor: theme.colors.surface.informative,
            })}
          />
        )}

        {product?.title && (
          <TypographyWithMaxLines
            maxLines={2}
            component="h3"
            variant="h2"
            sx={(theme) => ({
              color: theme.colors.text.primary,
            })}
          >
            {product.title}
          </TypographyWithMaxLines>
        )}

        {(product?.division || product?.market || product?.releaseYear) && (
          <Stack
            sx={(theme) => ({
              borderRadius: theme.border.radius.md,
              background: theme.colors.surface.secondary,
              padding: theme.spacing('md'),
              gap: theme.spacing('xxs'),
            })}
          >
            {product.division && (
              <Stack flexDirection="row">
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.colors.text.tertiary,
                    flexShrink: 0,
                    width: '6rem',
                  })}
                >
                  {translations.division}
                </Typography>
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.colors.text.secondary,
                    fontWeight: 'bold',
                  })}
                >
                  {product.division}
                </Typography>
              </Stack>
            )}
            {product.market && (
              <Stack flexDirection="row">
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.colors.text.tertiary,
                    flexShrink: 0,
                    width: '6rem',
                  })}
                >
                  {translations.market}
                </Typography>
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.colors.text.secondary,
                    fontWeight: 'bold',
                  })}
                >
                  {product.market}
                </Typography>
              </Stack>
            )}
            {product.releaseYear && (
              <Stack flexDirection="row">
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.colors.text.tertiary,
                    flexShrink: 0,
                    width: '6rem',
                  })}
                >
                  {translations.released}
                </Typography>
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.colors.text.secondary,
                    fontWeight: 'bold',
                  })}
                >
                  {product.releaseYear}
                </Typography>
              </Stack>
            )}
          </Stack>
        )}

        {product?.preamble && (
          <TypographyWithMaxLines maxLines={4} variant="body1">
            {product.preamble}
          </TypographyWithMaxLines>
        )}

        {product?.body && (
          <Typography variant="body1" component="div">
            <Parser html={product.body} />
          </Typography>
        )}

        {product?.contactPersons && product.contactPersons.length > 0 && (
          <Stack flexDirection="row">
            <Typography
              variant="body2"
              sx={(theme) => ({
                color: theme.colors.text.tertiary,
                flexShrink: 0,
                width: '6rem',
              })}
            >
              {translations.contact}
            </Typography>
            <Box>
              {product.contactPersons.map((contactPerson, index) => (
                <React.Fragment key={contactPerson.id}>
                  {index !== 0 && (
                    <Typography component="span" variant="body2">
                      ,&nbsp;
                    </Typography>
                  )}
                  <Link
                    variant="body2"
                    to={`/people/${contactPerson.id}`}
                    component={ReactRouterLink}
                    sx={(theme) => ({
                      fontWeight: 'bold',
                      color: theme.colors.text.secondary,
                    })}
                  >
                    {`${contactPerson?.firstName || ''} ${
                      contactPerson?.lastName || ''
                    }`.trim()}
                  </Link>
                </React.Fragment>
              ))}
            </Box>
          </Stack>
        )}
        {product?.startDate && (
          <Stack flexDirection="row">
            <Typography
              variant="body2"
              sx={(theme) => ({
                color: theme.colors.text.tertiary,
                flexShrink: 0,
                width: '6rem',
              })}
            >
              {translations.published}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={(theme) => ({
                fontWeight: 'bold',
                color: theme.colors.text.secondary,
              })}
            >
              {timeUtils.getDateOrDaysAgo(product.startDate)}
            </Typography>
          </Stack>
        )}
        <Divider />
        <Stack flexDirection="row">
          <Button
            variant="outlined"
            component={Link}
            href="https://forms.office.com/r/VrKnGMCHSt"
            target="_blank"
          >
            {translations.productOfTheWeekNominateLinkLabel}
          </Button>
          {likesMeta && (
            <LikeButton
              id={`${elementId}-like`}
              isLikedByMe={likesMeta.isLikedByMe}
              likesCount={likesMeta.likesCount}
              sx={{ marginLeft: 'auto' }}
              onClick={onLike}
            />
          )}
        </Stack>
      </Stack>

      <OnlyWithRole
        viewableByRole={['AvenueAdmin', 'AvenueSuperuser']}
        isAllRolesRequired={false}
      >
        <Stack flexDirection="row" justifyContent="end" marginTop="auto">
          <Link to="/productoftheweek/create" component={ReactRouterLink}>
            {translations.create}
          </Link>
          {product?._doc && (
            <>
              /
              <Link
                to={`/productoftheweek/${product._doc}/edit`}
                component={ReactRouterLink}
              >
                {translations.edit}
              </Link>
            </>
          )}
        </Stack>
      </OnlyWithRole>
    </Sheet>
  );
};

export { ProductOfTheWeekSheet };
