import Box from '@mui/material/Box';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import {
  ImageModal,
  ImageValue,
} from 'componentsNew/MediaInput/ImageInput/ImageModal';
import { ImagePreview } from 'componentsNew/MediaInput/ImageInput/ImagePreview';
import { useSnackbar } from 'context';
import { useCallback, useState } from 'react';
import { translations } from 'translations';

const elementId = 'media-input';

const ImageNodeView = ({
  node,
  updateAttributes,
  editor,
  getPos,
}: NodeViewProps) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);

  const { showSnackbar } = useSnackbar();

  const { src, alt, caption, width, height } = node.attrs;

  const setSelectionToThisNode = useCallback(() => {
    const pos = getPos();
    editor.commands.setNodeSelection(pos);
    editor.commands.focus();
  }, [editor.commands, getPos]);

  const handleDelete = useCallback(() => {
    const pos = getPos();
    editor
      .chain()
      .focus()
      .deleteRange({ from: pos, to: pos + 1 })
      .run();
  }, [editor, getPos]);

  const handleEdit = useCallback(() => {
    setSelectionToThisNode();
    setIsImageModalOpen(true);
  }, [setSelectionToThisNode]);

  const handleEditSubmit = useCallback(
    (image: ImageValue) => {
      if (image.blob) {
        showSnackbar({
          text: translations.imageInputLoadError,
          type: 'error',
        });
        return;
      }
      updateAttributes({
        src: image.src,
        alt: image.altText,
        title: image.altText,
        caption: image.caption,
        width: image.width,
        height: image.height,
      });
      setIsImageModalOpen(false);
      setSelectionToThisNode();
    },
    [setSelectionToThisNode, showSnackbar, updateAttributes]
  );

  const handleEditClose = useCallback(() => {
    setIsImageModalOpen(false);
    setSelectionToThisNode();
  }, [setSelectionToThisNode]);

  return (
    <NodeViewWrapper>
      <Box
        component="figure"
        contentEditable={false}
        sx={(theme) => ({
          margin: 0,
          marginBottom: theme.spacing('sm'),
          width: width ? `${width}px` : '100%',
          maxWidth: '100%',
        })}
      >
        <ImagePreview
          image={{
            src,
            width,
            height,
            altText: alt,
          }}
          {...(editor.isEditable && {
            onEdit: handleEdit,
            onDelete: handleDelete,
          })}
        />
        {caption && (
          <Box
            component="figcaption"
            sx={(theme) => ({
              fontSize: theme.typography.body2.fontSize,
              color: theme.colors.text.tertiary,
              padding: `${theme.spacing('xxs')} 0 `,
            })}
          >
            {caption}
          </Box>
        )}
      </Box>
      {isImageModalOpen && (
        <ImageModal
          disableRestoreFocus
          onSubmit={handleEditSubmit}
          onClose={handleEditClose}
          elementId={`${elementId}-modal`}
          defaultValue={{
            src,
            width,
            height,
            caption,
            altText: alt,
          }}
          labels={{
            title: translations.richTextImageModalTitle,
            submit: translations.save,
          }}
        />
      )}
    </NodeViewWrapper>
  );
};

export { ImageNodeView };
