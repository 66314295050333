import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import * as documentsApi from 'api/sharepointdocuments';
import { FolderItem } from 'api/sharepointdocuments/types';
import { AlertDialog } from 'componentsNew/AlertDialog/AlertDialog';
import { useSnackbar } from 'context';
import { usePrevious } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';

import { Icon } from '../Icon/Icon';
import { FileItem } from './FileItem';
import { FileItemType } from './FileItem';
import { FilePicker } from './FilePicker';
import * as helpers from './helpers';

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const MAX_NBR_OF_FILES = 10;

type FileUploadProps = {
  elementId: string;
  initialDocumentsFolderId?: string;
  initialDocuments: FolderItem[];
  onChangeDocumentsFolderId: (documentsFolderId: string) => void;
  onChangeDocuments: (documents: FolderItem[]) => void;
  onLoading?: (isLoading: boolean) => void;
  limit?: number;
  maxFileSizeBytes?: number;
  sx?: SxProps<Theme>;
};

const FileUpload = ({
  elementId,
  initialDocuments,
  initialDocumentsFolderId,
  onChangeDocumentsFolderId,
  onChangeDocuments,
  onLoading,
  limit = MAX_NBR_OF_FILES,
  maxFileSizeBytes = MAX_FILE_SIZE,
  sx,
}: FileUploadProps) => {
  const [fileListItems, setFileListItems] = useState<FileItemType[] | null>(
    null
  );
  const [validationDialog, setValidationDialog] = useState<{
    open: boolean;
    validFileListItems: FileItemType[];
    errors: string[];
  }>({
    open: false,
    validFileListItems: [],
    errors: [],
  });
  const [documentsFolderId, setDocumentsFolderId] = useState<string | null>(
    null
  );
  const [loadingItems, setLoadingItems] = useState<string[]>([]);

  const { showSnackbar } = useSnackbar();
  const prevLoadingItems = usePrevious(loadingItems);

  useEffect(() => {
    if (!fileListItems) {
      return;
    }

    const documents = fileListItems
      .map((fileItem) => {
        if (!fileItem.uploaded) {
          return null;
        }

        return fileItem.uploaded;
      })
      .filter(Boolean) as FolderItem[];

    onChangeDocuments(documents);
  }, [fileListItems, onChangeDocuments]);

  useEffect(() => {
    if (!onLoading) {
      return;
    }

    if (loadingItems.length === 0) {
      onLoading(false);
    }

    if (
      loadingItems.length === 1 &&
      (!prevLoadingItems || prevLoadingItems.length === 0)
    ) {
      onLoading(true);
    }
  }, [loadingItems.length, onLoading, prevLoadingItems]);

  useEffect(() => {
    if (fileListItems) {
      return;
    }

    const initalFileListItems: FileItemType[] = initialDocuments.map(
      (document) => {
        return {
          uploaded: document,
        };
      }
    );

    setFileListItems(initalFileListItems);
  }, [fileListItems, initialDocuments]);

  useEffect(() => {
    if (documentsFolderId !== null) {
      return;
    }

    setDocumentsFolderId(initialDocumentsFolderId || '');
  }, [documentsFolderId, initialDocumentsFolderId]);

  const setIsLoading = useCallback((name: string, isLoading: boolean) => {
    if (isLoading) {
      setLoadingItems((prevLoadingItems) => {
        return [...prevLoadingItems, name];
      });
      return;
    }

    setLoadingItems((prevLoadingItems) => {
      return prevLoadingItems.filter((loadingItem) => loadingItem !== name);
    });
  }, []);

  const onRemove = useCallback((item: FileItemType) => {
    setFileListItems((prevFileListItems) => {
      if (!prevFileListItems) {
        return prevFileListItems;
      }

      const newFileListItems = prevFileListItems.filter(
        (prevFile) =>
          prevFile.uploaded?.id !== item.uploaded?.id ||
          prevFile.raw?.name !== item.raw?.name
      );
      return newFileListItems;
    });
  }, []);

  const onAdd = useCallback((item: FileItemType, index: number) => {
    setFileListItems((prevFileListItems) => {
      if (!prevFileListItems) {
        return prevFileListItems;
      }

      const newFileListItems = [...prevFileListItems];

      newFileListItems.splice(index, 1, item);

      return newFileListItems;
    });
  }, []);

  const createDocumentFolder = useCallback(async () => {
    let _documentFolder = null;
    try {
      const response = await documentsApi.createNewArticleFolder();
      const data = response?.data?.data;
      if (data) {
        _documentFolder = data as FolderItem;
      }
    } finally {
      return _documentFolder;
    }
  }, []);

  const onAddFileList = useCallback(
    async (files: FileItemType[]) => {
      if (!fileListItems) {
        return;
      }

      const newFiles = [...fileListItems, ...files];

      if (!documentsFolderId && newFiles.length > 0) {
        const documentFolder = await createDocumentFolder();

        if (!documentFolder) {
          showSnackbar({
            type: 'error',
            text: translations.fileUploadError,
          });
          return;
        }

        setDocumentsFolderId(documentFolder.folderId);
        onChangeDocumentsFolderId(documentFolder.folderId);
      }

      setFileListItems((prevFileListItems) => {
        if (!prevFileListItems) {
          return prevFileListItems;
        }

        const newFileListItems = [...prevFileListItems, ...files];

        return newFileListItems;
      });
    },
    [
      createDocumentFolder,
      documentsFolderId,
      fileListItems,
      onChangeDocumentsFolderId,
      showSnackbar,
    ]
  );

  const onSelectOrDropFile = useCallback(
    (files: File[]) => {
      if (
        !files.length ||
        !fileListItems ||
        fileListItems.length >= limit ||
        loadingItems.length > 0
      ) {
        return;
      }

      const errors: string[] = [];

      const duplicateFileNames = helpers.getDuplicateFileNames(
        files,
        fileListItems
      );
      if (duplicateFileNames.length > 0) {
        errors.push(translations.fileUploadDialogDuplicateNames);
      }

      const filesThatAreTooLarge = files.filter(
        (file) => file.size >= maxFileSizeBytes
      );
      if (filesThatAreTooLarge.length > 0) {
        errors.push(
          textUtils.replaceTranslationAliases(
            translations.fileUploadDialogFileTooLarge,
            { size: maxFileSizeBytes / 1024 / 1024 }
          )
        );
      }

      let validFileListItems: FileItemType[] = files
        .map((file) => {
          if (
            !duplicateFileNames.includes(file.name) &&
            !filesThatAreTooLarge.includes(file)
          )
            return {
              raw: file,
            };

          return null;
        })
        .filter(Boolean) as FileItemType[];

      if (validFileListItems.length + fileListItems.length > limit) {
        const nbrOfFilesLeft = limit - fileListItems.length;
        validFileListItems = validFileListItems.slice(0, nbrOfFilesLeft);

        errors.push(translations.fileUploadDialogTooManyFiles);
      }

      if (errors.length > 0) {
        setValidationDialog({
          open: true,
          validFileListItems: validFileListItems,
          errors: errors,
        });

        return;
      }

      onAddFileList(validFileListItems);
    },
    [fileListItems, limit, loadingItems.length, maxFileSizeBytes, onAddFileList]
  );

  const isLimitReached = useMemo(() => {
    return fileListItems && fileListItems.length >= limit;
  }, [fileListItems, limit]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onSelectOrDropFile,
    noClick: true,
  });

  return (
    <Stack
      sx={[
        (theme) => ({
          padding: theme.spacing('sm'),
          gap: theme.spacing('sm'),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...getRootProps()}
    >
      <Stack
        sx={(theme) => ({
          padding: theme.spacing('sm'),
          alignItems: 'center',
          border: `${theme.border.thickness[1]} dashed ${theme.colors.border.surfaceSecondary}`,
          borderRadius: theme.border.radius.md,
          gap: theme.spacing('xxs'),
        })}
      >
        <input id={`${elementId}-file-dropzone`} {...getInputProps()} />
        <Stack
          sx={(theme) => ({
            borderRadius: '2rem',
            padding: theme.spacing('xs'),
            backgroundColor: !isLimitReached
              ? theme.colors.surface.highlight
              : theme.colors.surface.tertiary,
          })}
        >
          <Icon
            type="arrowUpTray"
            size={32}
            color={!isLimitReached ? 'brandBase' : 'secondary'}
          />
        </Stack>
        <Stack
          sx={(theme) => ({
            gap: theme.spacing('xxxs'),
            textAlign: 'center',
          })}
        >
          <Typography fontWeight={700}>
            {translations.fileUploadTitle}
          </Typography>
          <Typography
            variant="caption"
            sx={(theme) => ({
              color: theme.colors.text.tertiary,
            })}
          >
            {textUtils.replaceTranslationAliases(
              translations.fileUploadMaxFileSizeMB,
              { size: maxFileSizeBytes / 1024 / 1024 }
            )}
          </Typography>
        </Stack>
        <FilePicker
          elementId={`${elementId}-file-picker`}
          onChange={onSelectOrDropFile}
          disabled={isLimitReached || false}
          sx={(theme) => ({
            maxWidth: '7rem',
            marginTop: theme.spacing('xxxs'),
          })}
        />
      </Stack>
      {documentsFolderId && fileListItems && fileListItems.length > 0 && (
        <List sx={() => ({ borderTop: 'none' })}>
          <TransitionGroup>
            {fileListItems.map((item, index) => (
              <Collapse key={`${elementId}-file-item-${index}`}>
                <ListItem
                  disableGutters
                  divider={index < fileListItems.length - 1}
                  sx={(theme) => ({
                    paddingTop: index === 0 ? 0 : theme.spacing('xs'),
                    paddingBottom:
                      index === fileListItems.length - 1
                        ? 0
                        : theme.spacing('xs'),
                  })}
                >
                  <FileItem
                    elementId={`${elementId}-file-item-${index}`}
                    index={index}
                    documentsFolderId={documentsFolderId}
                    item={item}
                    isLoading={loadingItems.length > 0}
                    setIsLoading={setIsLoading}
                    onAdd={onAdd}
                    onRemoveItem={onRemove}
                  />
                </ListItem>
              </Collapse>
            ))}
          </TransitionGroup>
        </List>
      )}
      <AlertDialog
        open={validationDialog.open}
        type="warning"
        title={translations.fileUploadDialogTitle}
        primaryButton={{
          text: translations.upload,
          disabled: validationDialog.validFileListItems.length === 0,
          onClick: () => {
            validationDialog.validFileListItems &&
              onAddFileList(validationDialog.validFileListItems);
            setValidationDialog({
              open: false,
              validFileListItems: [],
              errors: [],
            });
          },
        }}
        secondaryButton={{
          text: translations.cancel,
          onClick: () => {
            setValidationDialog({
              open: false,
              validFileListItems: [],
              errors: [],
            });
          },
        }}
        onClose={() => {
          setValidationDialog({
            open: false,
            validFileListItems: [],
            errors: [],
          });
        }}
      >
        <Typography sx={(theme) => ({ marginTop: theme.spacing('sm') })}>
          {translations.fileUploadDialogInfo}
        </Typography>
        {validationDialog.errors.length === 1 ? (
          <Typography>{validationDialog.errors[0]}</Typography>
        ) : (
          <Box component="ul" sx={() => ({ margin: 0 })}>
            {validationDialog.errors.map((error) => (
              <Typography key={error} component="li">
                {error}
              </Typography>
            ))}
          </Box>
        )}
        {validationDialog.validFileListItems.length > 0 && (
          <>
            <Typography sx={(theme) => ({ marginTop: theme.spacing('sm') })}>
              {translations.fileUploadDialogValidFiles}
            </Typography>
            <Box component="ul" sx={() => ({ margin: 0 })}>
              {validationDialog.validFileListItems.map((validFile) => (
                <Typography key={validFile.raw?.name} component="li">
                  {validFile.raw?.name}
                </Typography>
              ))}
            </Box>
          </>
        )}
      </AlertDialog>
    </Stack>
  );
};

export { FileUpload };
