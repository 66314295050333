import * as cmsInnovationApi from 'api/cms-innovation';
import { ImageValue } from 'componentsNew/MediaInput/ImageInput/ImageModal';
import { useSnackbar } from 'context';
import { InnovationRouteEnum } from 'enums';
import { useDeleteDocumentsFolder } from 'hooks';
import { Page } from 'layout';
import { useCallback, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'translations';
import * as attachmentUtils from 'utils/cms/attachment';
import * as formUtils from 'utils/form';

import * as helpers from './helpers';
import { DEFAULT_VALUES, FormValues, TrainingForm } from './TrainingForm';

const TrainingCreate = () => {
  const methods = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    shouldFocusError: true,
    mode: 'all',
  });

  const location = useLocation();
  const history = useHistory();

  const { showSnackbar } = useSnackbar();
  const { deleteDocumentsFolder } = useDeleteDocumentsFolder();
  const { setError, setValue, getValues } = methods;

  const documentTitle = useMemo(
    () => [
      translations.innovationDocumentTitle,
      translations.learning,
      translations.innovationTypeTraining,
      translations.create,
    ],
    []
  );

  const setFormError = useCallback(
    (message: string) => {
      showSnackbar({
        type: 'error',
        text: message,
      });
      setError('root', {});
    },
    [setError, showSnackbar]
  );

  const beforeSubmit = useCallback(() => {
    const formValues = getValues();
    const trimmedValues = formUtils.trimValues(formValues);
    const fieldNames = Object.keys(formValues) as (keyof FormValues)[];

    for (const fieldName of fieldNames) {
      const formValue = formValues[fieldName];
      const trimmedValue = trimmedValues[fieldName];
      if (trimmedValue !== formValue) {
        setValue(fieldName, trimmedValue, { shouldValidate: true });
      }
    }
  }, [getValues, setValue]);

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    async (formValues: FormValues) => {
      const heroImage = formValues.hero.image;
      let uploadedHeroImage: ImageValue | null = null;

      if (heroImage && heroImage.blob) {
        try {
          const uploadedSrc = await attachmentUtils.uploadImage({
            blob: heroImage.blob,
            width: heroImage.width,
            height: heroImage.height,
          });
          uploadedHeroImage = {
            src: uploadedSrc,
            width: heroImage.width,
            height: heroImage.height,
            altText: heroImage.altText,
          };
          formValues.hero.image = uploadedHeroImage;
        } catch {
          setFormError(translations.imageUploadError);
          return;
        }
      }
      try {
        const payload =
          helpers.transformFormValuesToCreateOrEditPayload(formValues);

        const articleId = await cmsInnovationApi.createTrainingArticle(payload);

        showSnackbar({
          type: 'success',
          text: translations.contentCreateSuccess,
        });
        history.push(`${InnovationRouteEnum.Training}/${articleId}`);
      } catch {
        if (uploadedHeroImage) {
          try {
            await attachmentUtils.deleteImage(uploadedHeroImage.src);
          } catch {}
        }
        setFormError(translations.contentCreateError);
        return;
      }
    },
    [history, setFormError, showSnackbar]
  );

  const handleBack = useCallback(() => {
    const sharepointFolderName = getValues('resources.sharepointFolderName');
    if (sharepointFolderName) {
      deleteDocumentsFolder({
        documentsFolderId: sharepointFolderName,
        showErrorSnackbar: false,
      });
    }
    const canGoBack = Boolean(location.key);
    if (canGoBack) {
      history.goBack();
    } else {
      history.push(InnovationRouteEnum.Learning);
    }
  }, [getValues, location.key, deleteDocumentsFolder, history]);

  return (
    <Page title={documentTitle}>
      <FormProvider {...methods}>
        <TrainingForm
          beforeSubmit={beforeSubmit}
          onSubmit={handleSubmit}
          onBack={handleBack}
        />
      </FormProvider>
    </Page>
  );
};

export { TrainingCreate };
