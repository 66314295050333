import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { translations } from 'translations';

type FilePickerProps = {
  elementId: string;
  disabled?: boolean;
  onChange: (selectedFiles: File[]) => void;
  sx?: SxProps<Theme>;
};

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const FilePicker = ({
  elementId,
  disabled,
  onChange,
  sx,
}: FilePickerProps) => {
  return (
    <Button
      id={`${elementId}`}
      role={undefined}
      tabIndex={-1}
      variant="outlined"
      component="label"
      size="small"
      disabled={disabled}
      sx={sx}
    >
      {translations.filePickerTitle}
      <VisuallyHiddenInput
        type="file"
        onChange={(event) => {
          const selectedFiles = event.target.files;

          if (selectedFiles) {
            onChange([...selectedFiles]);
          }
        }}
        multiple
      />
    </Button>
  );
};
