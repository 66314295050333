import './StrategyHouse.scss';

import SHHouse from 'components/Portal/StrategyHouse/SHHouse';
import SHHouseContent from 'components/Portal/StrategyHouse/SHHouseContent';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from 'utils/misc/useWindowDimensions';

interface LocationState {
  activeSHButton: number;
}

const content = [
  {
    title: 'Together we build our way to the future',
    textContent:
      'The ASSA ABLOY Group strategy house is our common strategic framework. It contains all the cornerstones of our strategy and gives direction and guidance to all our employees.<br /><br />Click the different parts of the strategy house, <b>or click the arrows</b>, to explore and learn more about our strategy.',
    button: '',
    prevButton: '',
    nextButton: 'Vision',
  },
  {
    title: 'Our vision',
    textContent:
      'Our vision is what gives us direction and what we want to achieve as an organization:<br /><br />To be the global leader in providing innovative access solutions that help people feel safe and secure so that they can experience a more open world.',
    button: 'Vision',
    prevButton: 'home',
    nextButton: 'Mission',
  },
  {
    title: 'Our Mission',
    textContent:
      'Our mission describes our role as a company, our reason for being and how we aim to serve our key stakeholders:<br /><br /><ul style="margin-bottom: 0px; font-size: 16px"><li><span class="sh-house-content__list-item">Build sustainable shareholder value</span></li><li><span class="sh-house-content__list-item">Provide added value to our customers, partners and end-users</span></li><li><span class="sh-house-content__list-item">Be a world-leading organization where people succeed</span></li><li><span class="sh-house-content__list-item">Conduct business in an ethical, compliant and sustainable way</span></li></ul>  ',
    button: 'Mission',
    prevButton: 'Vision',
    nextButton: 'Financial targets',
  },
  {
    title: 'Our financial targets',
    textContent:
      'Our financial targets are what we are aiming to achieve financially over a business cycle:<br /><br /><ul style="font-size: 16px"><li><span class="sh-house-content__list-item">Sales growth of 10 percent over a business cycle</span></li><li><span class="sh-house-content__list-item">EBIT margin of 16–17 percent</span></li></ul>',
    button: 'Financial<br />targets',
    prevButton: 'Mission',
    nextButton: 'Strategic objectives',
  },
  {
    title: 'Our strategic objectives',
    textContent:
      'Our strategic objectives are intended as a guide of important activities to run a high-performing business unit in ASSA ABLOY.<br /><br /><b>Growth through customer relevance<br /><br />Product leadership through innovation<br /><br />Cost-efficiency in everything we do<br /><br />Evolution through people</b><br />',
    button: 'Strategic<br />objectives',
    prevButton: 'Financial target',
    nextButton: 'Priorities',
  },
  {
    title: 'Priorities',
    textContent:
      'Our priorities are high value adding business activities, important for us to succeed as a Group.<br /><br /><b>Growth accelerators</b><ul style="margin-top: 5px; font-size: 16px"><li><span class="sh-house-content__list-item">Pricing excellence</span></li><li><span class="sh-house-content__list-item">Increase service penetration</span></li><li><span class="sh-house-content__list-item">Generate more recurring revenue</span></li><li><span class="sh-house-content__list-item">Actively upgrade installed base</span></li><li><span class="sh-house-content__list-item">Grow in emerging markets</span></li><li><span class="sh-house-content__list-item">Continue with successful acquisitions</span></li></ul><b>Growth Enablers</b><ul style="margin-top: 5px; font-size: 16px"><li><span class="sh-house-content__list-item">Consolidate footprint and focus on value added</span></li><li><span class="sh-house-content__list-item">Optimize logistics</span></li><li><span class="sh-house-content__list-item">Reduce product cost</span></li></ul>',
    button: 'Priorities',
    prevButton: 'Strategic objectives',
    nextButton: 'Sustainability',
  },
  {
    title: 'Sustainability',
    textContent:
      'Sustainability is integrated into everything we do and is important for the success of our business.<br /><br />Sustainability is a natural part of who we are and how we operate. We view it as a journey of continuous improvement, based on transparency and integrity.<br />',
    button: 'Sustainability',
    prevButton: 'Priorities',
    nextButton: 'Core values and beliefs',
  },
  {
    title: 'Our core values and beliefs',
    textContent:
      'Our core values and beliefs are the backbone of our behavior, towards each other and in relationships with external stakeholders:<br /><br />Empowerment - We have trust in people<br /><br />Innovation - We have the courage to change<br /><br />Integrity - We stand up for what’s right<br />',
    button: 'Core values and beliefs',
    prevButton: 'Sustainability',
    nextButton: 'home',
  },
];

const StrategyHouse: FC = () => {
  const history = useHistory<LocationState>();

  const [activeSHButton, setActiveSHButton] = useState<number>(
    history.location.state?.activeSHButton || 0
  );
  const [isRoofFilled, setIsRoofFilled] = useState<boolean>(false);

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (!history.location.state) {
      setActiveSHButton(0);
      setIsRoofFilled(false);
    }
  }, [history.location.state]);

  const changeActiveState = (activeBox: number): void => {
    if (activeBox >= content.length) {
      setActiveSHButton(0);
    } else {
      setActiveSHButton(activeBox);
      history.push({ state: { activeSHButton: activeBox } });
    }
  };

  const getMobileOrDesktopView = (width: number) => {
    if (width > 767) {
      return (
        <div className="strategy-house__container">
          <SHHouse
            changeActiveState={changeActiveState}
            activeSHButton={activeSHButton}
            content={content}
            isRoofFilled={isRoofFilled}
            setIsRoofFilled={setIsRoofFilled}
            width={width}
          />
          <SHHouseContent
            activeSHButton={activeSHButton}
            content={content[activeSHButton]}
            changeActiveState={changeActiveState}
            setIsRoofFilled={setIsRoofFilled}
            width={width}
          />
        </div>
      );
    }

    return (
      <div className="strategy-house__container">
        <SHHouseContent
          activeSHButton={activeSHButton}
          content={content[activeSHButton]}
          changeActiveState={changeActiveState}
          setIsRoofFilled={setIsRoofFilled}
          width={width}
        />
        {activeSHButton === 0 && (
          <SHHouse
            changeActiveState={changeActiveState}
            activeSHButton={activeSHButton}
            content={content}
            isRoofFilled={isRoofFilled}
            setIsRoofFilled={setIsRoofFilled}
            width={width}
          />
        )}
      </div>
    );
  };

  return (
    <div className="strategy-house">
      <div className="strategy-house__background"></div>
      {getMobileOrDesktopView(width)};
    </div>
  );
};

export default StrategyHouse;
