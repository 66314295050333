import Stack from '@mui/material/Stack';
import { useDeleteDocumentsFolder } from 'hooks';
import { FormLoading } from 'layout';
import {
  ArticleTabContent,
  ArticleTabs,
  TabOrChapterInput,
} from 'pagesInnovation/common';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { translations } from 'translations';
import { v1 as uuidv1 } from 'uuid';

import { ChaptersEdit } from './ChaptersEdit';
import { FormValues } from './ProcessAndGuidesForm';

const elementId = 'process-and-guides-form-tabs';

const TabsAndChaptersEdit = () => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const [isDeletingDocuments, setIsDeletingDocuments] =
    useState<boolean>(false);

  const { deleteDocumentsFolder } = useDeleteDocumentsFolder();

  const methods = useFormContext<FormValues>();
  const { control, setValue, watch } = methods;

  const {
    fields: tabFields,
    append: appendTab,
    remove: removeTab,
    update: updateTab,
  } = useFieldArray({
    control,
    name: 'tabs',
    keyName: 'key',
  });

  const watchTabs = watch('tabs');

  const isFirstTabGhost = useMemo(() => {
    return watchTabs.length === 1 && watchTabs[0].title === '';
  }, [watchTabs]);

  const handleAddTab = useCallback(
    (value: { id: string; title: string }) => {
      if (isFirstTabGhost) {
        updateTab(0, {
          id: value.id,
          title: value.title,
          chapters: watchTabs[0].chapters,
        });
        return;
      }
      appendTab({
        id: value.id,
        title: value.title,
        chapters: [
          {
            id: uuidv1(),
            title: '',
            body: '',
            resources: {},
          },
        ],
      });
      setActiveTabIndex(tabFields.length);
    },
    [appendTab, isFirstTabGhost, tabFields.length, updateTab, watchTabs]
  );

  const handleUpdateTab = useCallback(
    (index: number, value: { id: string; title: string }) => {
      const tabValue = watchTabs[index];
      updateTab(index, {
        id: value.id,
        title: value.title,
        chapters: tabValue.chapters,
      });
    },
    [updateTab, watchTabs]
  );

  const handleRemoveTab = useCallback(
    async (index: number) => {
      const chapters = watchTabs[index].chapters;

      let isDeleteDocumentsSuccess = true;

      setIsDeletingDocuments(true);
      await Promise.all(
        chapters.map(async (chapter, chapterIndex) => {
          const sharepointFolderName = chapter.resources.sharepointFolderName;
          if (!sharepointFolderName) {
            return;
          }
          const deleteDocumentsFolderResponse = await deleteDocumentsFolder({
            documentsFolderId: sharepointFolderName,
          });
          if (deleteDocumentsFolderResponse.success) {
            setValue(
              `tabs.${index}.chapters.${chapterIndex}.resources.sharepointFolderName`,
              ''
            );
          } else {
            isDeleteDocumentsSuccess = false;
          }
        })
      );
      setIsDeletingDocuments(false);
      if (!isDeleteDocumentsSuccess) {
        return;
      }

      const isLast = tabFields.length === 1;
      if (isLast) {
        updateTab(0, {
          id: uuidv1(),
          title: '',
          chapters: [
            {
              id: uuidv1(),
              title: '',
              body: '',
              resources: {},
            },
          ],
        });
        return;
      }
      removeTab(index);

      if (activeTabIndex > index) {
        setActiveTabIndex(activeTabIndex - 1);
        return;
      }
      if (activeTabIndex > 0 && activeTabIndex === index) {
        setActiveTabIndex(index - 1);
      }
    },
    [
      activeTabIndex,
      deleteDocumentsFolder,
      removeTab,
      setValue,
      tabFields.length,
      updateTab,
      watchTabs,
    ]
  );

  return (
    <Stack>
      <FormLoading isLoading={isDeletingDocuments} />
      <Stack
        sx={(theme) => ({
          gap: theme.spacing('xs'),
          flexDirection: { xs: 'column', lg: 'row' },
          borderBottom: `1px solid ${theme.colors.border.surfacePrimary}`,
        })}
      >
        <TabOrChapterInput
          id={`${elementId}-input`}
          type="tab"
          items={
            isFirstTabGhost
              ? []
              : tabFields.map((tabField) => ({
                  id: tabField.id,
                  title: tabField.title,
                }))
          }
          disabled={isDeletingDocuments}
          onAdd={handleAddTab}
          onUpdate={handleUpdateTab}
          onRemove={handleRemoveTab}
          sx={(theme) => ({ margin: `${theme.spacing('xxs')} 0` })}
        />

        <ArticleTabs
          id={`${elementId}-items`}
          ariaLabel={translations.tabs}
          activeIndex={activeTabIndex}
          onChange={setActiveTabIndex}
          items={tabFields.map((tabsField, index) => ({
            id: `${elementId}-items-${index}`,
            title: tabsField.title,
            ariaControls: `${elementId}-content-${index}`,
          }))}
        />
      </Stack>

      {tabFields.map((tabsField, index) => {
        return (
          <ArticleTabContent
            key={tabsField.key}
            id={`${elementId}-content-${index}`}
            active={index === activeTabIndex}
            ariaLabelledBy={`${elementId}-items-${index}`}
          >
            <ChaptersEdit activeTabIndex={index} />
          </ArticleTabContent>
        );
      })}
    </Stack>
  );
};

export { TabsAndChaptersEdit };
