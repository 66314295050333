import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MuiIconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon, IconButton } from 'componentsNew';
import { KeyboardKeyEnum } from 'enums';
import { FormButtons } from 'layout';
import { forwardRef, useCallback, useState } from 'react';
import { translations } from 'translations';

export type VersionInputProps = {
  id?: string;
  ariaDescribedBy?: string;
  disabled?: boolean;
  error?: boolean;
  value: string;
  onChange: (value: string) => void;
};

const VersionInput = forwardRef<HTMLDivElement, VersionInputProps>(
  (
    {
      id = 'article-version-input',
      ariaDescribedBy,
      disabled,
      error,
      value,
      onChange,
    },
    ref
  ) => {
    const [dialogInput, setDialogInput] = useState<string>('');
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const handleOpenDialog = useCallback(() => {
      setDialogInput(value);
      setIsDialogOpen(!isDialogOpen);
    }, [isDialogOpen, value]);

    const handleCancel = useCallback(() => {
      setDialogInput('');
      setIsDialogOpen(false);
    }, []);

    const handleSubmit = useCallback(() => {
      onChange(dialogInput);
      setIsDialogOpen(false);
    }, [dialogInput, onChange]);

    return (
      <>
        <Stack
          ref={ref}
          aria-describedby={ariaDescribedBy}
          sx={(theme) => ({
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: theme.spacing('xs'),
            ...(error && {
              border: `1px solid ${theme.colors.border.critical}`,
              padding: theme.spacing('xxs'),
              borderRadius: theme.border.radius.sm,
            }),
          })}
        >
          <Stack
            sx={(theme) => ({
              flexDirection: 'row',
              alignItems: 'center',
              gap: theme.spacing('xs'),
            })}
          >
            <Typography
              variant="body1"
              sx={(theme) => ({
                color: theme.colors.text.tertiary,
                width: '7.5rem',
                flexShrink: '0',
              })}
            >
              {translations.version}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={(theme) => ({
                color: theme.colors.text.secondary,
              })}
            >
              {value}
            </Typography>
          </Stack>
          <IconButton
            id={`${id}-dialog-button`}
            onClick={handleOpenDialog}
            type={!value ? 'plus' : 'pencil'}
            disabled={disabled}
            sx={() => ({
              flexShrink: 0,
            })}
          />
        </Stack>

        <Dialog
          open={isDialogOpen}
          aria-labelledby={`${id}-dialog-title`}
          onKeyDown={(e) => {
            if (e.key === KeyboardKeyEnum.Escape) {
              e.preventDefault();
              handleCancel();
            }
          }}
          PaperProps={{
            sx: (theme) => ({
              maxWidth: '100%',
              width: '34.5rem',
              backgroundColor: theme.colors.surface.primary,
              boxShadow: theme.elevation.md,
              borderRadius: theme.border.radius.md,
            }),
          }}
        >
          <DialogContent
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing('sm'),
              padding: theme.spacing('md'),
            })}
          >
            <Stack
              sx={(theme) => ({
                flexDirection: 'row',
                alignItems: 'center',
                gap: theme.spacing('sm'),
              })}
            >
              <Icon
                sx={(theme) => ({
                  backgroundColor: theme.colors.surface.informative,
                  border: `1px solid ${theme.colors.border.surfaceInformative}`,
                  borderRadius: '100rem',
                  padding: theme.spacing('xxs'),
                })}
                type="plus"
                color="brandBase"
              />
              <Typography fontWeight="bold" id={`${id}-dialog-title`}>
                {translations.version}
              </Typography>
              <MuiIconButton
                size="medium"
                aria-label={translations.close}
                sx={() => ({ marginLeft: 'auto', padding: 0 })}
                onClick={handleCancel}
              >
                <Icon type="xMark" color="secondary" />
              </MuiIconButton>
            </Stack>
            <OutlinedInput
              fullWidth={true}
              disabled={disabled}
              size="small"
              placeholder={translations.formPlaceholderVersion}
              value={dialogInput}
              onChange={(e) => setDialogInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === KeyboardKeyEnum.Enter) {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
            />
            <FormButtons sx={() => ({ justifyContent: 'end', width: '100%' })}>
              <Button variant="outlined" onClick={handleCancel}>
                {translations.cancel}
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                {translations.save}
              </Button>
            </FormButtons>
          </DialogContent>
        </Dialog>
      </>
    );
  }
);

export { VersionInput };
