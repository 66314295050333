import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as findApi from 'api/find';
import {
  InnovationListingItem,
  InnovationListingItemsRequestParams,
} from 'api/find/types';
import { TablePagination } from 'componentsNew';
import { useFocusAreas, useSnackbar } from 'context';
import { useBreakpoints, usePrevious } from 'hooks';
import { Page, PageTitle } from 'layout';
import { debounce } from 'lodash';
import {
  ArticleList,
  ArticleListEmpty,
  ArticleListFilter,
  ArticleListSearch,
  ArticleListSkeleton,
} from 'pagesInnovation/common';
import * as articleListFilterHelpers from 'pagesInnovation/common/ArticleListFilter/helpers';
import {
  Filter,
  FilterIds,
} from 'pagesInnovation/common/ArticleListFilter/helpers';
import * as articleListSearchHelpers from 'pagesInnovation/common/ArticleListSearch/helpers';
import {
  INNOVATION_LISTING_PAGINATION_DEFAULT_LIMIT,
  INNOVATION_LISTING_PAGINATION_LIMITS,
} from 'pagesInnovation/common/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonInnovationFilterSubmit,
  GAonInnovationSearchSubmit,
  GAonShowMoreClick,
} from 'utils/analytics';

const elementId = 'process-and-guides-listing';

const ProcessAndGuidesListing = () => {
  const [list, setList] = useState<{
    items: InnovationListingItem[];
    total: number;
  } | null>(null);

  const [isListError, setIsListError] = useState<boolean>(false);
  const [isListLoading, setIsListLoading] = useState<boolean>(false);

  const { focusAreaFilterOptions, isFocusAreasError } = useFocusAreas();

  const history = useHistory();
  const location = useLocation();
  const { isMobile } = useBreakpoints();
  const { showSnackbar } = useSnackbar();

  const availableFilter = useMemo(() => {
    const filter: Filter = {
      types: [],
      focusAreas: focusAreaFilterOptions || [],
    };
    return filter;
  }, [focusAreaFilterOptions]);

  const activeParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    const pageStr = urlSearchParams.get('page') || '';
    const page = pageStr ? Number(pageStr) : 1;

    const limitStr = urlSearchParams.get('limit') || '';
    const limit = limitStr
      ? Number(limitStr)
      : INNOVATION_LISTING_PAGINATION_DEFAULT_LIMIT;

    const filterStr = urlSearchParams.get('filter') || '';
    const filter = articleListFilterHelpers.parseFilter(filterStr);

    const searchStr = urlSearchParams.get('search') || '';
    const search = articleListSearchHelpers.parseSearch(searchStr);

    return { page, limit, search, filter, filterStr };
  }, [location.search]);

  const prevActiveParams = usePrevious(activeParams);

  const documentTitle = useMemo(
    () => [
      translations.innovationDocumentTitle,
      translations.innovationTypeProcessAndGuides,
    ],
    []
  );

  const pageCount = useMemo(() => {
    if (!list) {
      return 0;
    }
    return list.total % activeParams.limit > 0
      ? Math.trunc(list.total / activeParams.limit) + 1
      : list.total / activeParams.limit;
  }, [activeParams.limit, list]);

  const isPageOutOfBound = useMemo(() => {
    return (
      activeParams.page > 1 &&
      activeParams.page > pageCount &&
      list !== null &&
      !list.items.length
    );
  }, [activeParams.page, list, pageCount]);

  const fetchList = useCallback(
    async (params: InnovationListingItemsRequestParams) => {
      const list: {
        items: InnovationListingItem[];
        total: number;
      } = {
        items: [],
        total: 0,
      };
      try {
        setIsListLoading(true);
        setIsListError(false);
        const query = findApi.getInnovationItemsQueryString(params);
        const response = await findApi.getInnovationProcessAndGuidesItems(
          query
        );
        if (!response?.data?.data) {
          throw Error();
        }
        list.total = response.data.data.total as number;
        list.items = response.data.data.items as InnovationListingItem[];
        return list;
      } catch {
        setIsListError(true);
        showSnackbar({
          type: 'error',
          text: translations.contentGetError,
        });
      } finally {
        setIsListLoading(false);
        return list;
      }
    },
    [showSnackbar]
  );

  const fetchListWithDebounce = useMemo(() => {
    return debounce(
      async (
        params: InnovationListingItemsRequestParams,
        callback: (newList: {
          items: InnovationListingItem[];
          total: number;
        }) => void
      ) => {
        const newList = await fetchList(params);
        callback(newList);
      },
      500,
      { leading: true, trailing: true }
    );
  }, [fetchList]);

  const handlePageChange = useCallback(
    (value: number) => {
      const page = isPageOutOfBound ? pageCount : value;
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set('page', `${page}`);
      history.push({ search: urlSearchParams.toString() });
      GAonShowMoreClick('Innovation');
    },
    [history, isPageOutOfBound, location.search, pageCount]
  );

  const handleLimitChange = useCallback(
    (value: number) => {
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set('limit', `${value}`);
      urlSearchParams.set('page', `${1}`);
      history.push({ search: urlSearchParams.toString() });
      GAonShowMoreClick('Innovation');
    },
    [history, location.search]
  );

  const handleFilterChange = useCallback(
    (value: FilterIds) => {
      const stringified = articleListFilterHelpers.stringifyFilter(value);
      const urlSearchParams = new URLSearchParams(location.search);
      if (stringified) {
        urlSearchParams.set('filter', stringified);
      } else {
        urlSearchParams.delete('filter');
      }
      urlSearchParams.set('page', `${1}`);
      history.push({ search: urlSearchParams.toString() });

      const labels = articleListFilterHelpers.getFilterLabelsAsStr(
        value,
        availableFilter
      );
      GAonInnovationFilterSubmit(labels);
    },
    [availableFilter, history, location.search]
  );

  const handleSearchSubmit = useCallback(
    (value: string) => {
      const stringified = articleListSearchHelpers.stringifySearch(value);
      const urlSearchParams = new URLSearchParams(location.search);
      if (stringified) {
        urlSearchParams.set('search', stringified);
      } else {
        urlSearchParams.delete('search');
      }
      urlSearchParams.set('page', `${1}`);
      history.push({ search: urlSearchParams.toString() });
      GAonInnovationSearchSubmit(value);
    },
    [history, location.search]
  );

  // Show error if no focus areas
  useEffect(() => {
    if (isFocusAreasError) {
      showSnackbar({
        type: 'error',
        text: translations.innovationFilterFetchError,
      });
    }
  }, [isFocusAreasError, showSnackbar]);

  // Fetch initial list
  useEffect(() => {
    if (list !== null) {
      return;
    }
    async function initFilterAndList() {
      const initList = await fetchList({
        filter: activeParams.filter,
        search: activeParams.search,
        page: activeParams.page,
        limit: activeParams.limit,
      });
      setList(initList);
    }
    initFilterAndList();
  }, [activeParams, fetchList, list]);

  // Fetch new list when "activeParams" changes
  useEffect(() => {
    if (!prevActiveParams) {
      return;
    }
    if (
      prevActiveParams.page === activeParams.page &&
      prevActiveParams.limit === activeParams.limit &&
      prevActiveParams.search === activeParams.search &&
      prevActiveParams.filterStr === activeParams.filterStr
    ) {
      return;
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const params: InnovationListingItemsRequestParams = {
      page: activeParams.page,
      limit: activeParams.limit,
      search: activeParams.search,
      filter: activeParams.filter,
    };
    const callback = (newList: {
      items: InnovationListingItem[];
      total: number;
    }) => {
      setList(newList);
    };
    fetchListWithDebounce(params, callback);
  }, [activeParams, fetchListWithDebounce, prevActiveParams]);

  const filterElement = useMemo(() => {
    return (
      <ArticleListFilter
        elementId={`${elementId}-filter`}
        title={translations.processAndGuidesFilterTitle}
        activeFilter={activeParams.filter}
        availableFilter={availableFilter}
        onChange={handleFilterChange}
        sx={{ width: { xs: '100%', md: '30%' } }}
      />
    );
  }, [activeParams.filter, availableFilter, handleFilterChange]);

  const listElement = useMemo(() => {
    if (isListLoading) {
      return <ArticleListSkeleton />;
    }
    if (isListError) {
      return (
        <ArticleListEmpty>
          <Typography>{translations.fetchError}</Typography>
        </ArticleListEmpty>
      );
    }
    if (!list) {
      return null;
    }
    if (!list.items?.length) {
      return (
        <ArticleListEmpty>
          <Typography>{translations.contentEmpty}</Typography>
          {isPageOutOfBound && (
            <Typography>{translations.paginationOutOfBound}</Typography>
          )}
        </ArticleListEmpty>
      );
    }
    return (
      <ArticleList
        elementId={`${elementId}-article-list`}
        articles={list.items}
        hideType
      />
    );
  }, [isListError, isListLoading, isPageOutOfBound, list]);

  const paginationElement = useMemo(() => {
    if (
      isMobile &&
      list &&
      list.total <= activeParams.limit &&
      !isPageOutOfBound
    ) {
      return null;
    }

    return (
      <TablePagination
        elementId={`${elementId}-pagination`}
        hideRowsPerPage={isMobile}
        disabled={isListLoading}
        page={activeParams.page}
        rowsPerPage={activeParams.limit}
        rowsPerPageOptions={INNOVATION_LISTING_PAGINATION_LIMITS}
        count={pageCount}
        sx={(theme) => ({
          backgroundColor: theme.colors.surface.primary,
          borderRadius: theme.border.radius.md,
        })}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
      />
    );
  }, [
    activeParams.limit,
    activeParams.page,
    handleLimitChange,
    handlePageChange,
    isListLoading,
    isMobile,
    isPageOutOfBound,
    list,
    pageCount,
  ]);

  return (
    <Page title={documentTitle}>
      <Stack
        sx={(theme) => ({
          minHeight: '90vh',
          gap: { xs: theme.spacing('xxs'), md: theme.spacing('md') },
        })}
      >
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            gap: theme.spacing('xxs'),
            marginBottom: theme.spacing('xxxs'),
          })}
        >
          <PageTitle
            display={translations.innovationTypeProcessAndGuides}
            hidden={documentTitle.join(' | ')}
          />
          <ArticleListSearch
            elementId={`${elementId}-search`}
            placeholder={translations.processAndGuidesSearchPlaceholder}
            activeSearch={activeParams.search}
            onSubmit={handleSearchSubmit}
          />
        </Stack>
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: theme.spacing('sm'), md: '2rem' },
          })}
        >
          {filterElement}
          <Stack
            sx={(theme) => ({
              flexGrow: 1,
              gap: theme.spacing('sm'),
              marginBottom: theme.spacing('sm'),
            })}
          >
            {listElement}
            {paginationElement}
          </Stack>
        </Stack>
      </Stack>
    </Page>
  );
};

export { ProcessAndGuidesListing };
