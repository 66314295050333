import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useRef } from 'react';
import { muiColors } from 'theme';

export type BackgroundColor = keyof typeof muiColors.surface;

export type FormLoadingProps = {
  isLoading?: boolean;
  scroll?: boolean;
  icon?: boolean;
  color?: BackgroundColor;
};

const FormLoading = ({
  isLoading = false,
  scroll = false,
  icon = true,
  color = 'primary',
}: FormLoadingProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isLoading && scroll && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [isLoading, scroll]);

  if (!isLoading) {
    return null;
  }

  return (
    <Box
      ref={ref}
      sx={(theme) => ({
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: `${theme.colors.surface[color]}88`,
        zIndex: theme.zIndex.modal,
      })}
    >
      {icon && (
        <CircularProgress
          size={64}
          sx={{
            position: 'absolute',
            top: 'calc(50% - 32px)',
            left: 'calc(50% - 32px)',
          }}
        />
      )}
    </Box>
  );
};

export { FormLoading };
