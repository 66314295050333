import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import React from 'react';

import {
  ArticleActionButton,
  ArticleActionButtonProps,
} from './ArticleActionButton';

type ArticleHeaderProps = {
  backgroundImage?: string;
  articleActionButtonProps?: ArticleActionButtonProps;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
};

const ArticleHeader = ({
  backgroundImage,
  articleActionButtonProps,
  sx,
  children,
}: ArticleHeaderProps) => {
  return (
    <Stack
      sx={[
        (theme) => ({
          position: 'relative',
          wordBreak: 'break-word',
          gap: theme.spacing('md'),
          alignItems: { xs: 'baseline', lg: 'center' },
          flexDirection: { xs: 'column', lg: 'row' },
          borderRadius: theme.border.radius.xxl,
          background: backgroundImage
            ? `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${backgroundImage})`
            : theme.colors.surface.tertiary,
          backgroundRepeat: 'round',
          padding: {
            xs: '1.5rem',
            lg: `2.5rem 3.5rem`,
          },
          ...(articleActionButtonProps && {
            paddingRight: { xs: '4rem', lg: '5rem' },
          }),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
      {articleActionButtonProps && (
        <ArticleActionButton
          {...articleActionButtonProps}
          sx={() => ({
            position: 'absolute',
            top: { xs: '1.5rem', lg: '2.5rem' },
            right: { xs: '1.5rem', lg: '2.5rem' },
          })}
        />
      )}
    </Stack>
  );
};

const ArticleHeaderLeftColumn = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  if (!children) {
    return null;
  }
  return (
    <Stack
      sx={(theme) => ({
        alignItems: 'baseline',
        gap: theme.spacing('sm'),
        width: { xs: '100%', lg: '65%' },
        flexShrink: 0,
      })}
    >
      {children}
    </Stack>
  );
};

const ArticleHeaderRightColumn = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  if (!children) {
    return null;
  }
  return (
    <Stack
      sx={(theme) => ({
        width: '100%',
        alignItems: 'baseline',
        gap: theme.spacing('sm'),
        borderLeft: { lg: `1px solid ${theme.colors.border.surfaceSecondary}` },
        paddingLeft: { lg: theme.spacing('md') },
      })}
    >
      {children}
    </Stack>
  );
};

export { ArticleHeader, ArticleHeaderLeftColumn, ArticleHeaderRightColumn };
