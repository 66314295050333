import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { Icon } from 'componentsNew';
import {
  Form,
  FormButtons,
  FormFieldWrapper,
  FormLoading,
  FormStack,
} from 'layout';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { translations } from 'translations';
import * as formUtils from 'utils/form';
import { emailPattern } from 'utils/form/customValidators';

export type FormValues = {
  name: string;
  email: string;
  title: string;
};

export const DEFAULT_VALUES: FormValues = {
  name: '',
  email: '',
  title: '',
};

type ExternalPersonsInputFormProps = {
  elementId: string;
  beforeSubmit?: () => void;
  onSubmit: SubmitHandler<FormValues>;
  disabled?: boolean;
};

const ExternalPersonsInputForm = ({
  elementId,
  beforeSubmit,
  onSubmit,
  disabled,
}: ExternalPersonsInputFormProps) => {
  const methods = useFormContext<FormValues>();

  const {
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <Form
      id={elementId}
      onSubmit={(e) => {
        beforeSubmit && beforeSubmit();
        handleSubmit(onSubmit)(e);
      }}
    >
      <FormLoading isLoading={isSubmitting} scroll />
      <FormStack
        sx={(theme) => ({
          gap: theme.spacing('xxs'),
        })}
      >
        <Stack
          sx={(theme) => ({
            padding: `${theme.spacing('xxxs')} 0`,
          })}
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: formUtils.getErrorMessage('required', {
                  displayName: translations.externalPersonsInputFormName,
                }),
              },
              maxLength: {
                value: 60,
                message: formUtils.getErrorMessage('maxLength', {
                  displayName: translations.externalPersonsInputFormName,
                  value: 60,
                }),
              },
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <FormFieldWrapper
                  id={`${elementId}-name`}
                  label={translations.externalPersonsInputFormName}
                  labelSize="small"
                  error={errors.name?.message}
                >
                  <OutlinedInput
                    {...field}
                    size="small"
                    placeholder={
                      translations.externalPersonsInputFormPlaceholderName
                    }
                    inputRef={ref}
                  />
                </FormFieldWrapper>
              );
            }}
          />
        </Stack>
        <Stack
          sx={(theme) => ({
            padding: `${theme.spacing('xxxs')} 0`,
          })}
        >
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: true,
                message: formUtils.getErrorMessage('required', {
                  displayName: translations.externalPersonsInputFormEmail,
                }),
              },
              maxLength: {
                value: 60,
                message: formUtils.getErrorMessage('maxLength', {
                  displayName: translations.externalPersonsInputFormEmail,
                  value: 60,
                }),
              },
              pattern: {
                value: emailPattern(),
                message: formUtils.getErrorMessage('pattern', {
                  displayName: translations.externalPersonsInputFormEmail,
                }),
              },
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <FormFieldWrapper
                  id={`${elementId}-email`}
                  label={translations.externalPersonsInputFormEmail}
                  labelSize="small"
                  error={errors.email?.message}
                >
                  <OutlinedInput
                    {...field}
                    size="small"
                    placeholder={
                      translations.externalPersonsInputFormPlaceholderEmail
                    }
                    inputRef={ref}
                  />
                </FormFieldWrapper>
              );
            }}
          />
        </Stack>
        <Stack
          sx={(theme) => ({
            padding: `${theme.spacing('xxxs')} 0 0`,
          })}
        >
          <Controller
            name="title"
            control={control}
            rules={{
              required: {
                value: true,
                message: formUtils.getErrorMessage('required', {
                  displayName: translations.externalPersonsInputFormJobTitle,
                }),
              },
              maxLength: {
                value: 60,
                message: formUtils.getErrorMessage('maxLength', {
                  displayName: translations.externalPersonsInputFormJobTitle,
                  value: 60,
                }),
              },
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <FormFieldWrapper
                  id={`${elementId}-title`}
                  label={translations.externalPersonsInputFormJobTitle}
                  labelSize="small"
                  error={errors.title?.message}
                >
                  <OutlinedInput
                    {...field}
                    size="small"
                    placeholder={
                      translations.externalPersonsInputFormPlaceholderJobtitle
                    }
                    inputRef={ref}
                  />
                </FormFieldWrapper>
              );
            }}
          />
        </Stack>
      </FormStack>
      <FormButtons
        sx={(theme) => ({
          justifyContent: 'flex-end',
          marginTop: theme.spacing('xs'),
        })}
      >
        <Button
          disabled={disabled}
          variant="outlined"
          type="submit"
          endIcon={<Icon type="plus" color="brandBase" />}
        >
          {translations.add}
        </Button>
      </FormButtons>
    </Form>
  );
};

export { ExternalPersonsInputForm };
