import {
  InnovationToolsAndTemplatesArticle,
  InnovationToolsAndTemplatesCreateBody,
} from 'api/cms-innovation/types';

import { FormValues } from './ToolsAndTemplatesForm';

export const transformFormValuesToCreateOrEditPayload = (data: FormValues) => {
  const transformed: InnovationToolsAndTemplatesCreateBody = data;
  return transformed;
};

export const transformGetResponseToFormValues = (
  data: InnovationToolsAndTemplatesArticle
) => {
  const transformed: FormValues = {
    title: data.title,
    preamble: data.preamble,
    focusAreas: data.focusAreas,
    toolsAndTemplates: data.toolsAndTemplates,
    chapters: data.chapters.map((chapter) => ({
      id: chapter.id,
      title: chapter.title || '',
      body: chapter.body || '',
      resources: chapter.resources || {},
    })),
    contactDetails: data.contactDetails || '',
    displayOnHome: data.displayOnHome,
  };

  return transformed;
};

export const getSharepointFolderNamesFromChapters = (
  chapters: FormValues['chapters']
) => {
  return chapters
    .map((chapter) => {
      return chapter.resources?.sharepointFolderName;
    })
    .filter(Boolean) as string[];
};
