import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material/MenuItem';
import { Icon } from 'componentsNew';

export type SelectItem<T = {}> = {
  name: string;
  value: string;
  disabled?: boolean;
  element?: React.ReactNode;
  data?: T;
};

type MenuItemProps<T = {}> = {
  disableCheckIcon: boolean;
  item: SelectItem<T>;
} & MuiMenuItemProps;

const SelectMenuItem = <T,>({
  item,
  disableCheckIcon,
  ...menuItemProps
}: MenuItemProps<T>) => {
  return (
    <MuiMenuItem
      {...menuItemProps}
      key={item.value}
      value={item.value}
      disabled={item.disabled}
      sx={{
        textWrapMode: 'wrap',
        '> span': {
          display:
            !disableCheckIcon && menuItemProps['aria-selected']
              ? 'block'
              : 'none',
        },
      }}
    >
      {item.element ? (
        item.element
      ) : (
        <>
          {item.name}
          <Icon
            type="checkCircleFilled"
            color="brandBase"
            size={20}
            sx={{ marginLeft: 'auto' }}
          />
        </>
      )}
    </MuiMenuItem>
  );
};

export { SelectMenuItem };
