import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { EmbedVideo } from 'componentsNew';

const IframeNodeView = ({ node }: NodeViewProps) => {
  return (
    <NodeViewWrapper>
      <EmbedVideo
        src={node.attrs['src']}
        title={node.attrs['title']}
        sx={(theme) => ({ marginBottom: theme.spacing('sm') })}
      />
    </NodeViewWrapper>
  );
};

export { IframeNodeView };
