import { SearchField } from 'componentsNew';
import { useEffect, useState } from 'react';

type ArticleListSearchProps = {
  elementId: string;
  placeholder?: string;
  activeSearch: string;
  onSubmit: (value: string) => void;
};

const ArticleListSearch = ({
  elementId,
  placeholder,
  activeSearch,
  onSubmit,
}: ArticleListSearchProps) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(activeSearch);
  }, [activeSearch]);

  return (
    <SearchField
      id={elementId}
      value={value}
      placeholder={placeholder}
      sx={{ width: { xs: '100%', md: '20rem' } }}
      onChange={setValue}
      onSubmit={() => {
        if (value !== activeSearch) {
          onSubmit(value);
        }
      }}
      onClear={() => {
        setValue('');
        onSubmit('');
      }}
    />
  );
};

export { ArticleListSearch };
