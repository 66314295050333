import Stack, { StackProps } from '@mui/material/Stack';

type ArticleChapterContentProps = {
  id: string;
  sx?: StackProps['sx'];
  ariaLabelledBy: string;
  active: boolean;
  children: React.ReactNode;
};

const ArticleChapterContent = ({
  id,
  sx,
  ariaLabelledBy,
  active,
  children,
}: ArticleChapterContentProps) => {
  return (
    <Stack
      id={id}
      role="tabpanel"
      hidden={!active}
      aria-labelledby={ariaLabelledBy}
      sx={[
        () => ({
          width: '100%',
          minWidth: 0,
          ...(!active && { display: 'none' }),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {active && children}
    </Stack>
  );
};

export { ArticleChapterContent };
