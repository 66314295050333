import './SharepointItem.scss';

import { Icon } from 'componentsNew';

const SharepointItem = ({ item }) => {
  return (
    <>
      <div className="sharepoint-item">
        <div className="sharepoint-item__label">Sharepoint</div>
        <h2 className="sharepoint-item__title">
          <a
            className="sharepoint-item__link"
            href={item.url}
            target="_blank"
            rel="noreferrer"
          >
            <span>{item.title}</span>{' '}
            <Icon
              type="arrowTopRightOnSquare"
              color="brandBase"
              sx={{ marginLeft: '12px' }}
            ></Icon>
          </a>
        </h2>
        <p className="sharepoint-item__summary">{item.summary}</p>
        <p className="sharepoint-item__owner">
          <Icon
            color="secondary"
            sx={{ marginRight: '12px' }}
            type="userCircleFilled"
          ></Icon>
          <span>{item.owner}</span>
        </p>
      </div>
    </>
  );
};

export default SharepointItem;
