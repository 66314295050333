import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

import {
  InnovationCaseStudyArticle,
  InnovationCaseStudyArticleCreateBody,
  InnovationCaseStudyArticleEditBody,
  InnovationFocusAreaArticle,
  InnovationFocusAreaArticleCreateBody,
  InnovationFocusAreaArticleEditBody,
  InnovationProcessAndGuidesArticle,
  InnovationProcessAndGuidesCreateBody,
  InnovationProcessAndGuidesEditBody,
  InnovationSimpleFocusArea,
  InnovationToolsAndTemplatesArticle,
  InnovationToolsAndTemplatesCreateBody,
  InnovationToolsAndTemplatesEditBody,
  InnovationTrainingArticle,
  InnovationTrainingArticleCreateBody,
  InnovationTrainingArticleEditBody,
  InnovationWebinarArticle,
  InnovationWebinarArticleCreateBody,
  InnovationWebinarArticleEditBody,
} from './types';

const { cmsInnovation } = settings.api;

export const getSimpleFocusAreas = async () => {
  const response = await createRequest({
    method: 'get',
    url: `${cmsInnovation}/focus-area/items`,
    errorMessages: {
      '*': 'Failed to get innovation focus areas, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data as InnovationSimpleFocusArea[];
};

export const getFocusAreaArticle = async (id: string) => {
  const response = await createRequest({
    method: 'get',
    url: `${cmsInnovation}/focus-area/${id}`,
    errorMessages: {
      '*': 'Failed to get innovation focus area, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data as InnovationFocusAreaArticle;
};

export const createFocusAreaArticle = async (
  data: InnovationFocusAreaArticleCreateBody
) => {
  const response = await createRequest({
    method: 'POST',
    url: `${cmsInnovation}/focus-area`,
    data: data,
    errorMessages: {
      '*': 'Failed to create focus area article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data.id as string;
};

export const updateFocusAreaArticle = async (
  id: string,
  data: InnovationFocusAreaArticleEditBody
) => {
  const response = await createRequest({
    method: 'PUT',
    url: `${cmsInnovation}/focus-area/${id}`,
    data: data,
    errorMessages: {
      '*': 'Failed to update focus area article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data.id as string;
};

export const deleteFocusAreaArticle = async (id: string) => {
  const response = await createRequest({
    method: 'DELETE',
    url: `${cmsInnovation}/focus-area/${id}`,
    errorMessages: {
      '*': 'Failed to delete focus area article, please contact support for assistance.',
    },
  });
  return response;
};

export const getTrainingArticle = async (id: string) => {
  const response = await createRequest({
    method: 'get',
    url: `${cmsInnovation}/learning/training/${id}`,
    errorMessages: {
      '*': 'Failed to get training article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data as InnovationTrainingArticle;
};

export const createTrainingArticle = async (
  data: InnovationTrainingArticleCreateBody
) => {
  const response = await createRequest({
    method: 'POST',
    url: `${cmsInnovation}/learning/training`,
    data: data,
    errorMessages: {
      '*': 'Failed to create training article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data.id as string;
};

export const updateTrainingArticle = async (
  id: string,
  data: InnovationTrainingArticleEditBody
) => {
  const response = await createRequest({
    method: 'PUT',
    url: `${cmsInnovation}/learning/training/${id}`,
    data: data,
    errorMessages: {
      '*': 'Failed to update training article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data.id as string;
};

export const deleteTrainingArticle = async (id: string) => {
  const response = await createRequest({
    method: 'DELETE',
    url: `${cmsInnovation}/learning/training/${id}`,
    errorMessages: {
      '*': 'Failed to delete training article, please contact support for assistance.',
    },
  });
  return response;
};

export const getWebinarArticle = async (id: string) => {
  const response = await createRequest({
    method: 'get',
    url: `${cmsInnovation}/learning/webinar/${id}`,
    errorMessages: {
      '*': 'Failed to get webinar article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data as InnovationWebinarArticle;
};

export const createWebinarArticle = async (
  data: InnovationWebinarArticleCreateBody
) => {
  const response = await createRequest({
    method: 'POST',
    url: `${cmsInnovation}/learning/webinar`,
    data: data,
    errorMessages: {
      '*': 'Failed to create webinar article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data.id as string;
};

export const updateWebinarArticle = async (
  id: string,
  data: InnovationWebinarArticleEditBody
) => {
  const response = await createRequest({
    method: 'PUT',
    url: `${cmsInnovation}/learning/webinar/${id}`,
    data: data,
    errorMessages: {
      '*': 'Failed to update webinar article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data.id as string;
};

export const deleteWebinarArticle = async (id: string) => {
  const response = await createRequest({
    method: 'DELETE',
    url: `${cmsInnovation}/learning/webinar/${id}`,
    errorMessages: {
      '*': 'Failed to delete webinar article, please contact support for assistance.',
    },
  });
  return response;
};

export const getCaseStudyArticle = async (id: string) => {
  const response = await createRequest({
    method: 'get',
    url: `${cmsInnovation}/learning/case-study/${id}`,
    errorMessages: {
      '*': 'Failed to get case study article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data as InnovationCaseStudyArticle;
};

export const createCaseStudyArticle = async (
  data: InnovationCaseStudyArticleCreateBody
) => {
  const response = await createRequest({
    method: 'POST',
    url: `${cmsInnovation}/learning/case-study`,
    data: data,
    errorMessages: {
      '*': 'Failed to create case study article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data.id as string;
};

export const updateCaseStudyArticle = async (
  id: string,
  data: InnovationCaseStudyArticleEditBody
) => {
  const response = await createRequest({
    method: 'PUT',
    url: `${cmsInnovation}/learning/case-study/${id}`,
    data: data,
    errorMessages: {
      '*': 'Failed to update case study article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data.id as string;
};

export const deleteCaseStudyArticle = async (id: string) => {
  const response = await createRequest({
    method: 'DELETE',
    url: `${cmsInnovation}/learning/case-study/${id}`,
    errorMessages: {
      '*': 'Failed to delete case study article, please contact support for assistance.',
    },
  });
  return response;
};

export const createProcessAndGuidesArticle = async (
  data: InnovationProcessAndGuidesCreateBody
) => {
  const response = await createRequest({
    method: 'POST',
    url: `${cmsInnovation}/process-and-guides`,
    data: data,
    errorMessages: {
      '*': 'Failed to create process and guides article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data.id as string;
};

export const getProcessAndGuidesArticle = async (id: string) => {
  const response = await createRequest({
    method: 'get',
    url: `${cmsInnovation}/process-and-guides/${id}`,
    errorMessages: {
      '*': 'Failed to get process and guides article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data as InnovationProcessAndGuidesArticle;
};

export const updateProcessAndGuidesArticle = async (
  id: string,
  data: InnovationProcessAndGuidesEditBody
) => {
  const response = await createRequest({
    method: 'PUT',
    url: `${cmsInnovation}/process-and-guides/${id}`,
    data: data,
    errorMessages: {
      '*': 'Failed to update process and guides article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data.id as string;
};

export const deleteProcessAndGuidesArticle = async (id: string) => {
  const response = await createRequest({
    method: 'DELETE',
    url: `${cmsInnovation}/process-and-guides/${id}`,
    errorMessages: {
      '*': 'Failed to delete process and guides article, please contact support for assistance.',
    },
  });
  return response;
};

export const createToolsAndTemplatesArticle = async (
  data: InnovationToolsAndTemplatesCreateBody
) => {
  const response = await createRequest({
    method: 'POST',
    url: `${cmsInnovation}/tools-and-templates`,
    data: data,
    errorMessages: {
      '*': 'Failed to create tools and templates article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data.id as string;
};

export const getToolsAndTemplatesArticle = async (id: string) => {
  const response = await createRequest({
    method: 'get',
    url: `${cmsInnovation}/tools-and-templates/${id}`,
    errorMessages: {
      '*': 'Failed to get tools and templates article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data as InnovationToolsAndTemplatesArticle;
};

export const updateToolsAndTemplatesArticle = async (
  id: string,
  data: InnovationToolsAndTemplatesEditBody
) => {
  const response = await createRequest({
    method: 'PUT',
    url: `${cmsInnovation}/tools-and-templates/${id}`,
    data: data,
    errorMessages: {
      '*': 'Failed to update tools and templates article, please contact support for assistance.',
    },
  });
  if (!response?.data?.data) {
    throw Error();
  }
  return response.data.data.id as string;
};

export const deleteToolsAndTemplatesArticle = async (id: string) => {
  const response = await createRequest({
    method: 'DELETE',
    url: `${cmsInnovation}/tools-and-templates/${id}`,
    errorMessages: {
      '*': 'Failed to delete tools and templates article, please contact support for assistance.',
    },
  });
  return response;
};
