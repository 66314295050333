import { MultiSelect } from 'componentsNew';
import { useSnackbar } from 'context';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { translations } from 'translations';

import { SelectItem } from './SelectMenuItem';
import { SelectBaseProps, SingleSelect } from './SingleSelect';

export type SearchSelectBaseProps<T> = Omit<
  SelectBaseProps<T>,
  | 'items'
  | 'disablePopupIcon'
  | 'filterSelectedItems'
  | 'onChange'
  | 'onInputChange'
  | 'onBlur'
>;

export type SearchSelectProps<T = {}> = {
  multiple?: boolean;
  hideTags?: boolean;
  hideClearIcon?: boolean;
  limit?: number;
  value: SelectItem<T>[];
  search:
    | ((query: string) => SelectItem<T>[])
    | ((query: string) => Promise<SelectItem<T>[]>);
  onChange: (value: T[]) => void;
} & SearchSelectBaseProps<T>;

const SearchSelect = <T,>({
  limit = -1,
  multiple,
  hideTags,
  hideClearIcon,
  value,
  search,
  onChange,
  ...selectBaseProps
}: SearchSelectProps<T>) => {
  const [items, setItems] = useState<SelectItem<T>[]>([]);

  const { showSnackbar } = useSnackbar();

  const handleInputChange = useMemo(() => {
    return debounce(async (value: string) => {
      try {
        const newItems = await search(value);
        setItems(newItems);
      } catch {
        showSnackbar({
          type: 'error',
          text: translations.searchSelectorFetchError,
        });
      }
    }, 250);
  }, [search, showSnackbar]);

  const handleChange = useCallback(
    (value: SelectItem<T>[]) => {
      const data = value.map((item) => item.data).filter(Boolean) as T[];
      onChange(data);
    },
    [onChange]
  );

  const availableItems = useMemo(() => {
    const isLimitReached = limit > 0 && value.length >= limit;
    return isLimitReached ? [] : items.concat(value);
  }, [items, limit, value]);

  if (multiple) {
    return (
      <MultiSelect<T>
        placeholder={translations.search}
        {...selectBaseProps}
        disablePopupIcon
        filterSelectedItems
        limit={limit}
        items={availableItems}
        hideTags={hideTags}
        hideClearIcon={hideClearIcon}
        value={value}
        onInputChange={handleInputChange}
        onChange={handleChange}
        onBlur={() => setItems([])}
      />
    );
  }

  return (
    <SingleSelect<T>
      placeholder={`${translations.search}...`}
      {...selectBaseProps}
      disablePopupIcon
      filterSelectedItems
      items={availableItems}
      value={value.length ? value[0] : null}
      onInputChange={handleInputChange}
      onChange={(value) => handleChange(value ? [value] : [])}
      onBlur={() => setItems([])}
    />
  );
};

export { SearchSelect };
