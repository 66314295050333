import { FloatingMenu } from '@tiptap/react';
import { IconButton, PopperMenu } from 'componentsNew';
import { ImageFilePicker } from 'componentsNew/MediaInput/ImageInput/ImageFilePicker';
import { ImageType } from 'componentsNew/MediaInput/ImageInput/ImageModal';
import { useState } from 'react';
import { translations } from 'translations';

import { EmbedVideoAction, ImageAction } from './actions';
import { MediaMenuItem } from './MediaMenuItem';

const elementId = 'rich-text-media-menu';

enum ActionType {
  Image,
  EmbedVideo,
}

type Action =
  | { type: ActionType.Image; image: ImageType }
  | { type: ActionType.EmbedVideo };

const MediaMenu = () => {
  const [activeAction, setActiveAction] = useState<Action | null>(null);

  const [popperMenuAnchorEl, setPopperMenuAnchorEl] =
    useState<HTMLElement | null>(null);

  return (
    <>
      {activeAction && activeAction.type === ActionType.Image && (
        <ImageAction
          image={activeAction.image}
          onDone={() => {
            setActiveAction(null);
          }}
        />
      )}
      {activeAction && activeAction.type === ActionType.EmbedVideo && (
        <EmbedVideoAction
          onDone={() => {
            setActiveAction(null);
          }}
        />
      )}
      <FloatingMenu editor={null}>
        <IconButton
          type="plus"
          variant="outlined"
          id={`${elementId}-button`}
          aria-label={translations.richTextAddMediaTitle}
          aria-controls={`${elementId}-popper`}
          onClick={(e) => {
            e.stopPropagation();
            setPopperMenuAnchorEl(e.currentTarget);
          }}
          sx={() => ({
            position: 'absolute',
            top: '-1rem',
            left: '-3rem',
          })}
        />
        <PopperMenu
          id={`${elementId}-popper`}
          aria-label={translations.richTextAddMediaAriaLabel}
          placement="bottom-end"
          open={Boolean(popperMenuAnchorEl)}
          anchorEl={popperMenuAnchorEl}
          onClose={() => {
            setPopperMenuAnchorEl(null);
          }}
        >
          <ImageFilePicker
            triggerElement={
              <MediaMenuItem
                icon="photo"
                elementId={`${elementId}-image`}
                text={translations.richTextAddMediaImage}
              />
            }
            onSubmit={(blobImage) => {
              setActiveAction({ type: ActionType.Image, image: blobImage });
            }}
          />
          <MediaMenuItem
            icon="playCircle"
            elementId={`${elementId}-embed-video`}
            text={translations.richTextAddMediaEmbedVideo}
            onClick={() => {
              setActiveAction({ type: ActionType.EmbedVideo });
            }}
          />
        </PopperMenu>
      </FloatingMenu>
    </>
  );
};

export { MediaMenu };
