import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useMemo } from 'react';

import { ReactComponent as CheckedIcon } from './Checked.svg';
import { ReactComponent as IndeterminateIcon } from './Indeterminate.svg';
import { ReactComponent as UncheckedIcon } from './Unchecked.svg';

export type CheckboxProps = {
  label?: React.ReactNode;
  error?: boolean;
} & MuiCheckboxProps;

const Checkbox = ({ label, error, ...muiCheckboxProps }: CheckboxProps) => {
  const checkboxElement = useMemo(
    () => (
      <MuiCheckbox
        icon={<UncheckedIcon />}
        checkedIcon={<CheckedIcon />}
        indeterminateIcon={<IndeterminateIcon />}
        {...muiCheckboxProps}
      />
    ),
    [muiCheckboxProps]
  );

  if (!label) {
    return checkboxElement;
  }

  return (
    <FormControlLabel
      label={label}
      sx={(theme) => ({
        ...(error && {
          border: `1px solid ${theme.colors.border.critical}`,
          borderRadius: theme.border.radius.sm,
        }),
      })}
      control={
        <MuiCheckbox
          icon={<UncheckedIcon />}
          checkedIcon={<CheckedIcon />}
          indeterminateIcon={<IndeterminateIcon />}
          {...muiCheckboxProps}
        />
      }
    />
  );
};

export { Checkbox };
