import 'react-image-crop/dist/ReactCrop.css';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AlertDialog, Icon } from 'componentsNew';
import { useSnackbar } from 'context';
import { FormFieldHelper, FormFieldWrapper, FormLoading } from 'layout';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactCrop, { PercentCrop } from 'react-image-crop';
import { translations } from 'translations';
import * as formUtils from 'utils/form';
import * as textUtils from 'utils/misc/text';

import * as imageHelpers from '../../Image/helpers';
import * as helpers from './helpers';

export type ImageType = {
  src: string;
  width: number;
  height: number;
  blob?: Blob;
};

export type ImageValue = {
  altText?: string;
  caption?: string;
} & ImageType;

export type ImageModalProps = {
  elementId: string;
  isLoading?: boolean;
  defaultValue: ImageValue;
  aspectRatio?: number;
  minWidth?: number;
  minHeight?: number;
  maxSizeBytes?: number;
  disabled?: boolean;
  hideCaption?: boolean;
  hideAltText?: boolean;
  labels?: {
    title?: string;
    submit?: string;
  };
  onSubmit: (value: ImageValue) => void;
  onClose: () => void;
} & Pick<DialogProps, 'disableRestoreFocus'>;

const ImageModal = ({
  elementId,
  isLoading,
  defaultValue,
  aspectRatio,
  minWidth: minWidthProp,
  minHeight: minHeightProp,
  maxSizeBytes,
  disabled,
  hideCaption,
  hideAltText,
  disableRestoreFocus,
  labels = {},
  onClose,
  onSubmit,
}: ImageModalProps) => {
  const [altTextError, setAltTextError] = useState<string>('');
  const [altTextInput, setAltTextInput] = useState<string>('');
  const [captionInput, setCaptionInput] = useState<string>('');

  const [imageError, setImageError] = useState<string>('');
  const [imageInput, setImageInput] = useState<ImageType | null>(null);
  const [imageCrop, setImageCrop] = useState<PercentCrop | undefined>(
    undefined
  );
  const imageRef = useRef<HTMLImageElement | null>(null);

  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] =
    useState<boolean>(false);

  const { showSnackbar } = useSnackbar();

  const { minWidth, minHeight } = useMemo(
    () =>
      helpers.getValidMinDimensions({
        minWidth: minWidthProp,
        minHeight: minHeightProp,
        aspectRatio,
      }),
    [aspectRatio, minHeightProp, minWidthProp]
  );

  const disableCrop = useMemo(() => {
    if (!defaultValue.blob) {
      return true;
    }
    const isTooSmallToCrop =
      defaultValue.height &&
      defaultValue.width &&
      (defaultValue.height <= minHeight || defaultValue.width <= minWidth);

    if (isTooSmallToCrop) {
      return true;
    }
    return false;
  }, [
    defaultValue.blob,
    defaultValue.height,
    defaultValue.width,
    minHeight,
    minWidth,
  ]);

  const validateImage = useCallback(
    (image: ImageValue) => {
      if (!image.blob) {
        return null;
      }
      if (image.width < minWidth || image.height < minHeight) {
        return textUtils.replaceTranslationAliases(
          translations.imageInputInvalidDimensions,
          { minWidth, minHeight }
        );
      }
      return null;
    },
    [minHeight, minWidth]
  );

  const validateAltText = useCallback(
    (altText: string) => {
      if (hideAltText) {
        return null;
      }
      if (altText.trim() === '') {
        return formUtils.getErrorMessage('required', {
          displayName: translations.imageInputAltTitle,
        });
      }
      return null;
    },
    [hideAltText]
  );

  const handleSubmit = useCallback(async () => {
    if (!imageInput || !imageRef.current) {
      return;
    }

    const newImage = disableCrop
      ? imageInput
      : await helpers.cropAndCompressImage({
          imageCrop,
          imageElement: imageRef.current,
          maxSizeBytes,
        });

    const newAltTextError = validateAltText(altTextInput);
    const newImageError = validateImage(newImage);

    if (newAltTextError || newImageError) {
      setAltTextError(
        (prevAltTextError) => newAltTextError || prevAltTextError
      );
      setImageError((prevImageError) => newImageError || prevImageError);
      return;
    }
    onSubmit({ ...newImage, altText: altTextInput, caption: captionInput });
  }, [
    altTextInput,
    captionInput,
    imageCrop,
    disableCrop,
    imageInput,
    maxSizeBytes,
    onSubmit,
    validateAltText,
    validateImage,
  ]);

  const handleImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (!imageInput) {
        return;
      }
      const image = e.currentTarget;

      setImageInput((prevImageInput) => {
        if (
          !prevImageInput ||
          (prevImageInput.width === image.naturalWidth &&
            prevImageInput.height === image.naturalHeight)
        ) {
          return prevImageInput;
        }
        return {
          ...prevImageInput,
          width: image.naturalWidth,
          height: image.naturalHeight,
        };
      });
      if (disableCrop) {
        return;
      }
      const initCrop = helpers.getInitialCrop({
        imageWidth: image.naturalWidth,
        imageHeight: image.naturalHeight,
        minWidth,
        minHeight,
        aspectRatio,
      });
      setImageCrop(initCrop);
    },
    [aspectRatio, disableCrop, imageInput, minHeight, minWidth]
  );

  useEffect(() => {
    if (imageInput) {
      return;
    }
    if (defaultValue.altText) {
      setAltTextInput(defaultValue.altText);
    }
    if (defaultValue.caption) {
      setCaptionInput(defaultValue.caption);
    }
    setImageInput({
      src: defaultValue.src,
      width: defaultValue.width,
      height: defaultValue.height,
      blob: defaultValue.blob,
    });
  }, [defaultValue, imageInput]);

  if (!imageInput) {
    return null;
  }

  const minWidthCropPx =
    minWidth && imageRef.current
      ? (minWidth / imageRef.current.naturalWidth) * imageRef.current.width
      : 0;

  const minHeightCropPx =
    minHeight && imageRef.current
      ? (minHeight / imageRef.current.naturalHeight) * imageRef.current.height
      : 0;

  const imageSrc = imageHelpers.getSrc({
    pathOrUrl: imageInput.src,
  }).originalSrc;

  return (
    <>
      <Dialog
        open
        aria-labelledby={`${elementId}-title`}
        disableRestoreFocus={disableRestoreFocus}
        onClose={() => {
          setIsCloseConfirmationOpen(true);
        }}
        PaperProps={{
          sx: (theme) => ({
            maxWidth: '100%',
            backgroundColor: theme.colors.surface.primary,
            boxShadow: theme.elevation.md,
            borderRadius: theme.border.radius.md,
            position: 'relative',
          }),
        }}
      >
        <FormLoading isLoading={isLoading} />
        <DialogContent
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing('md'),
            padding: theme.spacing('md'),
            overflowX: 'hidden',
          })}
        >
          <Stack sx={{ flexDirection: 'row' }}>
            <Typography variant="h3" id={`${elementId}-title`}>
              {labels.title
                ? labels.title
                : disableCrop
                ? translations.mediaInputImage
                : translations.imageInputCropTitle}
            </Typography>
            <IconButton
              size="small"
              aria-label={translations.close}
              onClick={onClose}
              sx={{
                alignSelf: 'baseline',
                marginLeft: 'auto',
                padding: 0,
                '&.MuiButtonBase-root.MuiIconButton-root:hover': {
                  backgroundColor: 'unset',
                },
              }}
            >
              <Icon type="xMark" color="secondary" />
            </IconButton>
          </Stack>
          <Stack
            sx={(theme) => ({
              flexDirection: { xs: 'column', md: 'row' },
              gap: theme.spacing('sm'),
            })}
          >
            <Stack
              sx={(theme) => ({
                flexGrow: 1,
                gap: theme.spacing('xxxs'),
                backgroundColor: theme.colors.surface.tertiary,
                borderRadius: theme.border.radius.lg,
                height: 'fit-content',
                width: imageInput.width ? `${imageInput.width}px` : '100%',
                maxWidth: '65%',
                aspectRatio:
                  imageInput.width && imageInput.height
                    ? imageInput.width / imageInput.height
                    : 1.5,
                '> .ReactCrop': {
                  borderRadius: theme.border.radius.lg,
                  overflow: 'hidden',
                },
                img: {
                  borderRadius: theme.border.radius.lg,
                },
                '> .ReactCrop .ReactCrop__crop-selection': {
                  outline: `2px dashed ${theme.colors.border.focus}`,
                  borderRadius: theme.border.radius.lg,
                  border: 'none',
                  animation: 'none',
                  background: 'unset',
                },
              })}
            >
              {disableCrop ? (
                <Box
                  ref={imageRef}
                  component="img"
                  src={imageSrc}
                  alt={altTextInput}
                  title={altTextInput}
                  onLoad={handleImageLoad}
                  onError={() =>
                    showSnackbar({
                      type: 'error',
                      text: translations.imageInputLoadError,
                    })
                  }
                />
              ) : (
                <ReactCrop
                  crop={imageCrop}
                  keepSelection
                  aspect={aspectRatio}
                  minWidth={minWidthCropPx}
                  minHeight={minHeightCropPx}
                  onChange={(_, percentCrop) => setImageCrop(percentCrop)}
                  onComplete={(_, percentCrop) => setImageCrop(percentCrop)}
                >
                  <Box
                    ref={imageRef}
                    component="img"
                    src={imageSrc}
                    alt={altTextInput}
                    title={altTextInput}
                    onLoad={handleImageLoad}
                    onError={() =>
                      showSnackbar({
                        type: 'error',
                        text: translations.imageInputLoadError,
                      })
                    }
                  />
                </ReactCrop>
              )}
              <FormFieldHelper type="critical" open={Boolean(imageError)}>
                {imageError}
              </FormFieldHelper>
            </Stack>
            <Stack
              sx={(theme) => ({ gap: theme.spacing('md'), maxWidth: '30rem' })}
            >
              {!hideAltText && (
                <Stack sx={(theme) => ({ gap: theme.spacing('xs') })}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({
                      fontWeight: theme.typography.fontWeightBold,
                      color: theme.colors.text.primary,
                      '&::after': {
                        content: '" *"',
                        color: theme.colors.text.critical,
                      },
                    })}
                  >
                    {translations.imageInputAltTitle}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={(theme) => ({
                      color: theme.colors.text.secondary,
                    })}
                  >
                    {translations.imageInputAltDescription}
                  </Typography>
                  <FormFieldWrapper
                    id={`${elementId}-alt-text`}
                    label={translations.imageInputAltDescription}
                    hideLabel
                    error={altTextError}
                  >
                    <OutlinedInput
                      fullWidth
                      multiline
                      autoFocus
                      size="small"
                      inputProps={{ maxLength: 125 }}
                      minRows={2}
                      maxRows={8}
                      placeholder={translations.imageInputAltTitle}
                      value={altTextInput}
                      error={Boolean(altTextError)}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setAltTextInput(newValue);
                        const error = validateAltText(newValue);
                        setAltTextError(error || '');
                      }}
                      sx={(theme) => ({
                        '.MuiOutlinedInput-notchedOutline': {
                          border: `1px dashed ${theme.colors.border.input}`,
                        },
                      })}
                    />
                  </FormFieldWrapper>
                </Stack>
              )}
              {!hideCaption && (
                <Stack sx={(theme) => ({ gap: theme.spacing('xs') })}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({
                      fontWeight: theme.typography.fontWeightBold,
                      color: theme.colors.text.primary,
                    })}
                  >
                    {translations.imageInputCaptionTitle}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={(theme) => ({
                      color: theme.colors.text.secondary,
                    })}
                  >
                    {translations.imageInputCaptionDescription}
                  </Typography>
                  <FormFieldWrapper
                    id={`${elementId}-caption`}
                    label={translations.imageInputCaptionTitle}
                    hideLabel
                  >
                    <OutlinedInput
                      fullWidth
                      multiline
                      size="small"
                      inputProps={{ maxLength: 250 }}
                      minRows={2}
                      maxRows={8}
                      placeholder={translations.imageInputCaptionTitle}
                      value={captionInput}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setCaptionInput(newValue);
                      }}
                      sx={(theme) => ({
                        '.MuiOutlinedInput-notchedOutline': {
                          border: `1px dashed ${theme.colors.border.input}`,
                        },
                      })}
                    />
                  </FormFieldWrapper>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack
            sx={(theme) => ({
              flexDirection: { xs: 'column', md: 'row' },
              gap: { xs: theme.spacing('xs'), md: theme.spacing('sm') },
              justifyContent: 'end',
            })}
          >
            <Button
              size="medium"
              variant="text"
              disabled={disabled}
              onClick={onClose}
            >
              {translations.cancel}
            </Button>
            <Button
              size="medium"
              variant="contained"
              disabled={disabled}
              onClick={handleSubmit}
            >
              {labels.submit ? labels.submit : translations.imageInputSubmit}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <AlertDialog
        open={isCloseConfirmationOpen}
        type="warning"
        title={translations.confirmUnsavedChangesTitle}
        primaryButton={{
          text: translations.yes,
          onClick: () => {
            onClose();
            setIsCloseConfirmationOpen(false);
          },
        }}
        secondaryButton={{
          text: translations.cancel,
          onClick: () => {
            setIsCloseConfirmationOpen(false);
          },
        }}
      >
        {translations.confirmUnsavedChangesText}
      </AlertDialog>
    </>
  );
};

export { ImageModal };
