import { Node, nodeInputRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { ImageNodeView } from './ImageNodeView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      setImage: (options: {
        src: string;
        alt?: string;
        title?: string;
        caption?: string;
        width: number;
        height: number;
      }) => ReturnType;
    };
  }
}

// Matches an image to a ![image](src "title") on input.
export const inputRegex =
  /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

const ImageExtension = Node.create({
  name: 'image',

  group: 'block',

  atom: true,

  draggable: true,

  addAttributes() {
    return {
      class: {
        default: null,
      },
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      caption: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'figure',
        getAttrs: (node) => {
          const img = node.querySelector('img');
          const figcaption = node.querySelector('figcaption');

          if (!img) {
            return false;
          }

          return {
            class: node.getAttribute('class'),
            src: img.getAttribute('src'),
            alt: img.getAttribute('alt'),
            title: img.getAttribute('title'),
            width: img.getAttribute('data-original-width')
              ? Number(img.getAttribute('data-original-width'))
              : null,
            height: img.getAttribute('data-original-height')
              ? Number(img.getAttribute('data-original-height'))
              : null,
            caption: figcaption?.innerText,
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'figure',
      {
        class: HTMLAttributes['class'],
      },
      [
        'img',
        {
          src: HTMLAttributes['src'],
          alt: HTMLAttributes['alt'],
          title: HTMLAttributes['title'],
          'data-original-width': HTMLAttributes['width'],
          'data-original-height': HTMLAttributes['height'],
        },
      ],
      ['figcaption', {}, HTMLAttributes['caption']],
    ];
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, , alt, src, title] = match;

          return { src, alt, title };
        },
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageNodeView);
  },
});

export { ImageExtension };
