import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { IframeNodeView } from './IframeNodeView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    iframe: {
      setIframe: (options: { src: string }) => ReturnType;
    };
  }
}

const IframeExtension = Node.create({
  name: 'iframe',

  group: 'block',

  inline: false,

  atom: true,

  draggable: true,

  addAttributes() {
    return {
      parentClass: {
        default: null,
      },
      src: {
        default: null,
      },
      title: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'iframe[src]',
        getAttrs: (node) => {
          const parentNode = node.parentElement;

          return {
            parentClass: parentNode?.getAttribute('class'),
            src: node.getAttribute('src'),
            title: node.getAttribute('title'),
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      {
        class: HTMLAttributes['parentClass'],
      },
      [
        'iframe',
        {
          src: HTMLAttributes['src'],
          title: HTMLAttributes['title'],
          frameborder: 0,
          allow: 'fullscreen',
        },
      ],
    ];
  },

  addCommands() {
    return {
      setIframe:
        (options: { src: string }) =>
        ({ tr, dispatch }) => {
          const { selection } = tr;
          const node = this.type.create(options);

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node);
          }

          return true;
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(IframeNodeView);
  },
});

export { IframeExtension };
