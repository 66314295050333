import Stack from '@mui/material/Stack';
import * as documentsApi from 'api/sharepointdocuments';
import { FolderItem } from 'api/sharepointdocuments/types';
import { useSnackbar } from 'context';
import { PageContentHeader } from 'layout';
import { ResourcesList } from 'pagesInnovation/common/ArticlePage/ResourcesList';
import { useCallback, useEffect, useState } from 'react';
import { translations } from 'translations';

type ArticleResourcesProps = {
  elementId: string;
  documentsFolderId?: string;
  links?: {
    title: string;
    url: string;
  }[];
  onLinkClick: (link: { title: string; url: string }) => void;
  onDocumentClick: (document: FolderItem) => void;
};

const ArticleResources = ({
  elementId,
  documentsFolderId,
  links = [],
  onLinkClick,
  onDocumentClick,
}: ArticleResourcesProps) => {
  const [documents, setDocuments] = useState<FolderItem[] | null>(null);

  const { showSnackbar } = useSnackbar();

  const fetchDocuments = useCallback(
    async (folderId: string) => {
      let _documents: FolderItem[] = [];
      try {
        const response = await documentsApi.readFolder(folderId);
        const data = response?.data?.data;
        if (data) {
          _documents = data as FolderItem[];
        }
      } catch {
        showSnackbar({
          type: 'error',
          text: translations.resourcesFetchError,
        });
      } finally {
        setDocuments(_documents);
      }
    },
    [showSnackbar]
  );

  useEffect(() => {
    if (documentsFolderId) {
      fetchDocuments(documentsFolderId);
      return;
    }

    setDocuments([]);
  }, [fetchDocuments, documentsFolderId]);

  if (
    (!links.length && !documentsFolderId) ||
    (!links.length && documentsFolderId && documents && !documents.length)
  ) {
    return null;
  }
  return (
    <Stack id={elementId} sx={(theme) => ({ gap: theme.spacing('sm') })}>
      <PageContentHeader text={translations.resources} />
      <ResourcesList
        elementId={elementId}
        links={links}
        documents={documents || []}
        isDocumentsLoading={documents === null}
        onLinkClick={onLinkClick}
        onDocumentClick={onDocumentClick}
      />
    </Stack>
  );
};

export { ArticleResources };
