import Stack from '@mui/material/Stack';
import { LinkList } from 'componentsNew';
import { InnovationRouteEnum } from 'enums';
import { INSIGHTS_DATABASE_LINK_URL } from 'pagesAvenue/Home/InsightsDatabase/helpers';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { translations } from 'translations';

const elementId = 'innovation-navigation';

type NavigationMobileProps = { navigationOpen?: boolean };

const NavigationMobile = ({ navigationOpen }: NavigationMobileProps) => {
  const location = useLocation();

  const selectedValue = useMemo(() => {
    if (location.pathname.startsWith(InnovationRouteEnum.ProcessAndGuides)) {
      return InnovationRouteEnum.ProcessAndGuides;
    }
    if (location.pathname.startsWith(InnovationRouteEnum.ToolsAndTemplates)) {
      return InnovationRouteEnum.ToolsAndTemplates;
    }
    if (location.pathname.startsWith(InnovationRouteEnum.Learning)) {
      return InnovationRouteEnum.Learning;
    }
    if (location.pathname.startsWith(InnovationRouteEnum.Strategy)) {
      return InnovationRouteEnum.Strategy;
    }
    if (location.pathname.startsWith(InnovationRouteEnum.FocusArea)) {
      return InnovationRouteEnum.FocusArea;
    }
    if (location.pathname.startsWith('/portals/innovation')) {
      return InnovationRouteEnum.InsightsDatabase;
    }
    if (
      location.pathname === InnovationRouteEnum.Home ||
      location.pathname === `${InnovationRouteEnum.Home}/`
    ) {
      return InnovationRouteEnum.Home;
    }
    return false;
  }, [location.pathname]);

  if (!navigationOpen) {
    return null;
  }

  return (
    <Stack sx={(theme) => ({ backgroundColor: theme.colors.surface.primary })}>
      <LinkList
        items={[
          {
            id: `${elementId}-${InnovationRouteEnum.Home}`,
            to: InnovationRouteEnum.Home,
            title: translations.home,
            active: selectedValue === InnovationRouteEnum.Home,
          },
          {
            id: `${elementId}-${InnovationRouteEnum.ProcessAndGuides}`,
            to: InnovationRouteEnum.ProcessAndGuides,
            title: translations.innovationTypeProcessAndGuides,
            active: selectedValue === InnovationRouteEnum.ProcessAndGuides,
          },
          {
            id: `${elementId}-${InnovationRouteEnum.ToolsAndTemplates}`,
            to: InnovationRouteEnum.ToolsAndTemplates,
            title: translations.innovationTypeToolsAndTemplates,
            active: selectedValue === InnovationRouteEnum.ToolsAndTemplates,
          },
          {
            id: `${elementId}-${InnovationRouteEnum.Learning}`,
            to: InnovationRouteEnum.Learning,
            title: translations.learning,
            active: selectedValue === InnovationRouteEnum.Learning,
          },
          {
            id: `${elementId}-${InnovationRouteEnum.Strategy}`,
            to: InnovationRouteEnum.Strategy,
            title: translations.innovationTypeStrategy,
            active: selectedValue === InnovationRouteEnum.Strategy,
          },
          {
            id: `${elementId}-${InnovationRouteEnum.InsightsDatabase}`,
            to: INSIGHTS_DATABASE_LINK_URL,
            title: translations.insightsDatabase,
            active: selectedValue === InnovationRouteEnum.InsightsDatabase,
          },
          {
            id: `${elementId}-${InnovationRouteEnum.FocusArea}`,
            to: InnovationRouteEnum.FocusArea,
            title: translations.innovationFocusArea,
            active: selectedValue === InnovationRouteEnum.FocusArea,
          },
        ]}
      />
    </Stack>
  );
};

export { NavigationMobile };
