import Divider from '@mui/material/Divider';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AccordionWithCheckboxes } from 'pagesInnovation/common';
import { useCallback } from 'react';
import { translations } from 'translations';

import { Filter, FilterIds, FilterName } from './helpers';

type ArticleListFilterDesktopProps = {
  elementId: string;
  sx?: StackProps['sx'];
  title?: string;
  activeFilter: FilterIds;
  availableFilter: Filter;
  onChange: (value: FilterIds) => void;
};

const ArticleListFilterDesktop = ({
  elementId,
  sx,
  title,
  activeFilter,
  availableFilter,
  onChange,
}: ArticleListFilterDesktopProps) => {
  const handleCheckboxChange = useCallback(
    (
      name: FilterName,
      item: { name: string; value: string },
      checked: boolean
    ) => {
      const newIds = checked
        ? [...activeFilter[name], item.value]
        : activeFilter[name].filter((id) => id !== item.value);

      const newFilter = {
        ...activeFilter,
        [name]: newIds,
      };
      onChange(newFilter);
    },
    [activeFilter, onChange]
  );

  return (
    <>
      {title && (
        <Typography variant="srOnly" component="h2">
          {title}
        </Typography>
      )}
      <Stack
        id={elementId}
        divider={<Divider />}
        sx={[
          (theme) => ({
            width: '100%',
            backgroundColor: theme.colors.surface.primary,
            borderRadius: theme.border.radius.md,
            padding: `0 ${theme.spacing('sm')}`,
            height: 'fit-content',
            flexShrink: 0,
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {availableFilter.types.length > 0 && (
          <AccordionWithCheckboxes
            elementId={`${elementId}-${FilterName.Types}`}
            title={translations.innovationTypes}
            value={activeFilter.types}
            items={availableFilter.types.map((option) => ({
              name: option.name,
              value: option.id,
            }))}
            defaultExpanded
            onChange={(item, checked) =>
              handleCheckboxChange(FilterName.Types, item, checked)
            }
          />
        )}
        {availableFilter.focusAreas.length > 0 && (
          <AccordionWithCheckboxes
            elementId={`${elementId}-${FilterName.FocusAreas}`}
            title={translations.innovationFocusAreas}
            value={activeFilter.focusAreas}
            items={availableFilter.focusAreas.map((option) => ({
              name: option.name,
              value: option.id,
            }))}
            defaultExpanded
            onChange={(item, checked) =>
              handleCheckboxChange(FilterName.FocusAreas, item, checked)
            }
          />
        )}
      </Stack>
    </>
  );
};
export { ArticleListFilterDesktop };
