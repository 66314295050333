import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { JobListingItem } from 'api/jobs/types';
import { Icon, Tooltip } from 'componentsNew';
import { useMemo } from 'react';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';
import * as timeUtils from 'utils/misc/time';

import { JobContentHiringManager } from '../JobContent/JobContentHiringManager';
import { JobContentPlaces } from '../JobContent/JobContentPlaces';

type JobListItemProps = {
  elementId: string;
  item: JobListingItem;
  isActive?: boolean;
  onClick: () => void;
};

const JobListItem = ({
  elementId,
  item,
  isActive,
  onClick,
}: JobListItemProps) => {
  const daysToExpiration = useMemo(
    () => timeUtils.getDaysAgo(item.applicationDueUtc) * -1,
    [item.applicationDueUtc]
  );

  const isSoonToExpire = useMemo(
    () => daysToExpiration <= 10 && daysToExpiration > 0,
    [daysToExpiration]
  );

  const tooltipClock = useMemo(
    () =>
      textUtils.replaceTranslationAliases(
        daysToExpiration === 1
          ? translations.jobsApplicationDaysLeftOne
          : translations.jobsApplicationDaysLeftMany,
        { count: daysToExpiration }
      ),
    [daysToExpiration]
  );

  return (
    <ListItemButton
      id={elementId}
      onClick={onClick}
      sx={(theme) => ({
        padding: 0,
        boxShadow: theme.elevation.sm,
        borderRadius: theme.border.radius.md,
        ...(isActive
          ? {
              backgroundColor: theme.colors.surface.selected,
              outline: `1px solid ${theme.colors.border.surfaceInformative}`,
              cursor: 'default',
              '&:focus': {
                backgroundColor: theme.colors.surface.selected,
                outline: `1px solid ${theme.colors.border.surfaceInformative}`,
              },
              '&:hover': {
                backgroundColor: theme.colors.surface.selected,
              },
            }
          : {
              backgroundColor: theme.colors.surface.primary,
              '&:hover': {
                backgroundColor: theme.colors.surface.actionTertiaryHover,
                [`#${elementId}-title`]: {
                  color: theme.colors.text.actionHover,
                  textDecoration: 'underline',
                  textDecorationColor: theme.colors.text.actionHover,
                },
              },
            }),
      })}
    >
      <Stack
        sx={(theme) => ({
          gap: theme.spacing('xxxs'),
          padding: `${theme.spacing('xxs')} ${theme.spacing('xs')}`,
          wordBreak: 'break-word',
          width: '100%',
        })}
      >
        <Typography
          id={`${elementId}-title`}
          variant="body2"
          sx={(theme) => ({
            fontWeight: 'bold',
            color: isActive
              ? theme.colors.text.brand
              : theme.colors.text.secondary,
          })}
        >
          {item.title}
        </Typography>
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            gap: theme.spacing('xxxs'),
            width: '100%',
          })}
        >
          <Stack
            sx={(theme) => ({
              gap: theme.spacing('xxxs'),
            })}
          >
            <JobContentHiringManager
              elementId={`${elementId}-hiring-manager`}
              hiringManager={item.hiringManager}
            />
            <JobContentPlaces
              elementId={`${elementId}-countries`}
              places={item.places}
            />
          </Stack>
          {(isSoonToExpire || item.isMostRelevant) && (
            <Stack
              sx={(theme) => ({
                flexDirection: 'row',
                gap: theme.spacing('xxxs'),
                padding: `${theme.spacing('xxxs')} ${theme.spacing('xxs')}`,
                backgroundColor: theme.colors.surface.secondary,
                borderRadius: '50px',
                marginLeft: 'auto',
                alignSelf: 'end',
                flexShrink: 0,
              })}
            >
              {isSoonToExpire && (
                <Tooltip title={tooltipClock} color="inversePrimary">
                  <Icon type="clock" color="secondary" size={20} />
                </Tooltip>
              )}
              {item.isMostRelevant && (
                <Tooltip
                  title={translations.jobsStarTooltip}
                  color="inversePrimary"
                >
                  <Icon type="star" color="secondary" size={20} />
                </Tooltip>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </ListItemButton>
  );
};

export { JobListItem };
