import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { FormStack } from 'layout';

const ArticleFormSkeleton = () => {
  const theme = useTheme();

  return (
    <Grid container spacing={theme.spacing('md')}>
      <Grid xs={12}>
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{
            height: '26rem',
          }}
        />
      </Grid>
      <Grid xs={12} lg={8}>
        <FormStack>
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ height: '11rem' }}
          />
          <Stack
            sx={(theme) => ({
              gap: theme.spacing('md'),
              maxWidth: '45rem',
            })}
          >
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{ height: '4rem' }}
            />
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{ height: '15.5rem' }}
            />
          </Stack>
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ height: '2rem' }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ height: '2rem' }}
          />
        </FormStack>
      </Grid>
      <Grid xs={12} lg={4}>
        <FormStack>
          {[...Array(2)].map((_, index) => (
            <Stack
              key={`innovation-article-form-skeleton-${index}`}
              sx={(theme) => ({ gap: theme.spacing('xs') })}
            >
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ height: '5rem' }}
              />
            </Stack>
          ))}
        </FormStack>
      </Grid>
    </Grid>
  );
};

export { ArticleFormSkeleton };
