import { useCallback, useMemo } from 'react';
import { getSiteSuggestions } from 'utils/misc/autoSuggest';

import { SearchSelect, SearchSelectProps } from './SearchSelect';
import { SelectItem } from './SelectMenuItem';

type Site = {
  name: string;
  value: string;
  country?: string;
};

export type SiteSearchSelectProps = {
  value: Site[];
} & Omit<SearchSelectProps<Site>, 'search' | 'value'>;

const SiteSearchSelect = ({ value, ...selectProps }: SiteSearchSelectProps) => {
  const transformToSelectItems = useCallback((sites: Site[]) => {
    return sites.map((site) => {
      const item: SelectItem<Site> = {
        name: site.name,
        value: site.value,
        data: site,
      };
      return item;
    });
  }, []);

  const search = useCallback(
    async (value: string) => {
      const suggestions = await getSiteSuggestions(value);

      if (!Array.isArray(suggestions)) {
        return [];
      }
      const sites = suggestions
        .map((suggestion: any) => {
          if (!suggestion.name || !suggestion.value) {
            return null;
          }
          const site: Site = {
            name: suggestion.name,
            value: suggestion.value,
            country: suggestion.country,
          };
          return site;
        })
        .filter(Boolean) as Site[];

      return transformToSelectItems(sites);
    },
    [transformToSelectItems]
  );

  const selectedItems = useMemo(
    () => transformToSelectItems(value),
    [value, transformToSelectItems]
  );

  return (
    <SearchSelect<Site>
      {...selectProps}
      value={selectedItems}
      search={search}
    />
  );
};

export { SiteSearchSelect };
