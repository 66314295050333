import Link from '@mui/material/Link';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import React, { useMemo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';
import * as timeUtils from 'utils/misc/time';

type EditedByProps = {
  id?: string;
  date?: string;
  user?: { id: string; name: string };
  sx?: SxProps<Theme>;
};

const EditedBy = ({ id, date, user, sx }: EditedByProps) => {
  const elements = useMemo(() => {
    let translation = '';
    if (date && user) {
      translation = translations.editedByNameAndDate;
    } else if (date) {
      translation = translations.editedByDate;
    } else if (user) {
      translation = translations.editedByName;
    }
    if (!translation) {
      return;
    }
    return textUtils.replaceTranslationAliasesWithElements(translation, {
      date: date ? timeUtils.getDateOrDaysAgo(date) : undefined,
      name: (
        <Link
          variant="caption"
          to={`/people/${user?.id}`}
          component={ReactRouterLink}
          sx={(theme) => ({
            color: theme.colors.text.tertiary,
            textDecorationColor: theme.colors.text.tertiary,
          })}
        >
          {user?.name}
        </Link>
      ),
    });
  }, [date, user]);

  if (!elements) {
    return null;
  }

  return (
    <Typography
      id={id}
      variant="caption"
      sx={[
        (theme) => ({ color: theme.colors.text.tertiary }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {elements.map((element, index) => [
        <React.Fragment key={`article-last-edited-by-${index}`}>
          {element}
        </React.Fragment>,
        ' ',
      ])}
    </Typography>
  );
};

export { EditedBy };
