import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import notFoundImage from 'assets/images/not-found.png';
import { translations } from 'translations';

type FetchErrorProps = {
  children?: React.ReactNode;
};

const FetchError = ({
  children = translations.fetchError,
}: FetchErrorProps) => (
  <Stack
    sx={(theme) => ({
      width: '100%',
      alignItems: 'center',
      textAlign: 'center',
      padding: theme.spacing('sm'),
      paddingBottom: theme.spacing('lg'),
    })}
  >
    <Box component="img" src={notFoundImage} maxWidth="20rem" />
    {children}
  </Stack>
);

export { FetchError };
