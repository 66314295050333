import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  InnovationListingItem,
  InnovationListingItemTypeEnum,
} from 'api/find/types';
import { Chip, TypographyWithMaxLines } from 'componentsNew';
import { InnovationRouteEnum } from 'enums';
import { useBreakpoints } from 'hooks';
import { useMemo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import * as textUtils from 'utils/misc/text';
import * as timeUtils from 'utils/misc/time';

import { INNOVATION_LISTING_ITEM_TYPE_TO_LABEL } from '../helpers';
import { ArticleImage } from './ArticleImage/ArticleImage';

type ArticleListItemProps = {
  elementId: string;
  sx?: ListItemProps['sx'];
  hideType?: boolean;
  article: InnovationListingItem;
  onClick?: (article: InnovationListingItem) => void;
};

const ArticleListItem = ({
  elementId,
  sx,
  hideType,
  article,
  onClick,
}: ArticleListItemProps) => {
  const { isMobile } = useBreakpoints();

  const linkPath = useMemo(() => {
    switch (article.type) {
      case InnovationListingItemTypeEnum.Training:
        return `${InnovationRouteEnum.Training}/${article.id}`;
      case InnovationListingItemTypeEnum.Webinar:
        return `${InnovationRouteEnum.Webinar}/${article.id}`;
      case InnovationListingItemTypeEnum.CaseStudy:
        return `${InnovationRouteEnum.CaseStudy}/${article.id}`;
      case InnovationListingItemTypeEnum.ToolsAndTemplates:
        return `${InnovationRouteEnum.ToolsAndTemplates}/${article.id}`;
      case InnovationListingItemTypeEnum.ProcessAndGuides:
        return `${InnovationRouteEnum.ProcessAndGuides}/${article.id}`;
      default:
        return '#';
    }
  }, [article.id, article.type]);

  return (
    <ListItem
      id={elementId}
      sx={[
        (theme) => ({
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: theme.colors.surface.primary,
          borderRadius: theme.border.radius.md,
          boxShadow: '0px 4px 8px -2px #0000000A',
          padding: theme.spacing('sm'),
          transition: 'all 0.3s ease',
          gap: theme.spacing('sm'),
          '&:hover': {
            backgroundColor: theme.colors.surface.informative,
            '.MuiLink-root > h2': {
              color: theme.colors.text.actionHover,
              textDecoration: 'underline',
            },
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <ArticleImage
        to={linkPath}
        src={article.imageUrl}
        fetchSize="sm"
        type={article.type}
        altText={article.imageAltText}
        sx={{ width: '30%', wordBreak: 'break-word' }}
        onClick={() => onClick && onClick(article)}
      />

      <Stack
        sx={(theme) => ({
          width: '100%',
          alignSelf: 'stretch',
          alignItems: 'baseline',
          gap: theme.spacing('xxs'),
        })}
      >
        {!hideType && (
          <Chip
            variant="outlined"
            size="small"
            label={INNOVATION_LISTING_ITEM_TYPE_TO_LABEL[article.type]}
            sx={(theme) => ({
              '&.MuiChip-outlined.MuiChip-outlinedDefault': {
                fontWeight: theme.typography.fontWeightBold,
                backgroundColor: theme.colors.surface.primary,
                borderColor: theme.palette.secondary2[900],
                color: theme.palette.secondary2[900],
              },
            })}
          />
        )}
        <Link
          to={linkPath}
          component={ReactRouterLink}
          sx={(theme) => ({
            color: theme.colors.text.secondary,
            textDecoration: 'none',
            fontWeight: theme.typography.fontWeightBold,
            '&:hover': {
              textDecoration: 'underline',
              color: theme.colors.text.actionHover,
            },
          })}
        >
          <TypographyWithMaxLines
            maxLines={2}
            variant={isMobile ? 'body1' : 'h3'}
            component="h3"
            sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
          >
            {article.title}
          </TypographyWithMaxLines>
        </Link>
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            rowGap: theme.spacing('xxs'),
          })}
          divider={
            <Divider
              orientation="vertical"
              sx={(theme) => ({
                margin: `auto ${theme.spacing('xxs')}`,
                height: theme.typography.body1.fontSize,
                borderColor: theme.colors.border.surfacePrimary,
                opacity: 0.7,
              })}
            />
          }
        >
          <Typography
            variant="body2"
            sx={(theme) => ({ color: theme.colors.text.disabled })}
          >
            {textUtils.capitalizeFirst(
              timeUtils.getDateOrDaysAgo(article.lastModifiedDate)
            )}
          </Typography>

          {article.focusAreas && article.focusAreas.length > 0 && (
            <Stack
              sx={(theme) => ({
                flexDirection: 'row',
                gap: theme.spacing('xxs'),
                flexWrap: 'wrap',
              })}
            >
              {article.focusAreas.map((focusArea, index) => (
                <Chip
                  key={`${elementId}-focusArea-${index}`}
                  size="small"
                  color="default"
                  variant="outlined"
                  label={focusArea.title}
                  sx={{ maxWidth: { xs: '10rem', md: '20rem' } }}
                />
              ))}
            </Stack>
          )}
        </Stack>
        {article.preamble && (
          <TypographyWithMaxLines
            maxLines={2}
            variant={isMobile ? 'caption' : 'body2'}
            sx={(theme) => ({ color: theme.colors.text.tertiary })}
          >
            {article.preamble}
          </TypographyWithMaxLines>
        )}
      </Stack>
    </ListItem>
  );
};
export { ArticleListItem };
