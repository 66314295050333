import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as findApi from 'api/find';
import { InnovationListingItem } from 'api/find/types';
import { AlertBar } from 'componentsNew';
import { LinkList, LinkListItem } from 'componentsNew/LinkList';
import { PageContentHeader } from 'layout';
import { useEffect, useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';
import { getDateOrDaysAgo } from 'utils/misc/time';

import {
  getInnovationListingItemLinkUrl,
  INNOVATION_LISTING_ITEM_TYPE_TO_LABEL,
} from '../helpers';

type RelatedArticlesProps = {
  elementId: string;
  articleId?: string;
  focusAreas?: {
    id: string;
    title: string;
  }[];
  showEmptyMessage?: boolean;
  limit?: number;
  articles?: InnovationListingItem[];
  onClick?: (article: InnovationListingItem) => void;
};

const RelatedArticles = ({
  elementId,
  articleId,
  focusAreas = [],
  showEmptyMessage = false,
  limit = 5,
  articles,
  onClick,
}: RelatedArticlesProps) => {
  const [relatedArticles, setRelatedArticles] = useState<
    InnovationListingItem[] | null
  >(null);

  const [alertBar, setAlert] = useState<{
    open: boolean;
    text: string;
  } | null>(null);

  const listItems = useMemo(() => {
    if (!relatedArticles) {
      return [];
    }
    return relatedArticles.map((relatedArticle, index) => {
      const item: LinkListItem = {
        id: `${elementId}-item-${index}`,
        to: getInnovationListingItemLinkUrl(
          relatedArticle.id,
          relatedArticle.type
        ),
        onClick: () => {
          if (onClick) {
            onClick(relatedArticle);
          }
        },
        divider: true,
        contentElement: (
          <Stack sx={{ width: '100%' }}>
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: theme.colors.text.tertiary,
              })}
            >
              {INNOVATION_LISTING_ITEM_TYPE_TO_LABEL[relatedArticle.type]}
            </Typography>
            <Typography
              variant="body2"
              sx={(theme) => ({
                color: theme.colors.text.secondary,
                fontWeight: theme.typography.fontWeightBold,
              })}
            >
              {relatedArticle.title}
            </Typography>
          </Stack>
        ),
        endElement: (
          <Typography
            variant="body2"
            sx={(theme) => ({
              width: '5rem',
              wordBreak: 'break-word',
              color: theme.colors.text.tertiary,
              textAlign: 'right',
            })}
          >
            {getDateOrDaysAgo(relatedArticle.lastModifiedDate)}
          </Typography>
        ),
      };
      return item;
    });
  }, [relatedArticles, elementId, onClick]);

  useEffect(() => {
    const fetchRelatedArticles = async () => {
      let articles: InnovationListingItem[] = [];
      try {
        const query = findApi.getInnovationItemsQueryString({
          limit: limit,
          filter: {
            focusAreas: focusAreas.map((focusArea) => focusArea.id),
          },
        });
        const response = await findApi.getInnovationItems(query);
        const data = response?.data?.data;
        if (data) {
          articles = data.items as InnovationListingItem[];
          articles = articles.filter(
            (relatedArticle) => relatedArticle.id !== articleId
          );
        }
      } catch {
      } finally {
        setRelatedArticles(articles);

        if (!showEmptyMessage) {
          return;
        }

        if (!articles.length) {
          setAlert({
            open: true,
            text: translations.relatedArticlesNoArticles,
          });
        } else {
          setAlert(null);
        }
      }
    };
    if (articles) {
      setRelatedArticles(articles);
      return;
    }
    if (!focusAreas.length) {
      setRelatedArticles([]);

      if (showEmptyMessage) {
        setAlert({
          open: true,
          text: translations.relatedArticlesNoFocusAreas,
        });
      }
      return;
    }
    fetchRelatedArticles();
  }, [articleId, articles, focusAreas, limit, showEmptyMessage]);

  if (!listItems.length && !showEmptyMessage) {
    return null;
  }

  return (
    <Stack id={elementId} sx={(theme) => ({ gap: theme.spacing('xxs') })}>
      <PageContentHeader text={translations.related} />
      <TransitionGroup>
        {listItems.length > 0 && (
          <Collapse>
            <LinkList items={listItems} />
          </Collapse>
        )}
      </TransitionGroup>
      {alertBar && (
        <AlertBar
          type="information"
          open={alertBar.open}
          text={alertBar.text}
        />
      )}
    </Stack>
  );
};

export { RelatedArticles };
