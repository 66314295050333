import PortalNode, { TEMPLATES } from 'api/models/PortalNode';
import PageHeader from 'components/PageHeader';
import PageTitle from 'components/PageTitle';
import NavigationWrapper from 'components/Portal/NavigationWrapper';
import { Component } from 'react';
import settings from 'settings';

import Edit from './Edit';
import ViewPage from './ViewPage';

const insightsDatabaseId = settings.portals[1].insightsDatabaseId;

const reserved = ['edit', 'create'];

class Portals extends Component {
  state = {
    isLoading: true,
    rootNode: null,
    pageId: null,
    type: null,
  };

  componentDidMount() {
    this._isMounted = true;
    const {
      match: {
        params: { portalUrl, pageId, type },
      },
    } = this.props;
    this.setState({ pageId, type }, () => {
      this.updateTree(portalUrl);
    });
  }

  componentDidUpdate(prevProps) {
    const { portalUrl, pageId, type } = this.props.match.params;
    const {
      portalUrl: prevPortalUrl,
      pageId: prevPageId,
      type: prevType,
    } = prevProps.match.params;

    if (
      prevPortalUrl !== portalUrl ||
      prevPageId !== pageId ||
      prevType !== type
    ) {
      this.setState(
        { pageId, type, isLoading: prevPortalUrl !== portalUrl },
        () => {
          prevPortalUrl !== portalUrl && this.updateTree(portalUrl);
        }
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getRenderData() {
    const { history } = this.props;
    const { rootNode, pageId, type } = this.state;

    if (!rootNode) {
      return { page: null, titles: [], editMode: null };
    }

    const titles = [rootNode.get('title')];

    if (!pageId || reserved.includes(pageId)) {
      setTimeout(() => history.replace(rootNode.getPath()), 0);

      return {
        page: rootNode,
        titles,
        editMode: null,
      };
    }

    const page = rootNode.deepFindChild(pageId);

    if (page && page !== rootNode) {
      const isInsightsContent =
        page.get('template') === TEMPLATES.INSIGHTS_CONTENT;
      if (isInsightsContent) {
        titles.push('Insights Database');
      }
      titles.push(page.get('title'));
    }

    const selectionPath = page ? page.getParentList() : [];

    // eslint-disable-next-line no-unused-vars
    const [selectedRoot, selectedL1Page, selectedL2Page] = selectionPath;

    const isInsightsDatabasePage =
      selectedL1Page && selectedL1Page.id === insightsDatabaseId;

    return {
      page,
      titles,
      editMode: type,
      isInsightsDatabasePage,
    };
  }

  async updateTree(portalUrl) {
    this.setState({ isLoading: true });

    const rootNode = await PortalNode.loadPortal(portalUrl);
    if (portalUrl !== this.props.match.params.portalUrl) {
      return;
    }

    if (this._isMounted) {
      this.setState({ rootNode, isLoading: false });
    }
  }

  render() {
    const { location, history } = this.props;
    const { isLoading } = this.state;

    const { page, editMode, titles, isInsightsDatabasePage } =
      this.getRenderData();

    //Remove when Strategy house event is over
    let isCodeOfConduct = false;

    //Remove when Strategy house event is over
    if (
      page &&
      (page.id === '779707869b2c787ea6ab' ||
        page.id === '3ad0c4b1dea670e0e808' ||
        page.id === '5723537e501f84604193' ||
        page.id === 'edf6c01880729e6d0562')
    ) {
      isCodeOfConduct = true;
    }

    return (
      <>
        <PageHeader
          signedIn={true}
          isInsightsDatabasePage={isInsightsDatabasePage}
        >
          <NavigationWrapper
            page={page}
            isInsightsDatabasePage={isInsightsDatabasePage}
          />
        </PageHeader>
        <PageTitle titles={titles} />

        <main className="main-content">
          {editMode ? (
            <Edit
              location={location}
              history={history}
              page={page}
              isCreating={editMode === 'create' || editMode === 'create-block'}
              isCreatingBlock={editMode === 'create-block'}
            />
          ) : (
            <ViewPage
              location={location}
              page={page}
              isLoading={isLoading}
              isCodeOfConduct={isCodeOfConduct}
            />
          )}
        </main>
      </>
    );
  }
}

export default Portals;
