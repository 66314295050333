import {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
  PercentCrop,
} from 'react-image-crop';

import { ImageType } from './ImageModal';

export const IMAGE_DEFAULT_MIN_WIDTH = 480;
export const IMAGE_DEFAULT_MIN_HEIGHT = 320;
const IMAGE_MAX_UPLOAD_SIZE = 3 * 1024 * 1024;

/**
 * Adjusts `minWidth` and `minHeight` to respect the given `aspectRatio`,
 * ensuring neither falls below the provided minimums. If only one dimension
 * is given, the other is calculated to maintain the aspect ratio.
 */
const getValidMinDimensions = (params: {
  minWidth?: number;
  minHeight?: number;
  aspectRatio?: number;
}) => {
  const aspectRatio = params.aspectRatio;
  let minWidth = params.minWidth;
  let minHeight = params.minHeight;

  if (!aspectRatio || aspectRatio <= 0) {
    return {
      minWidth: minWidth ?? IMAGE_DEFAULT_MIN_WIDTH,
      minHeight: minHeight ?? IMAGE_DEFAULT_MIN_HEIGHT,
    };
  }
  if (minWidth && minHeight) {
    if (minWidth / minHeight > aspectRatio) {
      minHeight = Math.floor(minWidth / aspectRatio);
    } else {
      minWidth = Math.floor(minHeight * aspectRatio);
    }
  } else if (minWidth) {
    minHeight = Math.floor(minWidth / aspectRatio);
  } else if (minHeight) {
    minWidth = Math.floor(minHeight * aspectRatio);
  } else {
    minWidth = IMAGE_DEFAULT_MIN_WIDTH;
    minHeight = Math.floor(minWidth / aspectRatio);
  }

  return { minWidth, minHeight };
};

/**
 * Calculates the initial crop area for an image, ensuring it meets the
 * specified minimum width and height while maintaining the aspect ratio.
 */
const getInitialCrop = (params: {
  imageWidth: number;
  imageHeight: number;
  minWidth: number;
  minHeight: number;
  aspectRatio?: number;
}) => {
  const { imageWidth, imageHeight, minWidth, minHeight, aspectRatio } = params;

  if (imageWidth <= minWidth && imageHeight <= minHeight) {
    const crop: PercentCrop = {
      unit: '%',
      height: 100,
      width: 100,
      x: 0,
      y: 0,
    };
    return crop;
  }

  const minWidthInPercent = minWidth ? (minWidth / imageWidth) * 100 : 100;

  const crop = makeAspectCrop(
    {
      unit: '%',
      width: minWidthInPercent,
    },
    aspectRatio || minWidth / minHeight,
    imageWidth,
    imageHeight
  );

  return centerCrop(crop, imageWidth, imageHeight);
};

/**
 * Crops an image based on the given percentage-based
 * crop and returns it as a Blob.
 */
const cropAndCompressImage = async (params: {
  imageCrop?: PercentCrop;
  imageElement: HTMLImageElement;
  maxSizeBytes?: number;
}) => {
  const {
    imageElement,
    maxSizeBytes = IMAGE_MAX_UPLOAD_SIZE,
    imageCrop: crop = { x: 0, y: 0, height: 100, width: 100, unit: '%' },
  } = params;

  const cropPx = convertToPixelCrop(
    crop,
    imageElement.naturalWidth,
    imageElement.naturalHeight
  );
  const widthPx = Math.round(cropPx.width);
  const heightPx = Math.round(cropPx.height);

  const canvas = document.createElement('canvas');
  canvas.width = widthPx;
  canvas.height = heightPx;

  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error();
  }

  ctx.drawImage(
    imageElement,
    cropPx.x,
    cropPx.y,
    widthPx,
    heightPx,
    0,
    0,
    widthPx,
    heightPx
  );

  const blob = await canvasToBlob({ canvas, maxSizeBytes });

  const newImage: ImageType = {
    src: URL.createObjectURL(blob),
    width: widthPx,
    height: heightPx,
    blob: blob,
  };
  return newImage;
};

/**
 * Converts a canvas to a JPEG Blob, compressing it iteratively
 * if needed to ensure it does not exceed the specified file size.
 */
const canvasToBlob = async ({
  canvas,
  maxSizeBytes,
}: {
  canvas: HTMLCanvasElement;
  maxSizeBytes: number;
}) => {
  let quality = 1;

  const tryCompression = (): Promise<Blob> =>
    new Promise((resolve, reject) => {
      canvas.toBlob(
        async (blob) => {
          if (!blob) return reject();
          if (blob.size > maxSizeBytes && quality > 0.1) {
            quality -= 0.1;
            return resolve(await tryCompression());
          }
          resolve(blob);
        },
        'image/jpeg',
        quality
      );
    });

  return await tryCompression();
};

/**
 * Converts a Base64-encoded string into a Blob.
 */
const base64ToBlob = (base64String: string): Blob => {
  let byteString;

  if (base64String.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(base64String.split(',')[1]);
  } else {
    byteString = decodeURIComponent(base64String.split(',')[1]);
  }

  const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

export {
  base64ToBlob,
  cropAndCompressImage,
  getInitialCrop,
  getValidMinDimensions,
};
