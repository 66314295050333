import { Editor, useCurrentEditor } from '@tiptap/react';
import React, { useImperativeHandle, useRef } from 'react';

export type RichTextRefType = {
  focus: () => void;
};

const RichTextRefHandler = React.forwardRef<RichTextRefType, {}>((_, ref) => {
  const { editor } = useCurrentEditor();

  const editorRef: React.MutableRefObject<Editor | null> = useRef(editor);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (editorRef.current) {
        editorRef.current.commands.focus();
      }
    },
  }));

  return null;
});

export { RichTextRefHandler };
