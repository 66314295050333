import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { InnovationListingItem } from 'api/find/types';
import { useBreakpoints } from 'hooks';
import {
  ArticleBody,
  ArticleChapterContent,
  ArticleChapters,
  ArticleResources,
  ArticleTabContent,
  ArticleTabs,
  RelatedArticles,
} from 'pagesInnovation/common';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonInnovationArticleRelatedClick,
  GAonInnovationArticleResourceDocumentClick,
  GAonInnovationArticleResourceLinkClick,
} from 'utils/analytics';

const elementIdTabs = 'process-and-guides-tabs';
const elementIdChapters = 'process-and-guides-chapters';

type TabsAndChaptersProps = {
  tabs: {
    id: string;
    title?: string;
    chapters: {
      id: string;
      title?: string;
      body?: string;
      resources?: {
        sharepointFolderName?: string;
        links?: { title: string; url: string }[];
      };
    }[];
  }[];
  relatedArticles: InnovationListingItem[];
};

const TabsAndChapters = ({ tabs, relatedArticles }: TabsAndChaptersProps) => {
  const { isMobile } = useBreakpoints();
  const location = useLocation();
  const history = useHistory();

  const activeParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    const tabId = urlSearchParams.get('tabId');
    const chapterId = urlSearchParams.get('chapterId');

    let tabIndex = tabs.findIndex((tab) => tab.id === tabId);
    tabIndex = tabIndex === -1 ? 0 : tabIndex;

    let chapterIndex = tabs[tabIndex].chapters.findIndex(
      (chapter) => chapter.id === chapterId
    );
    chapterIndex = chapterIndex === -1 ? 0 : chapterIndex;

    return { tabIndex, chapterIndex };
  }, [location.search, tabs]);

  const handleTabChange = useCallback(
    (index: number) => {
      const selectedTab = tabs[index];
      if (!selectedTab) {
        return;
      }
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set('tabId', selectedTab.id);
      history.push({ search: urlSearchParams.toString() });
    },
    [history, location.search, tabs]
  );

  const handleChapterChange = useCallback(
    (index: number) => {
      const selectedChapter = tabs[activeParams.tabIndex].chapters[index];
      if (!selectedChapter) {
        return;
      }
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set('chapterId', selectedChapter.id);
      history.push({ search: urlSearchParams.toString() });
    },
    [activeParams.tabIndex, history, location.search, tabs]
  );

  return (
    <Stack>
      <ArticleTabs
        id={`${elementIdTabs}-tab-items`}
        ariaLabel={translations.tabs}
        activeIndex={activeParams.tabIndex}
        onChange={handleTabChange}
        items={tabs.map((tab, index) => ({
          id: `${elementIdTabs}-items-${index}`,
          title: tab.title || '',
          ariaControls: `${elementIdTabs}-content-${index}`,
        }))}
      />
      {tabs.map((tab, index) => {
        return (
          <ArticleTabContent
            key={`${elementIdTabs}-content-${index}`}
            id={`${elementIdTabs}-content-${index}`}
            active={index === activeParams.tabIndex}
            ariaLabelledBy={`${elementIdTabs}-items-${index}`}
          >
            <Stack
              sx={(theme) => ({
                flexDirection: { xs: 'column', lg: 'row' },
                padding: `${theme.spacing('md')} 0`,
              })}
            >
              <ArticleChapters
                id={`${elementIdChapters}-items`}
                ariaLabel={translations.chapters}
                activeIndex={activeParams.chapterIndex}
                onChange={handleChapterChange}
                items={tab.chapters.map((chapter, index) => ({
                  id: `${elementIdChapters}-items-${index}`,
                  title: chapter.title || '',
                  ariaControls: `${elementIdChapters}-content-${index}`,
                }))}
                sx={() => ({
                  width: { xs: '100%', lg: '22.5%' },
                  flexShrink: 0,
                })}
              />
              {tab.chapters.map((chapter, index) => {
                return (
                  <ArticleChapterContent
                    key={`${elementIdChapters}-content-${index}`}
                    id={`${elementIdChapters}-content-${index}`}
                    active={index === activeParams.chapterIndex}
                    ariaLabelledBy={`${elementIdChapters}-items-${index}`}
                    sx={() => ({
                      flexDirection: { xs: 'column', lg: 'row' },
                      minHeight: '30rem',
                    })}
                  >
                    <Stack
                      sx={(theme) => ({
                        minWidth: 0,
                        width: '100%',
                        gap: theme.spacing('sm'),
                        margin: {
                          xs: `${theme.spacing('md')} 0`,
                          lg: `0 ${theme.spacing('md')}`,
                        },
                      })}
                    >
                      {chapter.title && (
                        <Typography
                          id={`${elementIdChapters}-content-${index}-title`}
                          variant={isMobile ? 'h1' : 'display4'}
                          sx={(theme) => ({
                            wordBreak: 'break-word',
                            color: theme.colors.text.secondary,
                            fontWeight: 'unset',
                          })}
                        >
                          {chapter.title}
                        </Typography>
                      )}
                      {chapter.body && (
                        <ArticleBody
                          elementId={`${elementIdChapters}-content-${index}-body`}
                          htmlContent={chapter.body}
                          fullWidth
                        />
                      )}
                    </Stack>
                    <Stack
                      sx={(theme) => ({
                        gap: theme.spacing('lg'),
                        width: {
                          xs: '100%',
                          lg:
                            tab.chapters.length === 1 && chapter.title === ''
                              ? '22.5%'
                              : `${(22.5 / (100 - 22.5)) * 100}%`,
                        },
                        flexShrink: 0,
                      })}
                    >
                      <ArticleResources
                        elementId={`${elementIdChapters}-content-${index}-resources`}
                        links={chapter.resources?.links || []}
                        documentsFolderId={
                          chapter.resources?.sharepointFolderName
                        }
                        onLinkClick={(link) =>
                          GAonInnovationArticleResourceLinkClick(link.title)
                        }
                        onDocumentClick={(document) =>
                          GAonInnovationArticleResourceDocumentClick(
                            document.name
                          )
                        }
                      />
                      <RelatedArticles
                        elementId={`${elementIdChapters}-content-${index}-related-articles`}
                        articles={relatedArticles}
                        onClick={(article) =>
                          GAonInnovationArticleRelatedClick(article.title)
                        }
                      />
                    </Stack>
                  </ArticleChapterContent>
                );
              })}
            </Stack>
          </ArticleTabContent>
        );
      })}
    </Stack>
  );
};

export { TabsAndChapters };
