import './Edit.scss';

import { listCategories } from 'api/cms/portals';
import PortalNode from 'api/models/PortalNode';
import Box from 'components/Box';
import { UserConsumer } from 'components/Context/User';
import withConsumer from 'components/Context/withConsumer';
import CreateOrEdit, { Consumer } from 'components/CreateOrEditArticle';
import Body from 'components/CreateOrEditArticle/components/Body';
import DisableComments from 'components/CreateOrEditArticle/components/DisableComments';
import DisplayOnHome from 'components/CreateOrEditArticle/components/DisplayOnHome';
import Documents from 'components/CreateOrEditArticle/components/Documents';
import Hero from 'components/CreateOrEditArticle/components/Hero';
import HidePagesFromNavigation from 'components/CreateOrEditArticle/components/HidePagesFromNavigation';
import InfoHighlightBox from 'components/CreateOrEditArticle/components/InfoHighlightBox';
import InformationOwner from 'components/CreateOrEditArticle/components/InformationOwner';
import PortalTopicSelector from 'components/CreateOrEditArticle/components/PortalTopicSelector';
import Preamble from 'components/CreateOrEditArticle/components/Preamble';
import PublishDate from 'components/CreateOrEditArticle/components/PublishDate';
import PublishOptions from 'components/CreateOrEditArticle/components/PublishOptions';
import SaveTextMediaBox from 'components/CreateOrEditArticle/components/SaveTextMediaBox';
import SortIndex from 'components/CreateOrEditArticle/components/SortIndex';
import SourceLink from 'components/CreateOrEditArticle/components/SourceLink';
import TagListEditor from 'components/CreateOrEditArticle/components/TagListEditor';
import Tags from 'components/CreateOrEditArticle/components/Tags';
import Title from 'components/CreateOrEditArticle/components/Title';
import UseCompactPageListing from 'components/CreateOrEditArticle/components/UseCompactPageListing';
import { Column, Container } from 'components/Grid';
import ArticleListBlockEditor from 'pages/Portals/TemplateBlockEditors/ArticleListBlockEditor';
import BodyBlockEditor from 'pages/Portals/TemplateBlockEditors/BodyBlockEditor';
import CustomBlockEditor from 'pages/Portals/TemplateBlockEditors/CustomBlockEditor';
import DividerBlockEditor from 'pages/Portals/TemplateBlockEditors/DividerBlockEditor';
import EventsListBlockEditor from 'pages/Portals/TemplateBlockEditors/EventsListBlockEditor';
import GradientBlockEditor from 'pages/Portals/TemplateBlockEditors/GradientBlockEditor';
import HeroBlockEditor from 'pages/Portals/TemplateBlockEditors/HeroBlockEditor';
import PropTypes from 'prop-types';
import { Component } from 'react';
import settings from 'settings';

import AvenueFeedListingBlock from '../TemplateBlockEditors/AvenueFeedListingBlockEditor';
import VivaEngageEmbedBlockEditor from '../TemplateBlockEditors/VivaEngageEmbedBlockEditor';
import ContentLoader from './components/ContentLoader';
import TemplateSelector from './components/TemplateSelector';
import { getFields } from './portalTemplateFields';

const portalBlockEditorTemplates = {
  heroblock: <HeroBlockEditor />,
  bodyblock: <BodyBlockEditor />,
  dividerblock: <DividerBlockEditor />,
  customblock: <CustomBlockEditor />,
  'article-list-block': <ArticleListBlockEditor />,
  gradientblock: <GradientBlockEditor />,
  'events-list-block': <EventsListBlockEditor />,
  'avenue-feed-listing-block': <AvenueFeedListingBlock />,
  'viva-engage-embed-block': <VivaEngageEmbedBlockEditor />,
};

const isPortalBlockTemplate = (template) =>
  !!portalBlockEditorTemplates[template];

const renderPortalBlockEditor = (template) =>
  portalBlockEditorTemplates[template];

class PortalEdit extends Component {
  constructor(props) {
    super(props);

    const { userContext } = this.props;

    this.state = {
      template: null,
      defaultInformationOwner: {
        name: userContext.displayName,
        oid: userContext.userId,
      },
      topics: [],
      selectedTopic: {
        name: null,
        value: null,
      },
    };
  }

  onAfterCreate = (updatedNode) => {
    const { history } = this.props;
    if (!updatedNode.isBlockType()) {
      history.push(updatedNode.getPath());
    } else {
      history.push(updatedNode.parent.getPath());
    }
  };

  onAfterDelete = (parent) => {
    const { history } = this.props;

    history.push(parent.getPath());
  };

  onAfterCancel = () => {
    const { page, history } = this.props;
    if (page.isBlockType()) {
      history.push(page.getParent().getPath());
    } else {
      history.push(page.getPath());
    }
  };

  onChangeTopic = (selectedTopic) => {
    this.setState({ selectedTopic });
  };

  onSelectTemplate = (template) => {
    this.setState({ template });
  };

  componentDidMount = async () => {
    const categories = await listCategories('insights-categories');
    const children = categories.data.data.attributes.children;
    const topics = children.find(
      (child) => child.attributes.title === 'Topics'
    );

    this.setState({
      topics: topics.attributes.children,
    });

    const insightsDatabaseTopic = this.props.page.get('insightsDatabaseTopic');

    if (
      insightsDatabaseTopic &&
      !this.props.isCreating &&
      !this.props.isCreatingBlock
    ) {
      this.setState({ selectedTopic: insightsDatabaseTopic });
    }
  };

  render() {
    const { location, page, isCreating, isCreatingBlock } = this.props;

    if (!page) {
      return <ContentLoader />;
    }

    const blockTemplates = page.getAllowedChildBlocks() || [];
    const pageTemplates = page.getAllowedChildPages() || [];

    let { template, defaultInformationOwner } = this.state;

    if (!template) {
      if (isCreating) {
        if (isCreatingBlock) {
          if (blockTemplates.length > 1) {
            return (
              <TemplateSelector
                templates={blockTemplates}
                onSelect={this.onSelectTemplate}
              />
            );
          }
          template = blockTemplates[0];
        } else {
          if (pageTemplates.length > 1) {
            return (
              <TemplateSelector
                templates={pageTemplates}
                onSelect={this.onSelectTemplate}
              />
            );
          }
          template = pageTemplates[0];
        }
      } else {
        template = page.get('template');
      }
    }

    // TODO: Min width for hero should be set lower, maybe 480 for the
    // listing and content pages.

    const fields = getFields(template, defaultInformationOwner);

    const showChildPageLinkStyleOption =
      !page.isRoot &&
      template === 'listing' &&
      page.getChildPageCount(true) !== 0;

    const allowTagSelection =
      !page.isRoot &&
      (template === 'listing' || template === 'content' || template === 'item');

    const insightsDatabaseId = settings.portals[1].insightsDatabaseId;

    const showHidePageFromNavigation =
      !page.isRoot && page.isPortalListing() && insightsDatabaseId !== page.id;

    return (
      <CreateOrEdit
        isCreating={isCreating}
        viewableByRole="AvenuePortalEditor"
        pageTitle="Content"
        location={location}
        fields={fields}
        useNewCreateEdit={true}
        ContentLoader={ContentLoader}
        onFetch={page.getEditData}
        onDelete={page.deleteNode}
        onCreate={page.createChildNode}
        onEdit={page.updateNode}
        onAfterCreate={this.onAfterCreate}
        onAfterDelete={this.onAfterDelete}
        onAfterCancel={this.onAfterCancel}
      >
        {isPortalBlockTemplate(template) ? (
          <Container className="m-t-6">
            <Column>
              <Box className="m-t-6 lg-m-t-0">
                {renderPortalBlockEditor(template)}
              </Box>
              <SaveTextMediaBox hideDelete={isCreating} />
            </Column>
          </Container>
        ) : (
          <>
            <Container className="m-t-6 ie-flex-fix">
              <Column>
                <Box className="m-t-6 lg-m-t-0">
                  <Title placeholder="Title (max 90 characters)" />
                  {template !== 'insights-listing' && (
                    <Hero useVideo={false} useAltText={true} minWidth={1050} />
                  )}
                  <div className="info-highlight-wrapper">
                    <div className="max-width info-highlight-wrapper__pre-body">
                      <Preamble placeholder="Preamble" />
                      {template !== 'insights-listing' && (
                        <Body placeholder="Body" />
                      )}
                    </div>
                    {(template === 'content' || template === 'listing') && (
                      <div className="max-width info-highlight-box">
                        <InfoHighlightBox placeholder="Highlight info box" />
                      </div>
                    )}
                  </div>
                  <div className="max-width">
                    <SortIndex />
                    <div className="m-t-3">
                      {showHidePageFromNavigation && (
                        <HidePagesFromNavigation />
                      )}
                    </div>
                    {allowTagSelection && <Tags />}
                    {showChildPageLinkStyleOption && <UseCompactPageListing />}
                  </div>
                </Box>
              </Column>
            </Container>

            <Container>
              <Column>
                {template !== 'insights-listing' && <Documents />}
                <Consumer>
                  {({ updatedArticle }) => {
                    return (
                      <PublishOptions
                        hideDelete={!page.allowDelete() && !isCreating}
                        onCancel={page.getPath()}
                        subject={template}
                        showDraftButton={
                          (isCreating || updatedArticle.isDraft) &&
                          template === 'insights-content'
                        }
                      >
                        {template === 'insights-content' && (
                          <>
                            <SourceLink
                              placeholderName="Source name"
                              placeholderUrl="Source URL"
                            />
                            <TagListEditor
                              attributes={page.attributes}
                              isCreating={isCreating}
                            />
                            <DisableComments />
                            <hr className="m-t-6 m-b-6" />
                            <PublishDate />
                            <hr className="m-t-6 m-b-6" />
                          </>
                        )}
                        {template !== 'insights-content' &&
                          template !== 'innovation-start' &&
                          template !== 'item' && (
                            <PortalTopicSelector
                              data={this.state.topics}
                              selectedTopic={this.state.selectedTopic}
                              onChangeTopic={this.onChangeTopic}
                            />
                          )}
                        <InformationOwner />
                        {template === 'insights-content' && <DisplayOnHome />}
                      </PublishOptions>
                    );
                  }}
                </Consumer>
              </Column>
            </Container>
          </>
        )}
      </CreateOrEdit>
    );
  }
}

PortalEdit.propTypes = {
  page: PropTypes.instanceOf(PortalNode),
  isCreating: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  userContext: PropTypes.object.isRequired,
};

export default withConsumer(UserConsumer, PortalEdit, 'userContext');
