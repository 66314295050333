import * as cmsInnovationApi from 'api/cms-innovation';
import { useSnackbar } from 'context';
import { InnovationRouteEnum } from 'enums';
import { useDeleteDocumentsFolder } from 'hooks';
import { Page } from 'layout';
import { useCallback, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'translations';
import * as formUtils from 'utils/form';

import * as helpers from './helpers';
import { DEFAULT_VALUES, FormValues, WebinarForm } from './WebinarForm';

const WebinarCreate = () => {
  const methods = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    shouldFocusError: true,
    mode: 'all',
  });

  const location = useLocation();
  const history = useHistory();

  const { showSnackbar } = useSnackbar();
  const { deleteDocumentsFolder } = useDeleteDocumentsFolder();
  const { setError, setValue, getValues } = methods;

  const documentTitle = useMemo(
    () => [
      translations.innovationDocumentTitle,
      translations.learning,
      translations.innovationTypeWebinar,
      translations.create,
    ],
    []
  );

  const setFormError = useCallback(
    (message: string) => {
      showSnackbar({
        type: 'error',
        text: message,
      });
      setError('root', {});
    },
    [setError, showSnackbar]
  );

  const beforeSubmit = useCallback(() => {
    const formValues = getValues();
    const trimmedValues = formUtils.trimValues(formValues);
    const fieldNames = Object.keys(formValues) as (keyof FormValues)[];

    for (const fieldName of fieldNames) {
      const formValue = formValues[fieldName];
      const trimmedValue = trimmedValues[fieldName];
      if (trimmedValue !== formValue) {
        setValue(fieldName, trimmedValue, { shouldValidate: true });
      }
    }
  }, [getValues, setValue]);

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload =
          helpers.transformFormValuesToCreateOrEditPayload(formValues);

        const articleId = await cmsInnovationApi.createWebinarArticle(payload);

        showSnackbar({
          type: 'success',
          text: translations.contentCreateSuccess,
        });
        history.push(`${InnovationRouteEnum.Webinar}/${articleId}`);
      } catch {
        setFormError(translations.contentCreateError);
        return;
      }
    },
    [history, setFormError, showSnackbar]
  );

  const handleBack = useCallback(() => {
    const sharepointFolderName = getValues('resources.sharepointFolderName');
    if (sharepointFolderName) {
      deleteDocumentsFolder({
        documentsFolderId: sharepointFolderName,
        showErrorSnackbar: false,
      });
    }
    const canGoBack = Boolean(location.key);
    if (canGoBack) {
      history.goBack();
    } else {
      history.push(InnovationRouteEnum.Learning);
    }
  }, [getValues, location.key, deleteDocumentsFolder, history]);

  return (
    <Page title={documentTitle}>
      <FormProvider {...methods}>
        <WebinarForm
          beforeSubmit={beforeSubmit}
          onSubmit={handleSubmit}
          onBack={handleBack}
        />
      </FormProvider>
    </Page>
  );
};

export { WebinarCreate };
