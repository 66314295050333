import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import * as cmsContentManagementApi from 'api/cms/contentManagement';
import { GetContentManagementBannersResponse } from 'api/cms/contentManagement/types';
import { CmsContentType } from 'api/cms/types';
import { useUser } from 'components/Context/User';
import { TablePagination } from 'componentsNew';
import { PopperMenuItem } from 'componentsNew/PopperMenu';
import { useSnackbar } from 'context';
import { AvenueRouteEnum } from 'enums';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';

import { CreateButton } from '../CreateButton';
import * as helpers from './helpers';
import { ManageBannersActions } from './ManageBannersActions';
import {
  ManageBannersTable,
  ManageBannersTableField,
  ManageBannersTableItem,
  SortOrder,
} from './ManageBannersTable';

const elementId = 'profile-manage-banners';

const INITIAL_SORT = {
  field: ManageBannersTableField.PublishedDate,
  order: SortOrder.Desc,
};
const INITIAL_PAGINATION = {
  page: 1,
  rowsPerPage: 10,
};
const INITIAL_SELECTION = {
  byId: {},
  isAllSelected: false,
  isSomeSelected: false,
};

const ManageBanners = () => {
  const [sort, setSort] = useState<{
    field: ManageBannersTableField;
    order: SortOrder;
  }>(INITIAL_SORT);

  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>(INITIAL_PAGINATION);

  const [selection, setSelection] = useState<{
    byId: Record<string, boolean>;
    isAllSelected: boolean;
    isSomeSelected: boolean;
  }>(INITIAL_SELECTION);

  const [tableContent, setTableContent] = useState<{
    items: ManageBannersTableItem[];
    total: number;
  }>({ items: [], total: 0 });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useUser();
  const { showSnackbar } = useSnackbar();

  const fetchItems = useCallback(
    async (
      sort: { field: ManageBannersTableField; order: SortOrder },
      pagination: { page: number; rowsPerPage: number }
    ) => {
      setIsLoading(true);
      let items: ManageBannersTableItem[] = [];
      let total = 0;

      try {
        const queryParams = helpers.getRequestQueryParams(sort, pagination);
        const response = (await cmsContentManagementApi.getBanners(
          queryParams
        )) as GetContentManagementBannersResponse;

        total = response.data.data.totalRows;
        items = response.data.data.banners.map((banner) => {
          const item: ManageBannersTableItem = {
            id: banner.id,
            title: banner.title,
            type: banner.type,
            publishedDate: banner.publishedDate,
            informationOwner: banner.informationOwner,
            divisions:
              (banner.publishedInDivisions || [])
                .map((division) => division.name)
                .join(', ') || translations.global,
            linkPath:
              banner.type === CmsContentType.NewsletterBanner
                ? `${AvenueRouteEnum.NewsletterBannerEdit}/${banner.id}`
                : banner.type === CmsContentType.HeroBanner
                ? `${AvenueRouteEnum.HeroBannerEdit}/${banner.id}`
                : '',
          };
          return item;
        });
      } catch {
        showSnackbar({
          type: 'error',
          text: translations.manageContentFetchError,
        });
      }
      setIsLoading(false);
      return { items, total };
    },
    [showSnackbar]
  );

  const handleSortChange = useCallback(
    async (newSort: { field: ManageBannersTableField; order: SortOrder }) => {
      setSort(newSort);

      if (tableContent.items.length === tableContent.total) {
        const newTableContent = {
          ...tableContent,
          items: helpers.sortTableItems(
            tableContent.items,
            newSort.field,
            newSort.order
          ),
        };
        setTableContent(newTableContent);
        return;
      }
      const newPagination = { ...pagination, page: 1 };
      const newTableContent = await fetchItems(newSort, newPagination);
      setTableContent(newTableContent);
      setPagination(newPagination);
    },
    [tableContent, pagination, fetchItems]
  );

  const handlePaginationChange = useCallback(
    async (newPagination: { page: number; rowsPerPage: number }) => {
      setPagination(newPagination);
      if (
        pagination.page === 1 &&
        newPagination.page === 1 &&
        newPagination.rowsPerPage < pagination.rowsPerPage
      ) {
        setTableContent({
          ...tableContent,
          items: tableContent.items.slice(0, newPagination.rowsPerPage),
        });
        return;
      }
      const newTableContent = await fetchItems(sort, newPagination);
      setTableContent(newTableContent);
    },
    [fetchItems, tableContent, pagination, sort]
  );

  const handleActionsComplete = useCallback(
    async (result: { message: string; isSuccess: boolean }) => {
      showSnackbar({
        type: result.isSuccess ? 'success' : 'error',
        text: result.message,
      });
      const newTableContent = await fetchItems(sort, pagination);
      setTableContent(newTableContent);
      setSelection(INITIAL_SELECTION);
    },
    [fetchItems, pagination, showSnackbar, sort]
  );

  const handleActionsCancel = useCallback(
    () => setSelection(INITIAL_SELECTION),
    []
  );

  const selectedIds = useMemo(() => {
    return Object.keys(selection.byId).filter((id) =>
      Boolean(selection.byId[id])
    );
  }, [selection.byId]);

  const createButtonItems = useMemo(() => {
    if (!user.roles) {
      return [];
    }
    const items: PopperMenuItem[] = [];
    if (user.roles.includes('AvenueAdmin')) {
      items.push({
        text: translations.newsletterBanner,
        to: AvenueRouteEnum.NewsletterBanner,
      });
    }
    if (user.roles.includes('AvenueSuperuser')) {
      items.push({
        text: translations.heroBanner,
        to: AvenueRouteEnum.HeroBannerCreate,
      });
    }
    return items;
  }, [user.roles]);

  useEffect(() => {
    async function fetchInitialItems() {
      const newTableContent = await fetchItems(
        INITIAL_SORT,
        INITIAL_PAGINATION
      );
      setTableContent(newTableContent);
    }
    fetchInitialItems();
  }, [fetchItems]);

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        backgroundColor: theme.colors.surface.primary,
      })}
    >
      <CreateButton items={createButtonItems} />
      <TransitionGroup>
        {!isLoading && selection.isSomeSelected && (
          <Collapse>
            <ManageBannersActions
              selectedIds={selectedIds}
              onCancel={handleActionsCancel}
              onComplete={handleActionsComplete}
              sx={(theme) => ({
                borderTopLeftRadius: theme.border.radius.md,
                borderTopRightRadius: theme.border.radius.md,
              })}
            />
          </Collapse>
        )}
      </TransitionGroup>
      <ManageBannersTable
        isLoading={isLoading || user.isLoading}
        items={tableContent.items}
        sort={sort}
        selection={selection}
        onSortChange={handleSortChange}
        onSelectionChange={setSelection}
        {...(!selection.isSomeSelected && {
          sx: (theme) => ({
            borderTopLeftRadius: theme.border.radius.md,
            borderTopRightRadius: theme.border.radius.md,
          }),
        })}
      />
      {tableContent.total > INITIAL_PAGINATION.rowsPerPage && (
        <TablePagination
          disabled={isLoading || user.isLoading}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          count={
            tableContent.total % pagination.rowsPerPage > 0
              ? Math.trunc(tableContent.total / pagination.rowsPerPage) + 1
              : tableContent.total / pagination.rowsPerPage
          }
          onPageChange={(value: number) =>
            handlePaginationChange({
              rowsPerPage: pagination.rowsPerPage,
              page: value,
            })
          }
          onRowsPerPageChange={(value: number) =>
            handlePaginationChange({
              rowsPerPage: value,
              page: 1,
            })
          }
        />
      )}
    </Stack>
  );
};

export { ManageBanners };
