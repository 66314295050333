import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

type RichTextStylesProps = {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
};

const RichTextStyles = ({ sx, children }: RichTextStylesProps) => {
  return (
    <Box
      sx={[
        (theme) => ({
          '.rich-text-readonly': {},

          '.rich-text-editable': {
            minHeight: '20rem',
            padding: '1rem 1.25rem',
            border: `1px dashed ${theme.colors.border.input}`,
            backgroundColor: theme.colors.surface.secondary,

            '.ProseMirror-selectednode': {
              outline: `1px solid ${theme.colors.border.focus}`,
              outlineOffset: '0.5rem',
              borderRadius: theme.border.radius.xs,
            },

            '&.rich-text-error': {
              border: `1px solid ${theme.colors.border.critical}`,
            },
          },

          '.rich-text': {
            borderRadius: theme.border.radius.md,
            color: theme.colors.text.primary,
            wordBreak: 'break-word',

            '& a': {
              color: theme.colors.text.actionDefault,
              textDecorationColor: theme.colors.text.actionDefault,
              '&:hover': {
                color: theme.colors.text.actionHover,
              },
            },

            '& blockquote': {
              margin: 0,
              marginBottom: theme.spacing('sm'),
              padding: theme.spacing('sm'),
              fontSize: theme.typography.h4.fontSize,
              backgroundColor: `${theme.colors.surface.informative}`,
              borderLeft: `${theme.border.thickness[4]} solid ${theme.colors.icon.brandBase}`,
              '& p': {
                fontSize: theme.typography.h4.fontSize,
              },
              '& p:last-of-type': {
                marginBottom: 0,
              },
            },

            '& p': {
              margin: 0,
              padding: 0,
              ...theme.typography.body1,
              marginBottom: theme.spacing('sm'),
              lineHeight: 1.75,
            },

            '& p.is-editor-empty:first-of-type::before': {
              content: 'attr(data-placeholder)',
              pointerEvents: 'none',
              color: theme.colors.text.tertiary,
              float: 'left',
              height: 0,
            },

            '& h2': {
              margin: 0,
              padding: 0,
              ...theme.typography.h2,
              marginBottom: theme.spacing('sm'),
            },

            '& mark': {
              backgroundColor: `${theme.colors.surface.warningDark}AA`,
            },

            '& ol': {
              '& p': { marginBottom: theme.spacing('xxs') },
            },

            '& ul': {
              '& p': { marginBottom: theme.spacing('xxs') },
            },
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};

export { RichTextStyles };
