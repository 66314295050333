import settings from 'settings';

const INSIGHTS_DATABASE_LINK_URL = `${settings.portals[1].homePath}/${settings.portals[1].insightsDatabaseId}`;

const getQueryForLatestInsightsDatabaseArticles = () => {
  const requestDetails = {
    sort: '-published',
    limit: '3',
    filter: {
      displayOnHome: true,
    },
  };
  const searchString = `/search?sort=${requestDetails.sort}&page[limit]=${requestDetails.limit}&filter[filtertypes]=[portalarticle]&filter[portaltypes]=[innovation]&filter[template]=insights-content&filter[displayonhome]=${requestDetails.filter.displayOnHome}`;
  return searchString;
};

export {
  getQueryForLatestInsightsDatabaseArticles,
  INSIGHTS_DATABASE_LINK_URL,
};
