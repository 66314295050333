import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import OnlyWithRole from 'components/OnlyWithRole';
import { Radio, SegmentedControl } from 'componentsNew';
import { useCallback } from 'react';
import { translations } from 'translations';
import { GAonChangeManageLibraryFilter } from 'utils/analytics';

const elementId = 'profile-manage-library-filter';

export enum ArticleStatus {
  Published = 'Published',
  Archive = 'Archive',
  Drafts = 'Drafts',
  Ownerless = 'Ownerless',
}

type ManageLibraryFilterProps = {
  isLoading: boolean;
  filter: {
    isAdmin: boolean;
    articleStatus: ArticleStatus;
  };
  onFilterChange: (filter: {
    isAdmin: boolean;
    articleStatus: ArticleStatus;
  }) => void;
};

const ManageLibraryFilter = ({
  isLoading,
  filter,
  onFilterChange,
}: ManageLibraryFilterProps) => {
  const onAdminChange = useCallback(
    (value: boolean) => {
      const newFilter = {
        ...filter,
        isAdmin: value,
        articleStatus:
          filter.isAdmin && filter.articleStatus === ArticleStatus.Ownerless
            ? ArticleStatus.Published
            : filter.articleStatus,
      };
      onFilterChange(newFilter);
      GAonChangeManageLibraryFilter(
        newFilter.isAdmin
          ? `${newFilter.articleStatus}, Admin`
          : `${newFilter.articleStatus}, Non-Admin`
      );
    },
    [filter, onFilterChange]
  );

  const onArticleStatusChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFilter = {
        ...filter,
        articleStatus: e.target.value as ArticleStatus,
      };
      onFilterChange(newFilter);
      GAonChangeManageLibraryFilter(
        newFilter.isAdmin
          ? `${newFilter.articleStatus}, Admin`
          : `${newFilter.articleStatus}, Non-Admin`
      );
    },
    [filter, onFilterChange]
  );

  return (
    <Stack
      sx={(theme) => ({
        gap: theme.spacing('sm'),
        padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
      })}
    >
      <OnlyWithRole viewableByRole="AvenueSuperuser">
        <SegmentedControl
          id={`${elementId}-admin`}
          value={filter.isAdmin}
          disabled={isLoading}
          isDeselectable={false}
          sx={{ alignSelf: 'flex-end' }}
          size="small"
          items={[
            {
              name: translations.manageContentFilterMyContent,
              value: false,
            },
            {
              name: translations.manageContentFilterAllContent,
              value: true,
            },
          ]}
          onChange={onAdminChange}
        />
        <Divider />
      </OnlyWithRole>
      <RadioGroup
        value={filter.articleStatus}
        sx={(theme) => ({
          flexDirection: 'row',
          gap: theme.spacing('xs'),
          margin: `${theme.spacing('xxxs')} 0`,
        })}
        onChange={onArticleStatusChange}
      >
        <FormControlLabel
          label={translations.manageContentPublished}
          value={ArticleStatus.Published}
          control={<Radio id={`${elementId}-published`} disabled={isLoading} />}
        />
        <FormControlLabel
          label={translations.manageContentArchived}
          value={ArticleStatus.Archive}
          control={<Radio id={`${elementId}-archive`} disabled={isLoading} />}
        />
        <FormControlLabel
          label={translations.manageContentDrafts}
          value={ArticleStatus.Drafts}
          control={<Radio id={`${elementId}-drafts`} disabled={isLoading} />}
        />
        {filter.isAdmin && (
          <FormControlLabel
            label={translations.manageContentOwnerless}
            value={ArticleStatus.Ownerless}
            control={
              <Radio id={`${elementId}-ownerless`} disabled={isLoading} />
            }
          />
        )}
      </RadioGroup>
    </Stack>
  );
};

export { ManageLibraryFilter };
