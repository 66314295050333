import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { Checkbox, Icon } from 'componentsNew';

export type AccordionWithCheckboxesItem = {
  name: string;
  value: string;
};

type AccordionWithCheckboxesProps = {
  elementId: string;
  title: string;
  value: string[];
  items: AccordionWithCheckboxesItem[];
  defaultExpanded?: AccordionProps['defaultExpanded'];
  disableExpandAndCollapse?: boolean;
  onChange: (item: AccordionWithCheckboxesItem, selected: boolean) => void;
};

const AccordionWithCheckboxes = ({
  elementId,
  title,
  value,
  items,
  defaultExpanded,
  disableExpandAndCollapse,
  onChange,
}: AccordionWithCheckboxesProps) => {
  return (
    <Accordion
      id={elementId}
      defaultExpanded={defaultExpanded}
      disableGutters
      elevation={0}
      sx={() => ({
        '&.Mui-expanded': { margin: 0 },
        '&:before': { display: 'none' },
      })}
    >
      <AccordionSummary
        id={`${elementId}-title`}
        aria-controls={`${elementId}-items`}
        expandIcon={<Icon type="chevronDown" color="brandBase" size={20} />}
        {...(disableExpandAndCollapse && {
          role: 'presentation',
          tabIndex: -1,
        })}
        sx={() => ({
          padding: 0,
          ...(disableExpandAndCollapse && {
            pointerEvents: 'none',
            '.MuiAccordionSummary-expandIconWrapper': { display: 'none' },
          }),
        })}
      >
        <Typography
          variant="body1"
          sx={(theme) => ({
            color: theme.colors.text.brand,
            fontWeight: theme.typography.fontWeightBold,
          })}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        id={`${elementId}-items`}
        sx={(theme) => ({ padding: 0, marginBottom: theme.spacing('sm') })}
      >
        <FormGroup>
          {items.map((item, index) => (
            <FormControlLabel
              id={`${elementId}-items-${index}`}
              key={`${elementId}-items-${index}`}
              label={item.name}
              control={
                <Checkbox
                  checked={value.some((v) => v === item.value)}
                  onChange={(
                    _e: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => onChange(item, checked)}
                />
              }
            />
          ))}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export { AccordionWithCheckboxes };
