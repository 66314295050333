import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import notFoundImage from 'assets/images/not-found.png';

type JobsEmptyProps = {
  children: string;
};
const JobsEmpty = ({ children }: JobsEmptyProps) => (
  <Stack alignItems="center" marginTop="3rem" marginBottom="6rem">
    <Box component="img" src={notFoundImage} maxWidth="20rem" />
    <Typography>{children}</Typography>
  </Stack>
);

export { JobsEmpty };
