import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { ExternalPerson } from 'componentsNew/PersonsInput/ExternalPersonsInput';
import { InternalPerson } from 'componentsNew/PersonsInput/InternalPersonsInput';
import { TransitionGroup } from 'react-transition-group';

import { ProfileMini } from './ProfileMini';
import { ProfileMiniView } from './ProfileMiniView';

type ProfileMiniListProps = {
  elementId: string;
  sx?: SxProps<Theme>;
  title?: string;
  internalPersons?: InternalPerson[];
  externalPersons?: ExternalPerson[];
  onInternalPersonClick?: (person: InternalPerson) => void;
  onExternalPersonClick?: (person: ExternalPerson) => void;
};

const ProfileMiniList = ({
  elementId,
  sx,
  title,
  internalPersons = [],
  externalPersons = [],
  onInternalPersonClick,
  onExternalPersonClick,
}: ProfileMiniListProps) => {
  if (!internalPersons.length && !externalPersons.length) {
    return null;
  }

  return (
    <Stack
      sx={[
        (theme) => ({ gap: theme.spacing('xs') }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {title && (
        <Typography
          variant="body2"
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
        >
          {title}
        </Typography>
      )}
      <Box
        sx={(theme) => ({
          '> div': {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing('xs'),
          },
        })}
      >
        <TransitionGroup>
          {internalPersons.map((person, index) => (
            <Collapse key={`${elementId}-internal-persons${index}`}>
              {person.name && person.email ? (
                <ProfileMiniView
                  elementId={`${elementId}-internal-persons-${index}`}
                  to={`/people/${person.id}`}
                  target="_blank"
                  name={person.name}
                  title={person.title}
                  imageUrl={person.imageUrl}
                  onClick={() =>
                    onInternalPersonClick && onInternalPersonClick(person)
                  }
                />
              ) : (
                <ProfileMini
                  elementId={`${elementId}-internal-persons-${index}`}
                  target="_blank"
                  profileId={person.id}
                  profileName={person.name}
                  onClick={() =>
                    onInternalPersonClick && onInternalPersonClick(person)
                  }
                />
              )}
            </Collapse>
          ))}
          {externalPersons.map((person, index) => (
            <Collapse key={`${elementId}-external-persons-${index}`}>
              <ProfileMiniView
                elementId={`${elementId}-external-persons-${index}`}
                target="_blank"
                name={person.name}
                title={person.title}
                onClick={() =>
                  onExternalPersonClick && onExternalPersonClick(person)
                }
              />
            </Collapse>
          ))}
        </TransitionGroup>
      </Box>
    </Stack>
  );
};

export { ProfileMiniList };
