import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';
import * as timeUtils from 'utils/misc/time';

type LastModifiedDateAndVersionProps = {
  version?: string;
  lastModifiedDate: string;
};

const LastModifiedDateAndVersion = ({
  version,
  lastModifiedDate,
}: LastModifiedDateAndVersionProps) => {
  const formattedLastModifiedDate = useMemo(() => {
    const dateStr = timeUtils.getDateOrDaysAgo(lastModifiedDate);
    return textUtils.capitalizeFirst(dateStr);
  }, [lastModifiedDate]);

  return (
    <Stack
      sx={(theme) => ({
        width: '100%',
        gap: theme.spacing('xxs'),
      })}
    >
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing('xs'),
        })}
      >
        <Typography
          variant="body1"
          sx={(theme) => ({
            color: theme.colors.text.tertiary,
            width: '7.5rem',
            flexShrink: '0',
          })}
        >
          {translations.updated}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="bold"
          sx={(theme) => ({
            color: theme.colors.text.secondary,
          })}
        >
          {formattedLastModifiedDate}
        </Typography>
      </Stack>
      {version && (
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            alignItems: 'center',
            gap: theme.spacing('xs'),
          })}
        >
          <Typography
            variant="body1"
            sx={(theme) => ({
              color: theme.colors.text.tertiary,
              width: '7.5rem',
              flexShrink: '0',
            })}
          >
            {translations.version}
          </Typography>
          <Typography
            variant="body2"
            fontWeight="bold"
            sx={(theme) => ({
              color: theme.colors.text.secondary,
            })}
          >
            {version}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export { LastModifiedDateAndVersion };
