import './PortalSwitch.scss';

import { ReactComponent as AvenueLogo } from 'components/Icon/AvenueLogoBlue.svg';
import { ReactComponent as AvenueLogoWhite } from 'components/Icon/AvenueLogoWhite.svg';
import { ReactComponent as Polygon } from 'components/Icon/Polygon.svg';
import OnlyWithRole from 'components/OnlyWithRole';
import { InnovationRouteEnum } from 'enums';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import settings from 'settings';
import { translations } from 'translations';
import isProduction from 'utils/misc/isProduction';

import AppSwitchItem from './AppSwitchItem/AppSwitchItem';

const { portals } = settings;

const PRODUCT_INNOVATION_SWITCH_OPTION = {
  id: 'product-innovation',
  name: translations.innovationDocumentTitle,
  url: InnovationRouteEnum.Home,
  homePath: InnovationRouteEnum.Home,
  roleRestriction: { isRestricted: false, role: '' },
};

const switchOptions = [
  {
    id: 'avenue-home-page',
    name: 'Avenue',
    url: '/',
    homePath: '/',
    icon: <AvenueLogo />,
    selectedIcon: <AvenueLogoWhite />,
    roleRestriction: { isRestricted: false, role: '' },
  },
  ...portals,
];

if (!isProduction()) {
  switchOptions.push(PRODUCT_INNOVATION_SWITCH_OPTION);
}

const avenueObj = switchOptions[0];

class PortalSwitch extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.location.pathname !== this.props.location.pathname ||
      nextProps.isInsightsDatabasePage !== this.props.isInsightsDatabasePage
    );
  }

  render() {
    const { location, isInsightsDatabasePage } = this.props;
    let currentPortal = switchOptions.find((item) =>
      item.url !== '/'
        ? location.pathname.includes(item.url) &&
          !location.pathname.includes('/find') &&
          !location.pathname.includes('/group-library') &&
          !location.pathname.includes('/library')
        : false
    );

    if (
      !isProduction() &&
      (location.pathname.includes(InnovationRouteEnum.Home) ||
        isInsightsDatabasePage)
    ) {
      currentPortal = PRODUCT_INNOVATION_SWITCH_OPTION;
    }

    const selectedOption = currentPortal || avenueObj;
    return (
      <div className="app-switch-wrapper">
        <ul className="app-switch">
          {switchOptions.map((item, index) => {
            return item.roleRestriction.isRestricted ? (
              <OnlyWithRole viewableByRole={item.roleRestriction.role}>
                <li key={item.id}>
                  {index === 0 && (
                    <AvenueLogo className="navigationitem__link--homeicon" />
                  )}
                  <AppSwitchItem
                    item={item}
                    isSelected={selectedOption.id === item.id}
                  />
                  {selectedOption.id === item.id ? (
                    <div className="app-switch__polygon">
                      <Polygon width={10} height={8} />
                    </div>
                  ) : null}
                </li>
              </OnlyWithRole>
            ) : (
              <li key={item.id}>
                {index === 0 && (
                  <AvenueLogo className="navigationitem__link--homeicon" />
                )}
                <AppSwitchItem
                  item={item}
                  isSelected={selectedOption.id === item.id}
                />
                {selectedOption.id === item.id &&
                selectedOption.id !== 'product-innovation' ? (
                  <div className="app-switch__polygon">
                    <Polygon width={10} height={8} />
                  </div>
                ) : null}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default withRouter(PortalSwitch);
