import settings from 'settings';

export type ImageSize = 'sm' | 'md' | 'lg' | 'xl';
type CMSImageSize = 300 | 450 | 480 | 610 | 768 | 1000;

const CMS_LANDSCAPE_IMAGE_WIDTH: Record<ImageSize, CMSImageSize> = {
  sm: 300,
  md: 480,
  lg: 768,
  xl: 1000,
};

const CMS_PORTRAIT_IMAGE_HEIGHT: Record<ImageSize, CMSImageSize> = {
  sm: 450,
  md: 450,
  lg: 610,
  xl: 610,
};

const { cms: cmsApiPath, cdn: cdnApiPath } = settings.api;
const { cdnTokenKey } = settings.misc;

const apiBasePath = cdnApiPath
  ? cdnApiPath
  : cmsApiPath
  ? cmsApiPath.replace('/api', '')
  : undefined;

const getFormattedArticleImageSrc = (
  srcOrId: string,
  options?: {
    isPortrait: boolean;
    imageSize: ImageSize;
  }
) => {
  const isLocalImage = /^\/static\/media\//.test(srcOrId);
  if (isLocalImage) {
    return srcOrId;
  }

  const isData = /^(data:image)/.test(srcOrId);
  const isBlob = /^(blob:)/.test(srcOrId);
  if (isData || isBlob) {
    return srcOrId;
  }

  let imageSrc = /^(https?:\/\/)/.test(srcOrId)
    ? srcOrId
    : `${apiBasePath}${srcOrId}`;

  const isCmsImage = /\/static\/node\//.test(srcOrId);

  if (!isCmsImage) {
    return imageSrc;
  }

  if (options) {
    const { isPortrait, imageSize } = options;
    const cmsImageSize = isPortrait
      ? CMS_PORTRAIT_IMAGE_HEIGHT[imageSize]
      : CMS_LANDSCAPE_IMAGE_WIDTH[imageSize];
    imageSrc = `${imageSrc}/${cmsImageSize}`;
  }

  if (cdnApiPath && cdnTokenKey) {
    imageSrc = `${imageSrc}?${cdnTokenKey}`;
  }
  return imageSrc;
};

const isVideoImageUrl = (src: string) => {
  return (
    src.indexOf('youtube.com') > -1 ||
    src.indexOf('youtu.be') > -1 ||
    src.indexOf('vimeo.com') > -1 ||
    src.indexOf('vimeocdn.com') > -1 ||
    src.indexOf('creo') > -1 ||
    src.indexOf('cloudfront') > -1 ||
    src.indexOf('microsoftstream.com') > -1 ||
    src.indexOf('cvm3.se') > -1 ||
    src.indexOf('assaabloy-internal.creomediamanager.com') > -1 ||
    src.indexOf('assaabloyeur-my.sharepoint.com') > -1 ||
    src.indexOf('assaabloy-my.sharepoint.com') > -1 ||
    src.indexOf('assaabloyeur.sharepoint.com') > -1
  );
};

export {
  CMS_LANDSCAPE_IMAGE_WIDTH,
  CMS_PORTRAIT_IMAGE_HEIGHT,
  getFormattedArticleImageSrc,
  isVideoImageUrl,
};
