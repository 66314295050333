import { useCurrentEditor } from '@tiptap/react';
import {
  EmbedVideoModal,
  EmbedVideoValue,
} from 'componentsNew/MediaInput/EmbedInput/EmbedVideoModal';
import { useCallback } from 'react';

const elementId = 'rich-text-embed-video-action';

type EmbedVideoActionProps = {
  onDone: () => void;
};

const EmbedVideoAction = ({ onDone }: EmbedVideoActionProps) => {
  const { editor } = useCurrentEditor();

  const handleSubmit = useCallback(
    (value: EmbedVideoValue) => {
      if (!editor) {
        return;
      }
      editor.chain().focus().setIframe({ src: value.url }).run();
      onDone();
    },
    [editor, onDone]
  );

  return (
    <EmbedVideoModal
      elementId={`${elementId}-modal`}
      disableRestoreFocus
      onSubmit={handleSubmit}
      onClose={onDone}
    />
  );
};

export { EmbedVideoAction };
