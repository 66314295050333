import './PageHeader.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { getNotificationCenterPermission } from 'api/cms/admin';
import classNames from 'classnames';
import { UserContext } from 'components/Context/User';
import { Column, Container } from 'components/Grid';
import Hamburger from 'components/Hamburger';
import { ReactComponent as Cancel } from 'components/Icon/Cancel.svg';
import { ReactComponent as SearchIconLightBlue } from 'components/Icon/SearchIconLightBlue.svg';
import Modal from 'components/Modal';
import AppSwitch from 'components/PageHeader/AppSwitch';
import PortalSwitch from 'components/PageHeader/PortalSwitch/PortalSwitch';
import Resize from 'components/Resize';
import Search from 'components/Search';
import { AIChatButton } from 'layout/AppHeader/AIChat/AIChatButton';
import { Alerts } from 'layout/AppHeader/Alerts';
import { cloneElement, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GAonSearchOpen } from 'utils/analytics';

import { TopHeaderLogo } from '../../layout/AppHeader/TopHeaderLogo';
import Notifications from './Notifications';
import NotificationButton from './Notifications/NotificationButton';
import UserMenu from './UserMenu';

interface PageHeaderProps extends RouteComponentProps {
  children?: React.ReactElement;
  signedIn: boolean;
  showBlueBackground?: boolean;
  hideHamburger?: boolean;
  isInsightsDatabasePage?: boolean;
}

const PageHeader = (props: PageHeaderProps) => {
  const {
    children,
    signedIn,
    showBlueBackground,
    hideHamburger,
    location,
    history,
    isInsightsDatabasePage,
  } = props;

  const userContext = useContext(UserContext);
  const userId = userContext?.userId;

  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);
  const [navigationOpen, setNavigationOpen] = useState<boolean>(false);
  const [notificationIsOpen, setNotificationIsOpen] = useState<boolean>(false);
  const [newNotifications, setNewNotifications] = useState<boolean>(false);
  const [hasNotificationPermission, setHasNotificationPermission] =
    useState<boolean>(false);

  const toggleSearch = () => setSearchIsOpen(!searchIsOpen);
  const setShowNotification = (state: any) => setNotificationIsOpen(state);
  const closeSearch = () => setSearchIsOpen(false);
  const toggleNavigation = () => setNavigationOpen(!navigationOpen);
  const closeNavigation = () => setNavigationOpen(false);

  let navButton: any;

  useEffect(() => {
    closeNavigation();
  }, [location]);

  useEffect(() => {
    if (!userId) return;
    getNotificationCenterPermission(userId).then((permission) => {
      setHasNotificationPermission(permission.hasNotificationCenter);
    });
  }, [userId]);

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 27) {
      navButton.focus();
      toggleNavigation();
      closeNavigation();
    }
  };

  const navigationClassName = classNames('pageheader__navigation', {
    'pageheader__navigation--open': navigationOpen,
    'pageheader__navigation--blue-background': showBlueBackground,
  });

  const htmlEl = document.querySelector('html');
  if (htmlEl) {
    if (navigationOpen) {
      htmlEl.classList.add('pageheader__navigation--open');
    } else {
      htmlEl.classList.remove('pageheader__navigation--open');
    }
  }

  const childElement = children
    ? cloneElement(children, { navigationOpen })
    : null;

  return (
    <>
      <div>
        {signedIn && !searchIsOpen && <Alerts />}
        <Resize>
          {({ width }: { width: number }) => (
            <header
              id="avenue-header"
              className="pageheader pageheader--desktop"
              onKeyDown={handleKeyDown}
            >
              <TopHeaderLogo />
              <Container
                width="full"
                type="space-between"
                className="new-design-page-spacing"
              >
                <>
                  <Column>
                    <section>
                      <PortalSwitch
                        isInsightsDatabasePage={isInsightsDatabasePage}
                      />
                    </section>
                  </Column>
                  {signedIn ? (
                    <Column>
                      <section className="pageheader__right-section">
                        {width > 950 ? (
                          <>
                            {closeSearch()}
                            <Search
                              onSubmit={closeSearch}
                              history={props.history}
                              showSuggestionFilters={false}
                              resetQueryOnSubmit={true}
                            />
                          </>
                        ) : (
                          <>
                            {searchIsOpen ? (
                              <Cancel onClick={toggleSearch} />
                            ) : (
                              <SearchIconLightBlue
                                onClick={() => {
                                  GAonSearchOpen();
                                  toggleSearch();
                                }}
                                aria-haspopup="true"
                                aria-label="Toggle search"
                              />
                            )}
                          </>
                        )}
                        <AIChatButton />
                        {hasNotificationPermission ? (
                          <NotificationButton
                            onClick={() =>
                              setShowNotification(!notificationIsOpen)
                            }
                            newNotification={newNotifications}
                          />
                        ) : null}
                        {!hideHamburger && (
                          <Hamburger
                            onClick={toggleNavigation}
                            label="Toggle navigation"
                            isOpen={navigationOpen}
                            ref={(n) => {
                              navButton = n;
                            }}
                          />
                        )}
                        <UserMenu />
                      </section>
                    </Column>
                  ) : (
                    <Column>
                      {!hideHamburger && (
                        <Hamburger
                          onClick={toggleNavigation}
                          label="Toggle navigation"
                          isOpen={navigationOpen}
                          ref={(n) => {
                            navButton = n;
                          }}
                        />
                      )}
                    </Column>
                  )}
                </>
              </Container>
              {hasNotificationPermission && (
                <Notifications
                  show={notificationIsOpen}
                  setShow={setShowNotification}
                  setNewNotificationState={setNewNotifications}
                />
              )}
              {childElement && width > 768 && (
                <nav className={navigationClassName}>{childElement}</nav>
              )}
            </header>
          )}
        </Resize>
      </div>
      <div>
        <Resize maxWidth={768}>
          {({ width }: { width: number }) => (
            <header
              id="avenue-header"
              className="pageheader pageheader--mobile"
              onKeyDown={handleKeyDown}
            >
              <Container width="full" type="space-between" mobileRow={true}>
                <>
                  <Column>
                    {!hideHamburger && (
                      <Hamburger
                        onClick={toggleNavigation}
                        label="Toggle navigation"
                        isOpen={navigationOpen}
                        ref={(n) => {
                          navButton = n;
                        }}
                      />
                    )}
                    {signedIn && <UserMenu />}
                  </Column>
                  <Column>
                    <AppSwitch
                      isNavOpen={navigationOpen}
                      isInsightsDatabasePage={isInsightsDatabasePage}
                    />
                  </Column>
                  {signedIn ? (
                    <Column>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                          marginTop: '0.25rem',
                        }}
                      >
                        {searchIsOpen ? (
                          <Cancel onClick={toggleSearch} />
                        ) : (
                          <SearchIconLightBlue
                            onClick={() => {
                              GAonSearchOpen();
                              toggleSearch();
                            }}
                            aria-haspopup="true"
                            aria-label="Toggle search"
                          />
                        )}
                        {searchIsOpen && (
                          <Modal
                            onClose={closeSearch}
                            size="full"
                            blur={true}
                            showMenu={true}
                            isMobileSearch={true}
                          >
                            <Search
                              onSubmit={closeSearch}
                              history={history}
                              showSuggestionFilters={true}
                              resetQueryOnSubmit={true}
                              toggleSearch={toggleSearch}
                            />
                          </Modal>
                        )}
                        <AIChatButton />
                        {hasNotificationPermission ? (
                          <NotificationButton
                            onClick={() =>
                              setShowNotification(!notificationIsOpen)
                            }
                            newNotification={newNotifications}
                          />
                        ) : null}
                      </div>
                    </Column>
                  ) : (
                    <Column />
                  )}
                </>
              </Container>
              {hasNotificationPermission && (
                <Notifications
                  show={notificationIsOpen}
                  setShow={setShowNotification}
                  setNewNotificationState={setNewNotifications}
                />
              )}
              {childElement && width <= 768 && (
                <ClickAwayListener
                  onClickAway={({ target }: any) => {
                    if (
                      target === navButton ||
                      target.parentElement === navButton ||
                      (target.classList &&
                        (target.classList.contains('app-switch-select__text') ||
                          target.classList.contains(
                            'navigation__items-wrapper'
                          )))
                    ) {
                      return;
                    }
                    closeNavigation();
                  }}
                >
                  <nav className={navigationClassName}>{childElement}</nav>
                </ClickAwayListener>
              )}
            </header>
          )}
        </Resize>
      </div>
    </>
  );
};

export default withRouter(PageHeader);
