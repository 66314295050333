import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as organizationApi from 'api/organization';
import { Shortcut, ShortcutTypeName } from 'api/organization/types';
import { AlertBar, Icon, LinkList } from 'componentsNew';
import { PageContentCard, PageContentHeader } from 'layout';
import { ShortcutsSheet } from 'pagesAvenue/Home/Shortcuts/ShortcutsSheet';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'translations';
import {
  GAonShortcutLinkClick,
  GAonShortcutManageClick,
} from 'utils/analytics';
import { loadData } from 'utils/api/handleAPIData';

import { ShortcutsSkeleton } from './ShortcutsSkeleton';

const LABELS: Record<ShortcutTypeName, { title: string; empty: string }> = {
  [ShortcutTypeName.Tools]: {
    title: translations.shortcutsPinnedTools,
    empty: translations.shortcutsPinnedToolsEmpty,
  },
  [ShortcutTypeName.MyProjects]: {
    title: '',
    empty: '',
  },
};

type ShortcutsProps = {
  shortcutType: ShortcutTypeName;
};

const Shortcuts = ({ shortcutType }: ShortcutsProps) => {
  const [shortcuts, setShortcuts] = useState<Shortcut[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const elementId = `home-shortcuts-${shortcutType.toLowerCase()}`;

  const visibleShortcuts = useMemo(() => {
    return shortcuts
      .filter(
        (shortcut) =>
          shortcutType === shortcut.shortcutType.displayName &&
          shortcut.addedAsFavorite
      )
      .slice(0, 10);
  }, [shortcutType, shortcuts]);

  const fetchShortcuts = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await loadData({
        apiCall: organizationApi.getShortcuts,
        reducer: undefined,
        headers: undefined,
        param: undefined,
        createLoading: undefined,
        dataState: undefined,
        useModelFactory: undefined,
      }).promise;
      const shortcuts = response.response as Shortcut[];
      setShortcuts(shortcuts);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateShortcutFavorite = useCallback(
    async (id: string, addedAsFavorite: boolean) => {
      const updatedShortcuts = shortcuts.map((shortcut) => {
        if (shortcut.id === id) {
          shortcut.addedAsFavorite = addedAsFavorite;
        }
        return shortcut;
      });
      setShortcuts(updatedShortcuts);

      await organizationApi
        .setShortcuts(
          updatedShortcuts
            .filter((shortcut) => shortcut.addedAsFavorite)
            .map((shortcut) => ({
              attributes: {
                shortcutTitle: shortcut.title,
              },
            }))
        )
        .then((response) => {
          if (response.status !== 200) {
            setShortcuts(shortcuts);
          }
        });
    },
    [shortcuts]
  );

  useEffect(() => {
    fetchShortcuts();
  }, [fetchShortcuts]);

  if (isLoading) {
    return (
      <PageContentCard>
        <PageContentHeader text={LABELS[shortcutType].title} />
        <ShortcutsSkeleton />
      </PageContentCard>
    );
  }

  if (isError) {
    return (
      <PageContentCard>
        <PageContentHeader text={LABELS[shortcutType].title} />
        <Typography variant="body2" marginBottom={1}>
          {translations.notAvailableLong}
        </Typography>
      </PageContentCard>
    );
  }

  return (
    <PageContentCard>
      <PageContentHeader text={LABELS[shortcutType].title} />
      {visibleShortcuts.length > 0 ? (
        <LinkList
          id={elementId}
          items={visibleShortcuts.map((visibleShortcut) => ({
            id: visibleShortcut.id,
            title: visibleShortcut.title,
            tooltip: visibleShortcut.description,
            divider: true,
            href: visibleShortcut.url,
            target:
              visibleShortcut.openInNewWindow === 'true' ? '_blank' : '_self',
            endElement: <Icon type="pin" color="secondary" />,
          }))}
          onClick={(item) => {
            const clickedShortcut = visibleShortcuts.find(
              (shortcut) => shortcut.id === item.id
            );
            if (clickedShortcut) {
              GAonShortcutLinkClick(
                clickedShortcut.shortcutType.displayName,
                clickedShortcut.title
              );
            }
          }}
        />
      ) : (
        <AlertBar
          open={true}
          type="information"
          text={LABELS[shortcutType].empty}
          sx={(theme) => ({ marginTop: theme.spacing('sm') })}
        />
      )}
      <Button
        id={`${elementId}-read-more`}
        variant="linkButton"
        sx={(theme) => ({ float: 'right', marginTop: theme.spacing('sm') })}
        onClick={() => {
          if (!open) GAonShortcutManageClick(`Manage ${shortcutType}`);
          setOpen(!open);
        }}
      >
        {translations.shortcutsManage}
      </Button>
      <ShortcutsSheet
        shortcuts={shortcuts}
        shortcutType={shortcutType}
        maxNumberOfFavorites={10}
        updateShortcutFavorite={updateShortcutFavorite}
        open={open}
        setOpen={setOpen}
      />
    </PageContentCard>
  );
};

export { Shortcuts };
