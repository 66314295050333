import './NavigationWrapper.scss';

import PortalNode from 'api/models/PortalNode';
import MainMenu from 'components/Portal/MainMenu';
import MobileMenu from 'components/Portal/MobileMenu';
import { NavigationDesktop, NavigationMobile } from 'pagesInnovation/common';
import PropTypes from 'prop-types';
import isProduction from 'utils/misc/isProduction';

const NavigationWrapper = ({
  page,
  navigationOpen,
  isInsightsDatabasePage,
}) => {
  const selectionPath = page ? page.getParentList() : [];

  return (
    <>
      <div className="navigation-wrapper--desktop">
        {!isProduction() && isInsightsDatabasePage ? (
          <NavigationDesktop />
        ) : (
          <MainMenu page={page} selectionPath={selectionPath} />
        )}
      </div>
      {navigationOpen && (
        <div className="navigation-wrapper--mobile">
          {!isProduction() && isInsightsDatabasePage ? (
            <NavigationMobile navigationOpen />
          ) : (
            <MobileMenu page={page} selectionPath={selectionPath} />
          )}
        </div>
      )}
    </>
  );
};

MainMenu.propTypes = {
  page: PropTypes.instanceOf(PortalNode),
  navigationOpen: PropTypes.bool, // comes from wrapping page header
};

export default NavigationWrapper;
