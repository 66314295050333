import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'componentsNew';
import { translations } from 'translations';

type JobContentMostRelevantInformationProps = {
  elementId: string;
};

const JobContentMostRelevantInformation = ({
  elementId,
}: JobContentMostRelevantInformationProps) => {
  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        flexDirection: 'row',
        gap: theme.spacing('xxxs'),
        width: '100%',
      })}
    >
      <Icon type="star" color="brandBase" size={20} />
      <Typography
        dangerouslySetInnerHTML={{
          __html: translations.jobMostRelevantInformation,
        }}
        sx={(theme) => ({
          color: theme.colors.text.brand,
        })}
      />
    </Stack>
  );
};

export { JobContentMostRelevantInformation };
