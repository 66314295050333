import Box from '@mui/material/Box';
import classNames from 'classnames';
import Button from 'components/Button';
import ButtonModalWrapper from 'components/Button/ButtonModalWrapper';
import { Tooltip } from 'componentsNew/Tooltip';
import { ReactNode } from 'react';

interface DeleteButtonProps {
  onDeleteArticle?: () => void;
  subject?: string;
  modalChildren?: ReactNode;
  disabled?: boolean;
  isCreating?: boolean;
  onCancel?: string;
  buttonStyle?: string;
  text?: 'Unpublish' | 'Archive' | 'Delete';
  toolTipText?: string;
}

const yesButton = {
  color: 'black',
  title: 'Yes',
};

const noButton = {
  color: 'white',
  title: 'No',
};

const DeleteButton = ({
  onDeleteArticle,
  subject,
  modalChildren,
  disabled,
  isCreating,
  buttonStyle,
  text = 'Delete',
  toolTipText,
}: DeleteButtonProps) => {
  const deleteButtonClass = classNames('sm-m-b-2', {
    'article-button--wrapper--delete': buttonStyle,
  });
  if (!isCreating) {
    if (toolTipText) {
      return (
        <Tooltip
          sx={() => ({
            background: 'transparent',
          })}
          title={toolTipText}
          size="small"
        >
          <Box
            sx={() => ({
              marginLeft: { md: 'auto' },
              borderColor: '#333',
              backgroundColor: 'transparent',
              color: '#333',
            })}
          >
            <ButtonModalWrapper
              title={`Are you sure you want to ${text.toLowerCase()} this ${subject}?`}
              modalChildren={modalChildren}
              yesButton={yesButton}
              noButton={noButton}
            >
              <Button
                className={deleteButtonClass}
                disabled={disabled}
                onClick={onDeleteArticle}
              >
                {`${text} ${subject}`}
              </Button>
            </ButtonModalWrapper>
          </Box>
        </Tooltip>
      );
    }
    return (
      <ButtonModalWrapper
        title={`Are you sure you want to ${text.toLowerCase()} this ${subject}?`}
        modalChildren={modalChildren}
        yesButton={yesButton}
        noButton={noButton}
      >
        <Button
          className={deleteButtonClass}
          color="transparent"
          disabled={disabled}
          onClick={onDeleteArticle}
          align="right"
        >
          {text}
        </Button>
      </ButtonModalWrapper>
    );
  } else {
    return null;
  }
};

export default DeleteButton;
