import { PaletteOptions } from '@mui/material/styles';
// eslint-disable-next-line no-restricted-imports
import createPalette from '@mui/material/styles/createPalette';

/**
 * The palette lets you to modify the color of the components to suit your brand.
 * You may override the default palette values or create new. Some of the default
 * values are "primary", "secondary", "error", "warning", "info" and "success".
 * Docs here: https://mui.com/material-ui/customization/palette/
 */

const paletteOptions: PaletteOptions = {
  primary: {
    100: '#006495', // N/A in AA GDS
    200: '#006495', // N/A in AA GDS
    300: '#006495', // N/A in AA GDS
    400: '#006495', // N/A in AA GDS
    500: '#006495', // N/A in AA GDS
    600: '#006495',
    700: '#034B70', // N/A in AA GDS
    800: '#003450',
    900: '#001E30',
    main: '#034B70',
  },
  secondary: {
    100: '#F1F7F9',
    200: '#E6EFF4',
    300: '#C8E0EA',
    400: '#A7D0E2',
    500: '#7CC1DE',
    600: '#7CC1DE', // N/A in AA GDS
    700: '#7CC1DE', // N/A in AA GDS
    800: '#7CC1DE', // N/A in AA GDS
    900: '#7CC1DE', // N/A in AA GDS
    main: '#00A0D0',
  },
  success: {
    100: '#F5F9F1',
    200: '#EDF4E6',
    300: '#D8E8C9',
    400: '#C3DFAA',
    500: '#AAD786',
    600: '#62AD20',
    700: '#366B00',
    800: '#275000',
    900: '#193800',
    main: '#3A8600',
  },
  warning: {
    100: '#F9F6F1',
    200: '#FEF3DC',
    300: '#FEE6B4',
    400: '#FDD98C',
    500: '#FACA61',
    600: '#C38400',
    700: '#815600',
    800: '#614000',
    900: '#442B00',
    main: '#FFAE00',
  },
  error: {
    100: '#F9F1F1',
    200: '#FEDCDC',
    300: '#FEB4B4',
    400: '#FD8C8C',
    500: '#FA6161',
    600: '#F04444',
    700: '#93000A',
    800: '#690005',
    900: '#410002',
    main: '#BA1A1A',
  },
  neutral: {
    100: '#FAFAFA',
    200: '#F5F5F5',
    300: '#E6E6E6',
    400: '#D5D5D5',
    500: '#ADADAD',
    600: '#909090',
    700: '#6C6C6C',
    800: '#464646',
    900: '#262626',
    main: '#1A1A1A',
  },
  info: {
    100: '#FAFAFA', // N/A in AA GDS
    200: '#F5F5F5', // N/A in AA GDS
    300: '#E6E6E6', // N/A in AA GDS
    400: '#D5D5D5', // N/A in AA GDS
    500: '#ADADAD', // N/A in AA GDS
    600: '#909090', // N/A in AA GDS
    700: '#6C6C6C', // N/A in AA GDS
    800: '#464646', // N/A in AA GDS
    900: '#262626', // N/A in AA GDS
    main: '#1A1A1A', // N/A in AA GDS
  },
  common: {
    black: '#1A1A1A',
    white: '#FFFFFF',
  },
  secondary2: {
    100: '#FCF9F6',
    200: '#F5EFE9',
    300: '#EFE5DD',
    400: '#E8DBCF',
    500: '#E1CFBF',
    600: '#C8A78B',
    700: '#A58265',
    800: '#96775C',
    900: '#5E4B3B',
    main: '#D8BFAA',
  },
};

const createMuiPalette = () => {
  return createPalette(paletteOptions);
};

export { createMuiPalette };
