import * as documentsApi from 'api/sharepointdocuments';
import { FolderItem } from 'api/sharepointdocuments/types';
import { useSnackbar } from 'context';
import { ToolsAndTemplatesDocumentsList } from 'pagesInnovation/ToolsAndTemplates/ToolsAndTemplatesDocumentsList';
import { useEffect, useState } from 'react';
import { translations } from 'translations';

type ToolsAndTemplatesDocumentsProps = {
  elementId: string;
  documentsFolderId?: string;
  documents?: FolderItem[];
  onDocumentClick: (document: FolderItem) => void;
};

const ToolsAndTemplatesDocuments = ({
  elementId = 'tools-and-templates-documents',
  documentsFolderId,
  documents,
  onDocumentClick,
}: ToolsAndTemplatesDocumentsProps) => {
  const [toolsAndTemplatesDocuments, setToolsAndTemplatesDocuments] = useState<
    FolderItem[] | null
  >(null);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchToolsAndTemplatesDocuments = async () => {
      let _documents: FolderItem[] = [];
      try {
        const response = await documentsApi.readFolder(documentsFolderId);
        const data = response?.data?.data;
        if (data) {
          _documents = data as FolderItem[];
        }
      } catch {
        showSnackbar({
          type: 'error',
          text: translations.resourcesFetchError,
        });
      } finally {
        setToolsAndTemplatesDocuments(_documents);
      }
    };

    if (documents || !documentsFolderId) {
      return;
    }

    fetchToolsAndTemplatesDocuments();
  }, [documentsFolderId, documents, showSnackbar]);

  if (!documents && !documentsFolderId) {
    return null;
  }

  if (documentsFolderId && !(toolsAndTemplatesDocuments || []).length) {
    return null;
  }

  return (
    <ToolsAndTemplatesDocumentsList
      elementId={`${elementId}-list`}
      documents={documents || toolsAndTemplatesDocuments || []}
      onDocumentClick={onDocumentClick}
    />
  );
};

export { ToolsAndTemplatesDocuments };
