import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import * as findApi from 'api/find';
import { InnovationListingItem } from 'api/find/types';
import { TablePagination } from 'componentsNew';
import { useSnackbar } from 'context';
import { useBreakpoints } from 'hooks';
import { ArticleListSkeleton } from 'pagesAvenue/common';
import { ArticleList, ArticleListEmpty } from 'pagesInnovation/common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'translations';
import { GAonShowMoreClick } from 'utils/analytics';
type ArticlesTabListProps = {
  id: string;
  focusAreaId: string;
};

const INITIAL_PAGINATION = {
  page: 1,
  limit: 10,
};

const ArticlesTabList = ({ id, focusAreaId }: ArticlesTabListProps) => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const [pagination, setPagination] = useState<{
    page: number;
    limit: number;
  }>(INITIAL_PAGINATION);

  const [list, setList] = useState<{
    items: InnovationListingItem[];
    total: number;
  } | null>(null);

  const [isListError, setIsListError] = useState<boolean>(false);
  const [isListLoading, setIsListLoading] = useState<boolean>(false);

  const { isMobile } = useBreakpoints();
  const { showSnackbar } = useSnackbar();

  const tabItems = useMemo(() => {
    const _tabItems = [
      {
        tabId: `${id}-process-and-guides-tab`,
        title: translations.innovationTypeProcessAndGuides,
        listItemsUrl: findApi.getInnovationProcessAndGuidesItems,
        tabContentId: `${id}-process-and-guides-tab-content`,
      },
      {
        tabId: `${id}-tools-and-templates-tab`,
        title: translations.innovationTypeToolsAndTemplates,
        listItemsUrl: findApi.getInnovationToolsAndTemplatesItems,
        tabContentId: `${id}-tools-and-templates-tab-content`,
      },
      {
        tabId: `${id}-learning-tab`,
        title: translations.learning,
        listItemsUrl: findApi.getInnovationLearningItems,
        tabContentId: `${id}-learning-tab-content`,
      },
    ];

    return _tabItems;
  }, [id]);

  const pageCount = useMemo(() => {
    if (!list) {
      return 0;
    }
    return list.total % pagination.limit > 0
      ? Math.trunc(list.total / pagination.limit) + 1
      : list.total / pagination.limit;
  }, [list, pagination.limit]);

  const isPageOutOfBound = useMemo(() => {
    return (
      pagination.page > 1 &&
      pagination.page > pageCount &&
      list !== null &&
      !list.items.length
    );
  }, [list, pageCount, pagination.page]);

  const fetchList = useCallback(
    async (
      pagination: {
        page: number;
        limit: number;
      },
      selectedTabIndex: number
    ) => {
      const list: {
        items: InnovationListingItem[];
        total: number;
      } = {
        items: [],
        total: 0,
      };
      try {
        setIsListLoading(true);
        setIsListError(false);
        const getListItems = tabItems[selectedTabIndex].listItemsUrl;
        const query = findApi.getInnovationItemsQueryString({
          ...pagination,
          filter: {
            focusAreas: [focusAreaId],
          },
        });
        const response = await getListItems(query);
        if (!response?.data?.data) {
          throw Error();
        }
        list.total = response.data.data.total as number;
        list.items = response.data.data.items as InnovationListingItem[];
        return list;
      } catch {
        setIsListError(true);
        showSnackbar({
          type: 'error',
          text: translations.contentGetError,
        });
      } finally {
        setIsListLoading(false);
        return list;
      }
    },
    [focusAreaId, showSnackbar, tabItems]
  );

  const handlePaginationChange = useCallback(
    async (newPagination: { page: number; limit: number }) => {
      setPagination(newPagination);
      GAonShowMoreClick('Innovation');
      if (
        list &&
        pagination.page === 1 &&
        newPagination.page === 1 &&
        newPagination.limit < pagination.limit
      ) {
        setList({
          ...list,
          items: list?.items.slice(0, newPagination.limit),
        });
        return;
      }
      const newList = await fetchList(newPagination, tabIndex);
      setList(newList);
    },
    [list, pagination.page, pagination.limit, tabIndex, fetchList]
  );

  const handleTabChange = useCallback(
    async (_e: React.SyntheticEvent<Element, Event>, newTabIndex: number) => {
      setTabIndex(newTabIndex);
      const newList = await fetchList(pagination, newTabIndex);
      setList(newList);
    },
    [fetchList, pagination]
  );

  // Fetch initial list
  useEffect(() => {
    if (list !== null) {
      return;
    }
    async function initList() {
      const initList = await fetchList(
        {
          page: pagination.page,
          limit: pagination.limit,
        },
        tabIndex
      );
      setList(initList);
    }
    initList();
  }, [fetchList, list, pagination.limit, pagination.page, tabIndex]);

  const listElement = useMemo(() => {
    if (isListLoading) {
      return <ArticleListSkeleton />;
    }
    if (isListError) {
      return (
        <ArticleListEmpty>
          <Typography>{translations.fetchError}</Typography>
        </ArticleListEmpty>
      );
    }
    if (!list) {
      return null;
    }
    if (!list.items?.length) {
      return (
        <ArticleListEmpty>
          <Typography>{translations.contentEmpty}</Typography>
          {isPageOutOfBound && (
            <Typography>{translations.paginationOutOfBound}</Typography>
          )}
        </ArticleListEmpty>
      );
    }
    return (
      <ArticleList
        elementId={`${id}-items`}
        articles={list.items}
        hideType={tabItems[tabIndex].title !== translations.learning}
      />
    );
  }, [
    id,
    isListError,
    isListLoading,
    isPageOutOfBound,
    list,
    tabIndex,
    tabItems,
  ]);

  const paginationElement = useMemo(() => {
    if (
      isMobile &&
      list &&
      list.total <= pagination.limit &&
      !isPageOutOfBound
    ) {
      return null;
    }

    return (
      <TablePagination
        elementId={`${id}-pagination`}
        hideRowsPerPage={isMobile}
        disabled={isListLoading}
        page={pagination.page}
        rowsPerPage={pagination.limit}
        rowsPerPageOptions={[10, 25, 50]}
        count={pageCount}
        sx={(theme) => ({
          marginTop: theme.spacing('sm'),
          backgroundColor: theme.colors.surface.primary,
          borderRadius: theme.border.radius.md,
        })}
        onPageChange={(value: number) =>
          handlePaginationChange({
            limit: pagination.limit,
            page: value,
          })
        }
        onRowsPerPageChange={(value: number) =>
          handlePaginationChange({
            limit: value,
            page: 1,
          })
        }
      />
    );
  }, [
    handlePaginationChange,
    id,
    isListLoading,
    isMobile,
    isPageOutOfBound,
    list,
    pageCount,
    pagination.limit,
    pagination.page,
  ]);

  return (
    <Stack>
      <Tabs
        id={id}
        aria-label={translations.innovationArticleTypeTabList}
        value={tabIndex}
        variant="scrollable"
        allowScrollButtonsMobile={true}
        selectionFollowsFocus={true}
        onChange={handleTabChange}
        sx={(theme) => ({
          marginBottom: theme.spacing('sm'),
        })}
      >
        {tabItems.map((item, index) => {
          return (
            <Tab
              key={`${id}-tab-${index}`}
              id={item.tabId}
              aria-controls={item.tabContentId}
              label={item.title}
            />
          );
        })}
      </Tabs>
      {tabItems.map((item, index) => {
        return (
          <Stack
            role="tabpanel"
            key={`${id}-tabpanel-${index}`}
            hidden={tabIndex !== index}
            id={item.tabContentId}
            aria-labelledby={item.tabId}
            sx={() => ({
              flexGrow: 1,
            })}
          >
            {tabIndex === index && listElement}
          </Stack>
        );
      })}
      {paginationElement}
    </Stack>
  );
};

export { ArticlesTabList };
