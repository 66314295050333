import { useCurrentEditor } from '@tiptap/react';
import {
  ImageModal,
  ImageType,
  ImageValue,
} from 'componentsNew/MediaInput/ImageInput/ImageModal';
import { useSnackbar } from 'context';
import { useCallback, useState } from 'react';
import { translations } from 'translations';
import * as attachmentUtils from 'utils/cms/attachment';

const elementId = 'rich-text-image-action';

type ImageActionProps = {
  image: ImageType;
  onDone: () => void;
};

const ImageAction = ({ image, onDone }: ImageActionProps) => {
  const { editor } = useCurrentEditor();
  const { showSnackbar } = useSnackbar();

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (image: ImageValue) => {
      try {
        if (!editor || !image.blob) {
          showSnackbar({
            text: translations.imageInputUploadError,
            type: 'error',
          });
          return;
        }
        setIsUploading(true);

        const uploadedSrc = await attachmentUtils.uploadImage({
          blob: image.blob,
          height: image.height,
          width: image.width,
        });

        editor
          .chain()
          .focus()
          .setImage({
            src: uploadedSrc,
            alt: image.altText,
            title: image.altText,
            caption: image.caption,
            width: image.width,
            height: image.height,
          })
          .run();
      } catch {
        showSnackbar({
          text: translations.imageInputUploadError,
          type: 'error',
        });
      } finally {
        setIsUploading(false);
        onDone();
      }
    },
    [editor, onDone, showSnackbar]
  );

  return (
    <ImageModal
      elementId={`${elementId}-modal`}
      defaultValue={{ ...image, caption: '', altText: '' }}
      isLoading={isUploading}
      disableRestoreFocus
      labels={{
        title: translations.richTextImageModalTitle,
        submit: translations.save,
      }}
      onSubmit={handleSubmit}
      onClose={onDone}
    />
  );
};

export { ImageAction };
