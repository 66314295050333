import Skeleton from '@mui/material/Skeleton';
import Stack, { StackProps } from '@mui/material/Stack';

type ArticleListSkeletonProps = {
  sx?: StackProps['sx'];
  count?: number;
};

const ArticleListSkeleton = ({ sx, count = 6 }: ArticleListSkeletonProps) => (
  <Stack
    sx={[
      (theme) => ({
        width: '100%',
        gap: theme.spacing('xs'),
      }),
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {[...Array(count)].map((_, index) => (
      <Skeleton
        key={`article-list-skeleton-${index}`}
        variant="rounded"
        animation="wave"
        sx={{
          height: '11rem',
        }}
      />
    ))}
  </Stack>
);

export { ArticleListSkeleton };
