import { AlertDialog } from 'componentsNew';
import { translations } from 'translations';

type DialogArchiveProps = {
  id: string;
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DialogArchive = ({
  id,
  open,
  loading,
  onClose,
  onConfirm,
}: DialogArchiveProps) => {
  return (
    <AlertDialog
      id={id}
      open={open}
      type="warning"
      title={translations.manageContentActionsArchiveDialogTitle}
      paragraphs={[translations.manageContentActionsArchiveDialogText]}
      primaryButton={{
        loading: loading,
        disabled: loading,
        text: translations.manageContentActionsArchive,
        onClick: onConfirm,
      }}
      secondaryButton={{
        disabled: loading,
        text: translations.cancel,
        onClick: onClose,
      }}
      disableClose={loading}
      onClose={onClose}
    />
  );
};
export { DialogArchive };
