import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import * as cmsInnovationApi from 'api/cms-innovation';
import { InnovationProcessAndGuidesArticle } from 'api/cms-innovation/types';
import * as findApi from 'api/find';
import { InnovationListingItem } from 'api/find/types';
import headerImage from 'assets/images/innovation-article-header-background-process-and-guides.png';
import { FetchError } from 'componentsNew';
import { AvenueRouteEnum, InnovationRouteEnum } from 'enums';
import { Page } from 'layout';
import {
  ArticleContactDetails,
  ArticleHeader,
  ArticleHeaderLeftColumn,
  ArticleHeaderRightColumn,
  ArticleSkeleton,
  TitlePreambleAndFocusAreas,
} from 'pagesInnovation/common';
import { LastModifiedDateAndVersion } from 'pagesInnovation/common/ArticlePage/LastModifiedDateAndVersion';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translations } from 'translations';

import { TabsAndChapters } from './TabsAndChapters';

const elementId = 'process-and-guides';

const ProcessAndGuides = () => {
  const [article, setArticle] =
    useState<InnovationProcessAndGuidesArticle | null>(null);

  const [relatedArticles, setRelatedArticles] = useState<
    InnovationListingItem[] | null
  >(null);

  const [isError, setIsError] = useState<boolean>(false);

  const theme = useTheme();
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const documentTitle = useMemo(
    () =>
      article
        ? [
            translations.innovationDocumentTitle,
            translations.innovationTypeProcessAndGuides,
            article.title,
          ]
        : null,
    [article]
  );

  const documentBreadcrumbs = useMemo(
    () =>
      article
        ? [
            {
              text: translations.innovationTypeProcessAndGuides,
              to: InnovationRouteEnum.ProcessAndGuides,
            },
            { text: article.title },
          ]
        : null,
    [article]
  );

  useEffect(() => {
    if (params.id === article?.id || isError) {
      return;
    }
    const fetchArticle = async () => {
      try {
        setIsError(false);
        const data = await cmsInnovationApi.getProcessAndGuidesArticle(
          params.id
        );
        setArticle(data);
      } catch (e: any) {
        if (e['status'] === 404) {
          history.push(AvenueRouteEnum.NotFound);
          return;
        }
        setIsError(true);
      }
    };
    fetchArticle();
  }, [article, history, isError, params.id]);

  useEffect(() => {
    const fetchRelatedArticles = async () => {
      if (!article) {
        return;
      }
      let articles: InnovationListingItem[] = [];
      try {
        const query = findApi.getInnovationItemsQueryString({
          limit: 5,
          filter: {
            focusAreas: article.focusAreas.map((focusArea) => focusArea.id),
          },
        });
        const response = await findApi.getInnovationItems(query);
        const data = response?.data?.data;
        if (data) {
          articles = data.items as InnovationListingItem[];
          articles = articles.filter(
            (relatedArticle) => relatedArticle.id !== article.id
          );
        }
      } catch {
      } finally {
        setRelatedArticles(articles);
      }
    };
    if (!article) {
      return;
    }
    if (!article.focusAreas.length) {
      setRelatedArticles([]);
      return;
    }
    fetchRelatedArticles();
  }, [article]);

  if (isError) {
    return (
      <Page title={documentTitle} gridContainer>
        <FetchError />
      </Page>
    );
  }

  if (!article) {
    return (
      <Page title={documentTitle} gridContainer>
        <ArticleSkeleton />
      </Page>
    );
  }

  return (
    <Page title={documentTitle} breadcrumbs={documentBreadcrumbs} gridContainer>
      <Grid xs={12}>
        <ArticleHeader
          backgroundImage={headerImage}
          articleActionButtonProps={{
            editPath: `${InnovationRouteEnum.ProcessAndGuidesEdit}/${article.id}`,
          }}
        >
          <ArticleHeaderLeftColumn>
            <TitlePreambleAndFocusAreas
              title={article.title}
              preamble={article.preamble}
              focusAreas={article.focusAreas}
              color={theme.palette.secondary2[900]}
            />
          </ArticleHeaderLeftColumn>
          <ArticleHeaderRightColumn>
            <LastModifiedDateAndVersion
              version={article.version}
              lastModifiedDate={article.lastModified.date}
            />
          </ArticleHeaderRightColumn>
        </ArticleHeader>
      </Grid>
      <Grid xs={12}>
        <TabsAndChapters
          tabs={article.tabs}
          relatedArticles={relatedArticles || []}
        />
      </Grid>
      <Grid xs={12} lg={8}>
        {article.contactDetails && (
          <ArticleContactDetails
            elementId={`${elementId}-contact-details`}
            htmlContent={article.contactDetails}
          />
        )}
      </Grid>
    </Page>
  );
};

export { ProcessAndGuides };
