import { translations } from 'translations';

import { FileItemType } from './FileItem';

const getDuplicateFileNames = (
  files: File[],
  fileListItems: FileItemType[]
) => {
  const fileNames = files.map((file) => {
    return file.name;
  });

  const duplicateFileNames: string[] = [];

  for (const fileItem of fileListItems) {
    if (!fileItem.uploaded) {
      continue;
    }

    if (fileNames.includes(fileItem.uploaded.name)) {
      duplicateFileNames.push(fileItem.uploaded.name);
    }
  }

  return duplicateFileNames;
};

const convertBytesToSize = (bytes: number, decimals = 0) => {
  if (!bytes || bytes === 0) {
    return translations.notAvailable;
  }

  const oneKB = 1024;

  let size = translations.fileSizeBytes;

  if (bytes >= oneKB) {
    size = translations.fileSizeKB;
    bytes = bytes / oneKB;
  }

  if (bytes >= oneKB) {
    size = translations.fileSizeMB;
    bytes = bytes / oneKB;
  }

  return `${parseFloat(bytes.toFixed(decimals))}${size}`;
};

export { convertBytesToSize, getDuplicateFileNames };
