import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { jobs } = settings.api;

export const getJobs = (query = '') =>
  createRequest({
    method: 'get',
    url: `${jobs}/jobs${query}`,
    errorMessages: {
      '*': 'Failed to get jobs, please contact support for assistance.',
    },
  });

export const getJob = (id, query) =>
  createRequest({
    method: 'get',
    url: `${jobs}/jobs/${id}${query}`,
    errorMessages: {
      '*': 'Failed to get job, please contact support for assistance.',
    },
  });

export const getJobsFilter = () =>
  createRequest({
    method: 'get',
    url: `${jobs}/jobs/filter`,
    errorMessages: {
      '*': 'Failed to get filter for jobs, please contact support for assistance.',
    },
  });
