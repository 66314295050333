import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { LikesAndCommentsMeta } from 'api/feedback/transformers';
import { NewsFlash } from 'api/news/transformers';
import {
  CommentButton,
  LikeButton,
  TypographyWithMaxLines,
} from 'componentsNew';
import { ArticleContentTypeEnum } from 'enums';
import { getUrlLink } from 'utils/misc/getUrlLink';

import { ArticleImage } from './ArticleImage';
import { ArticleMetaLabels } from './ArticleMetaLabels';
import { ArticleTags } from './ArticleTags';
import { ArticleTitle } from './ArticleTitle';
import { ArticleType } from './ArticleType';

export type ArticlePreview = NewsFlash & {
  likesAndCommentsMeta?: LikesAndCommentsMeta;
};

type ArticleListProps = {
  id: string;
  articles: ArticlePreview[];
  hideLastDivider?: boolean;
  onLikeClick?: (article: ArticlePreview) => void;
  onCommentClick?: (article: ArticlePreview) => void;
  onArticleClick?: (article: ArticlePreview) => void;
};

const ArticleList = ({
  id,
  articles,
  hideLastDivider = false,
  onLikeClick,
  onCommentClick,
  onArticleClick,
}: ArticleListProps) => {
  const theme = useTheme();

  return (
    <List id={id}>
      {articles.map((article, index) => {
        const articleLink = getUrlLink(article.type, article.id) || '/';

        const isPortraitImage =
          Boolean(article.imageUrl) &&
          (article.imageHeight || 0) > (article.imageWidth || 0);

        const showLikes = article.type !== ArticleContentTypeEnum.Press;
        const showComments =
          article.type !== ArticleContentTypeEnum.Press &&
          !article.disableComments;

        return (
          <ListItem
            key={article.id}
            id={id && `${id}-item-${index}`}
            divider={hideLastDivider ? index < articles.length - 1 : true}
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'row',
              padding: `${theme.spacing('sm')} 0`,
              backgroundColor: theme.colors.surface.primary,
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: theme.colors.surface.informative,
                '.MuiLink-root > h2': {
                  color: theme.colors.text.actionHover,
                  textDecoration: 'underline',
                },
              },
            })}
          >
            <ArticleImage
              src={article.imageUrl}
              type={article.type}
              to={articleLink}
              altText={article.imageAltText}
              onClick={() => onArticleClick && onArticleClick(article)}
              isPortrait={isPortraitImage}
              fetchSize="sm"
              sx={{
                width: '30%',
                flexShrink: 0,
                aspectRatio: 16 / 9,
              }}
            />

            <Stack
              sx={(theme) => ({
                width: '100%',
                alignSelf: 'stretch',
                alignItems: 'baseline',
                padding: `${theme.spacing('xs')}`,
              })}
            >
              <ArticleType type={article.type} />
              <ArticleTitle
                title={article.title}
                to={articleLink}
                variant="body1"
                onClick={() => onArticleClick && onArticleClick(article)}
              />
              <ArticleMetaLabels
                type={article.type}
                author={article.author}
                countries={article.country ? [article.country] : []}
                divisions={article.division ? [article.division] : []}
                departments={article.departments ? article.departments : []}
                segments={article.segment ? [article.segment] : []}
                sites={article.site ? [article.site] : []}
                companies={article.company ? [article.company] : []}
                publishedDate={article.publishedUtc}
                containsImageGallery={article.containsImageGallery}
                sx={(theme) => ({ marginBottom: theme.spacing('xxs') })}
              />
              {article.description && (
                <TypographyWithMaxLines
                  maxLines={1}
                  variant="caption"
                  sx={(theme) => ({
                    color: theme.colors.text.tertiary,
                    marginBottom: theme.spacing('xs'),
                  })}
                >
                  {article.description}
                </TypographyWithMaxLines>
              )}
              <Stack
                sx={() => ({
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                  alignItems: {
                    xs: 'baseline',
                    sm: 'center',
                  },
                  justifyContent: 'space-between',
                  marginTop: 'auto',
                  width: '100%',
                })}
              >
                <ArticleTags tags={article.tags} />
                {(showLikes || showComments) &&
                  article.likesAndCommentsMeta && (
                    <Stack
                      sx={() => ({
                        flexDirection: 'row',
                        alignSelf: {
                          xs: 'end',
                          sm: 'unset',
                        },
                        margin: {
                          xs: `${theme.spacing('xs')} 0 0 0`,
                          sm: `0 0 0 ${theme.spacing('xs')}`,
                        },
                      })}
                    >
                      {showLikes && (
                        <LikeButton
                          id={`${id}-item-${index}-like`}
                          isLikedByMe={article.likesAndCommentsMeta.isLikedByMe}
                          likesCount={article.likesAndCommentsMeta.likesCount}
                          sx={(theme) => ({
                            marginRight: showComments ? theme.spacing('xs') : 0,
                          })}
                          onClick={() => {
                            onLikeClick && onLikeClick(article);
                          }}
                        />
                      )}

                      {showComments && (
                        <CommentButton
                          id={`${id}-item-${index}-comment`}
                          isCommentedByMe={
                            article.likesAndCommentsMeta.isCommentedByMe
                          }
                          commentsCount={
                            article.likesAndCommentsMeta.commentsCount
                          }
                          to={articleLink}
                          onClick={() => {
                            onCommentClick && onCommentClick(article);
                          }}
                        />
                      )}
                    </Stack>
                  )}
              </Stack>
            </Stack>
          </ListItem>
        );
      })}
    </List>
  );
};

export { ArticleList };
