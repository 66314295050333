import Stack, { StackProps } from '@mui/material/Stack';
import { IconButton } from 'componentsNew';
import { FormFieldLabel } from 'layout';
import { forwardRef, useState } from 'react';
import { translations } from 'translations';

import {
  TabOrChapterInputSheet,
  TabOrChapterInputSheetProps,
} from './TabOrChapterInputSheet';

export type TabOrChapterInputProps = {
  id?: string;
  ariaDescribedBy?: string;
  error?: boolean;
  fullWidth?: boolean;
  type: 'tab' | 'chapter';
  sx?: StackProps['sx'];
} & Pick<
  TabOrChapterInputSheetProps,
  'items' | 'disabled' | 'onAdd' | 'onUpdate' | 'onRemove'
>;

const TabOrChapterInput = forwardRef<HTMLDivElement, TabOrChapterInputProps>(
  (
    {
      id = 'tab-or-chapter-input',
      sx,
      ariaDescribedBy,
      error,
      fullWidth,
      disabled,
      type,
      items,
      onAdd,
      onUpdate,
      onRemove,
    },
    ref
  ) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
      <Stack
        id={id}
        ref={ref}
        aria-describedby={ariaDescribedBy}
        sx={[
          (theme) => ({
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: theme.spacing('xs'),
            width: fullWidth ? '100%' : 'fit-content',
            ...(error && {
              border: `1px solid ${theme.colors.border.critical}`,
              padding: theme.spacing('xxs'),
              borderRadius: theme.border.radius.sm,
            }),
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <FormFieldLabel
          id={`${id}-sheet-button-label`}
          size="small"
          component="span"
          label={type === 'tab' ? translations.tabs : translations.chapters}
        />
        <IconButton
          id={`${id}-sheet-button`}
          onClick={() => setOpen((prevOpen) => !prevOpen)}
          aria-labelledby={`${id}-sheet-button-label`}
          type={items.length > 0 ? 'pencil' : 'plus'}
        />
        {open && (
          <TabOrChapterInputSheet
            id={`${id}-sheet`}
            type={type}
            items={items}
            disabled={disabled}
            onAdd={onAdd}
            onUpdate={onUpdate}
            onRemove={onRemove}
            onClose={() => setOpen(false)}
          />
        )}
      </Stack>
    );
  }
);

export { TabOrChapterInput };
