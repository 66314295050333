import {
  InnovationWebinarArticle,
  InnovationWebinarArticleCreateBody,
} from 'api/cms-innovation/types';

import { FormValues } from './WebinarForm';

const transformFormValuesToCreateOrEditPayload = (data: FormValues) => {
  const { hero, presenters, ...rest } = data;

  const transformed: InnovationWebinarArticleCreateBody = {
    ...rest,
    ...(hero.embedVideo && {
      heroVideoUrl: hero.embedVideo.url,
    }),
    presenters: {
      internalPersons: presenters.internalPersons.map((person) => ({
        name: person.name || '',
        oid: person.id,
      })),
      externalPersons: presenters.externalPersons.map((person) => ({
        name: person.name,
        title: person.title,
        email: person.email,
      })),
    },
  };

  return transformed;
};

export const transformGetResponseToFormValues = (
  data: InnovationWebinarArticle
) => {
  const internalPresenters = data.presenters?.internalPersons || [];
  const externalPresenters = data.presenters?.externalPersons || [];

  const transformed: FormValues = {
    title: data.title,
    preamble: data.preamble,
    focusAreas: data.focusAreas,
    presenters: {
      internalPersons: internalPresenters.map((person) => ({
        name: person.name || '',
        id: person.oid,
      })),
      externalPersons: externalPresenters.map((person) => ({
        name: person.name,
        title: person.title,
        email: person.email,
      })),
    },
    hero: {
      embedVideo: data.heroVideoUrl ? { url: data.heroVideoUrl } : undefined,
      image: undefined,
    },
    note: data.note || '',
    body: data.body || '',
    contactDetails: data.contactDetails || '',
    resources: data.resources || {},
    displayOnHome: data.displayOnHome,
  };

  return transformed;
};

export { transformFormValuesToCreateOrEditPayload };
