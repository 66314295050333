import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

const { cms } = settings.api;
const route = `${cms}/authenticatedVideo`;

export const getAuthenticatedVideo = (data: { url: string }) =>
  createRequest({
    method: 'post',
    url: `${route}`,
    data: data,
    errorMessages: {
      '*': 'Failed to get authenticated Hero video, please contact support for assistance.',
    },
  });
