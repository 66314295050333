import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { ShortcutTypeName } from 'api/organization/types';
import { useGlobalWebcast, useHomeBanner } from 'context';
import { Page } from 'layout';
import { PAGE_GRID_GAP } from 'layout/Page/Page';
import { useState } from 'react';
import { translations } from 'translations';

import { Banner } from './Banner/Banner';
import { InsightsDatabase } from './InsightsDatabase/InsightsDatabase';
import { JobOpenings } from './JobOpenings/JobOpenings';
import { LatestArticles } from './LatestArticles/LatestArticles';
import { ProductOfTheWeek } from './ProductOfTheWeek/ProductOfTheWeek';
import { Shortcuts } from './Shortcuts/Shortcuts';
import { Some } from './Some';
import { Stories } from './Stories/Stories';
import { UpdatedLibraryPages } from './UpdatedLibraryPages/UpdatedLibraryPages';

const Home = () => {
  const [locale, setLocale] = useState<string>('en');

  const { isCelebrationBannerActive } = useHomeBanner();
  const { isGlobalWebcastActive, iframeWrapperHeight } = useGlobalWebcast();

  return (
    <Page
      title={translations.home}
      isConfettiActive={isCelebrationBannerActive}
      isGlobalWebcastActive={isGlobalWebcastActive}
      {...(isGlobalWebcastActive && {
        overrideBlueRibbonHeight: iframeWrapperHeight,
      })}
      gridContainer
    >
      <Grid xs={12}>
        <Banner />
      </Grid>
      <Grid xs={12} md={8}>
        <Typography variant="srOnly" component="h2">
          {translations.articleFeed}
        </Typography>
        <LatestArticles locale={locale} setLocale={setLocale} />
      </Grid>
      <Grid xs={12} md={4}>
        <Stack spacing={PAGE_GRID_GAP}>
          <Shortcuts shortcutType={ShortcutTypeName.Tools} />
          <ProductOfTheWeek />
          <Some />
          <UpdatedLibraryPages />
        </Stack>
      </Grid>
      <Grid xs={12}>
        <Stories locale={locale} />
      </Grid>
      <Grid xs={12} lg={8}>
        <JobOpenings />
      </Grid>
      <Grid xs={12}>
        <InsightsDatabase />
      </Grid>
    </Page>
  );
};

export { Home };
