import { useUser } from 'components/Context/User';
import PageHeader from 'components/PageHeader';
import { FocusAreasProvider } from 'context';
import { InnovationRouteEnum } from 'enums';
import { useBreakpoints } from 'hooks';
import {
  CaseStudy,
  CaseStudyCreate,
  CaseStudyEdit,
  Create,
  FocusArea,
  FocusAreaCreate,
  FocusAreaEdit,
  FocusAreaListing,
  Home,
  LearningListing,
  ProcessAndGuides,
  ProcessAndGuidesCreate,
  ProcessAndGuidesEdit,
  ProcessAndGuidesListing,
  ToolsAndTemplates,
  ToolsAndTemplatesCreate,
  ToolsAndTemplatesEdit,
  ToolsAndTemplatesListing,
  Training,
  TrainingCreate,
  TrainingEdit,
  Webinar,
  WebinarCreate,
  WebinarEdit,
} from 'pagesInnovation';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NavigationDesktop, NavigationMobile } from './common';

const Root = () => {
  const user = useUser();
  const { isBreakpointMediumOrHigher } = useBreakpoints();

  return (
    <FocusAreasProvider>
      <PageHeader signedIn={true} showBlueBackground={false}>
        {!isBreakpointMediumOrHigher ? <NavigationMobile /> : <></>}
      </PageHeader>
      {isBreakpointMediumOrHigher && <NavigationDesktop />}
      <main className="main-content">
        <Switch>
          <Route exact path={InnovationRouteEnum.Home} component={Home} />

          {user.isPortalEditor && (
            <Route exact path={InnovationRouteEnum.Create} component={Create} />
          )}
          <Route
            exact
            path={InnovationRouteEnum.Home}
            component={() => <div>TODO: Home</div>}
          />
          {user.isPortalEditor && (
            <Route
              exact
              path={InnovationRouteEnum.FocusAreaCreate}
              component={FocusAreaCreate}
            />
          )}
          {user.isPortalEditor && (
            <Route
              exact
              path={`${InnovationRouteEnum.FocusAreaEdit}/:id`}
              component={FocusAreaEdit}
            />
          )}
          <Route
            exact
            path={InnovationRouteEnum.FocusArea}
            component={FocusAreaListing}
          />

          <Route
            exact
            path={`${InnovationRouteEnum.FocusArea}/:id`}
            component={FocusArea}
          />
          <Route
            exact
            path={InnovationRouteEnum.Learning}
            component={LearningListing}
          />
          {user.isPortalEditor && (
            <Route
              exact
              path={InnovationRouteEnum.CaseStudyCreate}
              component={CaseStudyCreate}
            />
          )}
          {user.isPortalEditor && (
            <Route
              exact
              path={`${InnovationRouteEnum.CaseStudyEdit}/:id`}
              component={CaseStudyEdit}
            />
          )}
          <Route
            exact
            path={`${InnovationRouteEnum.CaseStudy}/:id`}
            component={CaseStudy}
          />
          {user.isPortalEditor && (
            <Route
              exact
              path={InnovationRouteEnum.TrainingCreate}
              component={TrainingCreate}
            />
          )}
          {user.isPortalEditor && (
            <Route
              exact
              path={`${InnovationRouteEnum.TrainingEdit}/:id`}
              component={TrainingEdit}
            />
          )}
          <Route
            exact
            path={`${InnovationRouteEnum.Training}/:id`}
            component={Training}
          />
          {user.isPortalEditor && (
            <Route
              exact
              path={InnovationRouteEnum.WebinarCreate}
              component={WebinarCreate}
            />
          )}
          {user.isPortalEditor && (
            <Route
              exact
              path={`${InnovationRouteEnum.WebinarEdit}/:id`}
              component={WebinarEdit}
            />
          )}
          <Route
            exact
            path={`${InnovationRouteEnum.Webinar}/:id`}
            component={Webinar}
          />
          {user.isPortalEditor && (
            <Route
              exact
              path={InnovationRouteEnum.ProcessAndGuidesCreate}
              component={ProcessAndGuidesCreate}
            />
          )}
          {user.isPortalEditor && (
            <Route
              exact
              path={`${InnovationRouteEnum.ProcessAndGuidesEdit}/:id`}
              component={ProcessAndGuidesEdit}
            />
          )}
          <Route
            exact
            path={InnovationRouteEnum.ProcessAndGuides}
            component={ProcessAndGuidesListing}
          />
          <Route
            exact
            path={`${InnovationRouteEnum.ProcessAndGuides}/:id`}
            component={ProcessAndGuides}
          />
          {user.isPortalEditor && (
            <Route
              exact
              path={InnovationRouteEnum.ToolsAndTemplatesCreate}
              component={ToolsAndTemplatesCreate}
            />
          )}
          {user.isPortalEditor && (
            <Route
              exact
              path={`${InnovationRouteEnum.ToolsAndTemplatesEdit}/:id`}
              component={ToolsAndTemplatesEdit}
            />
          )}
          <Route
            exact
            path={`${InnovationRouteEnum.ToolsAndTemplates}`}
            component={ToolsAndTemplatesListing}
          />
          <Route
            exact
            path={`${InnovationRouteEnum.ToolsAndTemplates}/:id`}
            component={ToolsAndTemplates}
          />
          <Route
            exact
            path={InnovationRouteEnum.Strategy}
            component={() => <div>TODO: Strategy</div>}
          />
          <Redirect to="/404" />
        </Switch>
      </main>
    </FocusAreasProvider>
  );
};

export { Root };
