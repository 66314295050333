import { JobListingFilter, JobsFilterResponse } from './types';

const transformJobsFilterResponse = (response: JobsFilterResponse) => {
  if (!response?.data?.data) {
    return null;
  }
  const { data } = response.data;
  const transformed: JobListingFilter = {
    locations: (data.locations || []).map((item) => ({
      id: `${item.id}`,
      name: item.name,
    })),
    states: (data.states || []).map((item) => ({
      id: `${item.id}`,
      name: item.name,
      locationId: `${item.locationId}`,
    })),
    categories: (data.categories || []).map((item) => ({
      id: `${item.id}`,
      name: item.name,
    })),
    seniorityLevels: (data.seniorityLevels || []).map((item) => ({
      id: `${item.id}`,
      name: item.name,
    })),
  };
  return transformed;
};

export { transformJobsFilterResponse };
