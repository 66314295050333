import Stack from '@mui/material/Stack';
import { Icon } from 'componentsNew/Icon/Icon';

type FileItemProps = {
  type: 'default' | 'error' | 'success';
};

const FileItemIcon = ({ type }: FileItemProps) => {
  return (
    <Stack
      sx={(theme) => ({
        position: 'relative',
        borderRadius: '2rem',
        padding: theme.spacing('xxs'),
        backgroundColor:
          type === 'error'
            ? theme.colors.surface.critical
            : type === 'success'
            ? theme.colors.surface.success
            : theme.colors.surface.tertiary,
      })}
    >
      <Icon
        type="file"
        color={
          type === 'error'
            ? 'critical'
            : type === 'success'
            ? 'success'
            : 'secondary'
        }
      />
      {type === 'error' && (
        <Icon
          sx={(theme) => ({
            position: 'absolute',
            bottom: -3,
            right: -3,
            borderRadius: '2rem',
            backgroundColor: theme.colors.surface.critical,
          })}
          type="xCircleFilled"
          color="critical"
          size={16}
        />
      )}
      {type === 'success' && (
        <Icon
          sx={(theme) => ({
            position: 'absolute',
            bottom: -3,
            right: -3,
            borderRadius: '2rem',
            backgroundColor: theme.colors.surface.success,
          })}
          type="checkCircleFilled"
          color="success"
          size={16}
        />
      )}
    </Stack>
  );
};

export { FileItemIcon };
