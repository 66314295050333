import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import {
  AlertBar,
  Chip,
  ExternalPersonsInput,
  InternalPersonsInput,
  Sheet,
} from 'componentsNew';
import { PageContentHeader } from 'layout';
import { useMemo } from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';

import { ExternalPerson } from './ExternalPersonsInput';
import { InternalPerson } from './InternalPersonsInput';

type PersonsInputSheetProps = {
  elementId: string;
  open: boolean;
  title?: string;
  limit?: number;
  internalPersons: InternalPerson[];
  externalPersons: ExternalPerson[];
  hideInternalPersons?: boolean;
  hideExternalPersons?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onInternalPersonsChange?: (value: InternalPerson[]) => void;
  onExternalPersonsChange?: (value: ExternalPerson[]) => void;
};

const PersonsInputSheet = ({
  elementId,
  open,
  title = translations.add,
  limit = 10,
  internalPersons,
  externalPersons,
  hideInternalPersons,
  hideExternalPersons,
  setOpen,
  onInternalPersonsChange,
  onExternalPersonsChange,
}: PersonsInputSheetProps) => {
  const isLimitReached = useMemo(() => {
    const totalPersonCount = externalPersons.length + internalPersons.length;

    return totalPersonCount >= limit;
  }, [externalPersons.length, internalPersons.length, limit]);

  return (
    <Sheet
      id={elementId}
      open={open}
      width="33.125rem"
      onClose={() => setOpen(false)}
    >
      <Stack sx={(theme) => ({ gap: theme.spacing('xs') })}>
        <PageContentHeader text={title} />

        <TransitionGroup>
          <Collapse>
            <AlertBar
              type="warning"
              fullWidth={true}
              open={isLimitReached}
              text={textUtils.replaceTranslationAliases(
                translations.externalPersonsInputFormError,
                { limit }
              )}
            />
          </Collapse>
        </TransitionGroup>
        {!hideInternalPersons && (
          <InternalPersonsInput
            elementId={`${elementId}-internal-persons`}
            disabled={isLimitReached}
            onChange={onInternalPersonsChange}
            persons={internalPersons}
          />
        )}
        {!hideInternalPersons && !hideExternalPersons && (
          <Divider
            orientation="horizontal"
            sx={{ '.MuiDivider-wrapper': { padding: 0 } }}
          >
            <Chip
              label="Or"
              size="small"
              variant="outlined"
              sx={() => ({
                '&.MuiChip-outlined.MuiChip-outlinedDefault': {
                  backgroundColor: 'unset',
                },
              })}
            />
          </Divider>
        )}

        {!hideExternalPersons && (
          <ExternalPersonsInput
            elementId={`${elementId}-external-persons`}
            disabled={isLimitReached}
            persons={externalPersons}
            onChange={onExternalPersonsChange}
          />
        )}
      </Stack>
    </Sheet>
  );
};

export { PersonsInputSheet };
