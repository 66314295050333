import Stack from '@mui/material/Stack';

type ArticleTabContentProps = {
  id: string;
  ariaLabelledBy: string;
  active: boolean;
  children: React.ReactNode;
};

const ArticleTabContent = ({
  id,
  ariaLabelledBy,
  active,
  children,
}: ArticleTabContentProps) => {
  return (
    <Stack
      id={id}
      role="tabpanel"
      hidden={!active}
      aria-labelledby={ariaLabelledBy}
      sx={(theme) => ({
        gap: theme.spacing('sm'),
        ...(!active && { display: 'none' }),
      })}
    >
      {active && children}
    </Stack>
  );
};

export { ArticleTabContent };
