import { DIVISION_DISPLAY_NAME_BY_ID } from 'api/news/transformers';
import { useUser } from 'components/Context/User';
import { ButtonTabs } from 'componentsNew';
import { TabItem } from 'componentsNew/ButtonTabs';
import { ArticleListSkeleton } from 'pagesAvenue/common';
import {
  ArticleList,
  type ArticlePreview,
} from 'pagesAvenue/common/ArticleList/ArticleList';
import { useMemo } from 'react';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';

import { NoArticles } from './NoArticles';
import { NoDepartment } from './NoDepartment';
import { ReadMore } from './ReadMore';

const elementId = 'home-article-list';

export enum TabName {
  Default = 'default',
  Division = 'division',
  Department = 'department',
  SecondaryDepartment = 'secondaryDepartment',
  Site = 'site',
  Global = 'global',
}

type TabArticlesProps = {
  isLoading: boolean;
  articlesByTab: Record<TabName, ArticlePreview[] | null>;
  onTabChange: (tab: TabName) => void;
  onLikeClick: (article: ArticlePreview) => void;
  onCommentClick: (article: ArticlePreview) => void;
  onArticleClick: (article: ArticlePreview) => void;
};

const TabArticles = ({
  isLoading,
  articlesByTab,
  onTabChange,
  onLikeClick,
  onCommentClick,
  onArticleClick,
}: TabArticlesProps) => {
  const user = useUser();
  const tabTruncationMaxLength = 16;

  // Default tab

  const defaultTabItem: TabItem | null = useMemo(() => {
    const articles = articlesByTab.default;
    const hasNotLoaded = !articles && !isLoading;
    const hasNoArticles = !articles?.length;

    let content: React.ReactNode = (
      <>
        <ArticleList
          id={elementId}
          articles={articles || []}
          onLikeClick={onLikeClick}
          onCommentClick={onCommentClick}
          onArticleClick={onArticleClick}
        />
        <ReadMore id={`${elementId}-read-more`} to="/feed" />
      </>
    );
    if (hasNotLoaded) content = null;
    else if (isLoading) content = <ArticleListSkeleton />;
    else if (hasNoArticles) content = <NoArticles />;

    return {
      id: TabName.Default,
      label: textUtils.truncate(
        translations.articleFeedDefault,
        tabTruncationMaxLength
      ),
      tooltip: translations.articleFeedDefault,
      content,
    };
  }, [
    articlesByTab.default,
    isLoading,
    onArticleClick,
    onCommentClick,
    onLikeClick,
  ]);

  // Division tab

  const divisionTabItem: TabItem | null = useMemo(() => {
    if (!user.divisionId || !user.divisionName) {
      return null;
    }
    const articles = articlesByTab.division;
    const hasNotLoaded = !articles && !isLoading;
    const hasNoArticles = !articles?.length;

    let content: React.ReactNode = (
      <>
        <ArticleList
          id={elementId}
          articles={articles || []}
          onLikeClick={onLikeClick}
          onCommentClick={onCommentClick}
          onArticleClick={onArticleClick}
        />
        <ReadMore
          id={`${elementId}-read-more`}
          to="/feed?localization=division"
        />
      </>
    );
    if (hasNotLoaded) content = null;
    else if (isLoading) content = <ArticleListSkeleton />;
    else if (hasNoArticles) content = <NoArticles />;

    return {
      id: TabName.Division,
      label: textUtils.truncate(
        DIVISION_DISPLAY_NAME_BY_ID[user.divisionId] || user.divisionName,
        tabTruncationMaxLength
      ),
      tooltip: user.divisionName,
      content,
    };
  }, [
    user.divisionId,
    user.divisionName,
    articlesByTab.division,
    isLoading,
    onLikeClick,
    onCommentClick,
    onArticleClick,
  ]);

  // Department tab

  const departmentTabItem: TabItem | null = useMemo(() => {
    const articles = articlesByTab.department;
    const hasNotLoaded = !articles && !isLoading;
    const hasNoArticles = !articles?.length;
    const hasNoDepartment = !user.departmentId || !user.departmentName;

    let content: React.ReactNode = (
      <>
        <ArticleList
          id={elementId}
          articles={articles || []}
          onLikeClick={onLikeClick}
          onCommentClick={onCommentClick}
          onArticleClick={onArticleClick}
        />
        <ReadMore
          id={`${elementId}-read-more`}
          to="/feed?localization=department"
        />
      </>
    );
    if (hasNoDepartment) content = <NoDepartment />;
    else if (hasNotLoaded) content = null;
    else if (isLoading) content = <ArticleListSkeleton />;
    else if (hasNoArticles) content = <NoArticles />;

    const label = hasNoDepartment
      ? translations.articleFeedDepartment
      : user.departmentName;

    if (hasNoDepartment) {
      return null;
    }

    return {
      id: TabName.Department,
      label: textUtils.truncate(label, tabTruncationMaxLength),
      tooltip: label,
      content,
    };
  }, [
    articlesByTab.department,
    isLoading,
    user.departmentId,
    user.departmentName,
    onLikeClick,
    onCommentClick,
    onArticleClick,
  ]);

  // Secondary department tab

  const secondaryDepartmentTabItem: TabItem | null = useMemo(() => {
    const articles = articlesByTab.secondaryDepartment;
    const hasNotLoaded = !articles && !isLoading;
    const hasNoArticles = !articles?.length;
    const hasNoSecondaryDepartment =
      !user.secondaryDepartmentId || !user.secondaryDepartmentName;

    let content: React.ReactNode = (
      <>
        <ArticleList
          id={elementId}
          articles={articles || []}
          onLikeClick={onLikeClick}
          onCommentClick={onCommentClick}
          onArticleClick={onArticleClick}
        />
        <ReadMore
          id={`${elementId}-read-more`}
          to="/feed?localization=secondaryDepartment"
        />
      </>
    );
    if (hasNoSecondaryDepartment) content = <NoDepartment />;
    else if (hasNotLoaded) content = null;
    else if (isLoading) content = <ArticleListSkeleton />;
    else if (hasNoArticles) content = <NoArticles />;

    const label = hasNoSecondaryDepartment
      ? translations.articleFeedDepartment
      : user.secondaryDepartmentName;

    if (hasNoSecondaryDepartment) {
      return null;
    }

    return {
      id: TabName.SecondaryDepartment,
      label: textUtils.truncate(label, tabTruncationMaxLength),
      tooltip: label,
      content,
    };
  }, [
    articlesByTab.secondaryDepartment,
    isLoading,
    user.secondaryDepartmentId,
    user.secondaryDepartmentName,
    onLikeClick,
    onCommentClick,
    onArticleClick,
  ]);

  // Site tab

  const siteTabItem: TabItem | null = useMemo(() => {
    if (!user.siteId || !user.siteName) {
      return null;
    }
    const articles = articlesByTab.site;
    const hasNotLoaded = !articles && !isLoading;
    const hasNoArticles = !articles?.length;

    let content: React.ReactNode = (
      <>
        <ArticleList
          id={elementId}
          articles={articles || []}
          onLikeClick={onLikeClick}
          onCommentClick={onCommentClick}
          onArticleClick={onArticleClick}
        />
        <ReadMore id={`${elementId}-read-more`} to="/feed?localization=site" />
      </>
    );
    if (hasNotLoaded) content = null;
    else if (isLoading) content = <ArticleListSkeleton />;
    else if (hasNoArticles) content = <NoArticles />;

    return {
      id: TabName.Site,
      label: textUtils.truncate(user.siteName, tabTruncationMaxLength),
      tooltip: user.siteName,
      content,
    };
  }, [
    user.siteId,
    user.siteName,
    articlesByTab.site,
    isLoading,
    onLikeClick,
    onCommentClick,
    onArticleClick,
  ]);

  // Global tab

  const globalTabItem: TabItem | null = useMemo(() => {
    const articles = articlesByTab.global;
    const hasNotLoaded = !articles && !isLoading;
    const hasNoArticles = !articles?.length;

    let content: React.ReactNode = (
      <>
        <ArticleList
          id={elementId}
          articles={articles || []}
          onLikeClick={onLikeClick}
          onCommentClick={onCommentClick}
          onArticleClick={onArticleClick}
        />
        <ReadMore id={`${elementId}-read-more`} to="/feed/all" />
      </>
    );
    if (hasNotLoaded) content = null;
    else if (isLoading) content = <ArticleListSkeleton />;
    else if (hasNoArticles) content = <NoArticles />;

    return {
      id: TabName.Global,
      label: textUtils.truncate(
        translations.articleFeedAll,
        tabTruncationMaxLength
      ),
      tooltip: translations.articleFeedAll,
      content,
    };
  }, [
    articlesByTab.global,
    isLoading,
    onArticleClick,
    onCommentClick,
    onLikeClick,
  ]);

  return (
    <ButtonTabs
      ariaLabel={translations.articleFeed}
      items={[
        ...(defaultTabItem ? [defaultTabItem] : []),
        ...(divisionTabItem ? [divisionTabItem] : []),
        ...(departmentTabItem ? [departmentTabItem] : []),
        ...(secondaryDepartmentTabItem ? [secondaryDepartmentTabItem] : []),
        ...(siteTabItem ? [siteTabItem] : []),
        ...(globalTabItem ? [globalTabItem] : []),
      ]}
      sx={(theme) => ({
        paddingTop: theme.spacing('sm'),
        marginTop: theme.spacing('sm'),
        borderRadius: theme.border.radius.md,
        backgroundColor: theme.colors.surface.primary,
      })}
      onChange={(e) => {
        const tab = e.currentTarget.id as TabName;
        onTabChange(tab);
      }}
    />
  );
};

export { TabArticles };
