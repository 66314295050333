import { EditorState } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { BubbleMenu, Editor, isTextSelection } from '@tiptap/react';
import { useBreakpoints } from 'hooks';
import { useCallback } from 'react';

import {
  Blockquote,
  Bold,
  BulletList,
  Heading,
  Highlight,
  Italic,
  Link,
  OrderedList,
  Underline,
} from './actions';
import { FormatMenuDivider } from './FormatMenuDivider';
import { FormatMenuWrapper } from './FormatMenuWrapper';

const FormatMenu = () => {
  const { isMobile } = useBreakpoints();

  const shouldShow = useCallback(
    (params: {
      editor: Editor;
      element: HTMLElement;
      view: EditorView;
      state: EditorState;
      oldState?: EditorState;
      from: number;
      to: number;
    }) => {
      const { editor, view, state, from, to } = params;

      if (!view.hasFocus()) {
        return false;
      }
      const { doc, selection } = state;
      const isText = isTextSelection(selection);
      if (!isText) {
        return false;
      }
      const isEmpty =
        selection.empty || (isText && doc.textBetween(from, to).length === 0);

      if (isEmpty) {
        return false;
      }
      if (editor.isActive('codeBlock')) {
        return false;
      }
      return true;
    },
    []
  );

  return (
    <BubbleMenu editor={null} shouldShow={shouldShow}>
      <FormatMenuWrapper>
        <Heading />
        <Blockquote />
        <BulletList />
        <OrderedList />
        {!isMobile && <FormatMenuDivider />}
        <Bold />
        <Italic />
        <Underline />
        <Highlight />
        {!isMobile && <FormatMenuDivider />}
        <Link />
      </FormatMenuWrapper>
    </BubbleMenu>
  );
};

export { FormatMenu };
