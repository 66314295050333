import { RichText } from 'componentsNew';
import { RichTextProps } from 'componentsNew/RichText/RichText';

type ArticleBodyProps = {
  sx?: RichTextProps['sx'];
  elementId: string;
  htmlContent: string;
  fullWidth?: boolean;
};

const ArticleBody = ({
  sx,
  elementId,
  htmlContent,
  fullWidth,
}: ArticleBodyProps) => {
  return (
    <RichText
      id={elementId}
      sx={[
        {
          maxWidth: '45rem',
          ...(fullWidth && { width: '100%', maxWidth: '100%' }),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      defaultValue={htmlContent}
      editable={false}
    />
  );
};

export { ArticleBody };
