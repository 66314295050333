import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Icon, Tooltip } from 'componentsNew';
import { useState } from 'react';
import { translations } from 'translations';
import { GAonAIRetryResultClick } from 'utils/analytics';

import { ChatItemResult } from './AIChatSheet';

type AIChatActionRetryResultProps = {
  id: string;
  chatItem: ChatItemResult;
  isLoading?: boolean;
  onRetry: (currentPage: number, prompt: string) => void;
};

const AIChatActionRetryResult = ({
  id,
  chatItem,
  isLoading,
  onRetry,
}: AIChatActionRetryResultProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  return (
    <Tooltip title={translations.aiChatRetry}>
      <Box>
        <Button
          id={id}
          variant="text"
          aria-label={translations.aiChatRetry}
          sx={(theme) => ({
            height: '2rem',
            width: '2rem',
            padding: '0 !important',
            minWidth: 'unset',
            '&.MuiButton-text:not(.Mui-disabled)': {
              border: `1px solid ${theme.colors.surface.primary}`,
              backgroundColor: theme.colors.surface.primary,
            },
            '&:hover': {
              svg: { color: theme.colors.icon.brandBase },
            },
            '&.Mui-disabled': {
              backgroundColor: theme.colors.surface.primary,
              ...(isDisabled && {
                svg: {
                  color: theme.colors.icon.brandBase,
                },
              }),
            },
          })}
          disabled={isDisabled}
          onClick={() => {
            if (isLoading) {
              return;
            }

            setIsDisabled(true);
            GAonAIRetryResultClick();
            onRetry(chatItem.result.page, chatItem.prompt);
          }}
        >
          <Icon size={16} color="secondary" type="arrowLongUp" />
        </Button>
      </Box>
    </Tooltip>
  );
};

export { AIChatActionRetryResult };
