const matchProp = {
  categories: 'category.id',
  regions: 'region.id',
  locations: 'location.id',
  seniority: 'senioritylevel.id',
  division: 'division.id',
  segment: 'segment.id',
  region: 'region.id',
  country: 'country.id',
  department: 'department.id',
  secondaryDepartment: 'secondaryDepartment.id',
  site: 'site.id',
  siteName: 'site',
  company: 'company.id',
  companyName: 'company',
  states: 'state.id',
};

const encodeValue = (value) => {
  if (value === undefined || value === null) {
    return null;
  }

  switch (typeof value) {
    case 'string':
    case 'number':
    case 'boolean':
      return encodeURIComponent(value);
    case 'object':
      if (Array.isArray(value) && value.length > 0) {
        if (value.length === 1) {
          return encodeURIComponent(value.toString());
        } else {
          return `[${encodeURIComponent(value.toString())}]`;
        }
      } else if (Object.keys(value).length) {
        const values = Object.entries(value)
          .map(([key, value]) => (value ? key : null))
          .filter(Boolean);

        if (values.length) {
          return values.length > 1
            ? `[${values.map((v) => encodeURIComponent(v)).join(',')}]`
            : encodeURIComponent(values[0]);
        }
      }
      break;
    default:
      break;
  }
  return null;
};

const makeQueryString = (obj = {}) => {
  const query = Object.entries(obj)
    .map(([key, value]) => {
      if (value === undefined || value === null) {
        return null;
      }

      if (typeof value !== 'object') {
        const encodedValue = encodeValue(value);

        if (encodedValue) {
          return `${key}=${encodedValue}`;
        }

        return null;
      }
      const entries = Object.entries(value);

      return entries.map(([childKey, value]) => {
        const encodedValue = encodeValue(value);

        if (encodedValue) {
          return `${key}[${matchProp[childKey] || childKey}]=${encodedValue}`;
        }

        return null;
      });
    })
    .reduce((a, b) => a.concat(b), [])
    .filter((x) => x);

  if (query.length) {
    return query.join('&').replace(/^/, '?');
  } else {
    return '';
  }
};

export default makeQueryString;
